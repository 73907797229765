import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { customerService, CustomerServiceContent, } from '@budbee/js/tracking';
import { css } from '@css';
import Markdown from 'react-markdown';
import { CustomerServiceItemContent } from '../customer-service-item-content';
import { styles } from './style';
const contentOverridesText = (content) => content === CustomerServiceContent.EXTERNAL_LINK;
export const CustomerServiceItem = (props) => {
    const { text, contentType } = props;
    const content = contentType
        ? customerService.itemContent(CustomerServiceItemContent, contentType, props)
        : null;
    const newText = contentOverridesText(contentType) ? null : text;
    return (_jsxs("div", { className: css(styles.container), children: [_jsx(Markdown, { className: css(styles.text), components: {
                    a(linkProps) {
                        return (_jsx("a", { className: css(styles.link), ...linkProps, children: linkProps.children }));
                    },
                }, children: newText || '' }), content ? _jsx("div", { className: css(styles.content), children: content }) : null] }));
};
