import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { getBrand } from '../../utils/brand/get-brand';
import { BrandedIcon } from '../../utils/brand/icon';
import { View } from '../shared/view';
import { styles } from './summary-item.style';
export const SummaryItem = ({ title, text, icon, children }) => {
    const brand = getBrand();
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.content, children: [icon && brand !== 'porterbuddy' && (_jsx(View, { style: styles.iconContainer, children: _jsx(BrandedIcon, { src: icon, size: {
                                budbee: 30,
                                instabox: 26,
                            } }) })), _jsx("span", { className: css(styles.title), children: title }), _jsx("span", { className: css(styles.text), children: text })] }), children] }));
};
