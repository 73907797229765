import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { Color } from '../../styles';
import { Spinner } from '../spinner';
import { styles } from './style';
/**
 * @deprecated Use BaseButton instead
 */
export const TrackingButton = (props) => {
    const { variant = 'large', title, onClick, disabled, loading, discrete } = props;
    return (_jsx("button", { className: css(styles.root, disabled && styles.disabled, discrete && styles.discrete, variant === 'large' && styles.large, variant === 'small' && styles.small), type: "button", onClick: onClick, disabled: disabled, children: loading ? (_jsx(Spinner, { color: Color.White })) : (_jsx("span", { className: css(styles.title, discrete && styles.titleDiscrete), children: title })) }));
};
