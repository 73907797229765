import { connect } from 'react-redux';
import { Rebook } from '../../../components/steps/rebook';
import { afterBookConsignment } from '../../../state/order/actions';
import { getAuthToken, getToken } from '../../../state/order/selectors';
import { registerPreliminaryPayment, clearPaymentRequest } from '../../../state/payment/actions';
import { fetchTimeWindows } from '../../../state/timetable/actions';
import { getIsFetchingTimeWindows, getTimeWindows } from '../../../state/timetable/selectors';
import { injectIntl } from '../../../utils/i18n';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
    authToken: getAuthToken(state),
    timeWindows: getTimeWindows(state),
    isFetchingTimeWindows: getIsFetchingTimeWindows(state),
});
const mapDispatchToProps = (dispatch) => ({
    afterBookConsignment: (response, timeWindow) => dispatch(afterBookConsignment(response, timeWindow)),
    fetchTimeWindows: (token) => dispatch(fetchTimeWindows(token)),
    registerPreliminaryPayment: () => dispatch(registerPreliminaryPayment()),
    clearPaymentRequest: () => dispatch(clearPaymentRequest()),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps), injectIntl);
export const RebookContainer = decorate(Rebook);
