import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { connect } from 'react-redux';
import { BankIdRedirect } from '../../components/bankid-redirect';
import { setLocaleForRedirect as setLocaleForRedirectAction } from '../../state/locale/actions';
class BankIdRedirectRoute extends Component {
    componentDidMount() {
        const { setLocaleForRedirect } = this.props;
        // BankID is only used for SE so it could not be another country
        setLocaleForRedirect('SE');
    }
    render() {
        const { token } = this.props.match.params;
        return _jsx(BankIdRedirect, { token: token });
    }
}
const mapDispatchToProps = (dispatch) => ({
    setLocaleForRedirect: (countryCode) => dispatch(setLocaleForRedirectAction(countryCode)),
});
export default connect(null, mapDispatchToProps)(BankIdRedirectRoute);
