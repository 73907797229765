import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const ActionListItem = (props) => {
    const { icon, leftTitle, rightTitle, onPress, style, contentContainerStyle, disabled = false, variant = 'rightArrow', } = props;
    const isDisabled = disabled || !onPress;
    const rightTitleContent = typeof rightTitle === 'string' || typeof rightTitle === 'number' ? (_jsx("p", { className: css(styles.rightContentContainer, styles.rightTitle), children: rightTitle })) : (_jsx(View, { style: styles.rightContentContainer, children: rightTitle }));
    return (_jsx(View, { style: [styles.root, style], children: _jsxs("button", { type: "button", className: css(isDisabled ? styles.containerDisabled : styles.container, contentContainerStyle), onClick: onPress, disabled: isDisabled, children: [_jsxs(View, { style: styles.iconTextWrapper, children: [icon && (_jsx(View, { className: css(styles.leftIconContainer), children: _jsx(Icon, { color: Color.Budbee.GreenDark, src: icon }) })), _jsx("p", { className: css(styles.leftTitle), children: leftTitle })] }), _jsxs(View, { style: styles.rightContainer, children: [rightTitle ? rightTitleContent : null, !isDisabled ? (_jsx(View, { className: css(styles.rightIconContainer), children: _jsx(Icon, { size: IconSize.lg, src: variant === 'rightArrow' ? imageList.chevronRight : imageList.Edit }) })) : null] })] }) }));
};
