import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { hooks } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useDateFormat } from '../../../hooks/use-date-format';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useBoxOrder } from '../../../state/box-order/hooks';
import imageList from '../../../utils/image-list';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import { ErrorMessage } from '../../error-message';
import { RadioButton } from '../../radio-button';
import { Loader } from '../../shared/loader';
import { SubTitle } from '../../subtitle';
import { Title } from '../../title';
import { styles } from './style';
export const RebookExpiredBoxDelivery = (props) => {
    const { authToken, canContinueChanged, onContinue } = props;
    const { t } = useI18n();
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const trackingApi = useTrackingApi();
    const format = useDateFormat();
    const boxOrder = useBoxOrder();
    const { token } = boxOrder || {};
    const [pickupRoutes, setPickupRoutes] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        canContinueChanged(Boolean(selectedRoute));
    }, [canContinueChanged, selectedRoute]);
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        setLoading(true);
        trackingApi
            .getBoxPickupRoutes(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                setLoading(false);
                setPickupRoutes(response.payload.pickupRoutes);
            }
            else {
                setError(true);
            }
        })
            .catch(() => setError(true));
    }, [authToken, token, trackingApi]);
    useEffect(() => {
        onContinue((next) => {
            if (!token || !authToken || !selectedRoute) {
                return;
            }
            trackingApi
                .bookNewBoxDelivery(token, authToken, selectedRoute.routeId, selectedRoute.routeName)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    refreshTrackingPage();
                    next();
                    return;
                }
                setError(true);
            })
                .catch(() => {
                setError(true);
            });
        });
    }, [authToken, onContinue, selectedRoute, token, trackingApi]);
    if (error) {
        return _jsx(ErrorMessage, {});
    }
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    return (_jsxs("div", { children: [_jsx(Title, { title: t('rebookBoxDelivery'), style: styles.title }), _jsx(SubTitle, { text: t('rebookBoxDeliverySubtitle'), style: styles.subtitle }), pickupRoutes === null || pickupRoutes === void 0 ? void 0 : pickupRoutes.map((route, i) => {
                const toggled = selectedRoute === route;
                return (_jsx(RadioButton, { icon: imageList.Clock, toggled: toggled, style: styles.radioButton, title: format.formatDateTimeWithTilde(route.startAt) || route.startAt, onPress: () => setSelectedRoute(route) }, getKey({ i })));
            })] }));
};
