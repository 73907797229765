import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable func-names */
/* eslint-disable no-console */
import { useMemo, useEffect, useState, useContext, useCallback, useRef, createContext, } from 'react';
const NO_INTERCOM_PROVIDER_MESSAGE = 'Please wrap your component with `IntercomProvider`.';
const initialState = {
    boot: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    shutdown: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    update: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    hide: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    show: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    showMessages: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    showNewMessages: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    getVisitorId: () => {
        console.log('error', NO_INTERCOM_PROVIDER_MESSAGE);
        return '';
    },
    startTour: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
    trackEvent: () => console.log('error', NO_INTERCOM_PROVIDER_MESSAGE),
};
const IntercomContext = createContext(initialState);
// this function is taken from intercom web docs
// and is intended to be put in a script tag, but
// we don't want to load the app right away.
// Script taken from https://developers.intercom.com/installing-intercom/web/installation#single-page-app
function loadIntercom(appId, settings) {
    const intercomAPI = window.Intercom;
    if (typeof intercomAPI === 'function') {
        intercomAPI('reattach_activator');
        intercomAPI('update', settings);
    }
    else {
        const i = function (...args) {
            i.c(args);
        };
        i.q = [];
        i.c = function (args) {
            i.q.push(args);
        };
        window.Intercom = i;
        const load = () => {
            var _a;
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${appId}`;
            const x = document.getElementsByTagName('script')[0];
            (_a = x.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
            load();
        }
        else {
            window.addEventListener('load', load, false);
        }
    }
}
export const IntercomProvider = ({ appId, children, }) => {
    const [settings, setSettings] = useState({
        app_id: appId,
        api_base: 'https://api-iam.eu.intercom.io',
        hide_default_launcher: true,
    });
    const booted = useRef(false);
    useEffect(() => {
        if (appId) {
            const newSettings = {
                ...settings,
                app_id: appId,
            };
            setSettings(newSettings);
            loadIntercom(appId, newSettings);
        }
    }, [appId]);
    const IntercomAPI = useCallback((command, ...params) => {
        return window.Intercom(command, ...params);
    }, []);
    const ensureIntercom = useCallback((_functionName, callback) => {
        if (!booted.current) {
            return null;
        }
        return callback();
    }, []);
    const boot = useCallback((bootSettings) => {
        if (booted.current) {
            return;
        }
        const newSettings = { ...settings, ...bootSettings };
        IntercomAPI('boot', newSettings);
        setSettings(newSettings);
        booted.current = true;
    }, [settings, IntercomAPI]);
    const shutdown = useCallback(() => {
        if (!booted.current)
            return;
        IntercomAPI('shutdown');
        booted.current = false;
    }, [IntercomAPI]);
    const refresh = useCallback(() => {
        ensureIntercom('update', () => {
            const lastRequestedAt = new Date().getTime();
            IntercomAPI('update', { last_requested_at: lastRequestedAt });
        });
    }, [ensureIntercom, IntercomAPI]);
    const update = useCallback((updateProps) => {
        ensureIntercom('update', () => {
            if (!appId) {
                refresh();
                return;
            }
            const newSettings = { ...settings, ...updateProps };
            IntercomAPI('update', newSettings);
            setSettings(newSettings);
        });
    }, [ensureIntercom, refresh, IntercomAPI, setSettings, settings, appId]);
    const hide = useCallback(() => {
        ensureIntercom('hide', () => IntercomAPI('hide'));
    }, [ensureIntercom, IntercomAPI]);
    const show = useCallback(() => {
        ensureIntercom('show', () => IntercomAPI('show'));
    }, [ensureIntercom, IntercomAPI]);
    const showMessages = useCallback(() => {
        ensureIntercom('showMessages', () => IntercomAPI('showMessages'));
    }, [ensureIntercom, IntercomAPI]);
    const showNewMessages = useCallback((message) => {
        ensureIntercom('showNewMessage', () => {
            if (!message) {
                IntercomAPI('showNewMessage');
            }
            else {
                IntercomAPI('showNewMessage', message);
            }
        });
    }, [ensureIntercom, IntercomAPI]);
    const getVisitorId = useCallback(() => {
        var _a;
        return (_a = ensureIntercom('getVisitorId', () => IntercomAPI('getVisitorId'))) !== null && _a !== void 0 ? _a : '';
    }, [ensureIntercom, IntercomAPI]);
    const startTour = useCallback((tourId) => {
        ensureIntercom('startTour', () => IntercomAPI('startTour', tourId));
    }, [ensureIntercom, IntercomAPI]);
    const trackEvent = useCallback((...args) => {
        ensureIntercom('trackEvent', () => IntercomAPI('trackEvent', ...args));
    }, [ensureIntercom, IntercomAPI]);
    const providerValue = useMemo(() => {
        return {
            boot,
            shutdown,
            update,
            hide,
            show,
            showMessages,
            showNewMessages,
            getVisitorId,
            startTour,
            trackEvent,
        };
    }, [
        boot,
        shutdown,
        update,
        hide,
        show,
        showMessages,
        showNewMessages,
        getVisitorId,
        startTour,
        trackEvent,
    ]);
    return _jsx(IntercomContext.Provider, { value: providerValue, children: children });
};
export function useIntercom() {
    return useContext(IntercomContext);
}
