import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { useOrderHeroProps } from './use-order-hero-props';
import { styles } from '../box-header/style';
export function RegisteredBoxHeader({ authenticated, merchantName, distributionCity, needsVerification, canSwitchLocker, canSwitchDeliveryType, toggleIdentificationModal, toggleSwitchDeliveryTypeModal, returnEnabled, toggleReturnsModal, missingReason, }) {
    const { t } = useI18n();
    const orderHeroProps = useOrderHeroProps({
        returnEnabled,
        missingReason,
        needsVerification,
        canSwitchLocker,
        distributionCity,
        merchantName,
    });
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { ...orderHeroProps }), !!authenticated && (_jsxs(View, { style: styles.actionsWrapper, children: [!!needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })), canSwitchDeliveryType && !needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { toggleSwitchDeliveryTypeModal: toggleSwitchDeliveryTypeModal }) })), !!returnEnabled && (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: "primary" }) }))] }))] }));
}
