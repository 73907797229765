import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
const ICON_CONTAINER_WIDTH = Space['5xl'];
export const styles = StyleSheet.create({
    root: {
        width: '100%',
        backgroundColor: Color.White100,
        flexShrink: 1,
    },
    content: {
        paddingLeft: ICON_CONTAINER_WIDTH,
        porterbuddy: {
            paddingLeft: 0,
        },
    },
    iconContainer: {
        position: 'absolute',
        left: 0,
        width: ICON_CONTAINER_WIDTH,
        justifyContent: 'center',
        height: Space['6xl'],
        marginLeft: Space.md,
    },
    title: {
        ...Typography.Budbee.Web.h5,
        instabox: {
            ...Typography.Instabox.Web.h5,
        },
    },
    text: {
        marginTop: Space.sm,
        ...Typography.Budbee.Web['body-lg'],
        instabox: {
            ...Typography.Instabox.Web['body-lg'],
        },
    },
});
