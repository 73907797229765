import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { hooks, } from '@budbee/js/tracking';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { View } from '../shared/view';
import { styles } from './style';
export const ParcelDeliveryOverview = (props) => {
    const { deliveryOverview } = props;
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const { t } = useI18n();
    const brand = getBrand();
    if (!deliveryOverview) {
        return null;
    }
    if ((deliveryOverview === null || deliveryOverview === void 0 ? void 0 : deliveryOverview.parcels.length) <= 1 && brand === 'budbee') {
        return null;
    }
    return (_jsxs(View, { style: styles.root, children: [brand !== 'porterbuddy' && (_jsx(View, { style: styles.leftContainer, children: _jsx(BrandedIcon, { src: {
                        budbee: imageList.Fingerprint,
                        instabox: imageList.FingerprintInstabox,
                    }, size: IconSize['3xl'], color: Color.Budbee.Grey5 }) })), _jsxs(View, { style: styles.rightContainer, children: [brand === 'budbee' && (_jsx("span", { className: css(styles.header), children: t('order.multipleParcels.info') })), deliveryOverview.parcels.map((parcel) => (_jsxs(View, { style: styles.textContainer, children: [brand !== 'budbee' && (_jsx("span", { className: css(styles.topText), children: t('parcelIdTitle') })), _jsx("span", { className: css(styles.title), children: parcel.title }), _jsx("span", { className: css(styles.description), children: parcel.description })] }, getKey(parcel))))] })] }));
};
