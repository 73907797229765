import { ConsignmentType } from '@budbee/js/dist/tracking';
import { createSelector } from '@reduxjs/toolkit';
export const getBoxOrder = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder.boxOrderInfo);
export const getIsFetchingBoxOrder = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder.isFetchingBoxOrder);
export const getBoxOrderStatus = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.status; });
export const getBoxOrderConsignment = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.consignment; });
export const getBoxOrderAuthorized = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return ((_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.authorized) || false; });
export const getLockerAttributes = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.lockerAttributes; });
export const getConsumerAddressBox = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.consumerAddress; });
export const getBoxCheckpoints = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxCheckpoints);
export const getBoxNudges = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.nudges; });
export const getBoxCode = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.code);
export const getBoxNeedsVerification = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.needsVerification);
export const getIsBoxReturn = createSelector((state) => state.boxOrder, (boxOrder) => { var _a, _b; return Boolean(((_b = (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.consignment) === null || _b === void 0 ? void 0 : _b.type) === ConsignmentType.RETURN); });
export const getBoxSystem = createSelector((state) => state.boxOrder, (boxOrder) => boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.system);
