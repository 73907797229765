import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { Color, Spacing } from '../../styles';
import { inlineStyles } from '../../utils/inline-styles';
import { styles } from './style';
export const Spinner = ({ color = Color.Primary, size = Spacing.md }) => {
    return (_jsx("div", { className: css(styles.loader, inlineStyles({
            borderColor: color,
            borderTopColor: 'transparent',
            width: size,
            height: size,
        })) }));
};
