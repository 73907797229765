import { Color } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, BorderRadius, marginHorizontal, IconSize, MediaQuery } from '../../styles';
export const styles = StyleSheet.create({
    navigation: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: Spacing.xl,
    },
    arrowButton: {
        background: 'none',
        border: 0,
        padding: 0,
    },
    clickable: {
        cursor: 'pointer',
    },
    dotContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...marginHorizontal(Spacing['6xl']),
        [MediaQuery.maxWidthXs]: {
            ...marginHorizontal(Spacing['2xl']),
        },
    },
    dot: {
        width: IconSize['3xs'],
        height: IconSize['3xs'],
        backgroundColor: Color.Budbee.Grey2,
        borderRadius: BorderRadius['2xl'],
    },
    activeDot: {
        width: IconSize.xs,
        height: IconSize.xs,
        backgroundColor: Color.Black100,
    },
    activeColor: {
        backgroundColor: Color.Black100,
    },
    spacingHorizontal: {
        ...marginHorizontal(Spacing.md),
        paddingBottom: Spacing.md,
    },
    spacingRight: {
        marginRight: Spacing['2xl'],
    },
});
