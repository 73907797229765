import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    container: {
        gap: Space['7xl'],
        porterbuddy: {
            marginBottom: -Space.lg,
        },
    },
    screenTitle: {
        marginTop: 0,
        ...marginHorizontal(Space.sm),
        ...Typography.Budbee.Web.h3,
        instabox: {
            ...Typography.Instabox.Web.h2,
        },
    },
    input: {
        backgroundColor: Color.White100,
        textAlign: 'start',
        padding: Space.lg,
        borderRadius: Border.Radius.sm,
        height: 64,
        porterbuddy: {
            height: 32,
            marginTop: Space['2xl'],
            backgroundColor: 'inherit',
            padding: 0,
            borderRadius: 0,
            borderBottom: `${Border.Width.md}px solid ${Color.Porterbuddy.AmigoPurple}`,
        },
    },
    title: {
        ...Typography.Budbee.Web.h3,
        ...marginHorizontal(Space.sm),
        marginTop: 0,
        porterbuddy: {
            display: 'none',
        },
        instabox: {
            ...Typography.Instabox.Web.h2,
        },
    },
    inputContentContainer: {
        padding: 0,
    },
});
