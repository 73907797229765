export const REQUEST_LOCKERS = 'REQUEST_LOCKERS';
export const REQUEST_LOCKERS_SUCCESS = 'REQUEST_LOCKERS_SUCCESS';
export const REQUEST_LOCKERS_ERROR = 'REQUEST_LOCKERS_ERROR';
export const SELECT_LOCKER = 'SELECT_LOCKER';
export const REQUEST_LOCKERS_FOR_MAP = 'REQUEST_LOCKERS_FOR_MAP';
export const REQUEST_LOCKERS_FOR_MAP_SUCCESS = 'REQUEST_LOCKERS_FOR_MAP_SUCCESS';
export const REQUEST_LOCKERS_FOR_MAP_ERROR = 'REQUEST_LOCKERS_FOR_MAP_ERROR';
export const SELECT_LOCKER_FROM_MAP = 'SELECT_LOCKER_FROM_MAP';
export const SET_IS_PICKING_FROM_MAP = 'SET_IS_PICKING_FROM_MAP';
export const RESET_LOCKERS_STATE = 'RESET_LOCKERS_STATE';
export const SET_IS_SEARCHING_FOR_PLACES = 'SET_IS_SEARCHING_FOR_PLACES';
export const SET_SEARCH_PLACE_KEYWORD = 'SET_SEARCH_PLACE_KEYWORD';
export const SET_PLACE_SEARCH_RESULTS = 'SET_PLACE_SEARCH_RESULTS';
export const SET_LOCKERS_FOR_SEARCHED_PLACE = 'SET_LOCKERS_FOR_SEARCHED_PLACE';
export const SET_SELECTED_SEARCH_RESULT = 'SET_SELECTED_SEARCH_RESULT';
export const RESET_LOCKER_SEARCH_STATE = 'RESET_LOCKER_SEARCH_STATE';
export const setIsPickingFromMap = (isPickingFromMap) => {
    return {
        type: SET_IS_PICKING_FROM_MAP,
        payload: {
            isPickingFromMap,
        },
    };
};
export const requestLockers = () => {
    return {
        type: REQUEST_LOCKERS,
    };
};
export const requestLockersSuccess = (lockers) => {
    return {
        type: REQUEST_LOCKERS_SUCCESS,
        payload: {
            lockers,
        },
    };
};
export const requestLockersError = (error) => {
    return {
        type: REQUEST_LOCKERS_ERROR,
        payload: {
            error,
        },
    };
};
export const selectLocker = (id) => {
    return {
        type: SELECT_LOCKER,
        payload: {
            id,
        },
    };
};
export const selectLockerFromMap = (lockerDetails) => ({
    type: SELECT_LOCKER_FROM_MAP,
    payload: {
        lockerDetails,
    },
});
export const requestLockersForMap = () => {
    return {
        type: REQUEST_LOCKERS_FOR_MAP,
    };
};
export const requestLockersForMapSuccess = (lockers) => {
    return {
        type: REQUEST_LOCKERS_FOR_MAP_SUCCESS,
        payload: {
            lockers,
        },
    };
};
export const requestLockersForMapError = (error) => {
    return {
        type: REQUEST_LOCKERS_FOR_MAP_ERROR,
        payload: {
            error,
        },
    };
};
export const resetLockerState = () => {
    return {
        type: RESET_LOCKERS_STATE,
    };
};
export const setIsSearchingForPlaces = (isSearching) => {
    return {
        type: SET_IS_SEARCHING_FOR_PLACES,
        payload: {
            isSearching,
        },
    };
};
export const setSearchPlaceKeyword = (keyword) => {
    return {
        type: SET_SEARCH_PLACE_KEYWORD,
        payload: {
            keyword,
        },
    };
};
export const setPlaceSearchResults = (placeSearchResults) => {
    return {
        type: SET_PLACE_SEARCH_RESULTS,
        payload: {
            placeSearchResults,
        },
    };
};
export const setLockersForSearchedPlace = (lockerSearchResults) => {
    return {
        type: SET_LOCKERS_FOR_SEARCHED_PLACE,
        payload: { lockerSearchResults },
    };
};
export const setSelectedSearchResult = (selectedSearchResult) => {
    return {
        type: SET_SELECTED_SEARCH_RESULT,
        payload: {
            selectedSearchResult,
        },
    };
};
export const resetLockerSearchState = () => {
    return {
        type: RESET_LOCKER_SEARCH_STATE,
    };
};
