import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    container: {
        gap: Space.lg,
        porterbuddy: {
            gap: Space.xs,
        },
        instabox: {
            gap: Space.xs,
        },
    },
    title: {
        ...Typography.Budbee.Web.h3,
        paddingLeft: Space.sm,
        paddingRight: Space.sm,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h2,
            paddingLeft: 0,
        },
        instabox: {
            ...Typography.Instabox.Web.h2,
        },
    },
    optionsContainer: {
        flexDirection: 'column',
        gap: Space.md,
        backgroundColor: Color.White100,
        paddingLeft: Space['2xl'],
        paddingRight: Space['2xl'],
        paddingTop: Space.lg,
        paddingBottom: Space.lg,
        borderRadius: Border.Radius.sm,
        porterbuddy: {
            backgroundColor: 'inherit',
            paddingLeft: 0,
            paddingRight: 0,
            gap: Space.sm,
        },
        instabox: {
            backgroundColor: 'inherit',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    optionContainer: {
        gap: Space.md,
    },
});
