import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Space } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { BaseButton } from '../base-button';
import { ExpandingView } from '../shared/expanding-view';
import { View } from '../shared/view';
import { styles } from './style';
export const ParcelJourney = (props) => {
    const { toggled, onToggle, events, renderEvent } = props;
    const { t } = useI18n();
    const brand = getBrand();
    const renderFirstEvent = useCallback(() => {
        const stepToRender = events[0];
        if (!stepToRender) {
            return null;
        }
        return renderEvent(stepToRender, 0);
    }, [events, renderEvent]);
    const renderSubsequentEvents = useCallback(() => {
        return events.filter((_e, i) => i !== 0).map((event, index) => renderEvent(event, index + 1));
    }, [events, renderEvent]);
    if (!events || events.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('parcelJourney') }), _jsxs(View, { style: [
                    styles.root,
                    events.length > 1 ? inlineStyles({ paddingBottom: Space.lg }) : undefined,
                ], children: [renderFirstEvent(), _jsx(ExpandingView, { open: toggled, children: renderSubsequentEvents() }), events.length > 1 ? (_jsx(BaseButton, { iconSrc: toggled ? imageList.Minus : imageList.Plus, label: toggled ? t('showLess') : t('showMore'), onClick: onToggle, style: brand === 'porterbuddy' ? undefined : styles.button, size: "md", variant: "secondary" })) : undefined] })] }));
};
