import { useEffect } from 'react';
import { AccessModeType, EstateType, preface } from '@budbee/js/tracking';
import { getBrand } from '../../../../utils/brand/get-brand';
import { matchPattern } from '../../../../utils/match';
const canContinuePatterns = [
    ({ estateType }) => {
        return estateType === EstateType.HOUSE;
    },
    ({ estateType, floor, accessMode, doorCode }) => {
        return Boolean(estateType &&
            preface.isApartment(estateType) &&
            floor !== null &&
            accessMode === AccessModeType.DOOR_CODE &&
            doorCode);
    },
    ({ estateType, floor, accessMode, intercom }) => {
        return Boolean(estateType &&
            preface.isApartment(estateType) &&
            floor !== null &&
            accessMode === AccessModeType.INTERCOM &&
            intercom);
    },
    ({ estateType, floor, accessMode }) => {
        return Boolean(estateType &&
            preface.isApartment(estateType) &&
            floor !== null &&
            accessMode === AccessModeType.NONE);
    },
];
export function useCanContinue({ state, canContinueChanged, }) {
    const brand = getBrand();
    const canContinue = matchPattern({ patterns: canContinuePatterns, input: state });
    const canContinuePB = Boolean(state.estateType);
    const nextStepIsAllowed = brand === 'porterbuddy' ? canContinuePB : canContinue;
    useEffect(() => {
        canContinueChanged(nextStepIsAllowed);
    }, [nextStepIsAllowed]);
}
