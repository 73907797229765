import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { date, price as priceUtils } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { styles } from './style';
export const TimeWindowItem = (props) => {
    const { timetableTimeWindow, selected, countryCode, onChange } = props;
    const { start, stop, price } = timetableTimeWindow.timeWindow;
    const { t } = useI18n();
    const brand = getBrand();
    const freeText = brand === 'porterbuddy' ? '' : t('free');
    return (_jsx("button", { type: "button", className: css(styles.buttonDefault, selected ? styles.buttonSelected : undefined), onClick: () => onChange(timetableTimeWindow), children: _jsxs("div", { className: css(styles.timeSlotContent), children: [_jsxs("div", { className: css(styles.container), children: [brand === 'porterbuddy' && (_jsx("div", { className: css(styles.icon), children: _jsx(Icon, { src: selected ? imageList.CheckmarkPurple : imageList.PorterbuddyCheckmarUnselected, size: IconSize.xl }) })), _jsx("span", { className: css(selected ? styles.titleSelected : undefined), children: `${date.formatTimeSlotTime(start, countryCode)}–${date.formatTimeSlotTime(stop, countryCode)}` })] }), _jsxs("div", { className: css(styles.container), children: [_jsx("span", { className: css(styles.price), children: price ? priceUtils.formatPrice(price) : freeText }), selected && brand !== 'porterbuddy' ? (_jsx(BrandedIcon, { src: {
                                budbee: imageList.Checkmark,
                            }, size: IconSize.xl })) : null] })] }) }));
};
