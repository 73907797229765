import { connect } from 'react-redux';
import { ReturnSelectDate } from '../../../components/steps/return-select-date';
import { afterBookConsignment } from '../../../state/order/actions';
import { getToken } from '../../../state/order/selectors';
import { registerPreliminaryPayment, clearPaymentRequest } from '../../../state/payment/actions';
import { getReturnParcelCount } from '../../../state/returns/selectors';
import { fetchTimeWindows } from '../../../state/timetable/actions';
import { getTimeWindows, getIsFetchingTimeWindows } from '../../../state/timetable/selectors';
const mapStateToProps = (state) => ({
    token: getToken(state),
    timeWindows: getTimeWindows(state),
    isFetchingTimeWindows: getIsFetchingTimeWindows(state),
    returnParcelCount: getReturnParcelCount(state),
});
const mapDispatchToProps = (dispatch) => ({
    afterBookConsignment: (response, timeWindow) => dispatch(afterBookConsignment(response, timeWindow)),
    fetchTimeWindows: (token) => dispatch(fetchTimeWindows(token)),
    registerPreliminaryPayment: () => dispatch(registerPreliminaryPayment()),
    clearPaymentRequest: () => dispatch(clearPaymentRequest()),
});
export const ReturnSelectDateContainer = connect(mapStateToProps, mapDispatchToProps)(ReturnSelectDate);
