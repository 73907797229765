import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { styles } from '../box-header/style';
export const UndeliveredBoxHeader = ({ authenticated, toggleRebookExpiredBoxDeliveryModal, }) => {
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('undelivered.topText'), middleText: t('undelivered.middleText'), bottomText: t('undelivered.bottomText') }), authenticated ? (_jsx(View, { style: styles.actionsWrapper, children: _jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('rebookBoxDelivery'), iconSrc: imageList.Truck, onClick: toggleRebookExpiredBoxDeliveryModal, variant: "primary" }) }) })) : null] }));
};
