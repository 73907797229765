import Cookies from 'universal-cookie';
export const ACCEPT_COOKIE_POLICY = 'ACCEPT_COOKIE_POLICY';
export const CHECK_IF_COOKIES_ACCEPTED = 'CHECK_IF_COOKIES_ACCEPTED';
export const setCookiesAccepted = () => {
    const today = new Date();
    const cookieExpireYear = new Date(today.setFullYear(today.getFullYear() + 5));
    const cookies = new Cookies();
    cookies.set('seenCookie', true, { path: '/', expires: cookieExpireYear });
    return {
        type: ACCEPT_COOKIE_POLICY,
    };
};
export const checkIfCookiesAccepted = () => {
    const cookies = new Cookies();
    const seenCookie = cookies.get('seenCookie');
    return {
        type: CHECK_IF_COOKIES_ACCEPTED,
        payload: {
            accepted: Boolean(seenCookie),
        },
    };
};
