import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ConsignmentType, timetable as timetableUtils, } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useI18n } from '../../../hooks/use-i18n';
import { useToggledState } from '../../../hooks/use-toggled-state';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useConspectus } from '../../../state/order/hooks';
import { getBrand } from '../../../utils/brand/get-brand';
import { consignmentToDeliveryWindow } from '../../../utils/consignment-to-delivery-window';
import { ErrorMessage } from '../../error-message';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { SubTitle } from '../../subtitle';
import { TimeWindowSelector } from '../../time-window-selector';
import { Title } from '../../title';
import { styles } from './style';
const getDefaultDeliveryWindow = (timeWindows) => {
    const bookedTimeWindow = timeWindows.find((timeWindow) => timeWindow.booked);
    return bookedTimeWindow || null;
};
export const Timetable = ({ token, authToken, pendingTimeWindow, onContinue, canContinueChanged, timeWindows, returnParcelCount, isFetchingTimeWindows, afterBookConsignment, fetchTimeWindows, }) => {
    var _a;
    const { t } = useI18n();
    const [currentDeliveryWindow, onDeliveryWindowChange] = useToggledState(null);
    const trackingApi = useTrackingApi();
    const [error, setError] = useState(false);
    const brand = getBrand();
    const conspectus = useConspectus();
    const isReturn = ((_a = conspectus === null || conspectus === void 0 ? void 0 : conspectus.consignment) === null || _a === void 0 ? void 0 : _a.type) === ConsignmentType.RETURN;
    const deliveryWindow = conspectus && conspectus.consignment
        ? consignmentToDeliveryWindow(conspectus.consignment)
        : undefined;
    useEffect(() => {
        if (!token) {
            return;
        }
        fetchTimeWindows(token);
    }, []);
    useEffect(() => {
        if (timeWindows) {
            onDeliveryWindowChange(getDefaultDeliveryWindow(timeWindows));
        }
    }, [timeWindows]);
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        onContinue((next, onError) => {
            const request = isReturn
                ? trackingApi.bookReturn(authToken, token, currentDeliveryWindow, returnParcelCount).call()
                : trackingApi.bookConsignment(authToken, token, currentDeliveryWindow).call();
            request
                .then((response) => {
                if (response.status === ResponseStatus.FAILED) {
                    setError(true);
                    onError();
                    onDeliveryWindowChange(null);
                    if (!token) {
                        return;
                    }
                    fetchTimeWindows(token);
                }
                else {
                    afterBookConsignment(response, currentDeliveryWindow, true);
                    next();
                }
            })
                .catch(() => {
                setError(true);
                onError();
            });
        });
    }, [currentDeliveryWindow]);
    useEffect(() => {
        canContinueChanged(currentDeliveryWindow !== null);
    }, [currentDeliveryWindow]);
    if (timeWindows === null) {
        return null;
    }
    const bookableTimeWindows = timetableUtils.filterValidTimeWindows(timeWindows);
    const emptyTimeslotsResults = timeWindows.length === 0 && !isFetchingTimeWindows;
    if (error || emptyTimeslotsResults) {
        return (_jsx(ErrorMessage, { message: emptyTimeslotsResults ? t('timetable.noTimeslotsAvailable') : t('generalErrorMessage'), positionVariant: "inline" }));
    }
    if (isFetchingTimeWindows) {
        return (_jsx(View, { style: styles.loader, children: _jsx(Loader, {}) }));
    }
    return (_jsxs(View, { children: [brand !== 'porterbuddy' && (_jsx(Title, { title: t(isReturn ? 'timetable.selectTimeOfPickup' : 'timetable.selectTimeOfDelivery'), style: styles.title })), isReturn || brand === 'porterbuddy' ? null : (_jsx(SubTitle, { text: t('timetable.freeTimeslotInfo'), style: styles.subtitle })), _jsx(TimeWindowSelector, { timetableTimeWindows: bookableTimeWindows, deliveryWindow: pendingTimeWindow ? pendingTimeWindow.timeWindow : deliveryWindow, countryCode: (conspectus === null || conspectus === void 0 ? void 0 : conspectus.address.countryCode) || '', days: timetableUtils.filteredTimetableDays(timeWindows), onChange: onDeliveryWindowChange, value: currentDeliveryWindow })] }));
};
