import { StyleSheet } from '@css';
import { Color, BorderWidth, border } from '../../styles';
export const styles = StyleSheet.create({
    square: {
        objectFit: 'contain',
    },
    circle: {
        borderRadius: '50%',
        objectFit: 'cover',
        ...border(BorderWidth.sm, 'solid', Color.BorderColor),
    },
});
