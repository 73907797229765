import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { OrderType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useBoxSystem } from '../../../state/box-order/hooks';
import { useIntegrityPolicyLink, useOrderType } from '../../../state/order/hooks';
import imageList from '../../../utils/image-list';
import { BankIdRedirect } from '../../bankid-redirect';
import { BaseButton } from '../../base-button';
import { ErrorMessage } from '../../error-message';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { styles } from './style';
export const BankIdMethod = ({ onNext, orderToken, authToken, onContinue, canContinueChanged, }) => {
    const integrityPolicyLink = useIntegrityPolicyLink();
    const orderType = useOrderType();
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [initiated, setInitiated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        canContinueChanged(true);
        onContinue((next) => next());
    }, []);
    const onVerify = useCallback(() => {
        if (!authToken) {
            return;
        }
        setLoading(true);
        trackingApi
            .identifyConsumer(authToken, orderToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                const { url, criipto } = response.payload;
                if (criipto) {
                    window.location.href = criipto.authorizationUrl;
                    return;
                }
                // @ts-expect-error Location expected, not string
                window.location = url;
                setInitiated(true);
                setError(false);
            }
            else {
                setError(true);
            }
            setLoading(false);
        })
            .catch((err) => {
            setError(true);
            setLoading(false);
            Sentry.captureException(err);
        });
    }, [authToken, orderToken, trackingApi]);
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    if (initiated) {
        return _jsx(BankIdRedirect, { token: orderToken });
    }
    return (_jsxs("div", { className: css(styles.container), children: [_jsx("span", { className: css(styles.header), children: t('identifyWithBankIDHeader') }), _jsx("p", { className: css(styles.subtitle), children: t(orderType === OrderType.BOX
                    ? 'youWillReceiveAVerifcationCodeForBox'
                    : 'youWillReceiveAVerifcationCode') }), integrityPolicyLink && (_jsxs("p", { className: css(styles.privacyNotice), children: [t('informationRegardingPrivacy'), "\u00A0", _jsx("a", { href: integrityPolicyLink, className: css(styles.link), children: t('privacyNotice') }), "."] })), _jsx(BankIdButtons, { isError: !!error, onNext: onNext, onVerify: onVerify })] }));
};
const BankIdButtons = ({ onNext, isError, onVerify }) => {
    const { t } = useI18n();
    const system = useBoxSystem();
    if (isError) {
        return (_jsx(View, { style: styles.errorWrapper, children: _jsx(ErrorMessage, { message: t('bankIdTryLater'), positionVariant: "inline" }) }));
    }
    return (_jsxs("div", { className: css(styles.buttonContainer), "data-test": "mobileBankID-button-container", children: [_jsx(BaseButton, { onClick: onVerify, "data-test": "mobileBankID-button", iconSrc: imageList.bankId, label: t('mobileBankID'), variant: "dark" }), _jsx("div", { className: css(styles.divider) }), system === 'INSTABOX' ? null : (_jsx(BaseButton, { onClick: onNext, "data-test": "mobileBankID-button", iconSrc: imageList.bankId, label: t('mobileBankIDOnAnotherDevice'), variant: "dark" }))] }));
};
