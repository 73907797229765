import { useSelector } from 'react-redux';
import { getAdyenCheckout, getAdyenPaymentStatus, getAdyenSession, getPaymentRequired, getPrice, getProduct, getRequestedTimeWindow, getVersion, } from './selectors';
export function useRequestedTimeWindow() {
    return useSelector(getRequestedTimeWindow);
}
export function usePaymentRequired() {
    return useSelector(getPaymentRequired);
}
export function useAdyenPaymentStatus() {
    return useSelector(getAdyenPaymentStatus);
}
export function useAdyenCheckout() {
    return useSelector(getAdyenCheckout);
}
export function useAdyenSession() {
    return useSelector(getAdyenSession);
}
export function usePrice() {
    return useSelector(getPrice);
}
export function useProduct() {
    return useSelector(getProduct);
}
export function useVersion() {
    return useSelector(getVersion);
}
