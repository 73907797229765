import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { timetable as timetableUtils } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { useToggledState } from '../../../hooks/use-toggled-state';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useBoxOrder } from '../../../state/box-order/hooks';
import { useConspectus } from '../../../state/order/hooks';
import { consignmentToDeliveryWindow } from '../../../utils/consignment-to-delivery-window';
import { localizeMerchantName } from '../../../utils/locale';
import { ErrorMessage } from '../../error-message';
import { styles } from '../../preface/timetable/style';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { TimeWindowSelector } from '../../time-window-selector';
import { TimetableInfo } from '../../timetable-info';
const getDefaultDeliveryWindow = (timeWindows) => {
    const bookedTimeWindow = timeWindows.find((timeWindow) => timeWindow.booked);
    return bookedTimeWindow || null;
};
export const ReturnSelectDate = (props) => {
    const { token, authToken, onContinue, canContinueChanged, timeWindows, returnParcelCount, isFetchingTimeWindows, afterBookConsignment, fetchTimeWindows, registerPreliminaryPayment, clearPaymentRequest, } = props;
    const [currentDeliveryWindow, onDeliveryWindowChange] = useToggledState(null);
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [error, setError] = useState(false);
    const boxOrder = useBoxOrder();
    const conspectus = useConspectus();
    const deliveryWindow = useMemo(() => conspectus && conspectus.consignment
        ? consignmentToDeliveryWindow(conspectus.consignment)
        : undefined, [conspectus]);
    const updateDeliveryWindow = (timeWindow) => {
        onDeliveryWindowChange(timeWindow);
        if (!timeWindow) {
            return clearPaymentRequest();
        }
        const { price } = timeWindow.timeWindow;
        if (!price || price.costInCents === 0) {
            return clearPaymentRequest();
        }
        return registerPreliminaryPayment();
    };
    useEffect(() => {
        if (!token) {
            return;
        }
        fetchTimeWindows(token);
        clearPaymentRequest();
    }, []);
    useEffect(() => {
        if (timeWindows) {
            onDeliveryWindowChange(getDefaultDeliveryWindow(timeWindows));
        }
    }, [timeWindows]);
    useEffect(() => {
        if (!authToken) {
            return;
        }
        onContinue((next, onError) => {
            trackingApi
                .bookReturn(authToken, token, currentDeliveryWindow, returnParcelCount)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    afterBookConsignment(response, currentDeliveryWindow);
                    next();
                }
                else {
                    setError(true);
                }
            })
                .catch(() => {
                setError(true);
                onError();
            });
        });
    }, [currentDeliveryWindow]);
    useEffect(() => canContinueChanged(currentDeliveryWindow !== null), [currentDeliveryWindow]);
    if (error) {
        return _jsx(ErrorMessage, {});
    }
    const bookableTimeWindows = timetableUtils.filterValidTimeWindows(timeWindows);
    if (timeWindows === null || !conspectus) {
        return null;
    }
    return (_jsx(View, { children: isFetchingTimeWindows ? (_jsx(View, { style: styles.loader, children: _jsx(Loader, {}) })) : (_jsxs(_Fragment, { children: [_jsx(TimetableInfo, { isReturn: true, merchantName: boxOrder
                        ? boxOrder.merchant.name
                        : localizeMerchantName(conspectus.merchant.name, conspectus.address.countryCode), orderStatus: boxOrder ? boxOrder.status : conspectus.status.state }), _jsx("p", { className: css(styles.p), children: t('timetable.freeReturnPickUpTimeslotInfo') }), _jsx(TimeWindowSelector, { timetableTimeWindows: bookableTimeWindows, deliveryWindow: deliveryWindow, countryCode: boxOrder
                        ? boxOrder.lockerAttributes.address.countryCode
                        : conspectus.address.countryCode, days: timetableUtils.filteredTimetableDays(timeWindows), onChange: updateDeliveryWindow, value: currentDeliveryWindow })] })) }));
};
