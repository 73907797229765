import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { IconSize, MediaQuery } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Color.White100,
        padding: 0,
        width: '100%',
        porterbuddy: {
            backgroundColor: 'transparent',
        },
    },
    clickable: {
        border: 0,
        textAlign: 'left',
        backgroundColor: Color.White100,
        padding: 0,
        cursor: 'pointer',
    },
    leftContainer: {
        width: 38,
        height: 38,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        marginRight: Space.lg,
    },
    textContainer: {
        flex: 1,
        textAlign: 'left',
    },
    topText: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.Grey6,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h5,
            color: Color.White100,
        },
    },
    middleText: {
        marginTop: Space['2xs'],
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey5,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.White100,
            fontSize: 18,
        },
    },
    rightContainer: {
        height: IconSize['6xl'],
        justifyContent: 'center',
        marginLeft: Space.lg,
        maxWidth: '30%',
    },
    rightIcon: {
        width: IconSize['6xl'],
        height: IconSize['6xl'],
        [MediaQuery.maxWidthMd]: {
            width: IconSize['2xl'],
            height: IconSize['2xl'],
        },
    },
    childrenContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: Space.sm,
        gap: Space.sm,
    },
});
