import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Space } from '@budbee/js/ui';
import { css } from '@css';
import { length, substr } from 'stringz';
import { IconSize } from '../../../styles';
import { getBrand } from '../../../utils/brand/get-brand';
import imageList from '../../../utils/image-list';
import { inlineStyles } from '../../../utils/inline-styles';
import { Icon } from '../icon';
import { View } from '../view';
import { styles } from './style';
const valueFromLimit = (value, limit) => {
    return length(value) > limit ? substr(value, 0, limit) : value;
};
const onChangeWithLimit = (onChangeCallback, limit) => (e) => {
    const { value: eventValue } = e.currentTarget;
    e.preventDefault();
    if (!limit) {
        onChangeCallback(eventValue);
        return;
    }
    const value = valueFromLimit(eventValue, limit);
    if (onChangeCallback) {
        onChangeCallback(value);
    }
};
export const TextInput = forwardRef(({ rows, maxChars, type = 'text', showCounter = true, resizable = false, contentContainerStyle, style, icon, onChange, inputFeedback, children, ...props }, ref) => {
    var _a;
    if (rows) {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: ref, className: css(styles.rootTextArea, contentContainerStyle), children: [_jsx("textarea", { rows: rows, className: css(styles.textarea, resizable ? undefined : styles.textAreaNoResize, style), ...props, onChange: onChangeWithLimit(onChange, maxChars) }), _jsx(View, { style: styles.textareaCountContainer, children: maxChars && (_jsxs(View, { style: styles.countText, children: [typeof props.value === 'string' ? length(props.value) : 0, "/", maxChars] })) })] }), inputFeedback && _jsx("span", { className: css(styles.inputFeedback), children: inputFeedback })] }));
    }
    if (type === 'text' || type === 'tel') {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { ref: ref, className: css(styles.root, inputFeedback ? inlineStyles({ marginBottom: Space.sm }) : undefined, contentContainerStyle), children: [icon && (_jsx(View, { style: styles.iconContainer, children: _jsx(Icon, { src: imageList.DoorCode, size: IconSize.xl }) })), type === 'text' ? (_jsxs(_Fragment, { children: [_jsx("input", { type: type, className: css(styles.input, icon ? styles.inputIcon : undefined, maxChars ? styles.inputCounted : undefined, style), ...props, onChange: onChangeWithLimit(onChange, maxChars), "data-test": "door-code-input" }), _jsx("p", { className: css([
                                        styles.placeholder,
                                        getBrand() === 'porterbuddy' && (props === null || props === void 0 ? void 0 : props.value) && ((_a = props === null || props === void 0 ? void 0 : props.value) === null || _a === void 0 ? void 0 : _a.length) > 0
                                            ? styles.show
                                            : undefined,
                                    ]), children: props.placeholder })] })) : (_jsx("input", { step: "1", max: "30", type: type, className: css(styles.input, style), ...props })), maxChars && type === 'text' && showCounter && (_jsx(View, { style: styles.inputCountContainer, children: _jsxs("span", { className: css(styles.countText), children: [typeof props.value === 'string' ? length(props.value) : 0, "/", maxChars] }) })), children] }), inputFeedback && _jsx("span", { className: css(styles.inputFeedback), children: inputFeedback })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: ref, className: css(styles.root, contentContainerStyle), children: _jsx("input", { type: type, ...props }) }), inputFeedback && _jsx("span", { className: css(styles.inputFeedback), children: inputFeedback })] }));
});
