import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { eta as etaUtils, } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useNeighboursEnabled } from '../../../state/features/hooks';
import { useTimeZone } from '../../../state/locale/hooks';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { DeliveryModePicker } from '../../delivery-mode-picker';
import { View } from '../../shared/view';
import { styles as timeWindowItemStyles } from '../../time-window-item/style';
export const DeliverySettings = ({ conspectus, token, authToken, orderStatus, isReturn, language, setDeliveryExecution, canContinueChanged, onContinue, onPrevious, }) => {
    const { i18n } = useI18n();
    const trackingApi = useTrackingApi();
    const { routedEta, eta, consignment, address: { countryCode }, deliveryOptions, deliverySettings: { deliveryExecution: { method, notification, alternativeLeaveWithNeighbour, neighbours }, }, } = conspectus;
    const [currentMethod, setCurrentMethod] = useState(method);
    const [currentNotification, setCurrentNotification] = useState(notification);
    const [currentNeighbours, setCurrentNeighbours] = useState((neighbours === null || neighbours === void 0 ? void 0 : neighbours.length) > 0 ? neighbours : [{ deliveryInfo: '' }]);
    const timeZone = useTimeZone();
    const neighboursListEnabled = useNeighboursEnabled();
    const [neighboursValid, onChangeNeighboursValid] = useState(false);
    const [currentAlternativeLeaveWithNeighbour, setCurrentAlternativeLeaveWithNeighbour] = useState(alternativeLeaveWithNeighbour);
    useEffect(() => {
        onContinue((next, onError) => {
            if (!token || !currentMethod) {
                return;
            }
            setDeliveryExecution(currentMethod, currentNotification, currentAlternativeLeaveWithNeighbour, neighboursListEnabled && neighboursValid ? currentNeighbours : [])
                .then(next)
                .catch(onError);
        });
    }, [
        token,
        currentMethod,
        currentAlternativeLeaveWithNeighbour,
        authToken,
        trackingApi,
        onContinue,
        currentNeighbours,
        neighboursListEnabled,
        neighboursValid,
        currentNotification,
    ]);
    useEffect(() => {
        const hasDeliveryMethod = currentMethod !== null;
        const hasNotificationMethod = currentNotification !== null;
        const hasLeaveWithNeighbour = currentAlternativeLeaveWithNeighbour;
        const canLeaveWithNeighbour = conspectus === null || conspectus === void 0 ? void 0 : conspectus.deliveryOptions.deliveryModes.leaveWithNeighbour;
        if (!canLeaveWithNeighbour) {
            canContinueChanged(hasDeliveryMethod && hasNotificationMethod);
            return;
        }
        if (neighboursListEnabled && hasLeaveWithNeighbour) {
            canContinueChanged(hasDeliveryMethod && neighboursValid);
            return;
        }
        canContinueChanged(hasDeliveryMethod && hasNotificationMethod);
    }, [
        canContinueChanged,
        currentAlternativeLeaveWithNeighbour,
        currentMethod,
        currentNotification,
        currentNeighbours,
        neighboursListEnabled,
        neighboursValid,
        conspectus === null || conspectus === void 0 ? void 0 : conspectus.deliveryOptions.deliveryModes.leaveWithNeighbour,
    ]);
    return (_jsxs(View, { children: [(consignment || routedEta) && timeZone && (_jsx("button", { type: "button", className: css(timeWindowItemStyles.buttonDefault, timeWindowItemStyles.buttonSelected, timeWindowItemStyles.padding), onClick: onPrevious, children: _jsxs("div", { className: css(timeWindowItemStyles.timeSlotContent), children: [_jsx(BrandedIcon, { src: {
                                budbee: imageList.Clock,
                                instabox: imageList.Clock,
                                porterbuddy: imageList.PorterbuddyClock,
                            }, size: 24 }), _jsx("span", { className: css(timeWindowItemStyles.eta), children: i18n.etaText(eta || etaUtils.fromRoutedEta(routedEta), orderStatus, isReturn, consignment, countryCode, language, timeZone, false) })] }) })), _jsx(DeliveryModePicker, { deliveryOptions: deliveryOptions, onChangeMethod: (m) => setCurrentMethod(m), onChangeNotification: (n) => setCurrentNotification(n), onChangeAlternativeLeaveWithNeighbour: (v) => setCurrentAlternativeLeaveWithNeighbour(v), onChangeNeighbours: setCurrentNeighbours, onChangeNeighboursValid: onChangeNeighboursValid, currentMethod: currentMethod, currentNotification: currentNotification, currentAlternativeLeaveWithNeighbour: currentAlternativeLeaveWithNeighbour, currentNeighbours: currentNeighbours })] }));
};
