import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import imageList from '../../../utils/image-list';
import { PinCodeHeader } from '../pin-code-header';
import { styles } from './style';
export const EntryAccessCode = ({ entryAccessCode, locationName }) => {
    const { t } = useI18n();
    return (_jsxs("div", { className: css(styles.root), children: [_jsx(PinCodeHeader, { imageSrc: imageList.DoorClosed, title: t('entryAccessCodeTitle'), description: t('entryAccessCodeInstructions', {
                    locationName,
                }) }), _jsx("span", { className: css(styles.code), children: entryAccessCode })] }));
};
