import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: Space.lg,
        padding: Space.lg,
        marginTop: 0,
        backgroundColor: Color.Porterbuddy.WarmYellow,
        position: 'relative',
    },
    close: {
        cursor: 'pointer',
        marginLeft: Space.lg,
        top: Space.lg,
        right: Space.lg,
        position: 'absolute',
        color: Color.Porterbuddy.Grey4,
    },
    message: {
        margin: 0,
        textAlign: 'left',
        wordWrap: 'break-word',
        maxWidth: '100%',
        ...Typography.Porterbuddy.Web.h4,
        color: Color.Porterbuddy.Grey4,
        flex: 1,
        paddingRight: Space.lg,
    },
});
