import { PureComponent } from 'react';
export class Delay extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            canRender: false,
        };
        this.timeout = null;
    }
    componentDidMount() {
        const { delay } = this.props;
        this.timeout = setTimeout(this.setState.bind(this, { canRender: true }), delay);
    }
    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    render() {
        const { children } = this.props;
        const { canRender } = this.state;
        if (canRender) {
            return children;
        }
        return null;
    }
}
