import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { getBrand } from '../../utils/brand/get-brand';
import { BaseButton } from '../base-button';
import { styles } from './style';
export function PrefaceFooter({ canContinue, onPrevious, onNext, loading, finished, isBackButtonVisible, isNextButtonVisible, continueText, finishedText, }) {
    const { t } = useI18n();
    const brand = getBrand();
    if (!isBackButtonVisible && !isNextButtonVisible) {
        return null;
    }
    return (_jsxs("div", { className: css([styles.root, isBackButtonVisible && !isNextButtonVisible && styles.onlyBack]), children: [isBackButtonVisible && (_jsx("div", { className: css(styles.back), children: _jsx(BaseButton, { onClick: onPrevious, label: t('back'), variant: brand === 'porterbuddy' ? 'secondary' : 'light', style: styles.button }) })), isNextButtonVisible && (_jsx("div", { "data-test": "save-btn", className: css(styles.continue), children: _jsx(BaseButton, { disabled: !canContinue, onClick: onNext, isLoading: loading, label: t(finished ? finishedText : continueText), variant: "dark", style: styles.button }) }))] }));
}
