import { Color } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, BorderRadius, BorderWidth, FontSize, FontWeight, Spacing } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dimensionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: Spacing['2xl'],
        borderRadius: BorderRadius.xs,
        ...border(BorderWidth.sm, 'solid', Color.Budbee.GreenDark),
    },
    label: {
        fontWeight: FontWeight.Medium,
        fontSize: FontSize.md,
        alignSelf: 'center',
    },
    number: {
        color: Color.Budbee.GreenDark,
        width: Spacing['3xl'],
        border: 0,
        outline: 0,
        fontWeight: FontWeight.SemiBold,
        fontSize: FontSize.sm,
        paddingRight: Spacing.xs,
        backgroundColor: Color.Budbee.Grey1,
    },
    unit: {
        color: Color.Budbee.GreenDark,
        fontSize: FontSize.sm,
        fontWeight: FontWeight.SemiBold,
        marginTop: 0,
        marginLeft: 0,
        marginBottom: 0,
        marginRight: Spacing.xl,
    },
});
