import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { DeliveryMethod, NotificationMethod } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useNeighboursEnabled } from '../../state/features/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { CheckButtonOptions } from '../check-button-options';
import { NeighbourSettings } from '../neighbour-settings';
import { View } from '../shared/view';
import { styles } from './style';
export const DeliveryModePicker = ({ deliveryOptions, onChangeMethod, onChangeNotification, onChangeAlternativeLeaveWithNeighbour, onChangeNeighbours, onChangeNeighboursValid, currentMethod, currentNotification, currentAlternativeLeaveWithNeighbour, currentNeighbours, }) => {
    const { t } = useI18n();
    const brand = getBrand();
    const neighboursListEnabled = useNeighboursEnabled();
    const shouldRenderNotificationMethodButtons = useMemo(() => {
        const currentlyOutsideDoor = currentMethod === DeliveryMethod.LEAVE_BY_DOOR;
        const { deliveryModes } = deliveryOptions;
        if (currentlyOutsideDoor && deliveryModes.outsideDoor) {
            return Object.values(deliveryModes.outsideDoor).filter((d) => d === true).length > 1;
        }
        if (deliveryModes.inPerson) {
            return Object.values(deliveryModes.inPerson).filter((d) => d === true).length > 1;
        }
        return false;
    }, [currentMethod, deliveryOptions]);
    const preselectNotificationMethod = useCallback((newMethod) => {
        const { deliveryModes } = deliveryOptions;
        const currentlyOutsideDoor = newMethod === DeliveryMethod.LEAVE_BY_DOOR;
        const modes = currentlyOutsideDoor ? deliveryModes.outsideDoor : deliveryModes.inPerson;
        onChangeNotification(null);
        if (!modes) {
            return;
        }
        const onlyOneEnabled = Object.values(modes).filter((v) => v === true).length === 1;
        if (!onlyOneEnabled) {
            return;
        }
        if (modes.doorBell) {
            onChangeNotification(NotificationMethod.RING_DOOR_BELL);
        }
        if (modes.knock) {
            onChangeNotification(NotificationMethod.KNOCK_ON_DOOR);
        }
        if (modes.phone) {
            onChangeNotification(NotificationMethod.CALL_CUSTOMER);
        }
        if (modes.noNotification) {
            onChangeNotification(NotificationMethod.NO_NOTIFICATION);
        }
    }, [deliveryOptions, onChangeNotification]);
    const onChangeLeaveOutsideDoor = useCallback(() => {
        onChangeMethod(DeliveryMethod.LEAVE_BY_DOOR);
        preselectNotificationMethod(DeliveryMethod.LEAVE_BY_DOOR);
        onChangeAlternativeLeaveWithNeighbour(false);
        onChangeNeighbours([{ deliveryInfo: '' }]);
    }, [
        onChangeAlternativeLeaveWithNeighbour,
        onChangeMethod,
        preselectNotificationMethod,
        onChangeNeighbours,
    ]);
    const onChangeDeliverInPerson = useCallback(() => {
        onChangeMethod(DeliveryMethod.IN_PERSON);
        preselectNotificationMethod(DeliveryMethod.IN_PERSON);
        onChangeAlternativeLeaveWithNeighbour(false);
        onChangeNeighbours([{ deliveryInfo: '' }]);
    }, [
        onChangeMethod,
        onChangeAlternativeLeaveWithNeighbour,
        onChangeNeighbours,
        preselectNotificationMethod,
    ]);
    const onAlternativelyLeaveWithNeighbourChanged = useCallback((checked) => {
        onChangeAlternativeLeaveWithNeighbour(checked);
        onChangeNeighbours([{ deliveryInfo: '' }]);
    }, [onChangeAlternativeLeaveWithNeighbour, onChangeNeighbours]);
    const onUpdateNeighbours = useCallback((newNeighbours) => {
        onChangeNeighbours(newNeighbours);
    }, [onChangeNeighbours]);
    const canLeaveWithNeighbour = useMemo(() => {
        const enabled = deliveryOptions.deliveryModes.leaveWithNeighbour;
        return enabled && currentMethod === DeliveryMethod.IN_PERSON;
    }, [currentMethod, deliveryOptions.deliveryModes.leaveWithNeighbour]);
    const getDisabledMessage = useCallback(() => {
        const { leaveWithNeighbour } = deliveryOptions.deliveryModes;
        const { outsideDoor } = deliveryOptions.deliveryModes;
        if (leaveWithNeighbour !== null && leaveWithNeighbour === false && !outsideDoor) {
            return 'deliverySettings.leaveWithNeighbourAndLeaveOutsideDoorDisabled';
        }
        if (!outsideDoor) {
            return 'deliverySettings.leaveOutsideDoorDisabled';
        }
        return null;
    }, [deliveryOptions.deliveryModes]);
    const disabledMessage = getDisabledMessage();
    const ContactlessOrInPersonButtons = useMemo(() => ({
        title: t('deliverySettings.leaveParcelByDoor'),
        trailingComponent: (_jsx(_Fragment, { children: disabledMessage && _jsx(View, { style: styles.informationText, children: t(disabledMessage) }) })),
        options: [
            {
                label: `${t('yesPlease')}*`,
                leftIcon: brand === 'instabox' ? imageList.LeaveOutsideDoorInstabox : imageList.LeaveOutsideDoor,
                checked: currentMethod === DeliveryMethod.LEAVE_BY_DOOR,
                onPress: onChangeLeaveOutsideDoor,
                disabled: !deliveryOptions.deliveryModes.outsideDoor,
                shouldRender: true,
                trailingComponent: (_jsx(_Fragment, { children: currentMethod === DeliveryMethod.LEAVE_BY_DOOR &&
                        deliveryOptions.deliveryModes.outsideDoor && (_jsxs(View, { style: styles.informationText, children: ["*", t('deliverySettings.responsabilityInfo')] })) })),
            },
            {
                label: t('noThanks'),
                leftIcon: brand === 'instabox' ? imageList.DoorOpenInstabox : imageList.DoorOpen,
                checked: currentMethod === DeliveryMethod.IN_PERSON,
                onPress: onChangeDeliverInPerson,
                disabled: false,
                shouldRender: true,
                trailingComponent: null,
            },
        ],
    }), [
        currentMethod,
        disabledMessage,
        onChangeDeliverInPerson,
        onChangeLeaveOutsideDoor,
        t,
        deliveryOptions.deliveryModes.outsideDoor,
        brand,
    ]);
    const NotificationMethodButtons = useMemo(() => {
        const currentMode = currentMethod === DeliveryMethod.LEAVE_BY_DOOR
            ? deliveryOptions.deliveryModes.outsideDoor
            : deliveryOptions.deliveryModes.inPerson;
        return {
            title: t('deliverySettings.notification'),
            trailingComponent: null,
            options: [
                {
                    label: t('deliverySettings.ringDoorBell'),
                    leftIcon: brand === 'instabox' ? imageList.doorBellInstabox : imageList.doorBell,
                    checked: currentNotification === NotificationMethod.RING_DOOR_BELL,
                    onPress: () => onChangeNotification(NotificationMethod.RING_DOOR_BELL),
                    disabled: !(currentMode === null || currentMode === void 0 ? void 0 : currentMode.doorBell),
                    shouldRender: Boolean(currentMode === null || currentMode === void 0 ? void 0 : currentMode.doorBell),
                    trailingComponent: null,
                },
                {
                    label: t('deliverySettings.knockOnDoor'),
                    leftIcon: brand === 'instabox' ? imageList.knockOnDoorInstabox : imageList.knockOnDoor,
                    checked: currentNotification === NotificationMethod.KNOCK_ON_DOOR,
                    onPress: () => onChangeNotification(NotificationMethod.KNOCK_ON_DOOR),
                    disabled: !(currentMode === null || currentMode === void 0 ? void 0 : currentMode.knock),
                    shouldRender: Boolean(currentMode === null || currentMode === void 0 ? void 0 : currentMode.knock),
                    trailingComponent: null,
                },
                {
                    label: t('deliverySettings.callCustomer'),
                    leftIcon: imageList.CallMe,
                    checked: currentNotification === NotificationMethod.CALL_CUSTOMER,
                    onPress: () => onChangeNotification(NotificationMethod.CALL_CUSTOMER),
                    disabled: !(currentMode === null || currentMode === void 0 ? void 0 : currentMode.phone),
                    shouldRender: Boolean(currentMode === null || currentMode === void 0 ? void 0 : currentMode.phone),
                    trailingComponent: null,
                },
                {
                    label: t('deliverySettings.noNotification'),
                    leftIcon: imageList.NoNotification,
                    checked: currentNotification === NotificationMethod.NO_NOTIFICATION,
                    onPress: () => onChangeNotification(NotificationMethod.NO_NOTIFICATION),
                    disabled: !(currentMode === null || currentMode === void 0 ? void 0 : currentMode.noNotification),
                    shouldRender: Boolean(currentMode === null || currentMode === void 0 ? void 0 : currentMode.noNotification),
                    trailingComponent: null,
                },
            ],
        };
    }, [currentMethod, currentNotification, deliveryOptions, onChangeNotification, t, brand]);
    const AlternativeDelivery = useMemo(() => ({
        title: t('deliverySettings.deliverToNeigbourFallback'),
        trailingComponent: null,
        options: [
            {
                label: `${t('yesPlease')}`,
                leftIcon: imageList.LeaveWithNeighbour,
                checked: currentAlternativeLeaveWithNeighbour,
                onPress: () => onAlternativelyLeaveWithNeighbourChanged(true),
                disabled: !deliveryOptions.deliveryModes.outsideDoor,
                shouldRender: true,
                trailingComponent: null,
            },
            {
                label: t('noThanks'),
                leftIcon: imageList.NoNeighbour,
                checked: !currentAlternativeLeaveWithNeighbour,
                onPress: () => onAlternativelyLeaveWithNeighbourChanged(false),
                disabled: false,
                shouldRender: true,
                trailingComponent: (_jsx(_Fragment, { children: neighboursListEnabled && currentAlternativeLeaveWithNeighbour ? (_jsx(View, { children: _jsx(NeighbourSettings, { currentNeighbours: currentNeighbours, onChangeNeighbours: onUpdateNeighbours, onChangeNeighboursValid: onChangeNeighboursValid }) })) : null })),
            },
        ],
    }), [
        currentAlternativeLeaveWithNeighbour,
        currentNeighbours,
        neighboursListEnabled,
        onAlternativelyLeaveWithNeighbourChanged,
        onChangeNeighboursValid,
        onUpdateNeighbours,
        t,
        deliveryOptions,
    ]);
    return (_jsxs(View, { style: styles.container, children: [_jsx(CheckButtonOptions, { data: ContactlessOrInPersonButtons }), shouldRenderNotificationMethodButtons && (_jsx(CheckButtonOptions, { data: NotificationMethodButtons })), canLeaveWithNeighbour && _jsx(CheckButtonOptions, { data: AlternativeDelivery })] }));
};
