import { Typography, Color as JSColor, Border, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, IconSize, Spacing, Color, paddingHorizontal, ButtonEffect, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: BorderRadius.sm,
        backgroundColor: Color.White,
        ...paddingHorizontal(Spacing.lg),
        porterbuddy: {
            borderRadius: Border.Radius['2xs'],
        },
    },
    icon: {
        width: IconSize['3xl'],
        height: IconSize['3xl'],
        color: Color.SecondaryGreen,
    },
    input: {
        ...Typography.Budbee.Web.h3,
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'center',
        height: 60,
        padding: 0,
        margin: 0,
    },
    button: {
        ...ButtonEffect.Enabled,
        backgroundColor: JSColor.Budbee.GreenLight,
        border: 'none',
        borderRadius: Border.Radius.sm,
        width: Space['3xl'],
        height: Space['3xl'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        porterbuddy: {
            borderRadius: Border.Radius['2xs'],
            backgroundColor: 'inherit',
        },
        instabox: {
            backgroundColor: JSColor.Instabox.PalePink,
        },
    },
});
