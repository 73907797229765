import { AccessModeType } from '@budbee/js/tracking';
export const AccessModeLabels = {
    [AccessModeType.NONE]: 'accessSettings.freePassage',
    [AccessModeType.INTERCOM]: 'accessSettings.intercom',
    [AccessModeType.DOOR_CODE]: 'accessSettings.doorCode',
};
export const getAccessModeTitle = (accessMode, t) => {
    const title = [t('summary.accessMode')];
    const type = accessMode ? accessMode.type : AccessModeType.NONE;
    return type === AccessModeType.NONE
        ? title[0]
        : title.concat(' - ', t(AccessModeLabels[type])).join('');
};
export const getAccessModeValue = (accessMode, t) => {
    var _a;
    const type = accessMode ? accessMode.type : AccessModeType.NONE;
    const value = (_a = accessMode === null || accessMode === void 0 ? void 0 : accessMode.value) !== null && _a !== void 0 ? _a : '';
    return type === AccessModeType.NONE ? t(AccessModeLabels[type]) : value;
};
