import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, BorderRadius, ButtonEffect } from '../../styles';
const baseContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    backgroundColor: Color.White100,
    borderRadius: BorderRadius.sm,
    border: 0,
};
export const styles = StyleSheet.create({
    root: {
        width: '100%',
    },
    container: {
        ...baseContainerStyle,
        ...ButtonEffect.Enabled,
    },
    containerDisabled: {
        ...baseContainerStyle,
    },
    leftTitle: {
        ...Typography.Budbee.Web.h5,
        marginLeft: Space.lg,
    },
    rightContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    rightContentContainer: {
        marginRight: Spacing.lg,
        marginLeft: Spacing.sm,
    },
    rightTitle: {
        ...Typography.Budbee.Web['body-lg'],
    },
    rightIconContainer: {
        marginRight: Space.lg,
    },
    leftIconContainer: {
        marginLeft: Spacing.xl,
    },
    iconTextWrapper: {
        flexDirection: 'row',
    },
});
