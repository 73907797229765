import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { BaseButton } from '../../base-button';
import { ErrorMessage } from '../../error-message';
import { ParcelDimensionSlider } from '../../parcel-dimension-slider';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { Title } from '../../title';
import { styles } from './style';
const getMaxDimension = ({ length, width, height }) => Math.max(length, width, height);
const getFormattedMaxDimensions = ({ length, width, height, unit }) => `${length}x${width}x${height} ${unit}`;
export const BoxReturnParcelDimensions = ({ onContinue, canContinueChanged, token, authToken, dimensions, updateParcelDimensions, }) => {
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [unit, setUnit] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [validationFailed, setValidationFailed] = useState(false);
    const [maxDimensions, setMaxDimensions] = useState();
    const showResetButton = useMemo(() => dimensions && Object.values(dimensions).filter((d) => d === 0).length === 0, [dimensions]);
    const setDefaultDimensions = useCallback(() => {
        if (!dimensions) {
            return;
        }
        const { length: newLength, width: newWidth, height: newHeight, unit: newUnit } = dimensions;
        setLength(newLength);
        setWidth(newWidth);
        setHeight(newHeight);
        setUnit(newUnit);
    }, [dimensions, setLength, setWidth, setHeight, setUnit]);
    useEffect(() => {
        if (!dimensions) {
            return;
        }
        setDefaultDimensions();
    }, [dimensions, setDefaultDimensions]);
    useEffect(() => canContinueChanged([length, width, height].every((o) => o > 0)), [length, width, height, canContinueChanged]);
    useEffect(() => onContinue((next, onError) => {
        if (!token || !authToken) {
            return;
        }
        setValidationFailed(false);
        trackingApi
            .validateReturnBoxDimensions(token, authToken, width, height, length)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED && response.payload.valid) {
                updateParcelDimensions({ length, width, height, unit });
                next();
            }
            else {
                setValidationFailed(true);
                onError();
            }
        })
            .catch((e) => {
            setShowError(true);
            Sentry.captureException(e);
        });
    }), [
        onContinue,
        setValidationFailed,
        trackingApi,
        token,
        authToken,
        length,
        width,
        height,
        updateParcelDimensions,
        unit,
    ]);
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        setLoading(true);
        trackingApi
            .getParcelDimensions(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                updateParcelDimensions(response.payload.dimensions);
                setMaxDimensions(response.payload.maxDimensions);
            }
            else {
                setShowError(true);
            }
            setLoading(false);
        })
            .catch((e) => {
            Sentry.captureException(e);
            setShowError(true);
            setLoading(false);
        });
    }, [token, authToken, trackingApi, updateParcelDimensions]);
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    if (showError) {
        return _jsx(ErrorMessage, {});
    }
    return (_jsxs("div", { children: [_jsx(Title, { title: t('bookReturnTitle'), style: styles.title }), _jsx("p", { children: t('boxReturn.configureDimensions') }), maxDimensions && (_jsxs("p", { className: css(styles.maxDimensionsInfo), children: [t('boxReturn.maxDimensions'), " ", getFormattedMaxDimensions(maxDimensions)] })), validationFailed && (_jsx("p", { className: css(styles.invalidDimensions), children: t('boxReturn.invalidDimensions') })), dimensions && maxDimensions ? (_jsxs("div", { children: [_jsx(ParcelDimensionSlider, { label: t('length'), unit: unit, value: length, maxValue: getMaxDimension(maxDimensions), onChangeValue: setLength, contentContainerStyle: styles.parcelSliderContainer }), _jsx(ParcelDimensionSlider, { label: t('width'), unit: unit, value: width, maxValue: getMaxDimension(maxDimensions), onChangeValue: setWidth, contentContainerStyle: styles.parcelSliderContainer }), _jsx(ParcelDimensionSlider, { label: t('height'), unit: unit, value: height, maxValue: getMaxDimension(maxDimensions), onChangeValue: setHeight, contentContainerStyle: styles.parcelSliderContainer }), showResetButton ? (_jsx(View, { style: styles.resetButton, children: _jsx(BaseButton, { label: t('boxReturn.resetParcelSize'), onClick: setDefaultDimensions }) })) : null, _jsx(View, { style: styles.info, children: _jsx("span", { className: css(styles.instructions), children: t('boxReturn.packagingInstruction') }) })] })) : null] }));
};
