import { cloneElement, PureComponent } from 'react';
export class Step extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            canContinue: false,
            beforeNextCallback: null,
            loading: false,
        };
        this.canContinueChanged = this.canContinueChanged.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.beforeNext = this.beforeNext.bind(this);
        this.afterNext = this.afterNext.bind(this);
        this.onError = this.onError.bind(this);
    }
    canContinueChanged(canContinue) {
        if (this.state.canContinue !== canContinue) {
            this.setState(() => ({
                canContinue,
            }));
        }
    }
    onContinue(callback) {
        this.setState({
            beforeNextCallback: callback,
        });
    }
    beforeNext() {
        const { beforeNextCallback, loading } = this.state;
        if (loading) {
            return;
        }
        // if the continue is async and takes the next argument,
        // call it async and otherwise just do onNext
        if (beforeNextCallback && beforeNextCallback.length > 0) {
            this.setState(() => ({ loading: true }));
            beforeNextCallback(this.afterNext, this.onError);
        }
        else {
            this.afterNext();
        }
    }
    afterNext() {
        const { onNext } = this.props;
        this.setState(() => ({
            loading: false,
            beforeNextCallback: null,
            canContinue: false,
        }));
        onNext();
    }
    onError() {
        this.setState(() => ({
            loading: false,
        }));
    }
    render() {
        const { children, onPrevious, footer } = this.props;
        const { canContinue, loading } = this.state;
        const footerElement = footer
            ? cloneElement(footer, {
                canContinue,
                onNext: this.beforeNext,
                onPrevious,
                loading,
            })
            : undefined;
        if (!children) {
            return null;
        }
        return children({
            footer: footerElement,
            canContinueChanged: this.canContinueChanged,
            onContinue: this.onContinue,
            onNext: this.beforeNext,
            onPrevious,
        });
    }
}
