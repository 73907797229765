import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { Modal } from '..';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useToken } from '../../../state/order/hooks';
import imageList from '../../../utils/image-list';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import { BaseButton } from '../../base-button';
import { ErrorMessage } from '../../error-message';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { Title } from '../../title';
import { styles } from './style';
export const CancelBoxReturnModal = (props) => {
    const { authToken, closeModal } = props;
    const token = useToken();
    const { t } = useI18n();
    const trackingApi = useTrackingApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const cancelBoxReturnReservation = useCallback(() => {
        if (!token || !authToken) {
            return;
        }
        setLoading(true);
        trackingApi
            .cancelBoxReturnReservation(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                refreshTrackingPage();
                return;
            }
            setError(true);
        })
            .catch(() => setError(true));
    }, [authToken, token, trackingApi]);
    const renderContent = useCallback(() => {
        if (error) {
            return _jsx(ErrorMessage, {});
        }
        if (loading) {
            return (_jsx(View, { style: styles.center, children: _jsx(Loader, { variant: "inline" }) }));
        }
        return (_jsx(BaseButton, { onClick: cancelBoxReturnReservation, label: t('cancelReturn'), variant: "dark", iconSrc: imageList.CancelReturn }));
    }, [cancelBoxReturnReservation, error, loading, t]);
    return (_jsxs(Modal, { onClose: closeModal, children: [_jsx(Title, { title: t('cancelReturn'), style: styles.title }), !error && _jsx("p", { className: css(styles.subtitle), children: t('cancelReturnInfo') }), _jsx(View, { style: styles.button, children: renderContent() })] }));
};
