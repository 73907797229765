import appleTouchIcon from '../../img/app/app-store-icon.png';
import androidTouchIcon from '../../img/app/google-play-icon.png';
import { getBrand } from '../brand/get-brand';
const createMetaTag = (name, content) => {
    const meta = document.createElement('meta');
    meta.setAttribute('name', name);
    meta.setAttribute('content', content);
    return meta;
};
const createLinkTag = (rel, href) => {
    const link = document.createElement('link');
    link.setAttribute('rel', rel);
    link.setAttribute('href', href);
    return link;
};
const addTagToHead = (tag) => {
    var _a, _b;
    (_b = (_a = document === null || document === void 0 ? void 0 : document.head) === null || _a === void 0 ? void 0 : _a.querySelector('title')) === null || _b === void 0 ? void 0 : _b.insertAdjacentElement('afterend', tag);
};
export const setupBannerMetaTags = () => {
    const brand = getBrand();
    if (brand !== 'budbee') {
        return null;
    }
    return [
        createMetaTag('google-play-app', 'app-id=com.budbee.tracking'),
        createMetaTag('apple-itunes-app', 'app-id=1484774206'),
        createLinkTag('apple-touch-icon', appleTouchIcon),
        createLinkTag('android-touch-icon', androidTouchIcon),
    ].map(addTagToHead);
};
