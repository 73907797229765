import { StyleSheet } from '@css';
import { BorderWidth } from '../../styles';
const spin = {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
};
export const styles = StyleSheet.create({
    loader: {
        borderWidth: BorderWidth.lg,
        borderStyle: 'solid',
        borderRadius: '50%',
        animationName: spin,
        animationDuration: '1.2s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
});
