const concat = (first, ...args) => first.concat(...args);
const hasClass = (element) => (className) => element.className.match(className) !== null;
const addClass = (classNames) => (className) => classNames ? concat(classNames, ' ', className) : className;
const removeClass = (classNames) => (className) => classNames.replace(new RegExp(concat('\\s?', className), 'g'), '');
const addClassToElement = (element) => (className) => {
    if (!hasClass(element)(className)) {
        // eslint-disable-next-line no-param-reassign
        element.className = addClass(element.className)(className);
    }
};
const removeClassFromElement = (element) => (className) => {
    // eslint-disable-next-line no-param-reassign
    element.className = removeClass(element.className)(className);
};
export const applyModalBodyHtmlClass = (apply = true) => {
    const bodyClass = 'modal-body--open';
    const htmlClass = 'modal-html--open';
    if (apply) {
        addClassToElement(document.body)(bodyClass);
        addClassToElement(document.getElementsByTagName('html')[0])(htmlClass);
    }
    else {
        removeClassFromElement(document.body)(bodyClass);
        removeClassFromElement(document.getElementsByTagName('html')[0])(htmlClass);
    }
};
export const getModalRootElement = () => 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore We know modal-root exists, because we've hardcoded it
document.getElementById('modal-root');
