import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { hooks } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import useDebounce from '../../hooks/use-debounce';
import { useGeocoding } from '../../hooks/use-geocoding';
import { useI18n } from '../../hooks/use-i18n';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import imageList from '../../utils/image-list';
import { SearchGroup } from '../search-group';
export const LockerSearchGroup = ({ authToken, orderToken, consumerAddress, keyword, placeSearchResults, setSearchPlaceKeyword, setPlaceSearchResults, setLockersForSearchedPlace, setSelectedSearchResult, resetLockerSearchState, setIsSearchingForPlaces, isSearching, onSubmit, submitting, isPickingFromMap, }) => {
    const { t, language } = useI18n();
    const trackingApi = useTrackingApi();
    const geocoding = useGeocoding({
        country: (consumerAddress && consumerAddress.countryCode) || '',
        language: language || '',
    });
    const debouncedQuery = useDebounce(keyword, 400);
    const lastDebouncedQuery = hooks.usePrevious(debouncedQuery);
    useEffect(() => {
        if (debouncedQuery == null) {
            return;
        }
        if (debouncedQuery === lastDebouncedQuery) {
            return;
        }
        // check that the query is actually the debounced value as well
        if (keyword !== debouncedQuery || debouncedQuery.length < 3) {
            return;
        }
        if (keyword && keyword.length > 0) {
            geocoding.query(debouncedQuery);
        }
    }, [keyword, debouncedQuery, geocoding, lastDebouncedQuery]);
    const onSearchBarClear = () => {
        resetLockerSearchState();
        geocoding.clear();
    };
    const onSearchTermChange = useCallback((searchTerm) => {
        setSearchPlaceKeyword(searchTerm);
    }, [setSearchPlaceKeyword]);
    const onSearchResultItemClick = useCallback((feature) => {
        if (!orderToken || !authToken) {
            return;
        }
        setSelectedSearchResult(feature);
        trackingApi
            .getLockersByCoordinate(orderToken, authToken, feature.geometry.coordinates[1], feature.geometry.coordinates[0])
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                setLockersForSearchedPlace(response.payload.lockers || []);
            }
        });
    }, [setSelectedSearchResult, trackingApi, orderToken, authToken, setLockersForSearchedPlace]);
    useEffect(() => {
        if (geocoding.result) {
            setPlaceSearchResults(geocoding.result.features);
        }
    }, [geocoding.result, setPlaceSearchResults]);
    return (_jsx(SearchGroup, { placeholder: t('lockersSearchResultsTitle.SearchPlace'), submitButtonIcon: imageList.NavigationSecondaryGreen, loading: geocoding.loading, successfulRequest: !geocoding.failed, placeSearchResults: placeSearchResults, onSearchResultItemClick: onSearchResultItemClick, onClear: onSearchBarClear, onChange: onSearchTermChange, keyword: keyword, setFocused: setIsSearchingForPlaces, isFocused: isSearching, onSubmit: onSubmit, submitting: submitting, isPickingFromMap: isPickingFromMap }));
};
