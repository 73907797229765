import { Border, Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.md,
        ...paddingHorizontal(Space.lg),
        paddingBottom: Space.sm,
        porterbuddy: {
            backgroundColor: 'inherit',
            ...paddingHorizontal(Space['2xl']),
        },
    },
});
