import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createElement, useCallback, useEffect, useState } from 'react';
import { customerService } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { Modal } from '..';
import { BaseButton } from '../../../components/base-button';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { Accordion } from '../../accordion';
import { CustomerServiceItem } from '../../customer-service-item';
import { ErrorMessage } from '../../error-message';
import { styles } from './style';
const toAccordionItem = (props) => (item) => {
    if (!item.text) {
        return null;
    }
    return {
        key: item.id,
        text: item.text,
        title: item.title,
        content: createElement(CustomerServiceItem, {
            id: item.id,
            text: item.text,
            contentType: item.content,
            ...props,
        }),
    };
};
export const SupportModal = (props) => {
    const { onClose, toggleSettingsModal, toggleIdentificationModal, toggleBookDeliveryModal, toggleReturnsModal, toggleLockerPickerModal, toggleSwitchDeliveryTypeModal, token, authToken, authenticated, merchant, contactModal, } = props;
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [items, setItems] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    useEffect(() => {
        if (!token) {
            setError(true);
            return;
        }
        setLoading(true);
        trackingApi
            .getCustomerServiceItems(authToken || '', token)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.FAILED) {
                setLoading(false);
                setShowContactModal(true);
                return;
            }
            if (response.status === ResponseStatus.COMPLETED) {
                const { payload } = response;
                const accordionItems = payload.items
                    .sort(customerService.orderComparer)
                    .reduce((acc, next) => {
                    const { packageId } = payload;
                    const item = toAccordionItem({
                        packageId,
                        toggleSettingsModal,
                        toggleIdentificationModal,
                        toggleBookDeliveryModal,
                        toggleReturnsModal,
                        toggleLockerPickerModal,
                        toggleSwitchDeliveryTypeModal,
                        authenticated,
                        merchant,
                    })(next);
                    if (item) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
                setError(false);
                setItems(accordionItems);
                setLoading(false);
            }
        });
    }, []);
    const getHeader = useCallback(() => {
        if (showContactModal) {
            return t('helpModalChatWithBudbee');
        }
        if (items && !error) {
            return t('supportModal.customerSupportTitle');
        }
        return null;
    }, [error, items, showContactModal, t]);
    const getContent = useCallback(() => {
        if (showContactModal && contactModal) {
            return contactModal;
        }
        if (!items) {
            return null;
        }
        if (error) {
            return _jsx(ErrorMessage, { message: t('serviceItems.error'), positionVariant: "inline" });
        }
        return (_jsx(_Fragment, { children: items && !error ? (_jsx("div", { className: css(styles.itemsContainer), children: _jsx(Accordion, { items: items }) })) : null }));
    }, [contactModal, error, items, showContactModal, t]);
    const getFooter = useCallback(() => {
        if (!authenticated || showContactModal) {
            return null;
        }
        return (_jsx("div", { className: css(styles.footerContainer), children: _jsx(BaseButton, { onClick: () => setShowContactModal(true), label: t('contactUs'), variant: "dark", style: styles.button }) }));
    }, [authenticated, showContactModal, t]);
    return (_jsx(Modal, { onClose: onClose, loading: loading, footer: getFooter(), headerTitle: getHeader(), children: getContent() }));
};
