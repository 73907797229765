import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { StyleSheet, css } from '@css';
import { motion } from 'framer-motion';
const transition = {
    type: 'spring',
    damping: 25,
    stiffness: 300,
    duration: 0.4,
};
const styles = StyleSheet.create({
    contentWrapper: {
        overflow: 'hidden',
    },
});
export const ExpandingView = (props) => {
    const { open, children } = props;
    const contentRef = useRef(null);
    return (_jsx(_Fragment, { children: _jsx(motion.div, { className: css(styles.contentWrapper), initial: open ? 'visible' : 'hidden', transition: transition, animate: open ? 'visible' : 'hidden', variants: {
                hidden: {
                    height: 0,
                },
                visible: {
                    height: contentRef.current ? contentRef.current.clientHeight : 0,
                },
            }, children: _jsx("div", { ref: contentRef, children: children }) }) }));
};
