import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    container: {
        ...paddingHorizontal(Space.lg),
        paddingBottom: Space['2xl'],
        paddingTop: Space.md,
        porterbuddy: {
            paddingTop: 0,
        },
    },
    text: {
        ':first-of-type > p': {
            ...Typography.Budbee.Web['body-lg'],
            textAlign: 'left',
            margin: 0,
            whiteSpace: 'pre-line',
            porterbuddy: {
                ...Typography.Porterbuddy.Web['body-md'],
            },
        },
    },
    content: {
        textAlign: 'left',
        ...Typography.Budbee.Web['body-lg'],
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-md'],
        },
    },
    link: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.GreenDark,
        textAlign: 'left',
        textDecoration: 'none',
        marginBottom: Space.sm,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-md'],
            color: Color.Porterbuddy.BuddyPurple,
        },
    },
});
