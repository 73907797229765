import { Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { FontSize, Spacing } from '../../../styles';
export const styles = StyleSheet.create({
    container: {
        border: 0,
        borderRadius: 0,
        width: '100%',
        marginBottom: Space.md,
    },
    title: {
        textAlign: 'center',
        fontSize: FontSize.md,
        marginTop: Spacing.xs,
    },
    currentLocker: {
        ...Typography.Budbee.Web.h4,
        marginBottom: Space.sm,
        marginLeft: Space.sm,
    },
    otherLocker: {
        ...Typography.Budbee.Web.h4,
        marginTop: Space.lg,
        marginBottom: -Space['2xs'],
        marginLeft: Space.sm,
    },
});
