export const Color = {
    Primary: '#41CDA5',
    PrimaryText: '#39c69b',
    SecondaryGreen: '#18706D',
    Alert: '#E75C62',
    AlertDark: '#a92145',
    AlertBorder: '#F8EBEC',
    Secondary: '#e66487',
    TextDark: '#3e3e3e',
    SettingContainerBorder: '#e6e6e6',
    DisabledButton: '#ccc',
    BorderColor: '#E0E0E0',
    BorderColorLight: '#EDEFF1',
    DarkGreen: '#1b6f6c',
    Black: '#000000',
    White: '#FFFFFF',
    LightGreenAlt: '#B7D1D2',
    InactiveGrey: '#BDBDBD',
    MediumDarkGrey: '#4F4F4F',
    InfoBorder: '#d4e7e6',
};
export const AltColor = {
    SecondaryDark: '#BA3F3F',
};
export const TextStyle = {
    fontSize: 16,
    letterSpacing: -0.3,
};
export const Spacing = {
    xs: 5,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 25,
    '2xl': 30,
    '3xl': 35,
    '4xl': 40,
    '5xl': 45,
    '6xl': 50,
};
export const FontWeight = {
    Light: 300,
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    Black: 900,
};
export const FontSize = {
    '3xs': 10,
    '2xs': 11,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 22,
    '3xl': 24,
    '4xl': 26,
    '5xl': 28,
    '6xl': 30,
    '7xl': 32,
    '8xl': 34,
    '9xl': 36,
    '10xl': 38,
};
export const BorderRadius = {
    xs: 5,
    sm: 10,
    md: 15,
    lg: 20,
    xl: 40,
    '2xl': 50,
};
export const IconSize = {
    '4xs': 5,
    '3xs': 8,
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 22,
    '3xl': 24,
    '4xl': 26,
    '5xl': 28,
    '6xl': 30,
    '7xl': 32,
    '8xl': 34,
    '9xl': 36,
    '10xl': 38,
    '11xl': 40,
};
export const BorderWidth = {
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    '2xl': 8,
};
export const DropShadow = {
    boxShadow: `0px 4px 20px -5px rgba(0, 0, 0, 0.1)`,
};
export const ScreenSizes = {
    '2xs': 350,
    xs: 395,
    sm: 480,
    md: 600,
    lg: 760,
    xl: 1300,
};
export const MediaQuery = {
    // MAX
    maxWidth2xs: `@media (max-width: ${ScreenSizes['2xs']}px)`,
    maxWidthXs: `@media (max-width: ${ScreenSizes.xs}px)`,
    maxWidthSm: `@media (max-width: ${ScreenSizes.sm}px)`,
    maxWidthMd: `@media (max-width: ${ScreenSizes.md}px)`,
    maxWidthLg: `@media (max-width: ${ScreenSizes.lg}px)`,
    maxWidthXl: `@media (max-width: ${ScreenSizes.xl}px)`,
    // MIN
    minWidthXs: `@media (min-width: ${ScreenSizes.xs}px)`,
    minWidthSm: `@media (min-width: ${ScreenSizes.sm}px)`,
    minWidthMd: `@media (min-width: ${ScreenSizes.md}px)`,
    minWidthLg: `@media (min-width: ${ScreenSizes.lg}px)`,
    minWidthXl: `@media (min-width: ${ScreenSizes.xl}px)`,
};
export const border = (width, style, color, position) => {
    const borderPosition = position ? `border-${position}` : 'border';
    return { [borderPosition]: `${width}px ${style} ${color}` };
};
export const marginVertical = (value) => {
    return {
        'margin-top': value,
        'margin-bottom': value,
    };
};
export const marginHorizontal = (value) => {
    return {
        'margin-left': value,
        'margin-right': value,
    };
};
export const paddingVertical = (value) => {
    return {
        'padding-top': value,
        'padding-bottom': value,
    };
};
export const paddingHorizontal = (value) => {
    return {
        'padding-left': value,
        'padding-right': value,
    };
};
export const mobileOnly = () => {
    return {
        [MediaQuery.minWidthXl]: {
            display: 'none',
        },
    };
};
export const desktopOnly = () => {
    return {
        [MediaQuery.maxWidthXl]: {
            display: 'none',
        },
    };
};
export const Transition = {
    Ease: '200ms ease all',
};
export const ButtonEffect = {
    Enabled: {
        transition: Transition.Ease,
        cursor: 'pointer',
        ':hover': {
            filter: 'brightness(90%)',
        },
        ':active': {
            transform: 'scale(1.02)',
            filter: 'brightness(80%)',
        },
    },
    Disabled: {
        pointerEvents: 'none',
        opacity: 0.5,
    },
};
