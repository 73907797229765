import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    divider2xl: {
        height: Space['2xl'],
    },
    divider: {
        height: Space.lg,
    },
    dividerSm: {
        height: Space.sm,
    },
});
