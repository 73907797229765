import { Border, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    btn: {
        display: 'flex',
        cursor: 'pointer',
        background: 'none',
        border: 0,
        padding: 0,
        marginTop: Space.sm,
    },
    imgStyles: {
        width: 80,
        height: 80,
        porterbuddy: {
            borderRadius: Border.Radius['2xs'],
        },
    },
});
