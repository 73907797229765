import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Children } from 'react';
import { View } from '../shared/view';
import { SummaryItem } from './summary-item';
import { styles } from './summary.style';
export const Summary = ({ children }) => {
    const childArray = Children.toArray(children);
    return (_jsx(View, { style: styles.root, children: _jsx(View, { style: styles.container, children: childArray.map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            _jsxs(View, { children: [_jsx(View, { style: styles.childWrapper, children: child }), index < childArray.length - 2 && _jsx(View, { style: styles.itemDivider })] }, index))) }) }));
};
Summary.Item = SummaryItem;
