import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { AdyenPaymentStatus, } from '@budbee/js/tracking';
import { ProductsEligibleForPayment } from '../../../utils/payment';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import { Checkout } from '../../checkout';
export const Payment = (props) => {
    const { setPaymentReference, resetPaymentState, updateAdyenPaymentStatus, reloadOrder, reloadOrderType, onContinue, canContinueChanged, adyenSession, adyenPaymentStatus, adyenCheckout, token, price, locale, version, closeModal, product, requestedTimeWindow, isReturnConsignment, isReturn, orderType, } = props;
    useEffect(() => {
        canContinueChanged(false);
    }, [canContinueChanged]);
    useEffect(() => {
        onContinue((next, onError) => {
            if (product === ProductsEligibleForPayment.SWITCH_DELIVERY_TYPE) {
                reloadOrderType().then(resetPaymentState).catch(onError);
            }
            else {
                reloadOrder()
                    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                    .then(() => {
                    next();
                })
                    .catch(onError);
                refreshTrackingPage();
            }
            closeModal();
        });
    }, [onContinue]);
    useEffect(() => {
        canContinueChanged(adyenPaymentStatus === AdyenPaymentStatus.APPROVED);
    }, [
        adyenPaymentStatus,
        canContinueChanged,
        isReturnConsignment,
        product,
        requestedTimeWindow,
        isReturn,
        orderType,
    ]);
    return (_jsx("div", { className: "payment-container", children: _jsx(Checkout, { setPaymentReference: setPaymentReference, updateAdyenPaymentStatus: updateAdyenPaymentStatus, adyenSession: adyenSession, adyenPaymentStatus: adyenPaymentStatus, adyenCheckout: adyenCheckout, token: token, price: price, locale: locale, version: version }) }));
};
