import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { OrderType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { getBrand } from '../../../utils/brand/get-brand';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { ChatContact } from './chat-contact';
import { CustomerSupportHeader } from './customer-support-header';
import { EmailContact } from './email-contact';
import { PhoneContact } from './phone-contact';
import { styles } from './style';
export const Contact = (props) => {
    const { countryCode, authenticated, orderToken, authToken, merchantName, consumer, supportEmail, supportPhone, supportLink, boxOrder, } = props;
    const { t } = useI18n();
    const trackingApi = useTrackingApi();
    const [support, setSupport] = useState();
    const [fetchSupportFailed, setFetchSupportFailed] = useState(false);
    const brand = getBrand();
    useEffect(() => {
        const authorized = authToken || authenticated;
        if (!orderToken || !authorized) {
            return;
        }
        trackingApi
            .getSupport(authToken || '', orderToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                setSupport(response.payload);
            }
        })
            .catch(() => {
            setFetchSupportFailed(true);
        });
    }, [authToken, authenticated, orderToken, trackingApi]);
    const loading = !support && !fetchSupportFailed;
    const { chat, phone } = support || {};
    const chatOpen = (chat === null || chat === void 0 ? void 0 : chat.open) && !chat.temporarilyClosed;
    if (loading) {
        return _jsx(Loader, { style: styles.loader });
    }
    return (_jsxs(_Fragment, { children: [authenticated ? (_jsxs(_Fragment, { children: [_jsxs(View, { style: styles.header, children: [_jsx("span", { className: css(styles.title), children: t('openingHours') }), _jsx(View, { style: [styles.badgeOpen, !chatOpen ? styles.badgeClosed : undefined], children: _jsx("span", { className: css(styles.badgeText), children: chatOpen ? t('itsOpen') : t('closed') }) })] }), _jsxs(View, { style: styles.container, children: [(chat === null || chat === void 0 ? void 0 : chat.openHours) && _jsx("span", { className: css(styles.subtitle), children: chat.openHours }), (chat === null || chat === void 0 ? void 0 : chat.lunchHours) && _jsx("span", { className: css(styles.subtitle), children: chat.lunchHours }), (chat === null || chat === void 0 ? void 0 : chat.holidayHours) && (_jsx("span", { className: css(styles.subtitle), children: chat.holidayHours })), phone && _jsx(PhoneContact, { phone: phone, countryCode: countryCode }), brand !== 'budbee' && _jsx(EmailContact, { countryCode: countryCode }), chat && (_jsx(ChatContact, { chat: chat, countryCode: countryCode, token: orderToken, orderType: boxOrder ? OrderType.BOX : OrderType.DELIVERY, consumer: consumer, merchantName: merchantName }))] })] })) : null, boxOrder && (_jsx(CustomerSupportHeader, { merchantName: merchantName, supportEmail: supportEmail, supportPhone: supportPhone, supportLink: supportLink }))] }));
};
