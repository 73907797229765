import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { hooks } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useLanguage, useTimeZone } from '../../../state/locale/hooks';
import { useToken } from '../../../state/order/hooks';
import imageList from '../../../utils/image-list';
import { refreshTrackingPage } from '../../../utils/refresh-tracking-page';
import { ErrorMessage } from '../../error-message';
import { RadioButton } from '../../radio-button';
import { Loader } from '../../shared/loader';
import { SubTitle } from '../../subtitle';
import { Title } from '../../title';
import { styles } from './style';
export const ExtendLockerDuration = (props) => {
    const { authToken, canContinueChanged, onContinue, latestPickupDate } = props;
    const trackingApi = useTrackingApi();
    const { t, i18n } = useI18n();
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const token = useToken();
    const language = useLanguage();
    const timeZone = useTimeZone();
    const [availableDates, setAvailableDates] = useState(null);
    const [chosenDate, setChosenDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        canContinueChanged(Boolean(chosenDate));
    }, [canContinueChanged, chosenDate]);
    useEffect(() => {
        if (token && authToken) {
            setLoading(true);
            trackingApi
                .getRequestMoreBoxTimeDates(token, authToken)
                .call()
                .then((response) => {
                setLoading(false);
                if (response.status === ResponseStatus.COMPLETED) {
                    setAvailableDates(response.payload.availableDates);
                    return;
                }
                setError(true);
            })
                .catch(() => {
                setLoading(false);
                setError(true);
            });
        }
    }, [authToken, token, trackingApi]);
    useEffect(() => {
        if (!chosenDate || !token || !authToken) {
            return;
        }
        onContinue((next) => {
            trackingApi
                .requestMoreBoxTime(token, authToken, chosenDate)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    refreshTrackingPage();
                    next();
                    return;
                }
                setError(true);
            })
                .catch(() => {
                setError(true);
            });
        });
    }, [authToken, chosenDate, onContinue, token, trackingApi]);
    if (error) {
        return _jsx(ErrorMessage, {});
    }
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    return (_jsxs("div", { children: [_jsx(Title, { title: t('extendPickupTime.title'), style: styles.title }), _jsx(SubTitle, { text: t('extendPickupTime.subtitle', timeZone && latestPickupDate
                    ? {
                        date: i18n
                            .formatDeliveryDateWithoutTime(latestPickupDate, language, timeZone)
                            .toLowerCase(),
                    }
                    : ''), style: styles.subtitle }), availableDates === null || availableDates === void 0 ? void 0 : availableDates.map((date, i) => {
                if (!timeZone || !latestPickupDate) {
                    return null;
                }
                const { title, subtitle } = i18n.formatExtendLockerDurationTime(date, latestPickupDate, language, timeZone);
                const toggled = chosenDate === date;
                return (_jsx(RadioButton, { icon: imageList.Clock, toggled: toggled, style: styles.radioButton, title: title ? `${title}, ${subtitle}` : subtitle, onPress: () => setChosenDate(date) }, getKey({ i })));
            })] }));
};
