import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useCountryCode } from '../../state/locale/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import { SelectAccessMode } from '../select-access-mode';
import { SelectFloor } from '../select-floor';
import { AnimatedView } from '../shared/animated-view';
import { TextInput } from '../shared/text-input';
import { View } from '../shared/view';
import { styles } from './style';
export const ApartmentSettings = ({ isVisible, intercomFirst, floor, onChangeFloor, accessMode, onChangeAccessMode, doorCode, onChangeDoorCode, intercom, onChangeIntercom, careOf, onChangeCareOf, }) => {
    const { t } = useI18n();
    const countryCode = useCountryCode();
    const brand = getBrand();
    const isNL = countryCode === 'NL';
    if (!isVisible) {
        return null;
    }
    return (_jsxs(AnimatedView, { style: styles.container, animation: "slideInDown", children: [brand !== 'porterbuddy' && (_jsxs(View, { children: [_jsx("p", { className: css(styles.screenTitle), children: t('accessSettings.floor') }), _jsx(SelectFloor, { floor: floor, onFloorUpdated: onChangeFloor })] })), _jsxs(View, { children: [_jsx(SelectAccessMode, { currentAccessMode: accessMode, onChangeAccessMode: onChangeAccessMode, doorCode: doorCode, onDoorCodeChange: onChangeDoorCode, intercomCode: intercom, onIntercomCodeChange: onChangeIntercom, intercomFirst: intercomFirst }), brand === 'porterbuddy' && (_jsx(TextInput, { contentContainerStyle: styles.inputContentContainer, style: styles.input, value: floor.toString() || '', placeholder: t('accessSettings.floor'), onChange: (value) => {
                            onChangeFloor(parseInt(value, 10) || 0);
                        }, showCounter: false }))] }), _jsx(View, { children: !isNL && brand !== 'porterbuddy' && (_jsxs(View, { children: [_jsx("p", { className: css(styles.title), children: t('accessSettings.careOf.title') }), _jsx(TextInput, { contentContainerStyle: styles.inputContentContainer, style: styles.input, value: careOf, placeholder: t('accessSettings.careOf.placeholder'), onChange: (value) => onChangeCareOf(value), showCounter: false })] })) })] }, "house-description"));
};
