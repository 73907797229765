import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { date, orderSettings } from '@budbee/js/tracking';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { styles } from './style';
export const DriverStatusCard = (props) => {
    const { merchant, driver, eta, isReturn, code } = props;
    const { t } = useI18n();
    const driverText = orderSettings.getDriverStatusText(t, Boolean(isReturn), eta, merchant, code);
    return (_jsx("div", { className: css(styles.root), children: _jsxs("div", { className: css(styles.container), children: [_jsxs("div", { className: css(styles.topContainer), children: [eta ? (_jsxs("div", { className: css(styles.eta), children: [_jsx(Icon, { src: imageList.Clock, size: IconSize.md, color: Color.Budbee.Grey6 }), _jsx("span", { className: css(styles.etaText), children: date.formatEtaTime(eta.date) })] })) : null, driver && driver.icon ? (_jsx("img", { className: css(styles.icon), src: driver.icon, alt: "icon" })) : null] }), _jsxs("div", { className: css(styles.bottomContainer), children: [driver && driver.name ? (_jsx("span", { className: css(styles.driverName), children: t('driverName', {
                                driver: orderSettings.formatDriverName(driver.name),
                            }) })) : null, _jsx("span", { className: css(styles.driverStatusText), children: driverText })] })] }) }));
};
