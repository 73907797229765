import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { AnimatedView } from '../shared/animated-view';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const TemperatureControlBanner = () => {
    const [isClosed, setIsClosed] = useState(false);
    const { t } = useI18n();
    if (isClosed)
        return null;
    return (_jsxs(AnimatedView, { animation: "slideInDown", style: styles.root, children: [_jsx(View, { style: styles.message, children: t('banner.temperatureSensitive') }), _jsx(View, { style: styles.close, onClick: () => setIsClosed(true), children: _jsx(Icon, { src: imageList.PorterbuddyClose, size: IconSize.md }) })] }));
};
