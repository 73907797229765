import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { MediaQuery } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: Space['5xl'],
        position: 'relative',
        minHeight: 50,
        gap: Space.md,
        [MediaQuery.maxWidthMd]: {
            padding: Space['2xl'],
        },
        porterbuddy: {
            padding: Space['2xl'],
            marginBottom: Space['2xs'],
        },
    },
    onlyBack: {
        justifyContent: 'flex-start',
    },
    back: {
        fontWeight: 500,
        fontSize: 16,
        width: '50%',
        padding: 0,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        flex: '50%',
        porterbuddy: {
            marginRight: Space.xs,
        },
    },
    continue: {
        height: 50,
        padding: 0,
        boxSizing: 'border-box',
        flex: '50%',
        display: 'flex',
        justifyContent: 'center',
        porterbuddy: {
            marginLeft: Space.xs,
        },
    },
    button: {
        width: '100%',
    },
});
