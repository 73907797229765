import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { Color, IconSize, Spacing } from '../../../styles';
import { formatLockerOpenHours } from '../../../utils/date';
import imageList from '../../../utils/image-list';
import { IconButton } from '../../icon-button';
import { View } from '../view';
import { styles } from './style';
const getLabel = (t, isCurrentLocker, available = false) => {
    let text;
    if (isCurrentLocker) {
        text = t('map.callout.currentLocker');
    }
    else if (!available) {
        text = t('map.callout.unavailableLocker');
    }
    else {
        return null;
    }
    return (_jsx("p", { className: css(styles.label, isCurrentLocker && styles.currentLockerLabel, !available && styles.unavailableLockerLabel), children: text }));
};
export const LockerCalloutItem = ({ locker, lastItem, onSelectLocker, selectedLocker, }) => {
    const { t } = useI18n();
    const { openHours, lockerAddress, available, lockerIdentifier } = locker;
    const dates = formatLockerOpenHours({ openHours });
    const isCurrentLocker = selectedLocker && selectedLocker.lockerIdentifier === lockerIdentifier;
    const lockerIsSelectable = !isCurrentLocker && available;
    return (_jsx("div", { role: "button", tabIndex: 0, onClick: lockerIsSelectable ? () => onSelectLocker(locker) : undefined, onKeyPress: lockerIsSelectable ? () => onSelectLocker(locker) : undefined, className: css(styles.lockerCalloutItem, lastItem && styles.lockerCalloutItemLast, lockerIsSelectable && styles.selectable), children: _jsxs(View, { style: styles.calloutItemWrapper, children: [_jsxs(View, { style: styles.calloutItemContainer, children: [_jsx("p", { className: css(styles.lockerName), children: lockerAddress.name }), _jsx("p", { className: css(styles.calloutItemText), children: `${lockerAddress.street}, ${lockerAddress.postalCode}
                    ${lockerAddress.city}` }), _jsxs(View, { style: styles.row, children: [getLabel(t, isCurrentLocker || false, available), available && (_jsx("p", { className: css(styles.lockerOpeningHours, !dates && styles.lockerClosingHours), children: dates ? t('openBetween', { date: dates }) : t('closedToday') }))] })] }), lockerIsSelectable && (_jsx(IconButton, { src: imageList.chevronRight, color: Color.Black, size: IconSize.sm, padding: Spacing.sm }))] }) }));
};
