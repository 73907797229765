import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from 'aphrodite';
import { IconSize } from '../../../styles';
import { inlineStyles } from '../../../utils/inline-styles';
import { Icon } from '../../shared/icon';
import { Spinner } from '../../spinner';
import { styles } from './style';
export const BudbeeBaseButton = ({ iconSrc, iconPosition = 'left', label, onClick, isLoading, disabled, style, variant = 'primary', size = 'lg', }) => {
    const height = useMemo(() => {
        if (size === 'lg') {
            return 54;
        }
        if (size === 'md') {
            return 42;
        }
        if (size === 'sm') {
            return 33;
        }
        return 54;
    }, [size]);
    const iconColor = useMemo(() => {
        if (variant === 'primary' || variant === 'dark') {
            return Color.White100;
        }
        if (variant === 'secondary') {
            return Color.Budbee.GreenDark;
        }
        return Color.Budbee.Grey5;
    }, [variant]);
    const iconSize = useMemo(() => {
        if (size === 'lg') {
            return IconSize.xl;
        }
        if (size === 'md') {
            return IconSize.md;
        }
        if (size === 'sm') {
            return IconSize.xs;
        }
        return IconSize.xl;
    }, [size]);
    return (_jsx("button", { type: "button", disabled: disabled || isLoading, onClick: onClick, className: css(styles.root, inlineStyles({
            height,
        }), variant === 'primary' ? styles.primaryRoot : undefined, variant === 'secondary' ? styles.secondaryRoot : undefined, variant === 'dark' ? styles.darkRoot : undefined, variant === 'light' ? styles.lightRoot : undefined, disabled ? styles.disabled : undefined, style), children: isLoading ? (_jsx(Spinner, { size: IconSize['4xl'], color: Color.Black100 })) : (_jsxs(_Fragment, { children: [iconSrc && iconPosition === 'left' ? (_jsx(Icon, { src: iconSrc, size: iconSize, color: iconColor })) : null, _jsx("span", { className: css([
                        size === 'lg' ? styles.lg : undefined,
                        size === 'md' ? styles.md : undefined,
                        size === 'sm' ? styles.sm : undefined,
                        variant === 'primary' ? styles.whiteText : undefined,
                        variant === 'secondary' ? styles.greenText : undefined,
                        variant === 'dark' ? styles.whiteText : undefined,
                        variant === 'light' ? styles.greyText : undefined,
                    ]), children: label }), iconSrc && iconPosition === 'right' ? (_jsx(Icon, { src: iconSrc, size: iconSize, color: iconColor })) : null] })) }));
};
