import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    dateChanger: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: Spacing.lg,
        marginBottom: Spacing.lg,
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    day: {
        ...Typography.Budbee.Web.h5,
        textAlign: 'center',
        backgroundColor: Color.Budbee.GreenLight,
        color: Color.Budbee.GreenDark,
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        borderRadius: Border.Radius.sm,
        porterbuddy: {
            borderRadius: Border.Radius['2xs'],
            backgroundColor: Color.Porterbuddy.BuddyPurple,
            color: Color.White100,
        },
    },
    date: {
        ...Typography.Budbee.Web['body-md'],
        textAlign: 'center',
        marginTop: Space.sm,
    },
});
