import { createReducer } from '../../utils/create-reducer';
import { SET_BOX_CHECKPOINTS, SET_BOX_CODE, SET_BOX_CONSIGNMENT, SET_BOX_ORDER, SET_BOX_ORDER_LOCKER, SET_BOX_STATUS, SET_IS_FETCHING_BOX_ORDER, SET_SYSTEM, } from './actions';
const initState = {
    boxOrderInfo: null,
    boxCheckpoints: null,
    isFetchingBoxOrder: false,
    code: null,
    needsVerification: false,
    system: null,
};
export const boxOrder = createReducer(initState, {
    [SET_BOX_ORDER]: (state = initState, action) => ({
        ...state,
        boxOrderInfo: action.payload.data,
    }),
    [SET_IS_FETCHING_BOX_ORDER]: (state = initState, action) => ({
        ...state,
        isFetchingBoxOrder: action.payload.loading,
    }),
    [SET_BOX_ORDER_LOCKER]: (state = initState, action) => ({
        ...state,
        boxOrderInfo: state.boxOrderInfo
            ? {
                ...state.boxOrderInfo,
                ...action.payload.locker,
            }
            : null,
    }),
    [SET_BOX_STATUS]: (state = initState, action) => state.boxOrderInfo
        ? {
            ...state,
            boxOrderInfo: {
                ...state.boxOrderInfo,
                status: action.payload.status,
            },
        }
        : {
            ...state,
        },
    [SET_BOX_CONSIGNMENT]: (state = initState, action) => state.boxOrderInfo
        ? {
            ...state,
            boxOrderInfo: {
                ...state.boxOrderInfo,
                consignment: action.payload.consignment,
            },
        }
        : {
            ...state,
        },
    [SET_BOX_CHECKPOINTS]: (state = initState, action) => ({
        ...state,
        boxCheckpoints: action.payload.boxCheckpoints,
    }),
    [SET_BOX_CODE]: (state = initState, action) => ({
        ...state,
        code: action.payload.code,
        needsVerification: action.payload.needsVerification,
    }),
    [SET_SYSTEM]: (state = initState, action) => ({
        ...state,
        system: action.payload.system,
    }),
});
