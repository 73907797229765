import { useSelector } from 'react-redux';
import { getCountryCode, getCurrentLocale, getDefaultLocale, getLanguage, getTimeZone, } from './selectors';
export function useCurrentLocale() {
    return useSelector(getCurrentLocale);
}
export function useDefaultLocale() {
    return useSelector(getDefaultLocale);
}
export function useLanguage() {
    return useSelector(getLanguage);
}
export function useCountryCode() {
    return useSelector(getCountryCode);
}
export function useTimeZone() {
    return useSelector(getTimeZone);
}
