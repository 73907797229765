import { combineReducers } from '@reduxjs/toolkit';
import { banner } from './banner/reducer';
import { boxOrder } from './box-order/reducer';
import { cookies } from './cookies/reducer';
import { eta } from './eta/reducer';
import { features } from './features/reducer';
import { locale } from './locale/reducer';
import { lockers } from './lockers/reducer';
import { modal } from './modal/reducer';
import { order } from './order/reducer';
import { payment } from './payment/reducer';
import { positions } from './positions/reducer';
import { preface } from './preface/reducer';
import { returns } from './returns/reducer';
import { socket } from './socket/reducer';
import { timetable } from './timetable/reducer';
export const rootReducer = combineReducers({
    order,
    boxOrder,
    positions,
    socket,
    cookies,
    preface,
    timetable,
    payment,
    modal,
    lockers,
    returns,
    locale,
    eta,
    features,
    banner,
});
