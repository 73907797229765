import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, MediaQuery, Spacing, marginHorizontal, paddingHorizontal, } from '../../styles';
import imageList from '../../utils/image-list';
export const styles = StyleSheet.create({
    root: {
        position: 'relative',
        width: 560,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    container: {
        minHeight: '20rem',
        backgroundColor: Color.Budbee.Grey1,
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.ChumPurple,
        },
        [MediaQuery.maxWidthMd]: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0',
        },
    },
    loader: {
        height: '6rem',
        width: '6rem',
        margin: '10rem auto',
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        cursor: 'pointer',
        top: 25,
        right: 25,
        height: 30,
        width: 30,
        backgroundColor: Color.White100,
        borderRadius: 50,
        border: 0,
        zIndex: 9999,
        [MediaQuery.maxWidthMd]: {
            right: 20,
        },
        porterbuddy: {
            top: Space.sm,
            right: Space.lg,
            height: 'auto',
            width: 'auto',
            backgroundColor: 'transparent',
        },
    },
    closeIconBlack: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    closeText: {
        ...Typography.Porterbuddy.Web.h3,
        textTransform: 'lowercase',
        color: Color.Porterbuddy.Grey4,
        marginRight: Space['2xs'],
        marginBottom: Space['3xs'],
    },
    header: {
        position: 'relative',
        width: '100%',
        height: 80,
        zIndex: 1,
        [MediaQuery.minWidthMd]: {
            borderTopLeftRadius: BorderRadius.lg,
            borderTopRightRadius: BorderRadius.lg,
        },
        porterbuddy: {
            backgroundImage: `url(${imageList.PorterbuddyHero})`,
            height: 120,
            width: 'auto',
            borderRadius: Border.Radius['2xs'],
            objectFit: 'contain',
            backgroundSize: '150%',
            marginTop: Space['5xl'],
            ...marginHorizontal(Space.lg),
            ...paddingHorizontal(Space.md),
            marginBottom: Space.lg,
        },
    },
    headerInline: {
        height: '100%',
    },
    headerItem: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        porterbuddy: {
            height: '100%',
            width: 'auto',
            margin: 'none',
        },
    },
    headerItemInline: {
        padding: 0,
    },
    headerItemRegular: {
        paddingLeft: 80,
        paddingRight: 80,
        [MediaQuery.maxWidthMd]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
    },
    headerTitle: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        textAlign: 'center',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h1,
            color: Color.White100,
        },
    },
    content: {
        position: 'relative',
        boxSizing: 'border-box',
        padding: Spacing.xl,
        marginBottom: 'auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        porterbuddy: {
            paddingTop: Space.lg,
        },
    },
    footer: {
        width: '100%',
    },
});
