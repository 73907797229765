import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { ConfirmDeliveryReason, ConsignmentType, orderSettings, ParcelStatus, } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import { useTrackingHeaderText } from './hooks/use-tracking-header-text';
import { useAuthToken, useConspectus, useHasReturnPhotoUploaded, useIsAllowedToChangeReturnPhoto, useReturnableParcelCount, useReturnsEnabled, } from '../../state/order/hooks';
import { useLatestPosition } from '../../state/positions/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import { isConsignmentCancelled } from '../../utils/cancellation';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { styles } from './style';
import { useEtaHeader } from './hooks/useEtaHeader';
export const TrackingHeader = ({ authenticated, toggleBookConsignmentModal, toggleLockerPickerModal, toggleReturnsModal, toggleChangeReturnPhotoModal, toggleSettingsModal, toggleIdentificationModal, toggleLiveTrackingMap, }) => {
    const brand = getBrand();
    const allowedToChangeReturnPhoto = useIsAllowedToChangeReturnPhoto();
    const hasReturnPhotoUploaded = useHasReturnPhotoUploaded();
    const returnableParcelCount = useReturnableParcelCount();
    const returnsEnabled = useReturnsEnabled();
    const position = useLatestPosition();
    const authToken = useAuthToken();
    const trackingApi = useTrackingApi();
    const conspectus = useConspectus();
    const { status, identification, canSwitchDeliveryType, consignment, settings, orderToken } = conspectus || {};
    const [showConfirmDeliveryButton, setShowConfirmDeliveryButton] = useState(settings === null || settings === void 0 ? void 0 : settings.temperatureConfirmationRequired);
    const [showDeliveryConfirmed, setShowDeliveryConfirmed] = useState(false);
    const { topText, middleText, bottomText, badgeText } = useTrackingHeaderText({
        showDeliveryConfirmed,
    });
    const isReturn = (consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN;
    const cancelled = isConsignmentCancelled(consignment);
    const { t } = useI18n();
    const etaHeader = useEtaHeader();
    const confirmDelivery = useCallback(() => {
        if (!orderToken || !authToken) {
            return;
        }
        trackingApi
            .confirmDelivery(authToken, orderToken, ConfirmDeliveryReason.TEMPERATURE_SENSITIVE)
            .call()
            .then(() => {
            setShowDeliveryConfirmed(true);
            setShowConfirmDeliveryButton(false);
        })
            .catch((error) => {
            console.error('Error confirming delivery:', error);
        });
    }, [authToken, orderToken, trackingApi]);
    const canChangeConsignment = (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.NotStarted &&
        conspectus &&
        (conspectus.allowedToBookDelivery || conspectus.returns.enabled);
    const canRebookCancelledReturn = cancelled && isReturn;
    const isMissed = (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.Miss;
    const showLiveTrackingButton = (conspectus === null || conspectus === void 0 ? void 0 : conspectus.driver) &&
        position &&
        ((status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.Collected ||
            (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.OnRouteDelivery ||
            (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.CollectedShippingLabel ||
            (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.CrossDocked);
    const baseButtonVariant = brand !== 'porterbuddy' ? 'primary' : 'secondary';
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: topText, middleText: etaHeader || middleText, bottomText: bottomText, badgeText: badgeText }), authenticated ? (_jsx(_Fragment, { children: _jsxs(View, { style: styles.actionsWrapper, children: [showLiveTrackingButton && brand !== 'porterbuddy' ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { iconSrc: imageList.Geotag, label: isReturn ? t('followReturn') : t('followDelivery'), onClick: toggleLiveTrackingMap, variant: baseButtonVariant }) })) : null, canChangeConsignment && !canRebookCancelledReturn ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: isReturn
                                    ? t('upgrade.deliveryTime.return.change')
                                    : t('upgrade.deliveryTime.delivery.change'), iconSrc: isReturn ? imageList.Return : imageList.Clock, onClick: isReturn ? toggleReturnsModal : toggleBookConsignmentModal, variant: baseButtonVariant }) })) : null, canRebookCancelledReturn && !isMissed ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('rebook.failed.return.button'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: baseButtonVariant }) })) : null, isMissed ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: isReturn ? t('rebook.failed.return.button') : t('rebook.failed.delivery.button'), iconSrc: isReturn ? imageList.Return : imageList.Reverse, onClick: isReturn ? toggleReturnsModal : toggleBookConsignmentModal, variant: baseButtonVariant }) })) : null, (identification === null || identification === void 0 ? void 0 : identification.needsVerification) &&
                            !cancelled &&
                            (status === null || status === void 0 ? void 0 : status.state) !== ParcelStatus.Delivered &&
                            (status === null || status === void 0 ? void 0 : status.state) !== ParcelStatus.Miss ? (_jsx(View, { style: styles.actionContainer, "data-test": "needs-verification-button", children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: baseButtonVariant }) })) : null, canSwitchDeliveryType ? (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { isHomeDelivery: true, toggleSwitchDeliveryTypeModal: toggleLockerPickerModal }) })) : null, allowedToChangeReturnPhoto && !canRebookCancelledReturn ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t(hasReturnPhotoUploaded
                                    ? 'returnUploadPhoto.overviewButtonChangePhoto'
                                    : 'returnUploadPhoto.overviewButtonUploadPhoto'), iconSrc: imageList.Camera, onClick: toggleChangeReturnPhotoModal, variant: baseButtonVariant }) })) : null, (consignment === null || consignment === void 0 ? void 0 : consignment.type) !== ConsignmentType.RETURN &&
                            returnsEnabled &&
                            returnableParcelCount > 0 ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: baseButtonVariant }) })) : null, brand !== 'porterbuddy' &&
                            orderSettings.shouldRenderSettingsButton(status === null || status === void 0 ? void 0 : status.state, isReturn) ? (_jsx(View, { style: styles.actionContainer, "data-test": "settings-btn", children: _jsx(BaseButton, { label: t('goToSettingsBox'), iconSrc: imageList.Settings, onClick: toggleSettingsModal, variant: baseButtonVariant }) })) : null, brand === 'porterbuddy' &&
                            (status === null || status === void 0 ? void 0 : status.state) === ParcelStatus.Delivered &&
                            (settings === null || settings === void 0 ? void 0 : settings.temperatureConfirmationRequired) &&
                            showConfirmDeliveryButton ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('trackingHeader.temperatureConfirmationRequired.buttonText'), onClick: confirmDelivery, variant: "secondary" }) })) : null] }) })) : null] }));
};
