import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useEffect } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { FontSize, Spacing } from '../../../styles';
import { inlineStyles } from '../../../utils/inline-styles';
import { ReturnPhotoUploader } from '../../return-photo-uploader';
import { Title } from '../../title';
export const ReturnUploadPhoto = (props) => {
    const { onNext, onContinue, token, authToken, canContinueChanged, setHasReturnPhotoUploaded } = props;
    const { t } = useI18n();
    const [uploadRequest, setUploadRequest] = useState();
    const [photoChanged, setPhotoChanged] = useState(false);
    useEffect(() => {
        canContinueChanged(true);
    }, [canContinueChanged]);
    useEffect(() => {
        if (!photoChanged) {
            onContinue((next) => next());
        }
        else {
            onContinue((next, onError) => {
                setUploadRequest(() => (fn) => {
                    fn(next, onError, setHasReturnPhotoUploaded);
                });
            });
        }
    }, [onContinue, photoChanged, setHasReturnPhotoUploaded]);
    const onPhotoChanged = useCallback(() => {
        setPhotoChanged(true);
    }, [setPhotoChanged]);
    const onContinueStep = useCallback(() => {
        onNext();
    }, [onNext]);
    return (_jsxs(_Fragment, { children: [_jsx(Title, { title: t('bookReturnTitle'), style: inlineStyles({
                    textAlign: 'center',
                    fontSize: FontSize.md,
                    marginTop: Spacing.xs,
                }) }), _jsx("p", { className: css(inlineStyles({ color: Color.Black100 })), children: t('returnUploadPhoto.description') }), _jsx(ReturnPhotoUploader, { token: token, authToken: authToken, onContinue: onContinueStep, onPhotoChanged: onPhotoChanged, onUploadRequested: uploadRequest })] }));
};
