import { StyleSheet } from '@css';
import { BorderWidth, Spacing } from '../../../styles';
export const styles = StyleSheet.create({
    inline: {
        position: 'relative',
        width: Spacing.lg,
        height: Spacing.lg,
        borderWidth: BorderWidth.xl,
        margin: 0,
        marginLeft: Spacing.lg,
    },
    inlineMiddle: {
        margin: '0 auto',
    },
    loader: {
        width: 70,
        height: 70,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
    },
});
