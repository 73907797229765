import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { IconSize } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import { MerchantLogo } from '../merchant-logo';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export function DeliveryInformation({ topText, middleText, leftIcon, rightIcon, rightIconType = 'icon', onClick, children, }) {
    const brand = getBrand();
    return (_jsxs("div", { className: css(styles.root), children: [brand !== 'porterbuddy' && (_jsx(View, { style: styles.leftContainer, children: _jsx(Icon, { src: leftIcon, size: IconSize['3xl'], color: Color.Budbee.Grey5 }) })), _jsxs(View, { style: styles.textContainer, children: [_jsx("span", { className: css(styles.topText), children: topText }), onClick ? (_jsx("button", { type: "button", "data-test": "box-delivery-information-onclick-button", onClick: onClick, className: css(styles.clickable), children: _jsx("span", { className: css(styles.middleText), children: middleText }) })) : (_jsx("span", { className: css(styles.middleText), children: middleText })), children ? _jsx(View, { style: styles.childrenContainer, children: children }) : null] }), rightIconType !== 'hidden' ? (_jsx(View, { style: styles.rightContainer, children: _jsx(DeliveryInformationRightIcon, { rightIcon: rightIcon, rightIconType: rightIconType, onClick: onClick }) })) : null] }));
}
function DeliveryInformationRightIcon({ rightIcon, rightIconType, onClick, }) {
    const image = _jsx("img", { src: rightIcon, className: css(styles.rightIcon), alt: "" });
    if (onClick && rightIcon && rightIconType === 'icon') {
        return (_jsx("button", { type: "button", onClick: onClick, className: css(styles.clickable), children: _jsx(Icon, { src: rightIcon, size: IconSize.md, color: Color.Budbee.Grey5 }) }));
    }
    if (rightIconType === 'icon') {
        return image;
    }
    return _jsx(MerchantLogo, { size: 38, logoUrl: rightIcon });
}
