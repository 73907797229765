import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/dist/tracking-api';
import { OrderType } from '@budbee/js/tracking';
import { css } from '@css';
import { compose } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { NotFound } from '../../components/not-found';
import { OpenInAppPromo } from '../../components/open-in-app-promo';
import { CookieBanner } from '../../components/shared/cookie-banner';
import { Loader } from '../../components/shared/loader';
import { View } from '../../components/shared/view';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import { setSystem as setSystemAction } from '../../state/box-order/actions';
import { checkIfCookiesAccepted as checkIfCookiesAcceptedAction } from '../../state/cookies/actions';
import { useCookiesAccepted } from '../../state/cookies/hooks';
import { setCountry as setCountryAction, setCurrentLocale as setCurrentLocaleAction, setDefaultLocale as setDefaultLocaleAction, setLanguage as setLanguageAction, } from '../../state/locale/actions';
import { resetLockerState as resetLockerStateAction } from '../../state/lockers/actions';
import { resetOrderState as resetOrderStateAction, setOrderToken as setOrderTokenAction, setOrderType as setOrderTypeAction, } from '../../state/order/actions';
import { useOrderType } from '../../state/order/hooks';
import { applyModalBodyHtmlClass } from '../../utils/dom-classname';
import { getPersistedPreferredLanguage } from '../../utils/locale';
import { replaceTrackingUrl } from '../../utils/replace-tracking-url';
import { BoxTrackingContainer } from '../box-tracking-container';
import { TrackingContainer } from '../tracking-container';
import { styles } from './style';
const BootComponent = ({ location, match, resetOrderState, setOrderToken, resetLockerState, setOrderType, setCurrentLocale, setDefaultLocale, setCountry, setLanguage, setSystem, checkIfCookiesAccepted, }) => {
    const cookiesAccepted = useCookiesAccepted();
    const orderType = useOrderType();
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const trackingApi = useTrackingApi();
    const [realToken, setRealToken] = useState(null);
    const [realAuth, setRealAuth] = useState(null);
    const { auth } = queryString.parse((location === null || location === void 0 ? void 0 : location.search) || '');
    const { token } = match === null || match === void 0 ? void 0 : match.params;
    // 2. Get order meta-data, contains some useful info like order type (HOME/BOX) and locale
    const getOrderMeta = useCallback((orderToken) => {
        trackingApi
            .getOrderMeta(orderToken)
            .call()
            .then((response) => {
            if ((response.status === ResponseStatus.FAILED &&
                response.errorCode === 'ORDER_NOT_FOUND') ||
                !response.payload) {
                setLoading(false);
                setNotFound(true);
                return;
            }
            const { type, country, defaultLocale, system } = response.payload;
            applyModalBodyHtmlClass(false);
            if (type !== OrderType.BOX) {
                resetLockerState();
            }
            const persistedLanguage = getPersistedPreferredLanguage();
            const localeToUse = persistedLanguage ? `${persistedLanguage}_${country}` : defaultLocale;
            const languageToUse = persistedLanguage || defaultLocale.slice(0, 2);
            setCurrentLocale(localeToUse);
            setDefaultLocale(defaultLocale);
            setCountry(country);
            setLanguage(languageToUse);
            setOrderToken(orderToken);
            setOrderType(type);
            setSystem(system);
        })
            .catch(() => {
            setNotFound(true);
        });
    }, [
        // Don't include trackingApi, when locale is changed this function will rerun causing unwanted api calls
        token,
        setOrderType,
        setCurrentLocale,
        setDefaultLocale,
        setCountry,
        setLanguage,
        setOrderToken,
        setSystem,
        resetLockerState,
    ]);
    // 1. First get auth-info, could be another order token and auth than what's currently in the url
    // if the order has been converted to from home => box or vice versa.
    // We set this token/auth in component state and update the browser URL to the correct one
    // and then fetch order-meta based on the "real" order token.
    const getAuthInfo = useCallback(() => {
        return trackingApi
            .getAuthInfo(token, (auth === null || auth === void 0 ? void 0 : auth.toString()) || '')
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                const { trackingId, authToken } = response.payload;
                setRealToken(trackingId);
                setRealAuth(authToken);
                replaceTrackingUrl(trackingId, authToken);
                getOrderMeta(trackingId);
            }
            else {
                getOrderMeta(token);
            }
        })
            .catch(() => getOrderMeta(token));
    }, [
        // Don't include trackingApi, when locale is changed this function will rerun causing unwanted api calls
        auth,
        getOrderMeta,
        token,
    ]);
    useEffect(() => {
        resetOrderState();
        getAuthInfo();
    }, [getAuthInfo, resetOrderState]);
    useEffect(() => {
        checkIfCookiesAccepted();
    }, [checkIfCookiesAccepted]);
    const childProps = {
        token: realToken || token,
        authToken: realAuth || (auth === null || auth === void 0 ? void 0 : auth.toString()),
        reloadOrderType: getAuthInfo,
        setLoadingFinished: () => setLoading(false),
    };
    if (notFound) {
        return _jsx(NotFound, {});
    }
    return (_jsxs(View, { style: styles.root, children: [_jsx("div", { className: css(styles.container), children: _jsxs("div", { className: css(styles.innerContainer), children: [_jsx(OpenInAppPromo, { token: token }), loading ? _jsx(Loader, {}) : null, orderType && orderType === OrderType.BOX ? (_jsx(BoxTrackingContainer, { ...childProps })) : null, orderType && orderType === OrderType.DELIVERY ? (_jsx(TrackingContainer, { ...childProps })) : null] }) }), !cookiesAccepted && _jsx(CookieBanner, {})] }));
};
const mapDispatchToProps = (dispatch) => ({
    resetOrderState: compose(dispatch, resetOrderStateAction),
    setOrderToken: compose(dispatch, setOrderTokenAction),
    resetLockerState: compose(dispatch, resetLockerStateAction),
    setOrderType: compose(dispatch, setOrderTypeAction),
    setCurrentLocale: compose(dispatch, setCurrentLocaleAction),
    setDefaultLocale: compose(dispatch, setDefaultLocaleAction),
    setCountry: compose(dispatch, setCountryAction),
    setLanguage: compose(dispatch, setLanguageAction),
    setSystem: compose(dispatch, setSystemAction),
    checkIfCookiesAccepted: compose(dispatch, checkIfCookiesAcceptedAction),
});
export const BootContainer = connect(null, mapDispatchToProps)(BootComponent);
