import { useState, useEffect, useCallback } from 'react';
export const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';
    const getWindowDimensions = useCallback(() => {
        const windowWidth = hasWindow ? window.innerWidth : undefined;
        const windowHeight = hasWindow ? window.innerHeight : undefined;
        return {
            windowWidth,
            windowHeight,
        };
    }, [hasWindow]);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            };
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
        return undefined;
    }, [getWindowDimensions, hasWindow]);
    return windowDimensions;
};
