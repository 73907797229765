import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { IconSize, marginHorizontal } from '../../../styles';
export const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        textAlign: 'center',
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space['5xl'],
        textAlign: 'left',
    },
    loader: {
        width: IconSize['11xl'],
        height: IconSize['11xl'],
    },
    divider: {
        marginTop: Space['5xl'],
        ...marginHorizontal(Space['2xl']),
    },
});
