import { createReducer } from '../../utils/create-reducer';
import { SET_ETA } from './actions';
const initState = {
    eta: null,
};
export const eta = createReducer(initState, {
    [SET_ETA]: (state = initState, action) => ({
        ...state,
        eta: action.payload.eta,
    }),
});
