import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { hooks } from '@budbee/js/tracking';
import { css } from '@css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Color, IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { InstructionItem } from '../instruction-item';
import { Icon } from '../shared/icon';
import { styles } from './style';
export const InstructionList = ({ instructions }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const { length } = instructions;
    const setPrevSlide = () => setCurrentIndex(currentIndex - 1);
    const setNextSlide = () => setCurrentIndex(currentIndex + 1);
    const updateCurrentSlide = (index) => setCurrentIndex(index);
    return (_jsxs("div", { children: [_jsx(Carousel, { selectedItem: currentIndex, onChange: updateCurrentSlide, showThumbs: false, showIndicators: false, showArrows: false, showStatus: false, swipeable: true, emulateTouch: true, children: instructions.map((instruction) => (_jsx("div", { className: css(styles.spacingHorizontal), children: _jsx(InstructionItem, { text: instruction }) }, getKey({ instruction })))) }), _jsxs("div", { className: css(styles.navigation), children: [_jsx("button", { type: "button", onClick: setPrevSlide, className: css(styles.arrowButton, currentIndex > 0 && styles.clickable), children: _jsx(Icon, { src: imageList.ArrowLeft, size: IconSize.lg, color: currentIndex > 0 ? Color.Black : Color.InactiveGrey }) }), _jsx("div", { className: css(styles.dotContainer), children: instructions.map((instruction, index) => (_jsx("span", { className: css(styles.dot, index === currentIndex && styles.activeDot, index < length - 1 && styles.spacingRight) }, getKey({ instruction })))) }), _jsx("button", { type: "button", onClick: setNextSlide, className: css(styles.arrowButton, currentIndex < length - 1 && styles.clickable), children: _jsx(Icon, { src: imageList.ArrowRight, size: IconSize.lg, color: currentIndex < length - 1 ? Color.Black : Color.InactiveGrey }) })] })] }));
};
