import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { envConfig } from '../env.config';
export function setupSentry() {
    if (!envConfig.isProd) {
        return;
    }
    Sentry.init({
        dsn: 'https://8255ae1c7fbe493eaa9a847f8359f50b@o34206.ingest.sentry.io/222823',
        environment: envConfig.NODE_ENV,
        release: envConfig.RELEASE_ID,
        integrations: [
            new BrowserTracing(),
            new Sentry.Integrations.GlobalHandlers({
                onunhandledrejection: false,
                onerror: true,
            }),
        ],
        attachStacktrace: true,
        // list taken from sentry documentation: https://docs.sentry.io/platforms/javascript/configuration/filtering/
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            // Facebook borked
            'fb_xd_fragment',
            "undefined is not an object (evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen')",
            // https://stackoverflow.com/a/69576781
            'instantSearchSDKJSBridgeClearHighlight',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
            // reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
        ],
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
    });
}
