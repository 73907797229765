import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { MediaQuery, Spacing } from '../../styles';
export const styles = StyleSheet.create({
    actionsWrapper: {
        marginTop: Space.lg,
        position: 'relative',
        zIndex: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -Spacing.md,
        [MediaQuery.maxWidthMd]: {
            marginRight: -Spacing.md,
        },
    },
    actionContainer: {
        flex: '50%',
        boxSizing: 'border-box',
        paddingRight: Spacing.md,
        paddingTop: Spacing.md,
        [MediaQuery.maxWidthMd]: {
            flex: '100%',
        },
    },
});
