import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color, Space } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { usePrivacyNoticeLink } from '../../state/order/hooks';
import { IconSize } from '../../styles';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { View } from '../shared/view';
import { styles } from './privacy-notice.styles';
export const PrivacyNotice = () => {
    const privacyNoticeLink = usePrivacyNoticeLink();
    const { t } = useI18n();
    if (!privacyNoticeLink) {
        return null;
    }
    return (_jsxs("a", { href: privacyNoticeLink, className: css(styles.root), target: "_blank", rel: "noreferrer", children: [t('privacyNotice'), _jsx(View, { style: inlineStyles({ marginLeft: Space['2xs'] }), children: _jsx(BrandedIcon, { src: {
                        budbee: imageList.ExternalLink,
                        instabox: imageList.ExternalLinkInstabox,
                        porterbuddy: imageList.ExternalLinkPorterbuddy,
                    }, size: IconSize.xs, color: {
                        budbee: Color.Budbee.Grey4,
                        porterbuddy: Color.Porterbuddy.BuddyPurple,
                    } }) })] }));
};
