import { VehicleIcon } from '@budbee/js/tracking';
import imageList from './image-list';
const { VehicleIconVan, VehicleIconElectricVan, VehicleIconEnvironmentalVan, VehicleIconBicycle, VehicleIconSantasSleigh, } = imageList;
const VehicleMapIcon = {
    [VehicleIcon.VAN]: VehicleIconVan,
    [VehicleIcon.ELECTRIC_VAN]: VehicleIconElectricVan,
    [VehicleIcon.ENVIRONMENTAL_VAN]: VehicleIconEnvironmentalVan,
    [VehicleIcon.BICYCLE]: VehicleIconBicycle,
    [VehicleIcon.SANTAS_SLEIGH]: VehicleIconSantasSleigh,
};
export const vehicleIcon = (icon) => VehicleMapIcon[icon] || VehicleMapIcon[VehicleIcon.VAN];
