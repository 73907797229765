import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Color } from '@budbee/js/dist/ui';
import { date as JSDate } from '@budbee/js/tracking';
import { css } from '@css';
import moment from 'moment-timezone';
import { useDateFormat } from '../../hooks/use-date-format';
import { useI18n } from '../../hooks/use-i18n';
import { useCountryCode } from '../../state/locale/hooks';
import { IconSize } from '../../styles';
import { formatLockerOpenHours, getRelativeLockerOpeningHours } from '../../utils/date';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { Title } from '../title';
import { styles } from './style';
export const LockerLocationCard = ({ lockerAddress, openHours, variant = 'regular', reservableUntil, reservationDurationSeconds, }) => {
    const { directions } = lockerAddress;
    const { t } = useI18n();
    const format = useDateFormat();
    const countryCode = useCountryCode();
    const [hours, openHoursState] = getRelativeLockerOpeningHours(openHours, new Date(), countryCode || '', t);
    const showOpenBadge = Boolean(openHours && hours);
    return (_jsxs("div", { className: css([styles.root, variant === 'borderless' && styles.borderless]), children: [_jsx("h2", { className: css(styles.name), children: lockerAddress.name }), _jsxs("div", { className: css(styles.badgeWrapper), children: [showOpenBadge && _jsx(OpenBadge, { openHoursState: openHoursState }), reservationDurationSeconds && countryCode && (_jsx("div", { className: css(styles.badgeWrapper), children: _jsx("div", { className: css(styles.badge, styles.bookUntil), children: _jsx("span", { children: t('boxReturn.bookLockerFrom', {
                                    date: JSDate.formatSecondsAsHoursAndOrMinutes(reservationDurationSeconds, countryCode),
                                }) }) }) })), reservableUntil && countryCode && (_jsx("div", { className: css(styles.badgeWrapper), children: _jsx("div", { className: css(styles.badge, styles.bookUntil), children: _jsxs("span", { children: [t('boxReturn.bookLockerUntil'), ' ', format.formatDateTimeWithClockSymbol(reservableUntil, 'lowercase')] }) }) }))] }), _jsxs("div", { className: css(styles.container), children: [_jsx(Icon, { src: imageList.Geotag, size: IconSize['3xl'], color: Color.Budbee.Grey5[100] }), _jsxs("div", { className: css(styles.wrapper), children: [_jsx(Title, { title: t('address'), style: styles.subtitle }), _jsxs("p", { className: css(styles.text), children: [lockerAddress.street, ", ", lockerAddress.postalCode, ", ", lockerAddress.city] })] })] }), directions && directions.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", { className: css(styles.divider) }), _jsxs("div", { className: css(styles.container), children: [_jsx(Icon, { src: imageList.Map, size: IconSize['3xl'], color: Color.Budbee.Grey5[100] }), _jsxs("div", { className: css(styles.wrapper), children: [_jsx(Title, { title: t('directions'), style: styles.subtitle }), _jsx("p", { className: css(styles.text), "data-test": "directions", children: directions })] })] })] })), _jsx("div", { className: css(styles.divider) }), _jsxs("div", { className: css(styles.container), children: [_jsx("div", { className: css(styles.icon), children: _jsx(Icon, { src: imageList.Clock, size: IconSize['3xl'], color: Color.Budbee.Grey5[100] }) }), _jsxs("div", { className: css(styles.wrapper), "data-test": "opening-hours", children: [_jsx(Title, { title: t('openingHours'), style: styles.subtitle }), _jsxs("p", { className: css(styles.text), children: [_jsx(OpenHour, { day: t('today'), date: new Date(), openHours: openHours }), Object.keys(openHours).map((day, index) => {
                                        const date = moment(index, 'e');
                                        return (_jsx(OpenHour, { day: date.format('dddd'), date: date.toDate(), openHours: openHours }, day));
                                    })] })] })] })] }));
};
const OpenHour = ({ day, date, openHours }) => {
    var _a;
    const { t } = useI18n();
    const openingHours = formatLockerOpenHours({ openHours, date });
    return (_jsxs("span", { className: css(styles.openingHoursRow), children: [_jsx("span", { children: (_a = t(day.toLowerCase())) !== null && _a !== void 0 ? _a : day }), _jsx("span", { children: openingHours || t('closed') })] }));
};
const OpenBadge = ({ openHoursState }) => {
    const { t } = useI18n();
    return (_jsx("div", { className: css(styles.badge, openHoursState === 'closed' ? styles.closed : styles.open), children: _jsx("span", { children: openHoursState === 'closed' ? t('closed') : t('itsOpen') }) }));
};
