export const transitionEndEventName = () => {
    let i;
    let undefined;
    const el = document.createElement('div');
    const transitions = {
        transition: 'transitionend',
        OTransition: 'otransitionend', // oTransitionEnd in very old Opera
        MozTransition: 'transitionend',
        WebkitTransition: 'webkitTransitionEnd',
    };
    // eslint-disable-next-line no-restricted-syntax
    for (i in transitions) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        if (transitions.hasOwnProperty(i) && el.style[i] !== undefined) {
            // eslint-disable-next-line
            // @ts-ignore
            return transitions[i];
        }
    }
    return null;
};
