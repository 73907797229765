import { Color } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    topLeft: {
        position: 'absolute',
        left: 3,
        top: 3,
        zIndex: 15,
        padding: '0.5rem',
    },
    topRight: {
        position: 'absolute',
        right: 3,
        top: 3,
        zIndex: 15,
        padding: '0.5rem',
        porterbuddy: {
            display: 'none',
        },
    },
    mapContainer: {
        background: Color.Budbee.Grey1,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        porterbuddy: {
            position: 'relative',
            height: 260,
            width: '100%',
        },
    },
    mapModal: {
        position: 'fixed',
        margin: '0.2rem',
        borderRadius: 2,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        porterbuddy: {
            position: 'relative',
            height: 260,
            width: '100%',
            borderRadius: 0,
            margin: 0,
        },
    },
    trackingMap: {
        textAlign: 'left',
        flexBasis: '100%',
        width: '100%',
        height: '100%',
    },
    open: {
        display: 'block',
        zIndex: 10,
    },
    closed: {
        display: 'none',
    },
});
