import { getBrand } from '../brand/get-brand';
export const setupBrands = () => {
    var _a, _b, _c, _d, _e, _f;
    const body = document.getElementsByTagName('body');
    const html = document.getElementsByTagName('html');
    const brand = getBrand();
    if (body) {
        if (brand === 'budbee') {
            const style = 'font-family: Poppins !important; background: #f4f2f0;';
            (_a = body[0]) === null || _a === void 0 ? void 0 : _a.setAttribute('style', style);
            (_b = html[0]) === null || _b === void 0 ? void 0 : _b.setAttribute('style', style);
        }
        if (brand === 'instabox') {
            const style = 'font-family: Circular !important; background: #f7f7f7;';
            (_c = body[0]) === null || _c === void 0 ? void 0 : _c.setAttribute('style', style);
            (_d = html[0]) === null || _d === void 0 ? void 0 : _d.setAttribute('style', style);
        }
        if (brand === 'porterbuddy') {
            const style = 'font-family: Dazzed !important; background: #F9F6FF;';
            (_e = body[0]) === null || _e === void 0 ? void 0 : _e.setAttribute('style', style);
            (_f = html[0]) === null || _f === void 0 ? void 0 : _f.setAttribute('style', style);
        }
    }
    if (brand === 'budbee') {
        document.title = 'Budbee';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#f4f2f0" media="(prefers-color-scheme: light)">';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#f4f2f0" media="(prefers-color-scheme: dark)">';
    }
    if (brand === 'instabox') {
        document.title = 'Instabox';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#f4f2f0" media="(prefers-color-scheme: light)">';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#f4f2f0" media="(prefers-color-scheme: dark)">';
    }
    if (brand === 'porterbuddy') {
        document.title = 'Porterbuddy';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#F9F6FF" media="(prefers-color-scheme: light)">';
        document.head.innerHTML +=
            '<meta name="theme-color" content="#F9F6FF" media="(prefers-color-scheme: dark)">';
    }
};
