import { ParcelStatus, utils, } from '@budbee/js/tracking';
import { ResponseStatus, } from '@budbee/js/tracking-api';
import * as Sentry from '@sentry/react';
import { TrackingApi } from '../../server/tracking';
import { setEta } from '../../state/eta/actions';
import { setCurrentLocale, setLanguage, setTimeZone } from '../../state/locale/actions';
import { clearPaymentRequest, registerPaymentRequest } from '../../state/payment/actions';
import { getPosition } from '../../state/positions/actions';
import { prefaceFetched, showSteps } from '../../state/preface/actions';
import { initSockets } from '../../state/socket/actions';
import { getPersistedPreferredLanguage } from '../../utils/locale';
import { sortPrefaceStep } from '../../utils/preface-sorter';
import { prefaceToStep } from '../../utils/preface-to-step';
import { refreshTrackingPage } from '../../utils/refresh-tracking-page';
import { shouldRequestPositionForStatus } from '../../utils/statuses';
import { setBanner } from '../banner/actions';
import { setFeatures } from '../features/actions';
import { getCountryCode, getCurrentLocale, getDefaultLocale } from '../locale/selectors';
import { getSocketIsConnected, getSocketIsConnecting } from '../socket/selectors';
import { getAuthToken, getToken } from './selectors';
export const SET_ORDER_TYPE = 'SET_ORDER_TYPE';
export const SET_IS_FETCHING_ORDER = 'SET_IS_FETCHING_ORDER';
export const SET_CONSPECTUS = 'SET_CONSPECTUS';
export const GET_TIME_WINDOWS = 'GET_TIME_WINDOWS';
export const SET_LEGACY_ORDER = 'SET_LEGACY_ORDER';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const GET_POSITION = 'GET_POSITION';
export const UPDATE_ACCESS_SETTINGS = 'UPDATE_ACCESS_SETTINGS';
export const UPDATE_DELIVERY_SETTINGS = 'UPDATE_DELIVERY_SETTINGS';
export const UPDATE_DELIVERY_WINDOW_OPTIMISTICALLY = 'UPDATE_DELIVERY_WINDOW_OPTIMISTICALLY';
export const SET_RELOADING = 'SET_RELOADING';
export const RESET_ORDER_STATE = 'RESET_ORDER_STATE';
export const SET_ORDER_TOKEN = 'SET_ORDER_TOKEN';
export const SET_SWITCH_DELIVERY_TYPE_PRICE = 'SET_SWITCH_DELIVERY_TYPE_PRICE';
export const SET_LOCKER = 'SET_LOCKER';
export const SET_HAS_RETURN_PHOTO_UPLOADED = 'SET_HAS_RETURN_PHOTO_UPLOADED';
export const SET_EXTERNAL_LINKS = 'SET_EXTERNAL_LINKS';
export const resetOrderState = () => ({
    type: RESET_ORDER_STATE,
});
export const setOrderType = (orderType) => ({
    type: SET_ORDER_TYPE,
    payload: { orderType },
});
export const setOrderToken = (token) => ({
    type: SET_ORDER_TOKEN,
    payload: { token },
});
export const setReloading = (isReloading) => ({
    type: SET_RELOADING,
    payload: { isReloading },
});
export const updateAccessSettings = (payload) => ({
    type: UPDATE_ACCESS_SETTINGS,
    payload,
});
export const updateDeliverySettings = (method, notification, alternativeLeaveWithNeighbour, neighbours) => ({
    type: UPDATE_DELIVERY_SETTINGS,
    payload: {
        method,
        notification,
        alternativeLeaveWithNeighbour,
        neighbours,
    },
});
export const updateDeliveryWindow = (deliveryWindow) => ({
    type: UPDATE_DELIVERY_WINDOW_OPTIMISTICALLY,
    payload: { deliveryWindow },
});
const setConspectus = (conspectus) => ({
    type: SET_CONSPECTUS,
    payload: { conspectus },
});
const setAuth = (authenticated, authToken) => ({
    type: SET_AUTH,
    payload: {
        authenticated,
        authToken,
    },
});
export const setOrderStatus = (status, date) => ({
    type: SET_ORDER_STATUS,
    payload: { status, date },
});
export const setIsFetchingOrder = () => ({
    type: SET_IS_FETCHING_ORDER,
});
export const setHasReturnPhotoUploaded = (value) => ({
    type: SET_HAS_RETURN_PHOTO_UPLOADED,
    payload: { value },
});
export const setSwitchDeliveryTypePrice = (data) => ({
    type: SET_SWITCH_DELIVERY_TYPE_PRICE,
    payload: { data },
});
export const setLocker = (locker) => {
    return {
        type: SET_LOCKER,
        payload: { locker },
    };
};
export const setExternalLinks = (externalLinks) => ({
    type: SET_EXTERNAL_LINKS,
    payload: { externalLinks },
});
export const fetchOrder = (token, authToken = '') => async (dispatch, getState) => {
    dispatch(setIsFetchingOrder());
    const state = getState();
    const socketConnected = getSocketIsConnected(state);
    const socketConnecting = getSocketIsConnecting(state);
    try {
        const currentLocale = getCurrentLocale(state);
        const trackingApi = TrackingApi({
            'App-Locale': currentLocale,
        });
        const conspectus = await trackingApi
            .getConspectus(authToken, token)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                return response.payload;
            }
            return null;
        });
        if (!conspectus) {
            return;
        }
        const { city, countryCode } = conspectus.address;
        const status = conspectus.status.state;
        const { deliveryPinCode } = conspectus;
        const canCancelPreface = Boolean(status === ParcelStatus.OnRouteDelivery && deliveryPinCode);
        trackingApi
            .getBannerForCityAndCountry(city, countryCode)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                dispatch(setBanner(response.payload));
            }
        });
        trackingApi
            .getExternalLinks()
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED && response.payload) {
                dispatch(setExternalLinks(response.payload));
            }
        })
            .catch((error) => Sentry.captureException(error));
        dispatch(setAuth(conspectus.authorized, authToken));
        dispatch(prefaceFetched(conspectus.preface
            .map((prefaceStep) => prefaceToStep(prefaceStep, canCancelPreface))
            .sort(sortPrefaceStep)));
        dispatch(setConspectus(conspectus));
        dispatch(setEta(conspectus.eta));
        dispatch(setTimeZone(conspectus.timeZone));
        try {
            trackingApi
                .getOrderFeatures(token, authToken)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    dispatch(setFeatures(response.payload));
                }
            });
        }
        catch (e) {
            Sentry.captureException(e);
        }
        const requestPositions = shouldRequestPositionForStatus(status);
        if (requestPositions) {
            const { driver } = conspectus;
            if (driver) {
                trackingApi
                    .getCachedDriverPosition(token, authToken)
                    .call()
                    .then((response) => {
                    if (response.status === ResponseStatus.COMPLETED) {
                        dispatch(getPosition(response.payload));
                    }
                });
            }
        }
        if (!socketConnected && !socketConnecting) {
            if (!conspectus.identification || !conspectus.identification.needsVerification) {
                const { driver } = conspectus;
                dispatch(initSockets({ token, driver, authToken, requestPositions }));
            }
        }
        const shouldShowPreface = conspectus.preface.some((step) => step.prompt && conspectus.authorized);
        if (shouldShowPreface) {
            dispatch(showSteps(true));
        }
    }
    catch (e) {
        Sentry.captureException(e);
    }
};
export const reloadOrder = () => async (dispatch, getState) => {
    dispatch(setReloading(true));
    const state = getState();
    const token = getToken(state);
    const authToken = getAuthToken(state) || '';
    const socketConnected = getSocketIsConnected(state);
    const socketConnecting = getSocketIsConnecting(state);
    try {
        const country = getCountryCode(state);
        const defaultLocale = getDefaultLocale(state);
        const persistedLanguage = getPersistedPreferredLanguage();
        const localeToUse = persistedLanguage ? `${persistedLanguage}_${country}` : defaultLocale;
        const languageToUse = persistedLanguage || defaultLocale.slice(0, 2);
        dispatch(setCurrentLocale(localeToUse));
        dispatch(setLanguage(languageToUse));
        const trackingApi = TrackingApi({
            'App-Locale': localeToUse,
        });
        const conspectus = await trackingApi
            .getConspectus(authToken, token)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                return response.payload;
            }
            return null;
        });
        if (!conspectus) {
            return;
        }
        if (!socketConnected && !socketConnecting) {
            const requestPositions = shouldRequestPositionForStatus(conspectus.status.state);
            if (!conspectus.identification || !conspectus.identification.needsVerification) {
                dispatch(initSockets({
                    token,
                    authToken,
                    requestPositions,
                    driver: conspectus.driver,
                }));
            }
        }
        dispatch(setConspectus(conspectus));
        dispatch(setEta(conspectus.eta));
    }
    catch (e) {
        Sentry.captureException(e);
    }
    dispatch(setReloading(false));
};
export const setOrderStatusWithConditionalReload = (status, date) => async (dispatch, getState) => {
    const { order } = getState();
    if (order.conspectus.status.state !== ParcelStatus.Delivered &&
        status === ParcelStatus.Delivered) {
        dispatch(reloadOrder());
    }
    dispatch(setOrderStatus(status, date));
};
// Reload the whole tracking page after booking consignment by default
// Usually we book a return which could have switched delivery type, so easier to just reload enterily
// Exception is on the timetable during preface/order onboarding where we don't want to refresh after booking
export const afterBookConsignment = (response, timeWindow, 
// onlyReloadOrder: Controls whether to refresh entire page or just reload the order
onlyReloadOrder) => (dispatch) => {
    const { payload } = response;
    if (payload === null || payload === void 0 ? void 0 : payload.paymentRequired) {
        dispatch(registerPaymentRequest({ ...payload, timeWindow }));
    }
    else {
        dispatch(clearPaymentRequest());
        if (onlyReloadOrder) {
            dispatch(reloadOrder());
        }
        else {
            refreshTrackingPage();
        }
    }
    dispatch(updateDeliveryWindow(utils.fromTimeWindow(timeWindow)));
};
