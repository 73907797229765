import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ConsignmentType, ParcelStatus } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useEta } from '../../state/eta/hooks';
import { useConspectus } from '../../state/order/hooks';
import { useLatestPosition } from '../../state/positions/hooks';
import { IconSize } from '../../styles';
import { isConsignmentCancelled } from '../../utils/cancellation';
import imageList from '../../utils/image-list';
import { shouldRenderMap } from '../../utils/should-render';
import { MapModal } from '../modal/map';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const InlineDeliveryMap = () => {
    const { t } = useI18n();
    const [open, setOpen] = useState(true);
    const conspectus = useConspectus();
    const position = useLatestPosition();
    const eta = useEta();
    if (!conspectus) {
        return null;
    }
    const { consignment, status: { state }, identification, authorized, driver, merchant, address, } = conspectus;
    const showMap = shouldRenderMap((consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN, state, identification ? identification.needsVerification : false, authorized, driver) &&
        position &&
        !isConsignmentCancelled(consignment);
    if (state === ParcelStatus.Delivered) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs(View, { style: styles.container, children: [_jsxs(View, { style: styles.leftContainer, children: [_jsx(Icon, { src: imageList.markerDeliveryPorterbuddyWhite, size: IconSize['7xl'] }), (conspectus === null || conspectus === void 0 ? void 0 : conspectus.address) && (_jsxs(View, { style: styles.textContainer, children: [_jsx("span", { className: css(styles.textRow), children: conspectus === null || conspectus === void 0 ? void 0 : conspectus.address.street }), _jsxs("span", { className: css(styles.textRow), children: [conspectus === null || conspectus === void 0 ? void 0 : conspectus.address.postalCode, " ", conspectus === null || conspectus === void 0 ? void 0 : conspectus.address.city] })] }))] }), showMap && (_jsxs(View, { style: styles.toggleMap, onClick: () => setOpen(!open), children: [_jsx("span", { children: t('map') }), _jsx(View, { style: open ? styles.open : styles.close, children: _jsx(Icon, { src: imageList.chevronRight, size: IconSize.md }) })] }))] }), showMap && (_jsx(MapModal, { isReturn: (consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN, open: open, merchant: merchant, driver: driver, consignment: consignment, position: position, deliveryAddress: address, eta: eta, identification: identification, close: () => undefined }))] }));
};
