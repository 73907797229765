import { Border, Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginVertical, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        overflow: 'hidden',
        borderRadius: Border.Radius.sm,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space['2xs']),
        backgroundColor: Color.White100,
    },
    container: {
        overflow: 'hidden',
    },
    childWrapper: {
        ...marginVertical(Space.lg),
    },
    itemDivider: {
        height: 1,
        backgroundColor: Color.Budbee.Grey1,
    },
});
