import { languages } from '@budbee/js/tracking';
// country code regex
const CC_REGEX = /^[a-z]{2}$/i;
// offset between uppercase ascii and regional indicator symbols
const OFFSET = 127397;
/**
 * convert country code to corresponding emoji flag
 * @param {string} countryCode - the country code string
 * @returns {string} country code emoji
 */
export const getFlagEmoji = (countryCode) => {
    let convertedCountryCode = '';
    if (countryCode === languages.ENGLISH) {
        convertedCountryCode = 'GB';
    }
    else {
        convertedCountryCode = countryCode;
    }
    if (!CC_REGEX.test(convertedCountryCode)) {
        const type = typeof convertedCountryCode;
        throw new TypeError(`convertedCountryCode argument must be an ISO 3166-1 alpha-2 string, but got '${type === 'string' ? convertedCountryCode : type}' instead.`);
    }
    const chars = [...convertedCountryCode.toUpperCase()].map((c) => c.charCodeAt(0) + OFFSET);
    return String.fromCodePoint(...chars);
};
