import { Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginVertical, Spacing, Transition } from '../../styles';
export const styles = StyleSheet.create({
    spacing: {
        transition: Transition.Ease,
        ...marginVertical(Spacing.md),
    },
    pinTitle: {
        ...Typography.Budbee.Web.h3,
        marginTop: Space.sm,
        marginLeft: Space.sm,
        marginBottom: Space.lg,
        transition: Transition.Ease,
    },
});
