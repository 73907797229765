import { Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, FontSize, marginHorizontal, marginVertical, Spacing } from '../../styles';
export const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    wrapperDarkUnderlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.27)',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100%',
    },
    searchResultsWrapper: {
        position: 'absolute',
        marginTop: Spacing.sm,
        boxShadow: '0 3px 6px 3px rgba(0, 0, 0, 0.15)',
        borderRadius: BorderRadius.xs,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 11,
        backgroundColor: Color.White100,
        width: '100%',
        minWidth: 100,
        minHeight: 120,
    },
    searchResultsList: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 275,
        overflowY: 'scroll',
        padding: Space.lg,
    },
    searchResultsMessage: {
        ...marginVertical(0),
        ...marginHorizontal(Spacing.md),
        fontSize: FontSize.xs,
    },
    loadingIndicatorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: Spacing.lg,
    },
    loadingIndicator: {
        width: Spacing.xl,
        height: Spacing.xl,
        marginBottom: Spacing.sm,
        position: 'unset',
    },
    loadingIndicatorText: {
        ...marginVertical(0),
        ...marginHorizontal(Spacing.md),
        fontSize: FontSize.sm,
    },
});
