import { jsx as _jsx } from "react/jsx-runtime";
import { StyleSheet, css } from '@css';
import { desktopOnly, mobileOnly } from '../../../styles';
const styles = StyleSheet.create({
    view: {
        display: 'flex',
        flexDirection: 'column',
    },
    mobileOnly: {
        ...mobileOnly(),
    },
    desktopOnly: {
        ...desktopOnly(),
    },
});
export const View = (props) => {
    const { children, style, variant, ...rest } = props;
    return (_jsx("div", { ...rest, className: css(styles.view, style, variant === 'mobileOnly' && styles.mobileOnly, variant === 'desktopOnly' && styles.desktopOnly), children: children }));
};
