export const isConsignmentCancelled = (consignment) => consignment ? consignment != null && consignment.cancellation !== null : false;
export const getCancelledTexts = (conspectus, isReturn, t) => {
    var _a;
    if (conspectus.cancellation) {
        const { title, text } = conspectus.cancellation;
        return [title, text || ''];
    }
    if ((_a = conspectus === null || conspectus === void 0 ? void 0 : conspectus.consignment) === null || _a === void 0 ? void 0 : _a.cancellation) {
        const { title, text } = conspectus.consignment.cancellation;
        return [title, text || ''];
    }
    return [
        t(isReturn ? 'cancellation.other.return' : 'cancellation.other.delivery'),
        t('contactCustomerService'),
    ];
};
