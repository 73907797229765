import { useSelector } from 'react-redux';
import { getAllowedToBookDelivery, getAuthenticated, getAuthToken, getCanSwitchDeliveryType, getConsignment, getConspectus, getConspectusNudges, getConsumerAddress, getHasReturnPhotoUploaded, getIdentification, getIntegrityPolicyLink, getIsAllowedToChangeReturnPhoto, getIsFetchingOrder, getIsReloading, getIsReturn, getIsReturnConsignment, getOrderType, getParcelDeliveryOverview, getPrivacyNoticeLink, getReturnableParcelCount, getReturnsEnabled, getRoutedEta, getSelectedLocker, getSustainabilityLink, getSwitchDeliveryTypePrice, getToken, } from './selectors';
export function useConspectus() {
    return useSelector(getConspectus);
}
export function useConsignment() {
    return useSelector(getConsignment);
}
export function useIsReloading() {
    return useSelector(getIsReloading);
}
export function useIsReturn() {
    return useSelector(getIsReturn);
}
export function useIsReturnConsignment() {
    return useSelector(getIsReturnConsignment);
}
export function useToken() {
    return useSelector(getToken);
}
export function useSwitchDeliveryTypePrice() {
    return useSelector(getSwitchDeliveryTypePrice);
}
export function useOrderType() {
    return useSelector(getOrderType);
}
export function useAuthToken() {
    return useSelector(getAuthToken);
}
export function useSelectedLocker() {
    return useSelector(getSelectedLocker);
}
export function useIsAllowedToChangeReturnPhoto() {
    return useSelector(getIsAllowedToChangeReturnPhoto);
}
export function useHasReturnPhotoUploaded() {
    return useSelector(getHasReturnPhotoUploaded);
}
export function useReturnableParcelCount() {
    return useSelector(getReturnableParcelCount);
}
export function useConsumerAddress() {
    return useSelector(getConsumerAddress);
}
export function useParcelDeliveryOverview() {
    return useSelector(getParcelDeliveryOverview);
}
export function useCanSwitchDeliveryType() {
    return useSelector(getCanSwitchDeliveryType);
}
export function useIdentification() {
    return useSelector(getIdentification);
}
export function useReturnsEnabled() {
    return useSelector(getReturnsEnabled);
}
export function useAllowedToBookDelivery() {
    return useSelector(getAllowedToBookDelivery);
}
export function useAuthenticated() {
    return useSelector(getAuthenticated);
}
export function useIsFetchingOrder() {
    return useSelector(getIsFetchingOrder);
}
export function usePrivacyNoticeLink() {
    return useSelector(getPrivacyNoticeLink);
}
export function useIntegrityPolicyLink() {
    return useSelector(getIntegrityPolicyLink);
}
export function useSustainabilityLink() {
    return useSelector(getSustainabilityLink);
}
export function useConspectusNudges() {
    return useSelector(getConspectusNudges);
}
export function useRoutedEta() {
    return useSelector(getRoutedEta);
}
