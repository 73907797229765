import { ConsignmentType, } from '@budbee/js/tracking';
import { createReducer } from '../../utils/create-reducer';
import { SET_CONSPECTUS, SET_AUTH, SET_IS_FETCHING_ORDER, UPDATE_DELIVERY_WINDOW_OPTIMISTICALLY, SET_RELOADING, RESET_ORDER_STATE, SET_ORDER_TOKEN, SET_SWITCH_DELIVERY_TYPE_PRICE, SET_LOCKER, SET_HAS_RETURN_PHOTO_UPLOADED, SET_ORDER_STATUS, SET_ORDER_TYPE, SET_EXTERNAL_LINKS, UPDATE_DELIVERY_SETTINGS, UPDATE_ACCESS_SETTINGS, } from './actions';
const initState = {
    authenticated: false,
    token: '',
    authToken: undefined,
    isFetching: false,
    isReloading: false,
    returns: {
        enabled: false,
        returnableParcelCount: 0,
        photo: {
            allowedToChangeReturnPhoto: false,
            hasPhotoUploaded: false,
        },
        allowedToCancelReservation: false,
    },
    routedEta: null,
    type: undefined,
    canSwitchDeliveryType: false,
    canSwitchToHomeOrLockerFree: false,
    switchDeliveryTypePrice: null,
    switchDeliveryTypeMessage: null,
    selectedLocker: null,
    cancellation: null,
    parcelDeliveryOverview: null,
    consignment: null,
    identification: null,
    allowedToBookDelivery: false,
    externalLinks: null,
    conspectus: null,
};
export const order = createReducer(initState, {
    [RESET_ORDER_STATE]: (state = initState) => ({
        ...state,
        routedEta: null,
        returns: initState.returns,
        allowedToBookDelivery: false,
        orderLocation: null,
        consignment: null,
        canSwitchToHomeOrLockerFree: false,
        switchDeliveryTypePrice: null,
        selectedLocker: null,
    }),
    [SET_ORDER_TYPE]: (state = initState, action) => ({
        ...state,
        type: action.payload.orderType,
    }),
    [SET_ORDER_TOKEN]: (state = initState, action) => ({
        ...state,
        token: action.payload.token,
    }),
    [SET_CONSPECTUS]: (state = initState, action) => ({
        ...state,
        routedEta: action.payload.conspectus.routedEta,
        returns: action.payload.conspectus.returns,
        allowedToBookDelivery: action.payload.conspectus.allowedToBookDelivery,
        consignment: action.payload.conspectus.consignment,
        identification: action.payload.conspectus.identification,
        parcelDeliveryOverview: action.payload.conspectus.parcelDeliveryOverview,
        canSwitchDeliveryType: action.payload.conspectus.canSwitchDeliveryType,
        cancellation: action.payload.conspectus.cancellation,
        conspectus: action.payload.conspectus,
        isFetching: false,
    }),
    [SET_AUTH]: (state = initState, action) => ({
        ...state,
        authenticated: action.payload.authenticated,
        authToken: action.payload.authToken,
    }),
    [SET_ORDER_STATUS]: (state = initState, action) => {
        if (!state.conspectus) {
            return { ...state };
        }
        return {
            ...state,
            conspectus: {
                ...state.conspectus,
                status: {
                    ...state.conspectus.status,
                    state: action.payload.status,
                    date: action.payload.date,
                },
            },
            isFetching: false,
        };
    },
    [SET_IS_FETCHING_ORDER]: (state = initState) => ({
        ...state,
        isFetching: true,
    }),
    [UPDATE_ACCESS_SETTINGS]: (state = initState, action) => {
        if (!state.conspectus) {
            return { ...state };
        }
        const { payload } = action;
        return {
            ...state,
            conspectus: {
                ...state.conspectus,
                deliverySettings: {
                    ...state.conspectus.deliverySettings,
                    accessMode: payload.accessMode,
                    estateType: payload.estateType,
                    floor: payload.floor,
                    specialInstructions: payload.instructions,
                },
                address: {
                    ...state.conspectus.address,
                    street2: payload.careOf,
                    settings: {
                        ...state.conspectus.address.settings,
                        specialInstructions: payload.instructions,
                    },
                },
            },
        };
    },
    // Todo rename to update consignment
    [UPDATE_DELIVERY_WINDOW_OPTIMISTICALLY]: (state = initState, action) => {
        if (!state.conspectus || !state.conspectus.consignment) {
            return { ...state };
        }
        const isReturn = action.payload.deliveryWindow.return;
        return {
            ...state,
            conspectus: {
                ...state.conspectus,
                consignment: {
                    ...state.conspectus.consignment,
                    start: action.payload.deliveryWindow.start,
                    stop: action.payload.deliveryWindow.stop,
                    routingDeadline: action.payload.deliveryWindow.deadline,
                    type: isReturn ? ConsignmentType.RETURN : ConsignmentType.DELIVERY,
                },
            },
        };
    },
    [UPDATE_DELIVERY_SETTINGS]: (state = initState, action) => {
        if (!state.conspectus || !state.conspectus.consignment) {
            return { ...state };
        }
        return {
            ...state,
            conspectus: {
                ...state.conspectus,
                deliverySettings: {
                    ...state.conspectus.deliverySettings,
                    deliveryExecution: {
                        method: action.payload.method,
                        notification: action.payload.notification,
                        alternativeLeaveWithNeighbour: action.payload
                            .alternativeLeaveWithNeighbour,
                        neighbours: action.payload.neighbours,
                    },
                },
            },
        };
    },
    [SET_RELOADING]: (state = initState, action) => ({
        ...state,
        isReloading: action.payload.isReloading,
    }),
    [SET_SWITCH_DELIVERY_TYPE_PRICE]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        canSwitchToHomeOrLockerFree: action.payload.data
            .canSwitchToHomeOrLockerFree,
        switchDeliveryTypePrice: action.payload.data.price,
        switchDeliveryTypeMessage: action.payload.data.message,
    }),
    [SET_LOCKER]: (state = initState, action) => ({
        ...state,
        selectedLocker: {
            lockerIdentifier: action.payload.locker.lockerIdentifier,
            lockerAddress: action.payload.locker.lockerAddress,
            openHours: action.payload.locker.openHours,
            demand: action.payload.locker.demand,
            nextEta: action.payload.locker.nextEta,
        },
    }),
    [SET_EXTERNAL_LINKS]: (state = initState, action) => ({
        ...state,
        externalLinks: action.payload.externalLinks,
    }),
    [SET_HAS_RETURN_PHOTO_UPLOADED]: (state = initState, action) => ({
        ...state,
        returns: {
            ...state.returns,
            photo: {
                ...state.returns.photo,
                hasPhotoUploaded: action.payload.value,
            },
        },
    }),
});
