import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, paddingHorizontal, marginVertical, marginHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        overflow: 'hidden',
        backgroundColor: Color.White100,
        borderRadius: BorderRadius.md,
        ...paddingVertical(Space['2xs']),
    },
    divider: {
        ...marginHorizontal(Space.lg),
        height: Border.Width.sm,
        backgroundColor: Color.Budbee.Grey1,
    },
    title: {
        marginTop: Space.md,
        marginLeft: Space.sm,
        marginBottom: Space.lg,
        textAlign: 'left',
        ...Typography.Budbee.Web.h3,
    },
    container: {
        ...paddingHorizontal(Space.lg),
        ...marginVertical(Space.lg),
    },
});
