import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BoxDemandLevel } from '@budbee/js/tracking';
import { Color, Space } from '@budbee/js/ui';
import { css } from '@css';
import { useDateFormat } from '../../hooks/use-date-format';
import { useI18n } from '../../hooks/use-i18n';
import { useCountryCode } from '../../state/locale/hooks';
import { getRelativeLockerOpeningHours } from '../../utils/date';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const LockerListItem = ({ lockerName, openHours, onClick, address, nextEta, available = true, boxDemand, fasterDeliveryOption, }) => {
    return (_jsx("button", { className: css([styles.root, !available && styles.rootDisabled]), type: "button", onClick: available ? onClick : undefined, children: _jsxs(View, { style: styles.leftContainer, children: [_jsx("h1", { className: css(styles.lockerNameText), children: lockerName }), _jsx(Address, { address: address }), _jsx(Content, { available: available, nextEta: nextEta, fasterDeliveryOption: fasterDeliveryOption, openHours: openHours, boxDemand: boxDemand })] }) }));
};
const Content = ({ available, boxDemand, fasterDeliveryOption, nextEta, openHours, }) => {
    const { t } = useI18n();
    const format = useDateFormat();
    if (!available) {
        return _jsx("div", { className: css(styles.fullyBooked), children: t('fullyBookedLocker') });
    }
    if (nextEta) {
        return (_jsxs(View, { style: styles.row, children: [_jsx(HighDemandInfo, { boxDemand: boxDemand }), _jsxs("h3", { className: css([
                        styles.reservableUntil,
                        fasterDeliveryOption ? styles.greenBadge : undefined,
                    ]), children: [fasterDeliveryOption ? _jsx(FasterDeliveryOption, {}) : null, format.formatDateTimeWithTilde(nextEta)] })] }));
    }
    return (_jsxs(View, { style: styles.row, children: [_jsx(OpenHoursInfo, { openHours: openHours }), _jsx(HighDemandInfo, { boxDemand: boxDemand })] }));
};
const OpenHoursInfo = ({ openHours }) => {
    const countryCode = useCountryCode();
    const { t } = useI18n();
    if (!openHours) {
        return null;
    }
    const [hours, openHoursState] = getRelativeLockerOpeningHours(openHours, new Date(), countryCode || '', t);
    if (!hours) {
        return null;
    }
    return (_jsx("div", { className: css(styles.bottomSectionContainer), children: _jsx("div", { className: css(styles.openHours, openHoursState === 'closed' ? styles.closed : styles.open), children: hours }) }));
};
const HighDemandInfo = ({ boxDemand }) => {
    if (!boxDemand || boxDemand.level !== BoxDemandLevel.HIGH) {
        return null;
    }
    return _jsx("h2", { className: css(styles.highDemand), children: boxDemand.title });
};
const FasterDeliveryOption = () => {
    return (_jsxs(_Fragment, { children: [_jsx(Icon, { src: imageList.SpeedMeter, color: Color.White100, size: 14 }), _jsx("div", { className: css(inlineStyles({ marginRight: Space.sm })) })] }));
};
const Address = ({ address }) => {
    if (!address) {
        return null;
    }
    const fullAddress = [address.street, address.postalCode, address.city]
        .map((str) => str.trim())
        .filter(Boolean)
        .join(', ');
    return _jsx("h2", { className: css(styles.cityText), children: fullAddress });
};
