import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { OrderType, ParcelStatus } from '@budbee/js/tracking';
import { css } from '@css';
import { ContactContainer } from '../../container/contact-container';
import LockerPickerContainer from '../../container/modal/locker-picker-container';
import { ModalPresenterContainer } from '../../container/modal-presenter';
import { useI18n } from '../../hooks/use-i18n';
import { useEta } from '../../state/eta/hooks';
import { reloadOrder } from '../../state/order/actions';
import { useConspectus } from '../../state/order/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import { isConsignmentCancelled } from '../../utils/cancellation';
import { promptPayment } from '../../utils/preface';
import { getMultiStepModalSteps } from '../../utils/prepare-steps';
import { shouldRenderMap } from '../../utils/should-render';
import { getChangeReturnPhotoSteps, getIdentificationSteps, getNewDeliverySteps, getReturnSteps, getSummarySteps, getSwitchToBoxDeliverySteps, } from '../../utils/steps-generators';
import { Banner } from '../banner';
import { Delay } from '../delay';
import { ChangeReturnPhotoModal } from '../modal/change-return-photo';
import { MapModal } from '../modal/map';
import { MultiStepModal } from '../modal/multi-step';
import { SupportModal } from '../modal/support';
import { PageHeader } from '../page-header';
import { View } from '../shared/view';
import { TemperatureControlBanner } from '../temperature-control-banner';
import { TrackingContent } from '../tracking-content';
import { TrackingHeader } from '../tracking-header';
import { UnauthorizedOrder } from '../unauthorized-order';
import { styles } from './style';
export const HomeTracking = ({ authToken, reloadOrderType, token, authenticated, isReturn, paymentRequired, adyenPaymentStatus, prefaceSteps, prefaceVisible, position, returnType, setSteps, showSteps, steps, }) => {
    const { t } = useI18n();
    const conspectus = useConspectus();
    const eta = useEta();
    const brand = getBrand();
    // We know conspectus exists here, otherwise this component wouldn't be rendered
    // TODO: Improve this later when we remove useOrderTrackingData
    const { consignment, identification, status, driver, merchant, address, settings } = conspectus;
    const parcelStatus = status.state;
    const isCancelledConsignment = Boolean(consignment === null || consignment === void 0 ? void 0 : consignment.cancellation);
    const isMissedStatus = status.state === ParcelStatus.Miss;
    const [showMap, setShowMap] = useState(true);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showStepsModal, setShowStepsModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showTimeslotsModal, setShowTimeslotsModal] = useState(false);
    const [showSwitchDeliveryTypeModal, setShowSwitchDeliveryTypeModal] = useState(false);
    const [showChangeReturnPhotoModal, setShowChangeReturnPhotoModal] = useState(false);
    const [showLockerPickerModal, setShowLockerPickerModal] = useState(false);
    const toggleSettingsModal = useCallback((toggled) => {
        if (showSettingsModal) {
            reloadOrder();
        }
        setShowSettingsModal(toggled);
        setSteps(getSummarySteps());
        showSteps(toggled);
    }, [setSteps, showSettingsModal, showSteps]);
    const getChangeReturnPhotoModalSteps = useCallback(() => {
        setSteps(getChangeReturnPhotoSteps());
        showSteps(true);
    }, [setSteps, showSteps]);
    const onContactUsClick = useCallback(() => {
        showSteps(false);
        setShowStepsModal(false);
        setShowSupportModal(true);
    }, [showSteps]);
    const getReturnsModalSteps = useCallback((isCurrentlyReturn) => {
        setSteps(getReturnSteps({
            returnType,
            orderType: OrderType.DELIVERY,
            isReturn: isCurrentlyReturn,
            reloadOrderType,
            isCancelled: isCancelledConsignment,
            isMissed: isMissedStatus,
            onContactUsClick,
        }));
        showSteps(true);
    }, [
        reloadOrderType,
        returnType,
        setSteps,
        showSteps,
        isCancelledConsignment,
        isMissedStatus,
        onContactUsClick,
    ]);
    const getSwitchDeliveryTypeModalSteps = useCallback((toggled) => {
        setSteps(getSwitchToBoxDeliverySteps(() => {
            setShowLockerPickerModal(toggled);
            getSwitchDeliveryTypeModalSteps(toggled);
        }, authToken, reloadOrderType));
        setShowSwitchDeliveryTypeModal(!showSwitchDeliveryTypeModal);
    }, [authToken, reloadOrderType, setSteps, showSwitchDeliveryTypeModal, setShowLockerPickerModal]);
    const toggleBankIdModal = useCallback(() => {
        if (identification === null || identification === void 0 ? void 0 : identification.needsVerification) {
            setSteps(getIdentificationSteps(identification === null || identification === void 0 ? void 0 : identification.provider, token, authToken));
            showSteps(true);
        }
    }, [
        authToken,
        identification === null || identification === void 0 ? void 0 : identification.needsVerification,
        identification === null || identification === void 0 ? void 0 : identification.provider,
        setSteps,
        showSteps,
        token,
    ]);
    const toggleLiveTrackingMap = useCallback(() => {
        if (parcelStatus === ParcelStatus.Collected ||
            parcelStatus === ParcelStatus.OnRouteDelivery ||
            parcelStatus === ParcelStatus.CrossDocked ||
            parcelStatus === ParcelStatus.CollectedShippingLabel) {
            return setShowMap(true);
        }
        return null;
    }, [parcelStatus]);
    const toggleBookConsignmentModal = useCallback(() => {
        setSteps(getNewDeliverySteps());
        return showSteps(true);
    }, [setSteps, showSteps]);
    useEffect(() => {
        if (!prefaceVisible) {
            setShowStepsModal(false);
            return;
        }
        setShowStepsModal(true);
    }, [prefaceVisible, setShowStepsModal]);
    useEffect(() => {
        if (!returnType) {
            return;
        }
        getReturnsModalSteps(isReturn || false);
    }, [getReturnsModalSteps, isReturn, returnType]);
    useEffect(() => {
        const res = promptPayment(steps, prefaceSteps, paymentRequired, reloadOrderType);
        if (res.steps) {
            setSteps(res.steps, true);
        }
    }, [paymentRequired, prefaceSteps, prefaceVisible, steps, reloadOrderType, setSteps]);
    return (_jsxs(_Fragment, { children: [_jsx(ModalPresenterContainer, { id: "support", isOpen: showSupportModal, children: conspectus ? (_jsx(SupportModal, { token: token, authToken: authToken, authenticated: authenticated, merchant: conspectus.merchant, onClose: () => setShowSupportModal(false), toggleReturnsModal: () => getReturnsModalSteps(isReturn || false), toggleIdentificationModal: () => toggleBankIdModal(), toggleBookDeliveryModal: () => {
                        setSteps(getNewDeliverySteps());
                        showSteps(true);
                    }, toggleSettingsModal: () => toggleSettingsModal(true), contactModal: _jsx(ContactContainer, { authToken: authToken }) })) : null }), _jsx(ModalPresenterContainer, { id: "timeslots", isOpen: showTimeslotsModal, children: _jsx(MultiStepModal, { authToken: authToken, steps: getMultiStepModalSteps({ steps, paymentRequired, adyenPaymentStatus }), onClose: () => setShowTimeslotsModal(false) }) }), _jsx(Delay, { delay: 400, children: _jsx(ModalPresenterContainer, { id: "steps", isOpen: showStepsModal, children: _jsx(MultiStepModal, { authToken: authToken, steps: getMultiStepModalSteps({
                            steps,
                            paymentRequired,
                            adyenPaymentStatus,
                        }), onClose: () => {
                            setShowStepsModal(false);
                            showSteps(false);
                        } }) }) }), _jsx(ModalPresenterContainer, { id: "switch-delivery-type", isOpen: showSwitchDeliveryTypeModal, children: _jsx(MultiStepModal, { authToken: authToken, headerTitle: t('changeToBoxDelivery'), steps: getMultiStepModalSteps({
                        steps,
                        paymentRequired,
                        adyenPaymentStatus,
                        showBackButton: true,
                    }), onClose: () => {
                        setShowLockerPickerModal(false);
                        getSwitchDeliveryTypeModalSteps(false);
                    }, onOnFirstStepBackPress: () => getSwitchDeliveryTypeModalSteps(true) }) }), _jsx(ModalPresenterContainer, { id: "locker-picker", isOpen: showLockerPickerModal, className: "locker-picker-modal", children: _jsx(LockerPickerContainer, { authToken: authToken, onClose: () => setShowLockerPickerModal(false), onLockerPick: () => getSwitchDeliveryTypeModalSteps(true), title: t('changeToBoxDelivery') }) }), _jsx(ModalPresenterContainer, { id: "return-upload-photo", isOpen: showChangeReturnPhotoModal, children: _jsx(ChangeReturnPhotoModal, { onClose: () => setShowChangeReturnPhotoModal(false) }) }), _jsx(PageHeader, { authenticated: authenticated, toggleSupportModal: () => setShowSupportModal(true) }), brand === 'porterbuddy' &&
                settings.temperatureConfirmationRequired &&
                parcelStatus !== ParcelStatus.Delivered && _jsx(TemperatureControlBanner, {}), _jsx(Banner, {}), _jsx("div", { className: css(styles.divider2xl) }), _jsx(TrackingHeader, { authenticated: authenticated, toggleBookConsignmentModal: () => toggleBookConsignmentModal(), toggleLockerPickerModal: () => setShowLockerPickerModal(true), toggleReturnsModal: () => getReturnsModalSteps(isReturn || false), toggleChangeReturnPhotoModal: () => getChangeReturnPhotoModalSteps(), toggleSettingsModal: () => toggleSettingsModal(true), toggleIdentificationModal: () => toggleBankIdModal(), toggleLiveTrackingMap: () => toggleLiveTrackingMap() }), _jsxs(View, { style: styles.wrapper, children: [authenticated ? null : _jsx(UnauthorizedOrder, {}), _jsx(TrackingContent, { token: token, authToken: authToken, authenticated: authenticated, toggleSettingsModal: () => toggleSettingsModal(true) }), shouldRenderMap(isReturn, parcelStatus, identification ? identification.needsVerification : false, authenticated, driver) &&
                        position &&
                        !isConsignmentCancelled(consignment) &&
                        brand !== 'porterbuddy' ? (_jsx(MapModal, { isReturn: isReturn, open: showMap && !prefaceVisible, merchant: merchant, driver: driver, consignment: consignment, position: position, deliveryAddress: address, eta: eta, identification: identification, close: () => setShowMap(false) })) : null] })] }));
};
