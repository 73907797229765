import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { styles } from './style';
export const Title = ({ title, style, variant = 'h3' }) => {
    if (variant === 'h3') {
        return _jsx("h3", { className: css(styles.title, style), children: title });
    }
    if (variant === 'h4') {
        return _jsx("h4", { className: css(styles.title, style), children: title });
    }
    if (variant === 'h5') {
        return _jsx("h5", { className: css(styles.title, style), children: title });
    }
    return _jsx("h6", { className: css(styles.title, style), children: title });
};
