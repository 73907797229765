/* eslint-disable no-console */
import { envConfig } from './env.config';
export function printtReleaseId() {
    const { isProd, RELEASE_ID } = envConfig;
    const messages = [];
    if (!isProd) {
        messages.push('🛠️ 🧪  mode');
    }
    if (RELEASE_ID) {
        messages.push(`Version: ${RELEASE_ID}`);
    }
    for (const message of messages) {
        console.info(message);
    }
}
