import { createReducer } from '../../utils/create-reducer';
import { CLEAR_PAYMENT_REQUEST, CLEAR_PAYMENT_REQUEST_V3, REGISTER_PAYMENT_REQUEST, REGISTER_PAYMENT_REQUEST_V3, REGISTER_PRELIMINARY_PAYMENT, RESET_PAYMENT_STATE, SET_PAYMENT_REFERENCE, SET_PRODUCT, UPDATE_ADYEN_PAYMENT_STATUS, } from './actions';
const initState = {
    paymentRequired: false,
    requestedTimeWindow: null,
    adyenSession: undefined,
    adyenCheckout: undefined,
    adyenPaymentStatus: null,
    product: null,
    price: undefined,
    version: null,
};
export const payment = createReducer(initState, {
    [REGISTER_PAYMENT_REQUEST_V3]: (state = initState, action) => ({
        ...state,
        paymentRequired: action.payload.request.paymentRequired,
        adyenCheckout: action.payload.request.adyenCheckout,
        price: action.payload.request.price,
        version: action.payload.version,
    }),
    [REGISTER_PAYMENT_REQUEST]: (state = initState, action) => ({
        ...state,
        paymentRequired: action.payload.request.paymentRequired,
        requestedTimeWindow: action.payload.request.timeWindow,
        adyenSession: action.payload.request.adyenSession,
        price: action.payload.request.price,
    }),
    [CLEAR_PAYMENT_REQUEST]: (state = initState) => ({
        ...state,
        paymentRequired: false,
        requestedTimeWindow: null,
        adyenSession: undefined,
        adyenPaymentStatus: null,
        price: undefined,
    }),
    [CLEAR_PAYMENT_REQUEST_V3]: (state = initState) => ({
        ...state,
        paymentRequired: false,
        adyenCheckout: undefined,
        version: null,
        price: undefined,
    }),
    [UPDATE_ADYEN_PAYMENT_STATUS]: (state = initState, action) => ({
        ...state,
        adyenPaymentStatus: action.payload.status,
    }),
    [REGISTER_PRELIMINARY_PAYMENT]: (state = initState) => ({
        ...state,
        paymentRequired: true,
    }),
    // @ts-expect-error We need to set the rest of the adyenSession values since adyenSession could be undefined
    // I'm not sure if it could affect anything so I'll suppress this for now
    [SET_PAYMENT_REFERENCE]: (state = initState, action) => ({
        ...state,
        adyenSession: {
            ...state.adyenSession,
            reference: action.payload.reference,
        },
    }),
    [SET_PRODUCT]: (state = initState, action) => ({
        ...state,
        product: action.payload.product,
    }),
    [RESET_PAYMENT_STATE]: () => ({
        ...initState,
    }),
});
