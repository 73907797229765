import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, BorderWidth, ButtonEffect, Color, DropShadow, FontWeight, IconSize, } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    globe: {
        ...ButtonEffect.Enabled,
    },
    langContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        marginLeft: Space['3xl'],
        alignItems: 'center',
        padding: Space.xs,
        backgroundColor: Color.White,
        borderRadius: BorderRadius.xs,
        ...DropShadow,
        porterbuddy: {
            borderRadius: 0,
            boxShadow: 'none',
        },
    },
    lang: {
        marginLeft: Space.sm,
        fontWeight: FontWeight.SemiBold,
        textTransform: 'capitalize',
    },
    loader: {
        height: IconSize.sm,
        width: IconSize.sm,
        margin: 0,
        borderWidth: BorderWidth.md,
    },
});
