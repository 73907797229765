import { getFlavor, getQueryVariable } from './url-params';
const singleHeader = (key, value) => ({
    [key]: value,
});
export const NO_CACHE = () => singleHeader('cache-control', 'no-store');
export const JSON_API = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
});
export const AUTH = () => singleHeader('authorization', getQueryVariable('auth', ''));
export const FLAVOR = () => singleHeader('Budbee-flavor', getFlavor());
export const UTM_MEDIUM = () => singleHeader('Budbee-medium', getQueryVariable('utm_medium', 'sms'));
export const composeHeaders = (...headerFns) => headerFns.reduce((headers, headerFn) => ({ ...headers, ...headerFn() }), {});
