import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { inlineStyles } from '../../utils/inline-styles';
import { styles } from './style';
export function MerchantLogo({ logoUrl, size = 40, variant = 'regular' }) {
    if (!logoUrl) {
        return null;
    }
    return (_jsx("img", { src: logoUrl, className: css(variant === 'round' ? styles.circle : styles.square, inlineStyles({ width: size, height: size })), alt: "icon" }));
}
