import { createReducer } from '../../utils/create-reducer';
import { GET_POSITION } from './actions';
const initState = {
    isFetching: false,
    latest: undefined,
};
export const positions = createReducer(initState, {
    [GET_POSITION]: (state = initState, action) => ({
        ...state,
        latest: action.payload.position,
        isFetching: false,
    }),
});
