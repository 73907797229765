import { useMemo } from 'react';
import { datev2 } from '@budbee/js/tracking';
import translations from '../../translations.json';
import { useBoxOrder } from '../state/box-order/hooks';
import { useLanguage, useTimeZone } from '../state/locale/hooks';
import { useConspectus } from '../state/order/hooks';
/**
 * Returns a date formatting function containing different methods
 * EXAMPLE: datev2.formatDateTimeWithoutTime
 * WARNING: This hook depends on order state data. DO NOT USE OUTSIDE THE ORDER CONTEXT
 */
export function useDateFormat() {
    const timeZone = useTimeZone();
    const language = useLanguage();
    const boxOrder = useBoxOrder();
    const conspectus = useConspectus();
    const countryCode = useMemo(() => {
        var _a, _b, _c;
        if (boxOrder) {
            return (((_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.consumerAddress) === null || _a === void 0 ? void 0 : _a.countryCode) || ((_b = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.lockerAttributes.address) === null || _b === void 0 ? void 0 : _b.countryCode));
        }
        return (_c = conspectus === null || conspectus === void 0 ? void 0 : conspectus.address) === null || _c === void 0 ? void 0 : _c.countryCode;
    }, [boxOrder, conspectus === null || conspectus === void 0 ? void 0 : conspectus.address.countryCode]);
    return datev2(translations, countryCode, language, timeZone || undefined);
}
