import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    root: {
        marginTop: Space.lg,
        paddingTop: Space.lg,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: Color.Budbee.Grey1,
        display: 'block',
    },
    code: {
        backgroundColor: Color.Budbee.GreenLight,
        color: Color.Budbee.GreenDark,
        fontWeight: 600,
        paddingTop: Space.md,
        paddingBottom: Space.md,
        paddingLeft: Space.lg,
        paddingRight: Space.lg,
        display: 'block',
        letterSpacing: 12,
        borderRadius: Border.Radius.sm,
        fontSize: Typography.Budbee.Web.h1.fontSize,
        textAlign: 'center',
    },
});
