import { DeliveryMethod, NotificationMethod } from '@budbee/js/tracking';
export const DeliveryMethodLabels = {
    [DeliveryMethod.LEAVE_BY_DOOR]: 'deliverySettings.leaveOutsideDoor',
    [DeliveryMethod.IN_PERSON]: 'deliverySettings.inPerson',
};
export const DeliveryNotificationLabels = {
    [NotificationMethod.CALL_CUSTOMER]: 'deliverySettings.callCustomer',
    [NotificationMethod.NO_NOTIFICATION]: 'deliverySettings.noNotification',
    [NotificationMethod.KNOCK_ON_DOOR]: 'deliverySettings.knockOnDoor',
    [NotificationMethod.RING_DOOR_BELL]: 'deliverySettings.ringDoorBell',
};
