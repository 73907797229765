import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { OrderType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { Color, Space } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useBoxOrder } from '../../../state/box-order/hooks';
import { IconSize } from '../../../styles';
import imageList from '../../../utils/image-list';
import { inlineStyles } from '../../../utils/inline-styles';
import { ErrorMessage } from '../../error-message';
import { PricesInfoBox } from '../../prices-info-box';
import { Icon } from '../../shared/icon';
import { Loader } from '../../shared/loader';
import { LockerPickerOverview } from '../../shared/locker-picker/overview';
import { View } from '../../shared/view';
import { styles } from './style';
export const SwitchDeliveryType = (props) => {
    const trackingApi = useTrackingApi();
    const { orderType, consumerAddress, authToken, token, setSwitchDeliveryTypePrice, switchDeliveryTypePrice, registerPreliminaryPayment, clearPaymentRequest, canContinueChanged, onContinue, selectedLocker, registerPaymentRequestV3, clearPaymentRequestV3, reloadOrderType, closeModal, } = props;
    const boxOrder = useBoxOrder();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { t } = useI18n();
    useEffect(() => {
        if (orderType !== OrderType.BOX || !authToken || !token) {
            return;
        }
        setLoading(true);
        trackingApi
            .getSwitchToHomePrice(authToken, token)
            .call()
            .then((response) => {
            const { payload } = response;
            if (payload) {
                const { canSwitchToHomeOrLockerFree, price, message } = payload;
                if (response.status === ResponseStatus.COMPLETED) {
                    setSwitchDeliveryTypePrice({
                        canSwitchToHomeOrLockerFree,
                        price,
                        message,
                    });
                }
                else {
                    setError(true);
                    canContinueChanged(false);
                }
            }
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
            canContinueChanged(false);
            setError(true);
        });
    }, [authToken, token, trackingApi, orderType, setLoading, setError, canContinueChanged]);
    useEffect(() => {
        canContinueChanged(true);
        if (!switchDeliveryTypePrice.canSwitchToHomeOrLockerFree) {
            registerPreliminaryPayment();
        }
        else {
            clearPaymentRequest();
        }
    }, [canContinueChanged, switchDeliveryTypePrice, registerPreliminaryPayment, clearPaymentRequest]);
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        const lockerIdentifier = selectedLocker ? selectedLocker.lockerIdentifier : undefined;
        onContinue((next, onError) => {
            trackingApi
                .switchDeliveryType(authToken, token, lockerIdentifier)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.FAILED) {
                    setError(true);
                    canContinueChanged(false);
                    onError();
                }
                else {
                    const { payload } = response;
                    if (payload.paymentRequired) {
                        registerPaymentRequestV3(payload);
                    }
                    else {
                        clearPaymentRequestV3();
                        reloadOrderType();
                        if (closeModal) {
                            closeModal();
                        }
                    }
                    next();
                }
            })
                .catch(() => {
                onError();
                setError(true);
                canContinueChanged(false);
            });
        });
    }, [
        selectedLocker,
        token,
        onContinue,
        registerPaymentRequestV3,
        clearPaymentRequestV3,
        reloadOrderType,
        setError,
        canContinueChanged,
    ]);
    const address = consumerAddress ? (_jsxs(View, { children: [boxOrder ? (_jsxs(View, { style: styles.box, children: [_jsx(Icon, { src: imageList.Locker, size: IconSize['3xl'] }), _jsx("span", { className: css(styles.lockerName), children: boxOrder.lockerAttributes.address.name }), _jsx("span", { className: css(styles.lockerBadge), children: t('box') })] })) : null, _jsxs(View, { style: styles.arrowContainer, children: [_jsx(View, { style: styles.dot }), _jsx(View, { style: styles.dot }), _jsx(View, { style: styles.arrow, children: _jsx(Icon, { src: imageList.ArrowRight, color: Color.Budbee.GreenPop, size: IconSize['3xl'] }) })] }), _jsxs(View, { style: styles.home, children: [_jsxs(View, { style: styles.homeContent, children: [_jsx(Icon, { src: imageList.House, size: IconSize['3xl'] }), _jsxs(View, { style: styles.homeInner, children: [_jsxs("span", { className: css(styles.street), children: [consumerAddress.street, ', '] }), _jsx("span", { className: css(styles.city), children: `${consumerAddress.postalCode} ${consumerAddress.city}` })] })] }), _jsxs(View, { style: styles.badgesRow, children: [_jsx("span", { className: css(styles.homeBadge), children: t('home') }), _jsx("span", { className: css(styles.homeBadge, inlineStyles({ marginLeft: Space.md })), children: t('boxToHomeEta') })] })] })] })) : null;
    const isHomeDelivery = orderType === OrderType.DELIVERY;
    if (loading) {
        return _jsx(Loader, {});
    }
    if (error) {
        return _jsx(ErrorMessage, { positionVariant: "inline" });
    }
    return (_jsxs(_Fragment, { children: [isHomeDelivery ? _jsx(LockerPickerOverview, { ...props }) : address, _jsx(PricesInfoBox, { isHomeDelivery: isHomeDelivery, free: switchDeliveryTypePrice.canSwitchToHomeOrLockerFree, price: switchDeliveryTypePrice.switchDeliveryTypePrice, message: switchDeliveryTypePrice.switchDeliveryTypeMessage })] }));
};
