import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, IconSize } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        marginBottom: Space.sm,
        flex: 1,
    },
    leftContainer: {
        width: Space['2xl'],
        marginRight: Space.md,
        transform: `translateY:(${Space['2xs']}px)`,
        alignItems: 'center',
    },
    iconContainer: {
        marginBottom: Space.sm,
    },
    flex: {
        display: 'flex',
        flex: 1,
    },
    line: {
        width: Border.Width.lg,
        borderRadius: BorderRadius.sm,
        backgroundColor: Color.Budbee.Green,
        transform: `translateX:(${-0.75}px)`,
        flexGrow: 1,
    },
    lineRed: {
        backgroundColor: Color.Instabox.SillySalmon,
    },
    lineInactive: {
        backgroundColor: Color.Budbee.Grey2,
    },
    lineFailed: {
        backgroundColor: Color.Budbee.ReadyRed,
    },
    linePurple: {
        backgroundColor: Color.Porterbuddy.AmigoPurple,
    },
    rightContainer: {
        flex: 1,
    },
    topText: {
        ...Typography.Budbee.Web.h5,
        textAlign: 'left',
        marginBottom: Space['2xs'],
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h3,
            color: Color.Porterbuddy.Grey3,
        },
    },
    middleText: {
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey5,
        textAlign: 'left',
        marginBottom: Space.sm,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-md'],
            color: Color.Porterbuddy.Grey3,
        },
    },
    bottomText: {
        ...Typography.Budbee.Web['body-lg'],
        textAlign: 'left',
        marginBottom: Space['2xl'],
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.Porterbuddy.Grey4,
        },
    },
    descriptionInactive: {
        transform: `translateY:(${Space['2xs']}px)`,
    },
    imageContainer: {
        transform: `translateX:(${-Space.md}px)`,
        borderRadius: BorderRadius['2xl'],
        overflow: 'hidden',
        marginRight: Space['2xs'],
    },
    image: {
        width: IconSize['9xl'],
        height: IconSize['9xl'],
        backgroundColor: Color.Grey60,
        borderRadius: BorderRadius['2xl'],
    },
});
