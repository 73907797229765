import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccessModeType, DeliveryMethod, EstateType, NotificationMethod } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useCountryCode, useLanguage, useTimeZone } from '../../state/locale/hooks';
import { useConspectus } from '../../state/order/hooks';
import { AccessModeLabels } from '../../utils/access-mode-labels';
import { getBrand } from '../../utils/brand/get-brand';
import { BaseButton } from '../base-button';
import { View } from '../shared/view';
import { styles } from './style';
export const DeliveryMethodLabels = {
    [DeliveryMethod.LEAVE_BY_DOOR]: 'deliverySettings.leaveOutsideDoor',
    [DeliveryMethod.IN_PERSON]: 'deliverySettings.inPerson',
};
export const DeliveryNotificationLabels = {
    [NotificationMethod.CALL_CUSTOMER]: 'deliverySettings.callCustomer',
    [NotificationMethod.NO_NOTIFICATION]: 'deliverySettings.noNotification',
    [NotificationMethod.KNOCK_ON_DOOR]: 'deliverySettings.knockOnDoor',
    [NotificationMethod.RING_DOOR_BELL]: 'deliverySettings.ringDoorBell',
};
const EstateTypeText = {
    [EstateType.APARTMENT]: 'deliveryPreferences.estateType.apartment',
    [EstateType.APARTMENT_ACROSS_COURTYARD]: 'deliveryPreferences.estateType.apartmentAcrossCourtyard',
    [EstateType.HOUSE]: 'deliveryPreferences.estateType.house',
};
export const DeliverySettingsPresenter = ({ toggleSettingsModal }) => {
    var _a, _b, _c;
    const { t, i18n } = useI18n();
    const conspectus = useConspectus();
    const timeZone = useTimeZone();
    const countryCode = useCountryCode();
    const language = useLanguage();
    if (!conspectus) {
        return null;
    }
    const { consignment, deliverySettings } = conspectus;
    const deliveryTime = (consignment === null || consignment === void 0 ? void 0 : consignment.start) && consignment.stop && timeZone
        ? i18n.formatDeliveryDate(consignment === null || consignment === void 0 ? void 0 : consignment.start, consignment === null || consignment === void 0 ? void 0 : consignment.stop, countryCode, language, timeZone)
        : null;
    const showNotificationMethod = () => {
        const deliveryNotification = deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.deliveryExecution.notification;
        const exists = Boolean(deliveryNotification);
        const brand = getBrand();
        const isNoNotification = deliveryNotification === NotificationMethod.NO_NOTIFICATION;
        if (!exists) {
            return false;
        }
        if (isNoNotification && brand !== 'porterbuddy') {
            return false;
        }
        return true;
    };
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.header, children: [_jsx("span", { className: css(styles.title), children: t('deliveryPreferences.title') }), _jsx(BaseButton, { onClick: toggleSettingsModal, label: "Edit", size: "sm", style: styles.button })] }), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.deliveryTime') }), _jsx("span", { className: css(styles.itemText), children: deliveryTime || '-' }), _jsx("div", { className: css(styles.divider) })] }), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.type') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.deliveryExecution)
                            ? t(DeliveryMethodLabels[deliverySettings.deliveryExecution.method])
                            : '-' }), _jsx("div", { className: css(styles.divider) })] }), showNotificationMethod() && (_jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliverySettings.notification') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.deliveryExecution.notification)
                            ? t(DeliveryNotificationLabels[deliverySettings.deliveryExecution.notification])
                            : '-' }), _jsx("div", { className: css(styles.divider) })] })), (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.deliveryExecution.alternativeLeaveWithNeighbour) && (_jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliverySettings.leaveWithNeighbour') }), _jsx("span", { className: css(styles.itemText), children: t('deliverySettings.leaveWithNeighbourSubtitle') }), _jsx("div", { className: css(styles.divider) })] })), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.houseType') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.estateType) ? t(EstateTypeText[deliverySettings.estateType]) : '-' }), _jsx("div", { className: css(styles.divider) })] }), (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.estateType) !== EstateType.HOUSE && (_jsxs(_Fragment, { children: [_jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t(AccessModeLabels[((_a = deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.accessMode) === null || _a === void 0 ? void 0 : _a.type) || AccessModeType.NONE]) }), _jsx("span", { className: css(styles.itemText), children: ((_b = deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.accessMode) === null || _b === void 0 ? void 0 : _b.type) === AccessModeType.NONE
                                    ? t(AccessModeLabels[AccessModeType.NONE])
                                    : ((_c = deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.accessMode) === null || _c === void 0 ? void 0 : _c.value) || '-' }), _jsx("div", { className: css(styles.divider) })] }), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.nameOnDoor') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.nameOnDoor) ? deliverySettings.nameOnDoor : '-' }), _jsx("div", { className: css(styles.divider) })] }), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.floor') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.floor) !== undefined ? deliverySettings.floor : '-' }), _jsx("div", { className: css(styles.divider) })] })] })), _jsxs(View, { children: [_jsx("span", { className: css(styles.itemTitle), children: t('deliveryPreferences.specialInstructions') }), _jsx("span", { className: css(styles.itemText), children: (deliverySettings === null || deliverySettings === void 0 ? void 0 : deliverySettings.specialInstructions) ? deliverySettings.specialInstructions : '-' })] })] }));
};
