import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { View } from '../shared/view';
import { styles } from './style';
export const Footer = (props) => {
    const { onBack, onNext, disabled = false, nextVariant = 'continue' } = props;
    const { t } = useI18n();
    const brand = getBrand();
    return (_jsxs(View, { style: styles.row, children: [_jsx(BaseButton, { label: t('back'), variant: brand === 'porterbuddy' ? 'light' : 'secondary', disabled: !onBack, onClick: onBack !== null && onBack !== void 0 ? onBack : (() => undefined), iconSrc: imageList.chevronLeft, style: styles.button, size: "lg", showIcon: true }), _jsx(View, { style: styles.divider }), _jsx(BaseButton, { label: t(nextVariant), variant: brand === 'porterbuddy' ? 'secondary' : 'primary', disabled: !onNext || disabled, iconSrc: imageList.chevronRight, iconPosition: "right", style: styles.button, size: "lg", onClick: onNext !== null && onNext !== void 0 ? onNext : (() => undefined), showIcon: true })] }));
};
