import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { FontSize, Spacing } from '../../../styles';
export const styles = StyleSheet.create({
    loaderContainer: {
        position: 'relative',
        marginTop: Spacing['6xl'],
    },
    resetButton: {
        alignSelf: 'center',
        marginTop: Space['2xl'],
    },
    invalidDimensions: {
        color: Color.Budbee.ReadyRed,
        marginTop: Spacing['2xl'],
    },
    maxDimensionsInfo: {
        color: Color.Budbee.Grey3,
        marginBottom: Spacing['2xl'],
    },
    info: {
        marginTop: Spacing.md,
    },
    parcelSliderContainer: {
        margin: Spacing.sm,
        marginTop: 0,
    },
    title: {
        marginTop: Spacing.xs,
        fontSize: FontSize.md,
        textAlign: 'center',
    },
    instructions: {
        ...Typography.Budbee.Web['body-md'],
    },
});
