import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, BorderRadius, paddingVertical, paddingHorizontal, border } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: BorderRadius.md,
        marginHorizontal: Space.lg,
        ...paddingVertical(Spacing.lg),
        ...paddingHorizontal(Space.xl),
        ...border(Border.Width.md, 'solid', Color.Budbee.GreenDark),
        marginTop: Space.md,
        porterbuddy: {
            padding: Space.lg,
            backgroundColor: Color.Porterbuddy.Grey3,
            borderRadius: 0,
            margin: 0,
            border: 0,
        },
        instabox: {
            border: 0,
            backgroundColor: Color.White100,
            borderRadius: BorderRadius.sm,
        },
    },
    alert: {
        ...border(Border.Width.md, 'solid', Color.Budbee.ReadyRed),
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.PrettyPink,
            border: 0,
        },
        instabox: {
            border: 0,
            backgroundColor: Color.White100,
            borderRadius: BorderRadius.sm,
        },
    },
    leftIcon: {
        marginRight: Space.lg,
    },
    leftIconAlert: {
        transform: 'rotate(180deg)',
    },
    close: {
        cursor: 'pointer',
        marginLeft: Space.lg,
        color: Color.Budbee.GreenDark,
        porterbuddy: {
            color: Color.White100,
        },
        instabox: {
            color: Color.Instabox.GreyGray,
        },
    },
    closeAlert: {
        color: Color.Budbee.ReadyRed,
        porterbuddy: {
            color: Color.Porterbuddy.Grey4,
        },
        instabox: {
            color: Color.Instabox.GreyGray,
        },
    },
    message: {
        margin: 0,
        textAlign: 'left',
        wordWrap: 'break-word',
        maxWidth: '100%',
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        flex: 1,
        paddingRight: Space.lg,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.White100,
        },
        instabox: {
            ...Typography.Instabox.Web.h5,
            color: Color.Instabox.GreyGray,
        },
    },
    messageAlert: {
        porterbuddy: {
            color: Color.Porterbuddy.Grey4,
        },
        instabox: {
            ...Typography.Instabox.Web.h5,
            color: Color.Instabox.GreyGray,
        },
    },
});
