import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { styles } from '../box-header/style';
export const PendingReturnBoxHeader = (props) => {
    const { authenticated, allowedToCancelReservation, toggleCancelBoxReturnModal } = props;
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('pendingBoxReturnTitle'), middleText: t('pendingBoxReturnSubtitle') }), authenticated ? (_jsx(View, { style: styles.actionsWrapper, children: allowedToCancelReservation ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('cancelReturn'), iconSrc: imageList.CancelReturn, onClick: toggleCancelBoxReturnModal, variant: "primary" }) })) : null })) : null] }));
};
