import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet, css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { BorderRadius } from '../../styles';
import { View } from '../shared/view';
export const styles = StyleSheet.create({
    root: {
        backgroundColor: Color.Budbee.GreenDark,
        minHeight: 116,
        borderRadius: BorderRadius.md,
        marginTop: Space['8xl'],
        alignItems: 'center',
        justifyContent: 'center',
    },
    price: {
        ...Typography.Budbee.Web.h1,
        marginTop: Space.lg,
        color: Color.White100,
        textAlign: 'center',
    },
    priceText: {
        ...Typography.Budbee.Web.h4,
        fontSize: 16,
        color: Color.White100,
        textAlign: 'center',
    },
});
export const PricesInfoBox = (props) => {
    const { isHomeDelivery, free, price, message } = props;
    const { t } = useI18n();
    if (free) {
        return null;
    }
    if (message) {
        return (_jsxs(View, { style: styles.root, children: [_jsx("span", { className: css(styles.priceText), children: message }), _jsx("span", { className: css(styles.price), children: price })] }));
    }
    return (_jsxs(View, { style: styles.root, children: [_jsx("span", { className: css(styles.priceText), children: t(isHomeDelivery ? 'changeToBoxPriceTitle' : 'changeToHomePriceTitle') }), _jsx("span", { className: css(styles.price), children: price })] }));
};
