import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        border: 'none',
        display: 'flex',
        backgroundColor: Color.Budbee.GreenDark,
        borderRadius: Border.Radius.sm,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        ...ButtonEffect.Enabled,
    },
    text: {
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
        color: Color.White100,
    },
});
