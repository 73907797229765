/* eslint-disable prefer-destructuring */
import { getReleaseEnv } from './get-release-env';
const NODE_ENV = process.env.NODE_ENV;
/**
 * Base environment configurations
 * Shared between staging and production
 */
const envConfigBase = {
    NODE_ENV,
    RELEASE_ID: process.env.RELEASE_ID,
    MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiYnVkYmVlIiwiYSI6ImNrMjA3NGdveTA0MTczbm55b2ZxODkzaHQifQ.zkV5bTRCYZElRCA8ibBPLg',
    MAPBOX_STYLE_URL_DAY: 'mapbox://styles/budbee/ck2yt3ls81l8d1cqgllr1nby0',
    MAPBOX_LOCKERS_MAP_STYLE: 'mapbox://styles/budbee/ckq7oydo81u0t18qrggn6lawi',
    isProd: ['staging', 'testing', 'production'].includes(NODE_ENV),
};
/**
 * Staging environment configurations
 */
const envConfigStaging = {
    ...envConfigBase,
    // todo: update with staging url
    WEBSTOMP_API_URL: 'https://webstomp.testing.budbee.com/stomp',
};
/**
 * Production environment configurations
 */
const envConfigProduction = {
    ...envConfigBase,
    WEBSTOMP_API_URL: 'https://webstomp.budbee.com/stomp',
};
const configByEnv = {
    staging: envConfigStaging,
    production: envConfigProduction,
};
export const envConfig = configByEnv[getReleaseEnv()];
