import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../hooks/use-i18n';
import { setCookiesAccepted } from '../../../state/cookies/actions';
import { IconSize } from '../../../styles';
import { getBrand } from '../../../utils/brand/get-brand';
import imageList from '../../../utils/image-list';
import { Icon } from '../icon';
import { styles } from './style';
export const CookieBanner = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const brand = getBrand();
    const onAccept = useCallback(() => {
        dispatch(setCookiesAccepted());
    }, [dispatch]);
    return (_jsxs("button", { type: "button", className: css(styles.root), onClick: onAccept, children: [brand === 'porterbuddy' && (_jsx(Icon, { src: imageList.Cookies, color: Color.Porterbuddy.BuddyPurple, size: IconSize['3xl'] })), _jsx("span", { className: css(styles.text), children: t('cookieBanner') }), _jsx(Icon, { src: imageList.Close, size: IconSize.xs, color: Color.Black100 })] }));
};
