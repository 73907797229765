import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginHorizontal, paddingHorizontal } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: Color.White100,
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        border: 0,
        zIndex: 99999,
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.ChumPurple,
            ...paddingHorizontal(Space.lg),
        },
    },
    text: {
        ...Typography.Budbee.Web['body-sm'],
        color: Color.Budbee.Grey5,
        ...marginHorizontal(Space.lg),
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-sm'],
            textAlign: 'left',
        },
    },
});
