import { connect } from 'react-redux';
import { Payment } from '../../../components/preface/payment';
import { getCurrentLocale } from '../../../state/locale/selectors';
import { reloadOrder } from '../../../state/order/actions';
import { getIsReturn, getIsReturnConsignment, getOrderType, getToken, } from '../../../state/order/selectors';
import { setPaymentReference, updateAdyenPaymentStatus, resetPaymentState, } from '../../../state/payment/actions';
import { getAdyenCheckout, getAdyenPaymentStatus, getAdyenSession, getPrice, getProduct, getRequestedTimeWindow, getVersion, } from '../../../state/payment/selectors';
import { injectIntl } from '../../../utils/i18n';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    version: getVersion(state),
    adyenSession: getAdyenSession(state),
    adyenCheckout: getAdyenCheckout(state),
    adyenPaymentStatus: getAdyenPaymentStatus(state),
    price: getPrice(state),
    token: getToken(state),
    locale: getCurrentLocale(state),
    product: getProduct(state),
    requestedTimeWindow: getRequestedTimeWindow(state),
    isReturnConsignment: getIsReturnConsignment(state),
    isReturn: getIsReturn(state),
    orderType: getOrderType(state),
});
const mapDispatchToProps = (dispatch) => ({
    setPaymentReference: (reference) => dispatch(setPaymentReference(reference)),
    updateAdyenPaymentStatus: (status) => dispatch(updateAdyenPaymentStatus(status)),
    reloadOrder: () => dispatch(reloadOrder()),
    resetPaymentState: () => dispatch(resetPaymentState()),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps), injectIntl);
export const PaymentContainer = decorate(Payment);
