import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, marginHorizontal, paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: BorderRadius.md,
        backgroundColor: Color.White100,
        paddingTop: Space['2xl'],
        ...paddingHorizontal(Space.md),
        porterbuddy: {
            ...marginHorizontal(-Space['2xs']),
        },
    },
    contentWrapper: {
        overflow: 'hidden',
    },
    title: {
        marginTop: Space.md,
        marginLeft: Space.sm,
        marginBottom: Space.lg,
        textAlign: 'left',
        display: 'flex',
        alignSelf: 'flex-start',
        ...Typography.Budbee.Web.h3,
    },
    button: {
        ...marginHorizontal(Space['2xs']),
    },
});
