import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { hooks } from '@budbee/js/dist/tracking';
import { Space } from '@budbee/js/dist/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { IconButton } from '../icon-button';
import { AnimatedView } from '../shared/animated-view';
import { View } from '../shared/view';
import { EntryAccessCode } from './entry-access-code';
import { PinCodeHeader } from './pin-code-header';
import { styles } from './style';
const CODE_LENGTH_THRESHOLD_FOR_READABILITY = 6;
export const PinCode = ({ code, entryAccessCode, locationName, variant = 'BOX', }) => {
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const { t } = useI18n();
    const [copied, setCopied] = useState(false);
    const codeCharactersList = getCodeCharactersList(code);
    const handleClick = () => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    return (_jsxs(View, { style: styles.root, children: [variant === 'BOX' && (_jsx(PinCodeHeader, { imageSrc: imageList.DoorCode, title: t('passCode'), description: t('pinCodeInstructions'), titleButton: copied ? (_jsx(AnimatedView, { animation: "fadeIn", children: _jsxs("span", { style: { height: Space.lg }, children: [t('copied'), "!"] }) })) : (_jsx(IconButton, { src: imageList.Copy, onClick: handleClick })) })), _jsx(View, { style: styles.codeWrapper, "data-test": "digit-container", children: codeCharactersList.map((codeList, key, arr) => {
                    const isSplitted = arr.length > 1;
                    return (_jsx(View, { style: styles.codeInnerWrapper, children: codeList.map((item, i) => {
                            return (_jsx("span", { className: css(styles.code, !isSplitted ? styles.codeShort : undefined), "data-test": `digit-span${isSplitted ? `-${key + 1}` : ''}`, children: item }, getKey({ i })));
                        }) }, getKey({ key })));
                }) }), variant === 'BOX' && !!entryAccessCode && (_jsx(EntryAccessCode, { entryAccessCode: entryAccessCode, locationName: locationName }))] }));
};
function getCodeCharactersList(code) {
    const showSpace = code.length >= CODE_LENGTH_THRESHOLD_FOR_READABILITY;
    const codeList = Array.from(code);
    if (!showSpace) {
        return [codeList];
    }
    const spaceIndex = code.length / 2 - 1;
    return [codeList.slice(0, spaceIndex + 1), codeList.slice(spaceIndex + 1)];
}
