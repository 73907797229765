import { stompConnect } from '@budbee/js/webstomp';
import { setOrderStatusWithConditionalReload } from '../../state/order/actions';
import { envConfig } from '../../utils/env.config';
import { shouldRequestPositionForStatus } from '../../utils/statuses';
import { setEta } from '../eta/actions';
import { getPosition } from '../positions/actions';
let positionsSubscription = null;
const RETRIES = 1000;
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_CONNECTING = 'SOCKET_CONNECTING';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const socketConnected = () => ({
    type: SOCKET_CONNECTED,
});
export const socketConnecting = () => ({
    type: SOCKET_CONNECTING,
});
export const socketDisconnected = () => ({
    type: SOCKET_DISCONNECTED,
});
export const initPositionSocket = (client, driver) => (dispatch) => {
    if (positionsSubscription) {
        return;
    }
    positionsSubscription = client.subscribe(`/events/topic/position_updated/${driver.id}`, (message) => {
        const body = JSON.parse(message.body);
        dispatch(getPosition(body.position));
    });
};
export const initOrderStatusSocket = (client, token, driver) => (dispatch) => {
    client.subscribe(`/events/topic/status/${token}`, (message) => {
        const body = JSON.parse(message.body);
        dispatch(setOrderStatusWithConditionalReload(body.status, body.date));
        if (driver && shouldRequestPositionForStatus(body.status)) {
            dispatch(initPositionSocket(client, driver));
        }
        else if (positionsSubscription) {
            positionsSubscription.unsubscribe();
        }
    });
};
export const initEtaSocket = (client, token) => (dispatch) => {
    client.subscribe(`/events/topic/eta/${token}`, (message) => {
        dispatch(setEta(JSON.parse(message.body)));
    });
};
export const initSockets = ({ token, authToken, driver, requestPositions, }) => (dispatch) => {
    const onError = () => {
        dispatch(socketDisconnected());
    };
    const onUpdate = (retryNumber) => {
        if (retryNumber < RETRIES) {
            dispatch(socketConnecting());
        }
    };
    const onSuccess = (client) => {
        dispatch(socketConnected());
        dispatch(initOrderStatusSocket(client, token, driver));
        dispatch(initEtaSocket(client, token));
        if (driver && requestPositions) {
            dispatch(initPositionSocket(client, driver));
        }
    };
    const onFail = () => {
        dispatch(socketDisconnected());
    };
    const credentials = {
        order_token: token,
        auth_token: authToken,
    };
    dispatch(socketConnecting());
    stompConnect({
        host: envConfig.WEBSTOMP_API_URL,
        credentials,
        onSuccess,
        onUpdate,
        onError,
        onFail,
        retryOptions: {
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ maxDelay: number; retry: number; }' is not... Remove this comment to see the full error message
            maxDelay: 15 * 1000,
            retry: RETRIES,
        },
    });
};
