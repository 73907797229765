import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { ModalPresenter } from '../../components/modal-presenter-content';
import { closeModal, openModal } from '../../state/modal/actions';
import { getAnyModalOpen, getNumberOfModalsOpen, getTopmostModal, } from '../../state/modal/selectors';
const mapStateToProps = (state) => ({
    anyModalOpen: getAnyModalOpen(state),
    topMostModal: getTopmostModal(state),
    numberOfModalsOpen: getNumberOfModalsOpen(state),
});
const mapDispatchToProps = (dispatch) => ({
    openModal: compose(dispatch, openModal),
    closeModal: compose(dispatch, closeModal),
});
export const ModalPresenterContainer = connect(mapStateToProps, mapDispatchToProps)(ModalPresenter);
