import { createReducer } from '../../utils/create-reducer';
import { ACCEPT_COOKIE_POLICY, CHECK_IF_COOKIES_ACCEPTED, } from './actions';
const initState = {
    accepted: false,
};
export const cookies = createReducer(initState, {
    [ACCEPT_COOKIE_POLICY]: (state = initState) => ({
        ...state,
        accepted: true,
    }),
    [CHECK_IF_COOKIES_ACCEPTED]: (state = initState, action) => ({
        ...state,
        accepted: action.payload.accepted,
    }),
});
