import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect } from '../../../styles';
export const styles = StyleSheet.create({
    button: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: Color.Budbee.Grey1,
        borderRadius: Border.Radius.sm,
        border: 'none',
        outline: 0,
        padding: Space.lg,
        marginBottom: Space.lg,
        ...ButtonEffect.Enabled,
    },
    inner: {
        display: 'flex',
        marginRight: 'auto',
        flexDirection: 'column',
    },
    p1: {
        ...Typography.Budbee.Web.h6,
        textAlign: 'left',
        margin: 0,
        padding: 0,
    },
    p2: {
        ...Typography.Budbee.Web.h5,
        textAlign: 'left',
        margin: 0,
        padding: 0,
    },
});
