import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet, css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { View } from '../shared/view';
const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        marginHorizontal: Space['5xl'],
    },
    default: {
        marginTop: '30%',
    },
    title: {
        ...Typography.Budbee.Web.display,
        marginTop: Space['2xl'],
        marginBottom: Space.lg,
        textAlign: 'center',
        instabox: {
            ...Typography.Instabox.Web.display,
        },
    },
    subtitle: {
        ...Typography.Budbee.Web.h4,
        textAlign: 'center',
        instabox: {
            ...Typography.Instabox.Web.h3,
        },
    },
    lightText: {
        color: Color.White100,
    },
});
export const ErrorMessage = (props) => {
    const { message, positionVariant = 'default', colorVariant = 'dark', contentContainerStyle, } = props;
    const { t } = useI18n();
    return (_jsxs(View, { style: [
            styles.root,
            positionVariant === 'default' ? styles.default : undefined,
            contentContainerStyle,
        ], children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.SadFace,
                    instabox: imageList.ParcyFailed,
                }, size: 110 }), _jsx("span", { className: css([styles.title, colorVariant === 'light' ? styles.lightText : undefined]), children: t('errorMessageHeader') }), _jsx("span", { className: css([styles.subtitle, colorVariant === 'light' ? styles.lightText : undefined]), children: message || t('errorMessage') })] }));
};
