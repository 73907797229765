import { Color } from '@budbee/js/dist/ui';
import { StyleSheet } from '@css';
import { MediaQuery, Transition } from '../../styles';
export const styles = StyleSheet.create({
    closeIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        cursor: 'pointer',
        top: 25,
        right: 25,
        height: 30,
        width: 30,
        backgroundColor: Color.White100,
        borderRadius: 50,
        border: 0,
        zIndex: 9999,
        [MediaQuery.maxWidthMd]: {
            right: 20,
        },
    },
    map: {
        transition: Transition.Ease,
        flexBasis: '100%',
        display: 'flex',
        height: 250,
        width: 560,
    },
});
