import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import mapboxgl from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { useMapBox } from '../../../hooks/use-mapbox';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { requestLockersForMap, requestLockersForMapError, requestLockersForMapSuccess, } from '../../../state/lockers/actions';
import { useLockersForMap } from '../../../state/lockers/hooks';
import * as latlng from '../../../utils/latlng';
import { addClusterClickEventListener, addLockerClickEventListener, createFeature, createFeatureCollection, createNewPopup, getCameraBounds, getUserUserLocation, handleClusterFeatures, handleConsumerAddressPin, handleLockerPins, handleLockersSource, handleSearchedPlacePin, toCoordinatePair, ZOOM_MAX_LEVEL, ZOOM_MIN_LEVEL, } from '../../../utils/lockers-map';
import { Loader } from '../loader';
import { styles } from './style';
const mapProperties = {
    center: latlng.mapboxCoordinate({
        latitude: 59.250164,
        longitude: 18.009534,
    }),
    maxZoom: ZOOM_MAX_LEVEL,
    minZoom: ZOOM_MIN_LEVEL,
    attributionControl: true,
};
export const LockersMap = ({ selectedLocker, authToken, orderToken, visible, onSelectLocker, consumerAddress, searchedPlaceFeature, setMapboxGeolocation, requestedGeolocation, }) => {
    const dispatch = useDispatch();
    const trackingApi = useTrackingApi();
    const lockers = useLockersForMap();
    const [lockerFeatureCollection, setLockerFeatureCollection] = useState(() => createFeatureCollection([]));
    const [mapIsLoaded, setMapIsLoaded] = useState(false);
    const [mapFeaturesAreLoaded, setMapFeaturesAreLoaded] = useState(false);
    const [mapBounds, setMapBounds] = useState(null);
    const [mapRef, mapContainerRef] = useMapBox({
        ...mapProperties,
        // @ts-expect-error ts-migrate(2345) FIXME: Type 'null' is not assignable to type 'LngLatBounds... Remove this comment to see the full error message
        bounds: mapBounds,
    });
    // Prevent the map from re-centering between delivery address and selected locker or on consumer address,
    // after the search bar is cleared
    const [initialMapView, setInitialMapView] = useState(false);
    const fetchLockerDetails = useCallback((lockerIdentifier) => trackingApi
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        .getLockerDetails(orderToken, authToken, lockerIdentifier)
        .call(), [authToken, orderToken, trackingApi]);
    useEffect(() => {
        if (mapRef && visible) {
            const geolocation = getUserUserLocation(mapRef);
            if (geolocation) {
                setMapboxGeolocation(geolocation);
                if (requestedGeolocation) {
                    setTimeout(() => {
                        geolocation.trigger();
                    });
                }
            }
        }
    }, [mapRef, requestedGeolocation, setMapboxGeolocation, visible]);
    // Check and make sure the map is loaded
    useEffect(() => {
        if (!mapRef) {
            return;
        }
        mapRef.on('load', () => {
            const nav = new mapboxgl.NavigationControl({
                showCompass: true,
                showZoom: false,
                visualizePitch: true,
            });
            mapRef.addControl(nav, 'bottom-right');
            setMapIsLoaded(true);
        });
    }, [mapRef]);
    // Check and make sure all the data for the map features is present
    useEffect(() => {
        if (mapRef &&
            orderToken &&
            authToken &&
            consumerAddress &&
            lockerFeatureCollection.features.length > 0) {
            setMapFeaturesAreLoaded(true);
        }
    }, [mapRef, lockerFeatureCollection, orderToken, authToken, consumerAddress]);
    // Start populating the map with features once the map and all the data required is loaded
    // If the features drawn in the map during: mapRef.on('load', () => {}) and in the while
    // having all the dependencies listed above, the map will try to add for the second time the features,
    // causing 'duplicate map entities with the same id' error.
    useEffect(() => {
        if (!mapIsLoaded || !mapFeaturesAreLoaded) {
            return;
        }
        handleLockersSource(mapRef, lockerFeatureCollection);
        if (consumerAddress && consumerAddress.coordinate) {
            handleConsumerAddressPin(mapRef, consumerAddress);
        }
        handleClusterFeatures(mapRef);
        handleLockerPins(mapRef);
        addClusterClickEventListener(mapRef);
        addLockerClickEventListener(mapRef, selectedLocker, lockers, onSelectLocker, fetchLockerDetails, createNewPopup());
    }, [
        mapFeaturesAreLoaded,
        mapIsLoaded,
        lockerFeatureCollection,
        consumerAddress,
        selectedLocker,
        lockers,
        onSelectLocker,
        fetchLockerDetails,
        mapRef,
    ]);
    // Fetch all lockers
    useEffect(() => {
        if (!authToken || !orderToken) {
            return;
        }
        dispatch(requestLockersForMap());
        trackingApi
            .getAllLockers(orderToken, authToken)
            .call()
            .then((response) => {
            const { status, payload, errorMsg } = response;
            if (status === ResponseStatus.COMPLETED) {
                if (payload && (payload === null || payload === void 0 ? void 0 : payload.lockers)) {
                    dispatch(requestLockersForMapSuccess(payload.lockers));
                }
            }
            else {
                dispatch(requestLockersForMapError(errorMsg));
            }
        });
    }, [authToken, orderToken]);
    // 1. On the initial map render, calculate bounding box from consumer address and selected locker(if set)
    // 2. If searched and selected area, set frame to that bounding box
    // 3. If searched and selected a location, set map center to that location
    // 4. Otherwise zoom in on the consumer address
    useEffect(() => {
        if (!mapRef || !consumerAddress) {
            return;
        }
        if (searchedPlaceFeature) {
            if (searchedPlaceFeature.bbox) {
                setMapBounds({
                    // @ts-expect-error ts-migrate(7016) FIXME: Argument of type '{ bounds: any[][]; padding: number; ... Remove this comment to see the full error message
                    bounds: [searchedPlaceFeature.bbox.slice(0, 2), searchedPlaceFeature.bbox.slice(2)],
                    padding: 10,
                });
            }
            else if (searchedPlaceFeature.center) {
                mapRef.flyTo({
                    center: searchedPlaceFeature.geometry.coordinates,
                    zoom: 15,
                    speed: 1.5,
                });
            }
            return;
        }
        if (initialMapView) {
            if (selectedLocker && consumerAddress.coordinate) {
                const newBounds = getCameraBounds(consumerAddress.coordinate, selectedLocker.lockerAddress.coordinate);
                setMapBounds({
                    // @ts-expect-error ts-migrate(7016) FIXME: Argument of type '{ bounds: any[][]; padding: number; ... Remove this comment to see the full error message
                    bounds: [newBounds.sw, newBounds.ne],
                    padding: 150,
                });
            }
            else if (selectedLocker && !consumerAddress.coordinate) {
                mapRef.flyTo({
                    center: toCoordinatePair(selectedLocker.lockerAddress.coordinate),
                    zoom: 13,
                    speed: 1.5,
                });
            }
            else if (!selectedLocker && consumerAddress.coordinate) {
                mapRef.flyTo({
                    center: toCoordinatePair(consumerAddress.coordinate),
                    zoom: 13,
                    speed: 1.5,
                });
            }
        }
        else {
            setInitialMapView(true);
        }
    }, [
        selectedLocker,
        consumerAddress,
        searchedPlaceFeature,
        mapRef,
        setInitialMapView,
        initialMapView,
    ]);
    // Necessary to trigger a resize when changing visibility style
    useEffect(() => {
        if (!mapRef || !visible) {
            return;
        }
        mapRef.resize();
    }, [visible, mapRef]);
    // Update map bounds
    useEffect(() => {
        if (!mapRef || !mapBounds) {
            return;
        }
        // @ts-expect-error ts-migrate(7016) FIXME:Property 'fitBounds' does not exist on type 'MutableRefObject '... Remove this comment to see the full error message
        mapRef.fitBounds(mapBounds.bounds, { padding: mapBounds.padding || 150 });
    }, [mapRef, mapBounds]);
    // Update lockers source and searched place pin location when a search result item is selected
    useEffect(() => {
        if (!mapIsLoaded) {
            return;
        }
        handleLockersSource(mapRef, lockerFeatureCollection);
        handleSearchedPlacePin(mapRef, 
        // @ts-expect-error ts-migrate(7016) FIXME:T Argument of type '{ latitude: any; longitude: any; } | null'... Remove this comment to see the full error message
        searchedPlaceFeature
            ? {
                latitude: searchedPlaceFeature.geometry.coordinates[1],
                longitude: searchedPlaceFeature.geometry.coordinates[0],
            }
            : null);
    }, [lockerFeatureCollection, searchedPlaceFeature, mapRef, mapIsLoaded]);
    // Display all lockers
    useEffect(() => {
        if (!lockers) {
            return;
        }
        const lockerFeatures = lockers.map((locker) => {
            const { coordinate, id, available } = locker;
            const isCurrent = !!selectedLocker && id === selectedLocker.lockerIdentifier;
            return createFeature(id, coordinate, {
                available,
                isCurrent,
                markerRadius: isCurrent ? 30 : 25,
            });
        });
        setLockerFeatureCollection(createFeatureCollection(lockerFeatures));
    }, [lockers, selectedLocker]);
    // Clean up and release all internal resources associated with the map when modal closes
    useEffect(() => {
        return function cleanup() {
            if (mapRef) {
                mapRef.remove();
            }
        };
    }, [mapRef]);
    return (_jsx("div", { className: css([styles.lockerPickerMap, !visible && styles.hidden]), children: _jsx("div", { ref: mapContainerRef, className: css(styles.boxTrackingMap), children: (!mapIsLoaded || !mapFeaturesAreLoaded) && _jsx(Loader, {}) }) }));
};
