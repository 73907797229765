import { createReducer } from '../../utils/create-reducer';
import { SET_FEATURES } from './actions';
export const initialState = {
    features: {},
};
export const features = createReducer(initialState, {
    [SET_FEATURES]: (state = initialState, action) => ({
        ...state,
        features: action.payload.features,
    }),
});
