import { ResponseStatus } from '@budbee/js/tracking-api';
import * as Sentry from '@sentry/react';
import { TrackingApi } from '../../server/tracking';
import { getPersistedPreferredLanguage } from '../../utils/locale';
import { setBanner } from '../banner/actions';
import { setFeatures } from '../features/actions';
import { setCurrentLocale, setLanguage, setTimeZone } from '../locale/actions';
import { getCountryCode, getDefaultLocale } from '../locale/selectors';
import { setExternalLinks } from '../order/actions';
export const SET_BOX_ORDER = 'SET_BOX_ORDER';
export const SET_IS_FETCHING_BOX_ORDER = 'SET_IS_FETCHING_BOX_ORDER';
export const SET_BOX_STATUS = 'SET_BOX_STATUS';
export const SET_BOX_CONSIGNMENT = 'SET_BOX_CONSIGNMENT';
export const SET_BOX_ORDER_LOCKER = 'SET_BOX_ORDER_LOCKER';
export const SET_BOX_CHECKPOINTS = 'SET_BOX_CHECKPOINTS';
export const SET_BOX_CODE = 'SET_BOX_CODE';
export const SET_SYSTEM = 'SET_SYSTEM';
export const setBoxOrder = (data) => ({
    type: SET_BOX_ORDER,
    payload: { data },
});
export const setIsFetchingBoxOrder = (loading) => ({
    type: SET_IS_FETCHING_BOX_ORDER,
    payload: { loading },
});
export const setBoxStatus = (status) => ({
    type: SET_BOX_STATUS,
    payload: { status },
});
export const setBoxOrderLocker = (locker) => ({
    type: SET_BOX_ORDER_LOCKER,
    payload: { locker },
});
export const setBoxConsignment = (consignment) => ({
    type: SET_BOX_CONSIGNMENT,
    payload: { consignment },
});
export const setBoxCheckpoints = (boxCheckpoints) => ({
    type: SET_BOX_CHECKPOINTS,
    payload: { boxCheckpoints },
});
export const setBoxCode = (code, needsVerification) => ({
    type: SET_BOX_CODE,
    payload: { code, needsVerification },
});
export const setSystem = (system) => ({
    type: SET_SYSTEM,
    payload: { system },
});
const fetchExternalLinks = (trackingApi, dispatch) => {
    trackingApi
        .getExternalLinks()
        .call()
        .then((response) => {
        if (response.status === ResponseStatus.COMPLETED && response.payload) {
            dispatch(setExternalLinks(response.payload));
        }
    })
        .catch((error) => Sentry.captureException(error));
};
const fetchOrderFeatures = (token, authToken, trackingApi, dispatch) => {
    try {
        trackingApi
            .getOrderFeatures(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                dispatch(setFeatures(response.payload));
            }
        });
    }
    catch (e) {
        Sentry.captureException(e);
    }
};
export const fetchBoxOrder = (token, authToken = '') => (dispatch, getState) => {
    dispatch(setIsFetchingBoxOrder(true));
    const state = getState();
    const country = getCountryCode(state);
    const defaultLocale = getDefaultLocale(state);
    const persistedLanguage = getPersistedPreferredLanguage();
    const localeToUse = persistedLanguage ? `${persistedLanguage}_${country}` : defaultLocale;
    const languageToUse = persistedLanguage || defaultLocale.slice(0, 2);
    dispatch(setLanguage(languageToUse));
    dispatch(setCurrentLocale(localeToUse));
    const trackingApi = TrackingApi({
        'App-Locale': localeToUse,
    });
    trackingApi
        .getBoxOrder(authToken, token)
        .call()
        .then((response) => {
        if (response.status === ResponseStatus.COMPLETED) {
            const boxOrder = response.payload;
            const { lockerAttributes } = boxOrder;
            dispatch(setBoxOrder(boxOrder));
            dispatch(setTimeZone(boxOrder.timeZone));
            fetchExternalLinks(trackingApi, dispatch);
            fetchOrderFeatures(token, authToken, trackingApi, dispatch);
            if (lockerAttributes === null || lockerAttributes === void 0 ? void 0 : lockerAttributes.address) {
                const { city, countryCode } = lockerAttributes.address;
                trackingApi
                    .getBannerForCityAndCountry(city, countryCode)
                    .call()
                    .then((bannerResponse) => {
                    if (bannerResponse.status === ResponseStatus.COMPLETED) {
                        dispatch(setBanner(bannerResponse.payload));
                    }
                });
            }
            dispatch(setIsFetchingBoxOrder(false));
        }
    })
        .catch((e) => {
        Sentry.captureMessage(`Order (${token}) could not be located eventhough type has been established`);
        Sentry.captureException(e);
    });
    trackingApi
        .getBoxCheckpoints(token, authToken)
        .call()
        .then((response) => {
        if (response.status === ResponseStatus.COMPLETED && response.payload) {
            const { history } = response.payload;
            dispatch(setBoxCheckpoints(history.reverse()));
        }
    });
    trackingApi
        .getBoxCode(authToken, token)
        .call()
        .then((response) => {
        if (response.status === ResponseStatus.COMPLETED) {
            const { code, needsVerification } = response.payload;
            dispatch(setBoxCode(code, needsVerification));
        }
    });
};
