import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, MediaQuery, Transition, border, paddingHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'column',
        transition: Transition.Ease,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.md,
        padding: Space.lg,
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.BuddyPurple,
            borderRadius: 0,
            ...paddingVertical(Space['3xl']),
            ...paddingHorizontal(Space['2xl']),
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    heading: {
        ...Typography.Budbee.Web.h3,
        marginBottom: Space.lg,
        marginLeft: Space.sm,
        porterbuddy: {
            display: 'none',
        },
    },
    title: {
        ...Typography.Budbee.Web.h3,
        marginBottom: Space.xl,
        paddingTop: Space.sm,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h2,
            color: Color.White100,
            paddingTop: 0,
        },
    },
    titleAlt: {
        ...Typography.Budbee.Web.h1,
        color: Color.Budbee.GreenDark,
        marginTop: Space.xl,
        textAlign: 'center',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h1,
            color: Color.White100,
            marginBottom: Space.lg,
            marginTop: Space['3xl'],
        },
        instabox: {
            color: Color.Black100,
        },
    },
    subtitle: {
        ...Typography.Budbee.Web.h4,
        marginBottom: Space.lg,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.White100,
            marginBottom: Space['5xl'],
        },
    },
    subtitleAlt: {
        ...Typography.Budbee.Web.h4,
        marginTop: Space.sm,
        marginBottom: Space.sm,
        textAlign: 'center',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.White100,
            margin: 0,
        },
    },
    container: {
        marginBottom: Space['2xl'],
        display: 'flex',
        justifyContent: 'space-between',
        [MediaQuery.minWidthSm]: {
            ...paddingHorizontal(Space['3xl']),
        },
    },
    rating: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        border: 0,
        ...ButtonEffect.Enabled,
    },
    disabled: {
        cursor: 'not-allowed',
    },
    rateTitle: {
        ...Typography.Porterbuddy.Web.h3,
        color: Color.White100,
        marginTop: Space.md,
    },
    optionsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: Space.md,
        marginTop: Space.sm,
    },
    optionContainer: {
        display: 'flex',
        marginBottom: Space.lg,
        alignItems: 'center',
        background: 'none',
        border: 0,
        paddingLeft: 0,
    },
    option: {
        ...Typography.Budbee.Web['body-lg'],
        textAlign: 'left',
        marginLeft: Space['3xl'],
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.White100,
        },
    },
    checkbox: {
        width: 18,
        height: 18,
        ...border(1, 'solid', Color.Budbee.GreenPop),
        ...ButtonEffect.Enabled,
        borderRadius: 50,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        instabox: {
            ...border(1, 'solid', Color.Instabox.ReallyRed),
        },
        porterbuddy: {
            borderRadius: 4,
            ...border(1.5, 'solid', Color.White100),
        },
    },
    selected: {
        ...border(1, 'solid', Color.Budbee.Green),
        backgroundColor: Color.Budbee.Green,
        porterbuddy: {
            backgroundColor: Color.White100,
        },
        instabox: {
            backgroundColor: Color.Instabox.ReallyRed,
        },
    },
    input: {
        padding: Space.lg,
        marginBottom: Space.sm,
        background: Color.White100,
        ...border(Border.Width.sm, 'solid', Color.Budbee.Grey2),
        porterbuddy: {
            ...border(Border.Width.md, 'solid', Color.Porterbuddy.AmigoPurple),
            borderRadius: Border.Radius['2xs'],
            background: 'none',
        },
    },
    input2: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.Grey5,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.White100,
        },
        '::placeholder': {
            porterbuddy: {
                ...Typography.Porterbuddy.Web['body-lg'],
                color: Color.White100,
            },
        },
    },
    loading: {
        opacity: 0.25,
        transition: Transition.Ease,
        pointerEvents: 'none',
    },
    loader: {
        height: 46,
        margin: 0,
        alignSelf: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        justifyContent: 'center',
        left: 0,
        right: 0,
        width: '100%',
    },
    button: {
        width: '50%',
    },
    divider: {
        width: Space.md,
    },
    thankYouView: {
        minHeight: 200,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    retryButton: {
        marginTop: Space['2xl'],
    },
});
