import { ConsignmentType } from '@budbee/js/tracking';
/**
 * @deprecated Use Consignment instead, this is just as a bridge between migration
 */
export const consignmentToDeliveryWindow = (consignment) => {
    return {
        start: consignment.start,
        stop: consignment.stop,
        deadline: consignment.routingDeadline,
        return: consignment.type === ConsignmentType.RETURN,
    };
};
