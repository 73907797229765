import { StyleSheet } from 'aphrodite/no-important';
import { FontSize, FontWeight, Transition, border } from '../../../styles';
export const styles = StyleSheet.create({
    default: {
        cursor: 'pointer',
        fontWeight: FontWeight.SemiBold,
        fontSize: FontSize.md,
        borderRadius: 50,
        textRendering: 'optimizeLegibility',
        textAlign: 'center',
        padding: '1em 25px',
        minWidth: 235,
        minHeight: 56,
        ...border(2, 'solid', '#39c69b'),
        color: '#39c69b',
        background: 'white',
        outline: 'none',
        boxShadow: '0 12.5px 25px 0 rgba(0, 0, 0, 0.15)',
        transition: Transition.Ease,
    },
});
