import { jsx as _jsx } from "react/jsx-runtime";
import { Adyen } from '../../utils/adyen';
import { AdyenV3 } from '../../utils/adyenv3';
import { Loader } from '../shared/loader';
export const Checkout = ({ setPaymentReference, updateAdyenPaymentStatus, adyenSession, adyenPaymentStatus, token, price, locale, adyenCheckout, version, }) => {
    if (adyenSession || adyenCheckout) {
        return version === 'V3' ? (_jsx(AdyenV3, { setPaymentReference: setPaymentReference, updatePaymentStatus: updateAdyenPaymentStatus, paymentStatus: adyenPaymentStatus, session: adyenCheckout, token: token, price: price, locale: locale })) : (_jsx(Adyen, { setPaymentReference: setPaymentReference, updatePaymentStatus: updateAdyenPaymentStatus, paymentStatus: adyenPaymentStatus, session: adyenSession, token: token, price: price, locale: locale }));
    }
    return _jsx(Loader, {});
};
