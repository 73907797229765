import moment from 'moment-timezone';
class TimeWindow {
    constructor(props) {
        Object.keys(props).forEach((key) => {
            // eslint-disable-next-line
            // @ts-ignore
            this[key] = props[key];
        });
    }
    isSame(timeWindow) {
        if (!timeWindow) {
            return false;
        }
        return (moment(this.timeWindow.start).isSame(timeWindow.timeWindow.start) &&
            moment(this.timeWindow.stop).isSame(timeWindow.timeWindow.stop));
    }
}
export const toTimeWindow = (props) => {
    return new TimeWindow(props);
};
export default TimeWindow;
