import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    wrapper: {
        justifyContent: 'center',
    },
    divider2xl: {
        height: Space['2xl'],
        porterbuddy: {
            height: 0,
        },
    },
});
