import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { css } from '@css';
import Lottie from 'lottie-react';
import budbeeLoader from '../../../img/lottie/budbee-loader.json';
import instaboxLoader from '../../../img/lottie/instabox-loader.json';
import porterbuddyLoader from '../../../img/lottie/porterbuddy-loader.json';
import { getBrand } from '../../../utils/brand/get-brand';
import { styles } from './style';
export const Loader = ({ style, variant = 'regular' }) => {
    const brand = getBrand();
    const loader = useMemo(() => {
        switch (brand) {
            case 'budbee':
                return budbeeLoader;
            case 'instabox':
                return instaboxLoader;
            case 'porterbuddy':
                return porterbuddyLoader;
            default:
                return budbeeLoader;
        }
    }, [brand]);
    return (_jsx(Lottie, { animationData: loader, loop: true, className: css([
            styles.loader,
            variant === 'inline' ? styles.inline : undefined,
            variant === 'inlineMiddle' ? styles.inlineMiddle : undefined,
            style,
        ]) }));
};
