import { jsx as _jsx } from "react/jsx-runtime";
import { ConsignmentType } from '@budbee/js/dist/tracking';
import { Summary } from '../../../components/preface/summary';
import { useAuthToken, useConspectus, useToken } from '../../../state/order/hooks';
import { useRequestedTimeWindow } from '../../../state/payment/hooks';
import { getBrand } from '../../../utils/brand/get-brand';
export const SummaryContainer = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const conspectus = useConspectus();
    const token = useToken();
    const authToken = useAuthToken();
    const pendingTimeWindow = useRequestedTimeWindow();
    const brand = getBrand();
    if (!conspectus) {
        return null;
    }
    return (_jsx(Summary, { token: token, estateType: conspectus.deliverySettings.estateType, accessMode: conspectus.deliverySettings.accessMode, deliveryMethod: (_b = (_a = conspectus.deliverySettings) === null || _a === void 0 ? void 0 : _a.deliveryExecution) === null || _b === void 0 ? void 0 : _b.method, deliveryNotification: (_d = (_c = conspectus.deliverySettings) === null || _c === void 0 ? void 0 : _c.deliveryExecution) === null || _d === void 0 ? void 0 : _d.notification, alternativeLeaveWithNeighbour: (_f = (_e = conspectus.deliverySettings) === null || _e === void 0 ? void 0 : _e.deliveryExecution) === null || _f === void 0 ? void 0 : _f.alternativeLeaveWithNeighbour, floor: conspectus.deliverySettings.floor, countryCode: conspectus.address.countryCode, consignment: conspectus.consignment, pendingTimeWindow: pendingTimeWindow, routedEta: conspectus.routedEta, orderStatus: conspectus.status.state, isReturn: ((_g = conspectus === null || conspectus === void 0 ? void 0 : conspectus.consignment) === null || _g === void 0 ? void 0 : _g.type) === ConsignmentType.RETURN || false, allowedToBookDelivery: conspectus.allowedToBookDelivery, ...props, authToken: authToken, careOf: conspectus.address.street2, specialInstructions: conspectus.address.settings.specialInstructions, showDeliveryTime: brand !== 'porterbuddy' }));
};
