import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal, Spacing, Transition } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        position: 'relative',
    },
    input: {
        ...Typography.Budbee.Web['body-md'],
        outline: 'none',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: Border.Radius.sm,
        height: 50,
        appearance: 'none',
        transition: Transition.Ease,
        textAlign: 'center',
        backgroundColor: Color.Budbee.Grey1,
        border: 0,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
        },
        '::placeholder': {
            porterbuddy: {
                color: Color.Porterbuddy.Grey4,
            },
        },
    },
    rootTextArea: {
        position: 'relative',
        borderRadius: Border.Radius.sm,
        ...paddingHorizontal(Spacing.sm),
        paddingTop: Space.sm,
        backgroundColor: Color.Budbee.Grey1,
        border: 0,
    },
    textarea: {
        ...Typography.Budbee.Web['body-md'],
        border: 'none',
        width: '100%',
        outline: 'none',
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
        appearance: 'none',
        transition: Transition.Ease,
    },
    textAreaNoResize: {
        resize: 'none',
    },
    inputIcon: {
        paddingLeft: Space['5xl'],
    },
    inputCounted: {
        paddingRight: Space['5xl'],
    },
    iconContainer: {
        position: 'absolute',
        top: '50%',
        left: Space['2xl'],
        transform: 'translate(-50%, -50%)',
        opacity: 0.6,
    },
    inputCountContainer: {
        position: 'absolute',
        bottom: 0,
        right: Space.sm,
        margin: Space['2xs'],
    },
    textareaCountContainer: {
        margin: Space['2xs'],
    },
    countText: {
        ...Typography.Budbee.Web['body-sm'],
        transition: 'all 0.2s ease-in-out',
        opacity: 0.6,
        textAlign: 'right',
    },
    inputFeedback: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space.sm,
        paddingTop: Space['2xs'],
        textAlign: 'left',
    },
    placeholder: {
        position: 'absolute',
        top: -Space.sm,
        transition: Transition.Ease,
        opacity: 0,
        ...Typography.Porterbuddy.Web['body-sm'],
    },
    show: {
        opacity: 1,
    },
});
