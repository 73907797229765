import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { OrderType, } from '@budbee/js/tracking';
import { Color, Space } from '@budbee/js/ui';
import { css } from '@css';
import { View } from '../../../components/shared/view';
import { useI18n } from '../../../hooks/use-i18n';
import { useBoxOrder } from '../../../state/box-order/hooks';
import { useConsignment, useConspectus } from '../../../state/order/hooks';
import { getBrand } from '../../../utils/brand/get-brand';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { useIntercom } from '../../../utils/intercom';
import { capitalize } from '../../../utils/strings';
import { styles } from './style';
const getCustomDatapoints = (orderType, conspectus, consignment, boxOrder) => {
    var _a, _b, _c, _d, _e;
    if (orderType === OrderType.BOX && boxOrder) {
        return {
            status: boxOrder.status,
            createdAt: boxOrder.createdAt,
            pickedUpByMerchant: boxOrder.orderLocation.parcelsPickedUpFromMerchant || false,
            consignmenType: ((_a = boxOrder.consignment) === null || _a === void 0 ? void 0 : _a.type) || '',
            cancellationReason: ((_c = (_b = boxOrder.consignment) === null || _b === void 0 ? void 0 : _b.cancellation) === null || _c === void 0 ? void 0 : _c.reason) || '',
        };
    }
    if (orderType === OrderType.DELIVERY && conspectus) {
        return {
            status: conspectus === null || conspectus === void 0 ? void 0 : conspectus.status.state,
            createdAt: conspectus === null || conspectus === void 0 ? void 0 : conspectus.createdAt.toString(),
            pickedUpByMerchant: ((_d = conspectus === null || conspectus === void 0 ? void 0 : conspectus.orderLocation) === null || _d === void 0 ? void 0 : _d.parcelsPickedUpFromMerchant) || false,
            consignmenType: (consignment === null || consignment === void 0 ? void 0 : consignment.type) || '',
            cancellationReason: ((_e = consignment === null || consignment === void 0 ? void 0 : consignment.cancellation) === null || _e === void 0 ? void 0 : _e.reason) || '',
        };
    }
    return undefined;
};
export const ChatButton = ({ countryCode, token, orderType, consumer, merchantName, intercom, chatNoOperatorMessage, noOperator, disabled, }) => {
    const [loaded, setLoaded] = useState(false);
    const { t } = useI18n();
    const consignment = useConsignment();
    const boxOrder = useBoxOrder();
    const conspectus = useConspectus();
    const brand = getBrand();
    const datapoints = getCustomDatapoints(orderType, conspectus, consignment, boxOrder);
    const intercomAPI = useIntercom();
    const openChat = useCallback(() => {
        var _a;
        intercomAPI.boot({
            user_id: intercom.userId,
            user_hash: (_a = intercom.userIdentityHash) !== null && _a !== void 0 ? _a : undefined,
            name: consumer.name,
            email: consumer.email,
            phone: consumer.phoneNumber,
            last_ordertoken: token,
            last_merchant_name: merchantName,
            last_carriers_url: `https://carriers.budbee.com/admin/orders/${token}`,
            last_servo_url: `https://console.instadmin.net/servo/orders?parcel_id=${token}`,
            country_code: countryCode,
            order_type: orderType,
            status: (datapoints === null || datapoints === void 0 ? void 0 : datapoints.status) || '',
            order_created_date: (datapoints === null || datapoints === void 0 ? void 0 : datapoints.createdAt) || '',
            picked_up_by_merchant: (datapoints === null || datapoints === void 0 ? void 0 : datapoints.pickedUpByMerchant) || false,
            consignment_type: (datapoints === null || datapoints === void 0 ? void 0 : datapoints.consignmenType) || '',
            cancellation_reason: (datapoints === null || datapoints === void 0 ? void 0 : datapoints.cancellationReason) || '',
            delivery_company: capitalize(brand),
        });
        intercomAPI.show();
    }, [intercom]);
    useEffect(() => {
        setLoaded(true);
    }, []);
    if (!loaded) {
        return null;
    }
    if (noOperator) {
        return _jsx("p", { children: chatNoOperatorMessage });
    }
    return (_jsx(_Fragment, { children: _jsxs(View, { style: [styles.linkView, disabled ? styles.disabledLinkView : undefined], onClick: disabled ? undefined : openChat, children: [_jsx(BrandedIcon, { src: {
                        budbee: imageList.Chat,
                        porterbuddy: imageList.ChatPorterbuddy,
                        instabox: imageList.Chat,
                    }, size: Space['2xl'] }), _jsxs(View, { style: styles.rightContainer, children: [_jsx("span", { className: css(styles.p), children: t('writeToUs') }), _jsx(BrandedIcon, { src: imageList.chevronRight, size: Space.lg, color: {
                                budbee: Color.Budbee.GreenDark,
                                porterbuddy: Color.Porterbuddy.BuddyPurple,
                                instabox: Color.Instabox.GreyGray,
                            } })] })] }) }));
};
