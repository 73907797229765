import { ResponseStatus } from '@budbee/js/tracking-api';
import { toTimeWindow } from '../../utils/to-time-window';
import TrackingV3 from '../../utils/tracking-v3';
export const SET_TIME_WINDOWS = 'SET_TIME_WINDOWS';
export const FETCH_TIME_WINDOWS_FAILED = 'FETCH_TIME_WINDOWS_FAILED';
export const FETCH_TIME_WINDOWS_COMPLETED = 'FETCH_TIME_WINDOWS_COMPLETED';
export const FETCH_TIME_WINDOWS_STARTED = 'FETCH_TIME_WINDOWS_STARTED';
const fetchTimeWindowsFailed = (error) => ({
    type: FETCH_TIME_WINDOWS_FAILED,
    payload: { error },
});
const fetchTimeWindowsCompleted = (timeWindows) => ({
    type: FETCH_TIME_WINDOWS_COMPLETED,
    payload: { timeWindows },
});
const fetchTimeWindowsStarted = () => ({
    type: FETCH_TIME_WINDOWS_STARTED,
});
const afterFetchTimeWindows = (response, dispatch) => {
    const { status, errorCode, payload } = response;
    if (status !== ResponseStatus.COMPLETED) {
        return dispatch(fetchTimeWindowsFailed(errorCode));
    }
    return dispatch(fetchTimeWindowsCompleted(payload.timeWindows.map(toTimeWindow)));
};
export const fetchTimeWindows = (token) => (dispatch) => {
    dispatch(fetchTimeWindowsStarted());
    TrackingV3()
        .getTimetable(token)
        .then((response) => afterFetchTimeWindows(response, dispatch))
        .catch(fetchTimeWindowsFailed);
};
export const setTimeWindows = (timeWindows) => ({
    type: SET_TIME_WINDOWS,
    payload: { timeWindows },
});
