import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { IconSize } from '../../../styles';
export const styles = StyleSheet.create({
    wrapper: {
        height: 56,
        display: 'flex',
        position: 'relative',
        zIndex: 11,
    },
    container: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        backgroundColor: Color.White100,
        boxShadow: 'none',
        borderRadius: Border.Radius.sm,
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: Space.lg,
    },
    iconContainer: {
        margin: 'auto',
    },
    searchIcon: {
        marginTop: 3,
        width: IconSize.xl,
    },
    inputWrapper: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
    },
    input: {
        backgroundColor: 'transparent',
        border: 'none',
        wordWrap: 'break-word',
        outline: 'none',
        display: 'flex',
        flex: '100%',
        paddingLeft: Space.lg,
        ...Typography.Budbee.Web['body-lg'],
        width: '100%',
        height: '100%',
        color: Color.Budbee.Grey4,
    },
    clearIconWrapper: {
        display: 'flex',
        background: 'unset',
        border: 'none',
        alignItems: 'center',
        padding: Space.lg,
    },
    submitButtonWrapper: {
        marginLeft: Space.lg,
        display: 'flex',
        justifyContent: 'center',
    },
});
