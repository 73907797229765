import { connect } from 'react-redux';
import { Timetable } from '../../../components/preface/timetable';
import { afterBookConsignment } from '../../../state/order/actions';
import { getReturnableParcelCount, getAuthToken, getToken } from '../../../state/order/selectors';
import { getRequestedTimeWindow } from '../../../state/payment/selectors';
import { fetchTimeWindows } from '../../../state/timetable/actions';
import { getIsFetchingTimeWindows, getTimeWindows } from '../../../state/timetable/selectors';
const mapStateToProps = (state) => ({
    token: getToken(state),
    authToken: getAuthToken(state),
    pendingTimeWindow: getRequestedTimeWindow(state),
    timeWindows: getTimeWindows(state),
    isFetchingTimeWindows: getIsFetchingTimeWindows(state),
    returnParcelCount: getReturnableParcelCount(state),
});
const mapDispatchToProps = (dispatch) => ({
    afterBookConsignment: (response, timeWindow, onlyReloadOrder) => dispatch(afterBookConsignment(response, timeWindow, onlyReloadOrder)),
    fetchTimeWindows: (token) => dispatch(fetchTimeWindows(token)),
});
export const TimetableContainer = connect(mapStateToProps, mapDispatchToProps)(Timetable);
