import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.sm,
        backgroundColor: Color.White100,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        border: 'none',
        appearance: 'none',
        outline: 0,
        ...paddingVertical(Space.lg),
        ...paddingHorizontal(Space['2xl']),
        ...ButtonEffect.Enabled,
    },
    rootDisabled: {
        ...ButtonEffect.Disabled,
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    leftContainer: {
        marginRight: 'auto',
    },
    cityText: {
        margin: 0,
        paddingTop: Space['2xs'],
        textAlign: 'left',
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey4,
    },
    lockerNameText: {
        margin: 0,
        textAlign: 'left',
        ...Typography.Budbee.Web.h4,
    },
    openHours: {
        margin: 0,
        marginRight: Space.sm,
        display: 'flex',
        alignSelf: 'flex-start',
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
        ...paddingVertical(Space['2xs']),
        overflow: 'hidden',
        borderRadius: Border.Radius.sm,
    },
    open: {
        backgroundColor: Color.Budbee.GreenLight,
        color: Color.Budbee.GreenDark,
    },
    closed: {
        backgroundColor: Color.Budbee.LightPink,
        color: Color.Budbee.ReadyRed,
    },
    fullyBooked: {
        margin: 0,
        display: 'flex',
        alignSelf: 'flex-start',
        marginTop: Space.sm,
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
        ...paddingVertical(Space['2xs']),
        overflow: 'hidden',
        borderRadius: Border.Radius.sm,
        backgroundColor: Color.Budbee.LightPink,
        color: Color.Budbee.ReadyRed,
    },
    reservableUntil: {
        margin: 0,
        marginTop: Space.sm,
        display: 'flex',
        alignSelf: 'flex-start',
        textAlign: 'left',
        borderRadius: Border.Radius.sm,
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
        ...paddingVertical(Space['2xs']),
        color: Color.Budbee.Grey4,
        backgroundColor: Color.Budbee.Grey1,
    },
    highDemand: {
        margin: 0,
        display: 'flex',
        alignSelf: 'flex-start',
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
        ...paddingVertical(Space['2xs']),
        overflow: 'hidden',
        borderRadius: Border.Radius.sm,
        backgroundColor: Color.Budbee.LightPink,
        color: Color.Budbee.ReadyRed,
        marginRight: Space.sm,
        marginTop: Space.sm,
    },
    bottomSectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: Space.sm,
    },
    greenBadge: {
        backgroundColor: Color.Budbee.GreenPop,
        color: Color.White100,
        alignItems: 'center',
    },
});
