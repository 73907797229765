import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { OrderType, ReturnType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { ErrorMessage } from '../../error-message';
import { InstructionList } from '../../instruction-list';
import { ParcelCounter } from '../../parcel-counter';
import { Loader } from '../../shared/loader';
import { Title } from '../../title';
import { styles } from './style';
export const ReturnInstructions = ({ token, authToken, onContinue, canContinueChanged, returnParcelCount, setReturnParcels, returnableParcels, returnType, orderType, }) => {
    const trackingApi = useTrackingApi();
    const [instructions, setInstructions] = useState();
    const [showError, setShowError] = useState();
    const [loading, setLoading] = useState();
    const { t } = useI18n();
    useEffect(() => canContinueChanged(!showError), [showError]);
    useEffect(() => onContinue((next) => next()), []);
    useEffect(() => {
        if (!authToken) {
            return;
        }
        setLoading(true);
        trackingApi
            .getReturnInstructions(token, authToken, returnType)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                setInstructions(response.payload.instructions.map((i) => i.text));
            }
            else {
                setShowError(true);
            }
            setLoading(false);
        })
            .catch((e) => {
            Sentry.captureException(e);
            setShowError(true);
            setLoading(false);
        });
    }, []);
    if (loading || !instructions) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    if (showError) {
        return _jsx(ErrorMessage, {});
    }
    return (_jsxs("div", { children: [_jsx(Title, { title: t('bookReturnTitle'), style: styles.title }), _jsx(InstructionList, { instructions: instructions }), _jsx("div", { className: css(styles.parcelCounter), children: returnType === ReturnType.HOME_PICKUP && orderType === OrderType.DELIVERY ? (_jsx(ParcelCounter, { maxParcels: returnableParcels, returnParcelCount: returnParcelCount, setReturnParcelCount: setReturnParcels })) : null })] }));
};
