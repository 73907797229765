import { jsx as _jsx } from "react/jsx-runtime";
import { EstateType } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { CheckButtonOptions } from '../check-button-options';
export const EstatePicker = ({ onChange, currentEstateType }) => {
    const { t } = useI18n();
    const brand = getBrand();
    const data = {
        title: t('accessSettings.howDoYouLive'),
        options: [
            {
                label: t('accessSettings.house'),
                leftIcon: brand === 'instabox' ? imageList.HouseInstabox : imageList.House,
                checked: currentEstateType === EstateType.HOUSE,
                onPress: () => onChange(EstateType.HOUSE),
                disabled: false,
                shouldRender: true,
                trailingComponent: null,
            },
            {
                label: t('accessSettings.apartment'),
                leftIcon: brand === 'instabox' ? imageList.ApartmentInstabox : imageList.Apartment,
                checked: currentEstateType === EstateType.APARTMENT ||
                    currentEstateType === EstateType.APARTMENT_ACROSS_COURTYARD,
                onPress: () => onChange(EstateType.APARTMENT),
                disabled: false,
                shouldRender: true,
                trailingComponent: null,
                dataTest: 'apartment-btn',
            },
        ],
    };
    return _jsx(CheckButtonOptions, { data: data });
};
