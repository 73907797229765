import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal } from '../../../styles';
export const styles = StyleSheet.create({
    container: {
        marginTop: Space.md,
        backgroundColor: Color.White100,
        ...paddingHorizontal(Space.lg),
        paddingTop: Space['2xl'],
        paddingBottom: Space.lg,
        borderRadius: Border.Radius.sm,
        marginBottom: Space['2xl'],
        porterbuddy: {
            padding: 0,
            backgroundColor: 'inherit',
        },
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: Space.xl,
    },
    title: {
        ...Typography.Budbee.Web.h2,
        marginLeft: Space.sm,
        porterbuddy: {
            marginLeft: 0,
            ...Typography.Porterbuddy.Web.h3,
        },
    },
    loader: {
        top: 120,
        porterbuddy: {
            top: 200,
        },
    },
    badgeOpen: {
        backgroundColor: Color.Budbee.GreenPop,
        borderRadius: Border.Radius.sm,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        ...paddingHorizontal(Space.md),
        instabox: {
            backgroundColor: Color.Instabox.Grass,
        },
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.AppleGreen,
            borderRadius: Border.Radius['3xs'],
            height: Space['3xl'],
        },
    },
    badgeClosed: {
        backgroundColor: Color.Budbee.ReadyRed,
        instabox: {
            backgroundColor: Color.Instabox.ReallyRed,
        },
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.RosyRed,
        },
    },
    badgeText: {
        ...Typography.Budbee.Web.h5,
        color: Color.White100,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h5,
            color: Color.White100,
        },
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginBottom: Space['3xl'],
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-md'],
        },
    },
    linkView: {
        display: 'flex',
        padding: Space.lg,
        backgroundColor: Color.Budbee.Grey1,
        borderRadius: Border.Radius.sm,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: Space.sm,
        textDecoration: 'none',
        cursor: 'pointer',
        porterbuddy: {
            backgroundColor: Color.White100,
            borderRadius: Border.Radius['2xs'],
            marginbottom: Space.sm,
        },
    },
    disabledLinkView: {
        opacity: 0.7,
        cursor: 'auto',
        porterbuddy: {
            opacity: 0.5,
        },
    },
    rightContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    p: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.GreenDark,
        marginRight: Space.sm,
        marginLeft: Space.lg,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.Porterbuddy.BuddyPurple,
        },
    },
    merchantLinkText: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.Grey5,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.Porterbuddy.Grey4,
        },
    },
    link: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.GreenDark,
        textDecoration: 'none',
        marginLeft: Space.lg,
        overflowWrap: 'anywhere',
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.Porterbuddy.BuddyPurple,
        },
        instabox: {
            color: Color.Instabox.GreyGray,
        },
    },
    textareaContainer: {
        marginBottom: Space.xl,
    },
});
