import { createSelector } from '@reduxjs/toolkit';
// Example Locale: en-SE
export const getCurrentLocale = createSelector((state) => state.locale, (locale) => locale.currentLocale);
// Example Locale: en-SE
export const getDefaultLocale = createSelector((state) => state.locale, (locale) => locale.defaultLocale);
// Example Language: sv or en
export const getLanguage = createSelector((state) => state.locale, (locale) => locale.language);
// Example CountryCode: se
export const getCountryCode = createSelector((state) => state.locale, (locale) => locale.country);
// Example: Europe/Stockholm
export const getTimeZone = createSelector((state) => state.locale, (locale) => locale.timeZone);
