import { StyleSheet } from '@css';
import { ButtonEffect } from '../../styles';
export const styles = StyleSheet.create({
    button: {
        border: 'none',
        background: 'unset',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        ...ButtonEffect.Enabled,
    },
    disabled: {
        ...ButtonEffect.Disabled,
    },
    icon: {
        borderRadius: 100,
        pointerEvents: 'none',
    },
    iconDefault: {
        borderWidth: 1,
        borderStyle: 'solid',
    },
});
