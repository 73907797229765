import { RESET_ORDER_STATE } from '../../state/order/actions';
import { createReducer } from '../../utils/create-reducer';
import { NEXT_STEP, PREFACE_FETCHED, PREV_STEP, SET_STEPS, SHOW_STEP, SHOW_STEPS, } from './actions';
const initState = {
    step: 0,
    steps: [],
    currentSteps: [],
    visible: false,
};
const stepByKeyPred = (key) => (step) => step.id === key;
const shouldPromptPrefaceStep = (step) => step.prompt;
const constrainStep = (steps) => (newStep) => {
    if (newStep >= steps.length) {
        return steps.length - 1;
    }
    if (newStep < 0) {
        return 0;
    }
    return newStep;
};
export const preface = createReducer(initState, {
    [RESET_ORDER_STATE]: (state = initState) => ({
        ...state,
        ...initState,
    }),
    [SHOW_STEPS]: (state = initState, action) => ({
        ...state,
        visible: action.payload.show,
    }),
    [PREFACE_FETCHED]: (state = initState, action) => {
        return {
            ...state,
            steps: action.payload.preface,
            currentSteps: action.payload.preface.filter(shouldPromptPrefaceStep),
            step: 0,
        };
    },
    [SET_STEPS]: (state = initState, action) => ({
        ...state,
        currentSteps: action.payload.steps,
        step: action.payload.keepIndex ? state.step : 0,
    }),
    [NEXT_STEP]: (state = initState) => ({
        ...state,
        step: constrainStep(state.currentSteps)(state.step + 1),
    }),
    [PREV_STEP]: (state = initState) => ({
        ...state,
        step: constrainStep(state.currentSteps)(state.step - 1),
    }),
    [SHOW_STEP]: (state = initState, action) => {
        const step = state.steps.find(stepByKeyPred(action.payload.step));
        const currentStep = state.steps.find(stepByKeyPred(state.currentSteps[state.step].id));
        if (!step || !currentStep) {
            return state;
        }
        return {
            ...state,
            currentSteps: [step, currentStep],
            step: 0,
        };
    },
});
