import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const ParcelCounter = (props) => {
    const { maxParcels, returnParcelCount, setReturnParcelCount } = props;
    const { t } = useI18n();
    if (maxParcels && maxParcels < 2) {
        return null;
    }
    return (_jsxs(View, { children: [_jsx("span", { className: css(styles.title), children: t('bookReturn.numberOfParcelsTitle') }), _jsxs(View, { style: styles.container, children: [_jsx("button", { onClick: () => setReturnParcelCount(returnParcelCount - 1), type: "button", className: css(styles.button), disabled: returnParcelCount === 1, children: _jsx(Icon, { src: imageList.Minus, size: IconSize.xl }) }), _jsx("span", { className: css(styles.text), children: returnParcelCount }), _jsx("button", { onClick: () => setReturnParcelCount(returnParcelCount + 1), type: "button", className: css(styles.button), disabled: returnParcelCount === maxParcels, children: _jsx(Icon, { src: imageList.Plus, size: IconSize.xl }) })] }), _jsx("span", { className: css(styles.bottomText), children: t('bookReturn.numberOfParcelsAmount', { n: maxParcels }) })] }));
};
