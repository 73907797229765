import { ParcelStatus } from '@budbee/js/tracking';
export const shouldRenderMap = (isReturn, status, needsVerification, authenticated, driver) => {
    if (!driver) {
        return false;
    }
    if (!authenticated) {
        return false;
    }
    if (needsVerification) {
        return false;
    }
    if (isReturn && status === ParcelStatus.CollectedShippingLabel) {
        return true;
    }
    return (status === ParcelStatus.Collected ||
        status === ParcelStatus.OnRouteDelivery ||
        status === ParcelStatus.CrossDocked);
};
