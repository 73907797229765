import { useEffect } from 'react';
export const useClickDetector = (ref, clickOutsideCallback, clickInsideCallback, excludedElementsIds = [], delay = 200) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                clickOutsideCallback();
            }
        };
        const handleClickInside = (event) => {
            if (ref.current && ref.current.contains(event.target)) {
                clickInsideCallback();
            }
        };
        document.addEventListener('mousedown', (event) => {
            if (!excludedElementsIds.includes(event.target.id)) {
                // Debounce the clicks
                setTimeout(() => {
                    handleClickOutside(event);
                    handleClickInside(event);
                }, delay);
            }
        });
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', (event) => {
                if (!excludedElementsIds.includes(event.target.id)) {
                    handleClickOutside(event);
                    handleClickInside(event);
                }
            });
        };
    }, [ref, delay, clickOutsideCallback, clickInsideCallback, excludedElementsIds]);
};
