import { Color, Space, Typography } from '@budbee/js/dist/ui';
import { StyleSheet } from '@css';
import { Spacing } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        height: '100%',
    },
    title: {
        ...Typography.Budbee.Web.h3,
        marginTop: Space.md,
        marginBottom: Space.lg,
        color: Color.Budbee.Grey5,
        paddingLeft: Space.sm,
    },
    actionListItem: {
        marginBottom: Spacing.sm,
        paddingRight: Spacing.lg,
    },
});
