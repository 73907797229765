import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Nudge } from '@budbee/js/tracking';
import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet, css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useBoxNudges } from '../../state/box-order/hooks';
import { BorderRadius, ButtonEffect, IconSize, border } from '../../styles';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: BorderRadius.md,
        ...border(Border.Width.md, 'solid', Color.Budbee.GreenDark),
        padding: Space.xl,
        backgroundColor: Color.Budbee.Grey1,
        marginTop: Space.md,
        ...ButtonEffect.Enabled,
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
    },
    title: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        flex: 1,
        textAlign: 'left',
        paddingRight: Space.lg,
        marginLeft: Space.lg,
    },
    iconRightContainer: {
        position: 'absolute',
        right: 0,
    },
    iconRight: {
        width: IconSize.xs,
        height: IconSize.xs,
        color: Color.Budbee.GreenDark,
    },
});
export const BoxNudges = (props) => {
    const { onChangeLocker } = props;
    const nudges = useBoxNudges();
    const changeLocker = nudges === null || nudges === void 0 ? void 0 : nudges.includes(Nudge.CHANGE_LOCKER);
    const { t } = useI18n();
    const content = useMemo(() => {
        if (changeLocker) {
            return {
                title: t('nudge.changeLocker'),
                icon: imageList.chevronRight,
                onClick: onChangeLocker,
            };
        }
        return null;
    }, [changeLocker, onChangeLocker, t]);
    if (!content) {
        return null;
    }
    return (_jsx(View, { children: _jsx("button", { type: "button", className: css(styles.root), onClick: content.onClick, children: _jsxs(View, { style: styles.container, children: [_jsx(Icon, { src: imageList.InfoFilledGreen, size: IconSize['3xl'] }), _jsx("span", { className: css(styles.title), children: content.title }), _jsx(View, { style: styles.iconRightContainer, children: _jsx(Icon, { src: content.icon, size: IconSize.xs, color: Color.Budbee.GreenDark }) })] }) }) }));
};
