import { jsx as _jsx } from "react/jsx-runtime";
import { BoxParcelStatus, ConsignmentType, locale } from '@budbee/js/tracking';
import { CancelledBoxContent } from '../cancelled-box-content';
import { DeliveredBoxContent } from '../delivered-box-content';
import { EstimatedBoxContent } from '../estimated-box-content';
import { ParcelInTransitBoxContent } from '../parcel-in-transit-box-content';
import { PickedupBoxContent } from '../pickedup-box-content';
import { RecalledBoxContent } from '../recalled-box-content';
import { RegisteredBoxContent } from '../registered-box-content';
import { ReturnBookedBoxContent } from '../return-booked-box-content';
import { ReturnToLockerBoxContent } from '../return-to-locker-box-content';
import { UndeliveredBoxContent } from '../undelivered-box-content';
export function BoxContent({ boxOrder, authenticated, code, authToken, toggleLocationModal, toggleSwitchDeliveryTypeModal, toggleConfirmChangeLockerLocation, toggleLockerPickerModal, }) {
    const { etaInformation, status, lockerAttributes, recall, orderLocation, canSwitchLocker, canSwitchDeliveryType, consignment, } = boxOrder;
    const language = locale.defaultLanguageForCountry(boxOrder.lockerAttributes.address.countryCode);
    const { recalled } = recall;
    const { parcelsPickedUpFromMerchant } = orderLocation;
    const isReturn = consignment && consignment.type === ConsignmentType.RETURN;
    const props = {
        authenticated,
        toggleLocationModal,
        toggleSwitchDeliveryTypeModal,
        toggleConfirmChangeLockerLocation,
        toggleLockerPickerModal,
        canSwitchLocker,
        canSwitchDeliveryType,
        merchantName: boxOrder.merchant.name,
        merchantLogo: boxOrder.merchant.logo,
        countryCode: lockerAttributes.address.countryCode,
        language,
        authToken,
        token: boxOrder.token,
    };
    if (status === BoxParcelStatus.Delivered) {
        return _jsx(DeliveredBoxContent, { ...props, code: code });
    }
    // Only used for red originating orders
    if (status === BoxParcelStatus.Undelivered) {
        return _jsx(UndeliveredBoxContent, { ...props });
    }
    if (consignment === null || consignment === void 0 ? void 0 : consignment.cancellation) {
        return _jsx(CancelledBoxContent, { ...props });
    }
    if ((status === BoxParcelStatus.NotStarted || status === BoxParcelStatus.CollectedShippingLabel) &&
        consignment &&
        isReturn) {
        return _jsx(ReturnToLockerBoxContent, { ...props, status: status, code: code });
    }
    if (isReturn) {
        return _jsx(ReturnBookedBoxContent, { ...props, status: status });
    }
    if (recalled) {
        return _jsx(RecalledBoxContent, { ...props });
    }
    if (status === BoxParcelStatus.PickedUp) {
        return _jsx(PickedupBoxContent, { ...props });
    }
    if (etaInformation === null || etaInformation === void 0 ? void 0 : etaInformation.eta) {
        return _jsx(EstimatedBoxContent, { ...props });
    }
    if (parcelsPickedUpFromMerchant) {
        return _jsx(ParcelInTransitBoxContent, { ...props });
    }
    return _jsx(RegisteredBoxContent, { ...props });
}
