/**
 * Get the release environment based on the hostname
 * Since we will have one build release for staging and one for production
 * We cannot rely on the NODE_ENV to determine the environment
 *
 * @returns 'staging' | 'production'
 */
export function getReleaseEnv() {
    const stagingEnvs = ['staging', 'testing', 'localhost'];
    if (stagingEnvs.some((env) => window.location.hostname.includes(env))) {
        return 'staging';
    }
    return 'production';
}
