import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    container: {
        gap: Space['7xl'],
    },
    informationText: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.Grey4,
        paddingHorizontal: Space.sm,
        porterbuddy: {
            paddingLeft: Space.sm,
            ...Typography.Porterbuddy.Web['body-md'],
            marginBottom: Space.md,
        },
    },
});
