import { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
export const useMapBox = (properties) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef();
    useEffect(() => {
        if (mapRef.current || !mapContainerRef.current) {
            return;
        }
        mapRef.current = new mapboxgl.Map({
            ...properties,
            container: mapContainerRef.current,
            style: 'mapbox://styles/budbee/ckq7oydo81u0t18qrggn6lawi',
        });
    }, [properties]);
    return [mapRef.current, mapContainerRef];
};
