import { composeHeaders, NO_CACHE, JSON_API, AUTH, FLAVOR, UTM_MEDIUM } from './headers';
import { convertStringToNull } from './null-helpers';
const baseUrl = (() => {
    return `/api/v3/`;
})();
const parse = (response) => {
    if (response.ok) {
        return response.json();
    }
    const error = new Error(response.statusText);
    // @ts-expect-error ts-migrate error
    error.response = response;
    throw error;
};
const request = (method, headers, url, body) => {
    const options = {
        method,
        mode: 'cors',
        cache: 'no-cache',
        // credentials: 'include',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body,
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ method: any; mode: string; cac... Remove this comment to see the full error message
    return fetch(baseUrl + url.join('/'), options).then(parse);
};
/**
 * @deprecated Use useTrackingApi hook instead
 */
const TrackingV3 = (method, headers = {}, url = [], body) => ({
    path(part) {
        return TrackingV3(method, headers, [...url, part], body);
    },
    header(key, value) {
        return TrackingV3(method, { ...headers, [key]: value }, url, body);
    },
    composeHeaders(...fns) {
        return TrackingV3(method, { ...headers, ...composeHeaders(...fns) }, url, body);
    },
    get() {
        return request('get', headers, url);
    },
    post(payload) {
        return request('post', headers, url, JSON.stringify(payload));
    },
    put(payload) {
        return request('put', headers, url, payload ? JSON.stringify(payload) : body);
    },
    delete() {
        return request('delete', headers, url, body);
    },
    identificationStatus(orderToken) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('identification')
            .path('orders')
            .path(orderToken)
            .path('status')
            .get();
    },
    createPayment(
    // @ts-expect-error ts-migrate(6133) FIXME
    token, checkoutId, 
    // @ts-expect-error ts-migrate(6133) FIXME
    price, paymentDetails, redirectUrl) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('payments')
            .path('checkout')
            .post({ checkoutId, paymentDetails, channel: 'WEB', redirectUrl });
    },
    updatePayment(checkoutId, paymentsResponse) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('payments')
            .path('checkout')
            .path(checkoutId)
            .put(paymentsResponse);
    },
    paymentStatus(checkoutId) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('payments')
            .path('checkout')
            .path(checkoutId)
            .path('status')
            .get();
    },
    getTimetable(token) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('timetable')
            .get();
    },
    setAccessMode(token, accessMode, value) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('access-mode')
            .put({ type: accessMode, value });
    },
    setFloor(token, floor) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('floor')
            .put({ value: floor });
    },
    setEstateType(token, estateType) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('estate-type')
            .put({ value: convertStringToNull(estateType) });
    },
    setCareOf(token, careOf) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('care-of')
            .put({ value: careOf });
    },
    setSpecialInstructions(token, specialInstructions) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('special-instructions')
            .put({ value: specialInstructions });
    },
});
export default TrackingV3;
