import { connect } from 'react-redux';
import { LockerSearchGroup } from '../../components/lockers-search-group';
import { setIsSearchingForPlaces, setPlaceSearchResults, setSearchPlaceKeyword, setLockersForSearchedPlace, setSelectedSearchResult, resetLockerSearchState, } from '../../state/lockers/actions';
import { getPlaceSearchKeyword, getPlaceSearchResults, getIsSearchingForPlace, } from '../../state/lockers/selectors';
import { getConsumerAddress, getToken } from '../../state/order/selectors';
const mapStateToProps = (state) => ({
    keyword: getPlaceSearchKeyword(state),
    placeSearchResults: getPlaceSearchResults(state),
    consumerAddress: getConsumerAddress(state),
    orderToken: getToken(state),
    isSearching: getIsSearchingForPlace(state),
});
const mapDispatchToProps = (dispatch) => ({
    setIsSearchingForPlaces: (isSearching) => dispatch(setIsSearchingForPlaces(isSearching)),
    setPlaceSearchResults: (placeSearchResults) => dispatch(setPlaceSearchResults(placeSearchResults)),
    setSearchPlaceKeyword: (keyword) => dispatch(setSearchPlaceKeyword(keyword)),
    setLockersForSearchedPlace: (lockerSearchResults) => dispatch(setLockersForSearchedPlace(lockerSearchResults)),
    setSelectedSearchResult: (lockerSearchResults) => dispatch(setSelectedSearchResult(lockerSearchResults)),
    resetLockerSearchState: () => dispatch(resetLockerSearchState()),
});
export const LockersSearchGroupContainer = connect(mapStateToProps, mapDispatchToProps)(LockerSearchGroup);
