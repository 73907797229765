import { TrackingApi as BudbeeApi } from '@budbee/js/tracking-api';
export function TrackingApi(headers) {
    return BudbeeApi('/api', {
        'Budbee-client': 'web',
        ...headers,
    }, {
        timeout: {
            response: 35000,
            deadline: 45000,
        },
    });
}
