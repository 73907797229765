import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { MediaQuery, Spacing } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        width: '100%',
        marginLeft: Spacing['2xl'],
        marginRight: Spacing['2xl'],
        [MediaQuery.maxWidthMd]: {
            margin: 0,
        },
        porterbuddy: {
            position: 'absolute',
            top: -Space['3xl'],
        },
    },
    logo: {
        height: 22,
        margin: 'auto 0',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        porterbuddy: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    title: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey6,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.Porterbuddy.BuddyPurple,
            marginRight: Space.lg,
        },
    },
    subtitle: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.Grey5,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h5,
            color: Color.Porterbuddy.BuddyPurple,
        },
    },
    headerTitle: {
        textAlign: 'center',
        ...Typography.Porterbuddy.Web.h1,
        color: Color.White100,
    },
});
