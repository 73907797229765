import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginVertical } from '../../../styles';
export const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        textAlign: 'center',
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space['5xl'],
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...marginVertical(Space['2xl']),
    },
    divider: {
        height: Space.lg,
    },
    privacyNotice: {
        marginTop: 0,
        ...Typography.Budbee.Web['body-md'],
    },
    link: {
        textTransform: 'lowercase',
        textDecoration: 'none',
        color: Color.Budbee.GreenDark,
    },
    errorWrapper: {
        marginTop: Space['2xl'],
    },
});
