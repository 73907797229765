import { createReducer } from '../../utils/create-reducer';
import { CLOSE_MODAL, OPEN_MODAL } from './actions';
const initState = {
    modals: [],
};
export const modal = createReducer(initState, {
    [OPEN_MODAL]: (state = initState, action) => ({
        ...state,
        modals: [action.payload.id].concat([...state.modals]),
    }),
    [CLOSE_MODAL]: (state = initState, action) => ({
        ...state,
        modals: state.modals.filter((id) => id !== action.payload.id),
    }),
});
