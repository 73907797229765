import { connect } from 'react-redux';
import { BoxReturnParcelDimensions } from '../../../components/steps/box-return-parcel-dimensions';
import { getToken } from '../../../state/order/selectors';
import { setParcelDimensions } from '../../../state/returns/actions';
import { getParcelDimensions } from '../../../state/returns/selectors';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
    dimensions: getParcelDimensions(state),
});
const mapDispatchToProps = (dispatch) => ({
    updateParcelDimensions: (dimensions) => dispatch(setParcelDimensions(dimensions)),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps));
export const BoxReturnParcelDimensionsContainer = decorate(BoxReturnParcelDimensions);
