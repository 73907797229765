import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useI18n } from '../../../hooks/use-i18n';
import { Spacing } from '../../../styles';
import { Loader } from '../loader';
import { LockerCalloutItem } from './locker-callout-item';
const isSuccessfulLockerDetailsRequest = (response) => response.status === ResponseStatus.COMPLETED;
export const LockerCallout = (props) => {
    const { selectedLocker, lockers, fetchLockerDetails, onSelectLocker } = props;
    const { t } = useI18n();
    const [detailedLockers, setDetailedLockers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (!lockers) {
            return;
        }
        const requests = lockers.map((locker) => fetchLockerDetails(locker.id));
        Promise.all(requests)
            .then((results) => {
            if (!results) {
                setError(true);
            }
            else {
                setDetailedLockers(results.filter(isSuccessfulLockerDetailsRequest).map((response) => response.payload));
            }
            setLoading(false);
        })
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }, [lockers, fetchLockerDetails]);
    const renderCalloutItem = () => {
        if (loading || error) {
            return (_jsx("div", { style: { margin: Spacing.sm }, children: loading ? _jsx(Loader, { variant: "inline" }) : t('generalErrorMessage') }));
        }
        return (_jsx(_Fragment, { children: detailedLockers.map((locker, index) => {
                if (!locker) {
                    return null;
                }
                return (_jsx(LockerCalloutItem, { selectedLocker: selectedLocker, onSelectLocker: onSelectLocker, locker: locker, lastItem: index === detailedLockers.length - 1 }, locker === null || locker === void 0 ? void 0 : locker.lockerIdentifier));
            }) }));
    };
    return renderCalloutItem();
};
