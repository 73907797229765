import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { lockers } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useBoxOrder, useIsBoxReturn } from '../../state/box-order/hooks';
import { useCountryCode } from '../../state/locale/hooks';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { JointLockerModal } from '../modal/joint-locker';
import { Icon } from '../shared/icon';
import { styles } from './style';
const BudbeeBrand = () => _jsx("div", { className: css([styles.circle, styles.budbee]) });
const InstaboxBrand = () => _jsx("div", { className: css([styles.circle, styles.instabox]) });
export const JointLockerBadge = () => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useI18n();
    const boxOrder = useBoxOrder();
    const isReturn = useIsBoxReturn();
    const countryCode = useCountryCode();
    const color = ((_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.lockerAttributes.color) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || null;
    const unbrandedLockers = lockers.isUnbrandedLockers(countryCode);
    const text = useMemo(() => {
        if (!color)
            return null;
        if (isReturn) {
            if (unbrandedLockers) {
                return color === 'red'
                    ? t('jointLocker.depositAtRedBox')
                    : t('jointLocker.depositAtGreenBox');
            }
            return t('jointLocker.depositAtInstabox');
        }
        if (unbrandedLockers) {
            return color === 'red' ? t('jointLocker.redLocker') : t('jointLocker.greenLocker');
        }
        return t('jointLocker.deliveryToInstabox');
    }, [isReturn, unbrandedLockers, color]);
    const icon = useMemo(() => {
        if (unbrandedLockers) {
            return color === 'red' ? _jsx(InstaboxBrand, {}) : _jsx(BudbeeBrand, {});
        }
        return _jsx(Icon, { src: imageList.InstaboxIcon, size: IconSize.md });
    }, [unbrandedLockers, color]);
    if (!color || (color !== 'red' && !lockers.isUnbrandedLockers(countryCode))) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs("button", { type: "button", className: css(styles.root), onClick: () => setIsOpen(true), children: [icon, _jsx("span", { className: css(styles.text), children: text }), _jsx(Icon, { src: imageList.InfoCircle, size: IconSize.md })] }), _jsx(JointLockerModal, { isOpen: isOpen, onClose: () => setIsOpen(false) })] }));
};
