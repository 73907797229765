import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal } from '../../styles';
import imageList from '../../utils/image-list';
export const styles = StyleSheet.create({
    text: {
        color: Color.Black100,
        textAlign: 'center',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h1,
            color: Color.White100,
        },
    },
    black: {
        color: Color.Black100,
    },
    header: {
        porterbuddy: {
            position: 'relative',
            zIndex: 1,
            backgroundImage: `url(${imageList.PorterbuddyHero})`,
            height: 120,
            width: 'auto',
            borderRadius: Border.Radius['2xs'],
            objectFit: 'contain',
            backgroundSize: '150%',
            marginTop: Space.lg,
            ...paddingHorizontal(Space.md),
            marginBottom: Space.lg,
            justifyContent: 'center',
        },
    },
});
