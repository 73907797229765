import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { hooks } from '@budbee/js/tracking';
import { Color, Typography } from '@budbee/js/ui';
import { StyleSheet, css } from '@css';
import Markdown from 'react-markdown';
import { useI18n } from '../../hooks/use-i18n';
import { useToggle } from '../../hooks/use-toggle';
import { useCountryCode, useLanguage, useTimeZone } from '../../state/locale/hooks';
import { FontWeight, IconSize, Color as OldColor } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { capitalize } from '../../utils/strings';
import { ParcelJourney } from '../parcel-journey';
import { ParcelJourneyStep } from '../parcel-journey-step';
import { Thumbnail } from '../thumbnail';
const markdown = {
    ':first-of-type > p': {
        ...Typography.Budbee.Web['body-lg'],
        color: OldColor.TextDark,
        margin: 0,
        textAlign: 'left',
    },
    ':first-of-type > p > strong': {
        color: Color.Budbee.Grey5,
        fontWeight: 600,
    },
};
export const markdownStyles = StyleSheet.create({
    primary: {
        ...markdown,
    },
    secondary: {
        ...markdown,
        ':first-of-type > p > strong': {
            fontWeight: FontWeight.SemiBold,
        },
    },
});
export function HomeParcelJourney({ events }) {
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const [isOpen, { toggle }] = useToggle();
    const { i18n } = useI18n();
    const brand = getBrand();
    const countryCode = useCountryCode();
    const language = useLanguage();
    const timeZone = useTimeZone();
    const renderEvent = useCallback((event, index) => {
        const firstActiveEvent = events[0] === event;
        const isLastEvent = event === events[events.length - 1];
        const hideLine = (firstActiveEvent && !isOpen) || isLastEvent;
        const middleText = timeZone && event.date
            ? capitalize(i18n.formatDateAndTime(event.date, countryCode, language, timeZone))
            : null;
        const image = event.icon;
        return (_jsx(ParcelJourneyStep, { index: index, topText: event.sender, middleText: middleText, bottomText: () => (_jsx(Markdown, { className: css(brand === 'budbee' && markdownStyles.primary, brand === 'instabox' && markdownStyles.secondary, brand === 'porterbuddy' && markdownStyles.secondary), components: {
                    img(imgProps) {
                        if (!imgProps.src || !imgProps.alt) {
                            return null;
                        }
                        return _jsx(Thumbnail, { src: imgProps.src, alt: imgProps.alt });
                    },
                }, children: event.message })), iconComponent: _jsx(BrandedIcon, { src: {
                    budbee: imageList.HexagonCheckmark,
                    instabox: imageList.InstaboxCheckmark,
                    porterbuddy: imageList.CheckmarkPurple,
                }, size: firstActiveEvent ? IconSize['3xl'] : IconSize.md }), hideLine: hideLine, image: image, variant: "active" }, getKey({ event })));
    }, [events, isOpen, timeZone, i18n, countryCode, language, getKey, brand]);
    return (_jsx(ParcelJourney, { events: events, renderEvent: renderEvent, toggled: isOpen, onToggle: toggle }));
}
