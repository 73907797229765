import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ConsignmentType, ParcelStatus } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useConspectus } from '../../state/order/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import { isConsignmentCancelled } from '../../utils/cancellation';
import { CompanyInfo } from '../company-info';
import { DeliverySettingsPresenter } from '../delivery-settings-presenter';
import { HomeOrderLocation } from '../home-order-location';
import { HomeParcelJourney } from '../home-parcel-journey';
import { InlineDeliveryMap } from '../inline-delivery-map';
import { PinCode } from '../pin-code';
import { PrivacyNotice } from '../privacy-notice';
import { Rating } from '../rating';
import { View } from '../shared/view';
import { Title } from '../title';
import { styles } from './style';
export function TrackingContent({ token, authenticated, authToken, toggleSettingsModal, }) {
    var _a;
    const { t } = useI18n();
    const brand = getBrand();
    const conspectus = useConspectus();
    const { events, consignment } = conspectus || {};
    const cancelled = isConsignmentCancelled(consignment || null);
    const isReturn = (consignment === null || consignment === void 0 ? void 0 : consignment.type) === ConsignmentType.RETURN;
    const status = conspectus === null || conspectus === void 0 ? void 0 : conspectus.status.state;
    const code = ((_a = conspectus === null || conspectus === void 0 ? void 0 : conspectus.identification) === null || _a === void 0 ? void 0 : _a.code) || (conspectus === null || conspectus === void 0 ? void 0 : conspectus.deliveryPinCode);
    const showCode = authenticated &&
        code &&
        !cancelled &&
        status !== ParcelStatus.Delivered &&
        status !== ParcelStatus.Miss;
    return (_jsxs(_Fragment, { children: [brand === 'porterbuddy' && (_jsxs(_Fragment, { children: [_jsx(View, { style: styles.divider }), _jsx(InlineDeliveryMap, {}), showCode ? (_jsxs(View, { style: styles.codeContainer, children: [_jsx(Title, { style: styles.pinTitle, title: t('settings.codeRequired') }), _jsx(PinCode, { code: code, variant: "HOME" })] })) : null, !cancelled && status !== ParcelStatus.Delivered ? (_jsx(DeliverySettingsPresenter, { toggleSettingsModal: () => toggleSettingsModal() })) : null] })), _jsxs("div", { children: [authenticated &&
                        (status === ParcelStatus.Delivered ||
                            (isReturn &&
                                (status === ParcelStatus.Collected ||
                                    status === ParcelStatus.ReturnedToTerminal ||
                                    status === ParcelStatus.ReturnedToMerchant))) ? (_jsx(Rating, { token: token, authToken: authToken })) : null, showCode && brand !== 'porterbuddy' ? (_jsxs(View, { style: styles.codeContainer, children: [_jsx(Title, { style: styles.pinTitle, title: t('settings.codeRequired') }), _jsx(PinCode, { code: code, variant: "HOME" })] })) : null, events ? (_jsx(View, { style: styles.homeParcelJourney, children: _jsx(HomeParcelJourney, { events: events }) })) : null, conspectus ? (_jsx(View, { style: styles.location, children: _jsx(HomeOrderLocation, { authenticated: authenticated, address: conspectus.address.street, logo: conspectus.merchant.logo, orderStatus: conspectus.status, merchantName: conspectus.merchant.name }) })) : null] }), _jsxs("div", { className: css(styles.infoContainer), children: [_jsx(CompanyInfo, {}), _jsx(PrivacyNotice, {})] })] }));
}
