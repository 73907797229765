import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState, } from 'react';
import { UploadType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import superagent from 'superagent';
import { useI18n } from '../../hooks/use-i18n';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { BaseButton } from '../base-button';
import { Loader } from '../shared/loader';
import { TrackingButton } from '../tracking-button';
import { styles } from './style';
export const ReturnPhotoUploader = (props) => {
    const { token, authToken, onContinue, onPhotoChanged, onUploadRequested } = props;
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState('');
    const [error, setError] = useState(false);
    const fileInputRef = useRef(null);
    const getPhoto = useCallback(() => {
        setLoading(true);
        if (!token || !authToken) {
            setLoading(false);
            return;
        }
        trackingApi
            .getReturnPhoto(token, authToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED && response.payload.url) {
                setPhoto(response.payload.url);
            }
            setLoading(false);
        })
            .catch(() => {
            setError(true);
        });
    }, [setLoading, token, authToken, trackingApi, setPhoto, setError]);
    const uploadPhoto = useCallback((url, type, file) => {
        return superagent
            .put(url)
            .set('Content-Type', type)
            .send(file)
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }, [setLoading, setError]);
    const handleUploadPhoto = useCallback((next, onError, setHasReturnPhotoUploaded) => {
        var _a, _b;
        setLoading(true);
        const file = (_b = (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.item(0);
        if (!token || !authToken || !file) {
            setLoading(false);
            onError();
            return Promise.reject(new Error('Key variables are missing.'));
        }
        return trackingApi
            .getSignedUploadUrl(token, authToken, UploadType.RETURN_PHOTO, file.type)
            .call()
            .then((apiResponse) => {
            if (apiResponse.status === ResponseStatus.COMPLETED) {
                return uploadPhoto(apiResponse.payload.url, file.type, file).then(() => apiResponse.payload.uploadId);
            }
            throw new Error('FAILED_UPLOAD');
        })
            .then((uploadId) => trackingApi.confirmUpload(token, authToken, uploadId).call())
            .then((apiResponse) => {
            if (apiResponse.status === ResponseStatus.COMPLETED) {
                setHasReturnPhotoUploaded(true);
                next();
            }
            else {
                onError();
            }
        })
            .catch(() => {
            setLoading(false);
            setError(true);
        });
    }, [setLoading, token, authToken, uploadPhoto, trackingApi, setError]);
    const onFileChange = useCallback((e) => {
        var _a;
        const targetPhoto = (_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (!targetPhoto)
            return;
        setPhoto(URL.createObjectURL(targetPhoto));
        onPhotoChanged();
    }, [setPhoto, onPhotoChanged]);
    const retry = useCallback(() => {
        setLoading(false);
        setError(false);
    }, [setLoading, setError]);
    useEffect(() => {
        onUploadRequested === null || onUploadRequested === void 0 ? void 0 : onUploadRequested((next, onError, setHasReturnPhotoUploaded) => {
            handleUploadPhoto(next, onError, setHasReturnPhotoUploaded);
        });
    }, [onUploadRequested, handleUploadPhoto]);
    useEffect(() => {
        getPhoto();
    }, [getPhoto]);
    if (error) {
        return (_jsxs("div", { className: css(styles.errorContainer), children: [_jsx("div", { className: css(styles.error), children: t('returnUploadPhoto.error') }), _jsx(BaseButton, { label: t('returnUploadPhoto.retry'), onClick: () => retry(), iconSrc: imageList.Refresh })] }));
    }
    return (_jsxs("div", { className: css(styles.container), children: [loading || photo ? (_jsx("div", { className: css(styles.greyWrapper), children: loading ? (_jsx(Loader, { style: styles.loader })) : (_jsx("img", { className: css(styles.image), src: photo, alt: "" })) })) : null, !loading ? (_jsxs("div", { children: [_jsx("input", { className: css(inlineStyles({ display: 'none' })), type: "file", accept: "image/png,image/jpg,image/jpeg", onChange: (e) => onFileChange(e), ref: fileInputRef }), _jsxs("div", { className: css(styles.buttonContainer), children: [_jsx(BaseButton, { label: t(photo
                                    ? 'returnUploadPhoto.changePhotoButton'
                                    : 'returnUploadPhoto.uploadPhotoButton'), iconSrc: imageList.Camera, onClick: () => {
                                    var _a;
                                    (_a = fileInputRef === null || fileInputRef === void 0 ? void 0 : fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                }, variant: "primary" }), onContinue && (_jsx(TrackingButton, { title: t(photo ? 'continue' : 'returnUploadPhoto.skipButton'), onClick: onContinue, discrete: true }))] })] })) : null] }));
};
