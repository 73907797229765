import { connect } from 'react-redux';
import { LockerPicker } from '../../components/shared/locker-picker';
import { selectLocker, selectLockerFromMap, setIsPickingFromMap, resetLockerSearchState, } from '../../state/lockers/actions';
import { getIsFetchingLockers, getLockerSearchResults, getSelectedSearchResult, } from '../../state/lockers/selectors';
import { getToken, getSelectedLocker } from '../../state/order/selectors';
const mapStateToProps = (state) => ({
    token: getToken(state),
    isPickingFromMap: state.lockers.isPickingFromMap,
    isFetching: getIsFetchingLockers(state),
    selectedLocker: getSelectedLocker(state),
    selectedSearchResult: getSelectedSearchResult(state),
    lockerSearchResults: getLockerSearchResults(state),
});
const mapDispatchToProps = (dispatch) => ({
    selectLocker: (id) => dispatch(selectLocker(id)),
    selectLockerFromMap: (lockerDetails) => dispatch(selectLockerFromMap(lockerDetails)),
    setIsPickingFromMap: (isPickingFromMap) => dispatch(setIsPickingFromMap(isPickingFromMap)),
    resetLockerSearchState: () => dispatch(resetLockerSearchState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LockerPicker);
