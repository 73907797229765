import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useCallback, useRef } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { motion } from 'framer-motion';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
const transition = {
    type: 'spring',
    damping: 25,
    stiffness: 300,
    duration: 0.4,
};
export const BoxHighDemand = (props) => {
    const { title, children } = props;
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);
    const onExpand = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);
    return (_jsxs("button", { type: "button", className: css(styles.root), onClick: onExpand, children: [_jsx("div", { className: css(styles.wrapper), children: _jsxs("div", { className: css(styles.titleContainer), children: [_jsxs(_Fragment, { children: [_jsx(View, { style: styles.leftIconContainer, children: _jsx(Icon, { src: imageList.InfoFilledRed, size: IconSize['3xl'] }) }), _jsx("p", { className: css([styles.title]), children: title })] }), _jsx("div", { className: css(styles.rightIcon), children: open ? (_jsx(Icon, { src: imageList.Minus, color: Color.Budbee.ReadyRed, size: IconSize.xs })) : (_jsx(Icon, { src: imageList.Plus, color: Color.Budbee.ReadyRed, size: IconSize.xs })) })] }) }), _jsx(motion.div, { className: css(styles.contentWrapper), initial: open ? 'visible' : 'hidden', transition: transition, animate: open ? 'visible' : 'hidden', variants: {
                    hidden: {
                        height: 0,
                    },
                    visible: {
                        height: contentRef.current ? contentRef.current.clientHeight : 0,
                    },
                }, children: _jsx("div", { ref: contentRef, className: css(styles.content), children: typeof children === 'string' ? (_jsx("p", { className: css(styles.contentText), children: children })) : (children) }) })] }));
};
