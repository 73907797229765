import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { css } from '@css';
import { motion } from 'framer-motion';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { styles } from './style';
const transition = {
    type: 'spring',
    damping: 25,
    stiffness: 300,
    duration: 0.1,
};
const variants = {
    accordion: {
        visible: { rotate: -0 },
        hidden: { rotate: 90 },
    },
    divider: {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    },
};
export const ExpandableItem = ({ item }) => {
    const { title, titleContent, content } = item;
    const [open, setOpen] = useState(false);
    const contentRef = useRef(null);
    const mapHeight = content.props.showMap ? 180 : 0;
    const brand = getBrand();
    const expandableContainerHeight = contentRef.current
        ? contentRef.current.clientHeight + mapHeight
        : 0;
    const onExpand = useCallback(() => {
        setOpen(!open);
        if (content.props.setShowMap) {
            content.props.setShowMap(!open);
        }
    }, [open, content]);
    const getRightIcon = () => {
        if (brand === 'porterbuddy') {
            return open ? imageList.PorterbuddyRedMinus : imageList.PorterbuddyPurplePlus;
        }
        return open ? imageList.RedMinus : imageList.GreenPlus;
    };
    return (_jsxs("div", { className: css(styles.container), children: [_jsx("button", { type: "button", className: css(styles.itemWrapper), onClick: onExpand, children: _jsxs("div", { className: css(styles.itemTitle), children: [titleContent || (_jsx(_Fragment, { children: _jsx("p", { className: css(styles.itemTitleText), children: title }) })), _jsx(motion.img, { alt: "", src: getRightIcon(), className: css(styles.itemIconRight), initial: open ? 'visible' : 'hidden', transition: transition, animate: open ? 'visible' : 'hidden', variants: variants.accordion })] }) }), _jsx(motion.div, { className: css(styles.itemDivider), initial: open ? 'visible' : 'hidden', transition: transition, animate: open ? 'visible' : 'hidden', variants: variants.divider }), _jsx(motion.div, { className: css(styles.itemContentWrapper), initial: open ? 'visible' : 'hidden', transition: transition, animate: open ? 'visible' : 'hidden', variants: {
                    hidden: {
                        height: 0,
                    },
                    visible: {
                        height: expandableContainerHeight,
                    },
                }, children: _jsx("div", { ref: contentRef, children: content }) })] }));
};
