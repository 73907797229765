import { connect } from 'react-redux';
import { LockerList } from '../../components/shared/locker-picker/lockers-list';
import { requestLockers, requestLockersSuccess, requestLockersError, } from '../../state/lockers/actions';
import { getLockersForList, getPlaceSearchKeyword, getIsSearchingForPlace, getIsFetchingLockers, } from '../../state/lockers/selectors';
import { setSwitchDeliveryTypePrice } from '../../state/order/actions';
import { getSelectedLocker } from '../../state/order/selectors';
import { resetPaymentState, setProduct } from '../../state/payment/actions';
const mapStateToProps = (state) => ({
    lockers: getLockersForList(state),
    isFetching: getIsFetchingLockers(state),
    selectedLocker: getSelectedLocker(state),
    placeSearchKeyword: getPlaceSearchKeyword(state),
    isSearchingForPlace: getIsSearchingForPlace(state),
});
const mapDispatchToProps = (dispatch) => ({
    requestLockers: () => dispatch(requestLockers()),
    requestLockersSuccess: (lockers) => dispatch(requestLockersSuccess(lockers)),
    requestLockersError: () => dispatch(requestLockersError()),
    setSwitchDeliveryTypePrice: (payload) => dispatch(setSwitchDeliveryTypePrice(payload)),
    setProduct: (payload) => dispatch(setProduct(payload)),
    resetPaymentState: () => dispatch(resetPaymentState()),
});
export const LockerListContainer = connect(mapStateToProps, mapDispatchToProps)(LockerList);
