import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { hooks } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useBoxCheckpoints } from '../../state/box-order/hooks';
import { useCountryCode, useLanguage, useTimeZone } from '../../state/locale/hooks';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { capitalize } from '../../utils/strings';
import { ParcelJourney } from '../parcel-journey';
import { ParcelJourneyStep } from '../parcel-journey-step';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
export const BoxParcelJourney = (props) => {
    const { style } = props;
    const { getKey } = hooks.useUniqueKey(new WeakMap());
    const { i18n } = useI18n();
    const [open, setOpen] = useState(false);
    const countryCode = useCountryCode();
    const language = useLanguage();
    const timeZone = useTimeZone();
    const boxCheckpoints = useBoxCheckpoints();
    const renderEvent = useCallback((checkpoint, index) => {
        if (!boxCheckpoints) {
            return null;
        }
        const firstEvent = boxCheckpoints[0] === checkpoint;
        const failed = checkpoint.state === 'FAILED';
        const isLastEvent = checkpoint === boxCheckpoints[boxCheckpoints.length - 1];
        const hideLine = (firstEvent && !open) || isLastEvent;
        const topText = checkpoint.city ? `${checkpoint.city}, ${checkpoint.countryCode}` : null;
        const middleText = timeZone && checkpoint.timestamp
            ? capitalize(i18n.formatDateAndTime(checkpoint.timestamp, countryCode, language, timeZone))
            : null;
        const bottomText = checkpoint.message;
        return (_jsx(ParcelJourneyStep, { index: index, topText: topText, middleText: middleText, bottomText: bottomText, iconComponent: _jsx(Icon, { src: failed ? imageList.HexagonAlert : imageList.HexagonCheckmark, size: firstEvent ? IconSize['3xl'] : IconSize.md }), hideLine: hideLine, variant: failed ? 'failed' : 'active' }, getKey({ event: checkpoint })));
    }, [boxCheckpoints, open, timeZone, i18n, countryCode, language, getKey]);
    if (!boxCheckpoints || boxCheckpoints.length === 0) {
        return null;
    }
    return (_jsx(View, { style: style, children: _jsx(ParcelJourney, { events: boxCheckpoints, renderEvent: renderEvent, toggled: open, onToggle: () => setOpen(!open) }) }));
};
