import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    title: {
        ...Typography.Budbee.Web.h4,
    },
    container: {
        marginTop: Space.lg,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.sm,
        ...paddingVertical(Space.lg),
        ...paddingHorizontal(Space.md),
    },
    text: {
        ...Typography.Budbee.Web.h3,
    },
    button: {
        ...ButtonEffect.Enabled,
        backgroundColor: 'transparent',
        border: 'none',
    },
    bottomText: {
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey2,
        marginTop: Space.lg,
    },
});
