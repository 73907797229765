import { connect } from 'react-redux';
import { SwitchDeliveryType } from '../../../components/steps/switch-delivery-type';
import { getUserSelectedLocker } from '../../../state/lockers/selectors';
import { setSwitchDeliveryTypePrice } from '../../../state/order/actions';
import { getSwitchDeliveryTypePrice, getToken, getOrderType } from '../../../state/order/selectors';
import { clearPaymentRequest, registerPreliminaryPayment, registerPaymentRequestV3, clearPaymentRequestV3, } from '../../../state/payment/actions';
const mapStateToProps = (state) => ({
    token: getToken(state),
    orderType: getOrderType(state),
    switchDeliveryTypePrice: getSwitchDeliveryTypePrice(state),
    selectedLocker: getUserSelectedLocker(state),
});
const mapDispatchToProps = (dispatch) => ({
    setSwitchDeliveryTypePrice: (data) => dispatch(setSwitchDeliveryTypePrice(data)),
    registerPreliminaryPayment: () => dispatch(registerPreliminaryPayment()),
    clearPaymentRequest: () => dispatch(clearPaymentRequest()),
    registerPaymentRequestV3: (response) => dispatch(registerPaymentRequestV3(response)),
    clearPaymentRequestV3: () => dispatch(clearPaymentRequestV3()),
});
export const SwitchDeliveryTypeOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(SwitchDeliveryType);
