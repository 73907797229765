import { jsx as _jsx } from "react/jsx-runtime";
import { Nudge } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useConspectusNudges } from '../../state/order/hooks';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
export const SwitchDeliveryTypeButton = ({ isHomeDelivery = false, toggleSwitchDeliveryTypeModal, }) => {
    const { t } = useI18n();
    const nudges = useConspectusNudges();
    const fasterLockerDelivery = !!(nudges === null || nudges === void 0 ? void 0 : nudges.includes(Nudge.FASTER_LOCKER_DELIVERY));
    return (_jsx(BaseButton, { iconSrc: isHomeDelivery ? imageList.Locker : imageList.House, label: t(getTitle({ isHomeDelivery, fasterLockerDelivery })), onClick: toggleSwitchDeliveryTypeModal, variant: "primary" }));
};
function getTitle({ isHomeDelivery, fasterLockerDelivery, }) {
    if (isHomeDelivery && fasterLockerDelivery) {
        return 'changeToBoxDelivery.faster';
    }
    if (isHomeDelivery) {
        return 'changeToBoxDelivery';
    }
    return 'changeToHomeDelivery';
}
