import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { css } from '@css';
import { LockerListContainer } from '../../../container/locker-picker/lockers-list';
import { LockersSearchGroupContainer } from '../../../container/lockers-search-group-container';
import { useI18n } from '../../../hooks/use-i18n';
import { useConsumerAddressBox } from '../../../state/box-order/hooks';
import { useConsumerAddress } from '../../../state/order/hooks';
import { Alert } from '../../alert';
import { HorizontalSwitch } from '../horizontal-switch';
import { View } from '../view';
import { LockersMap } from './lockers-map';
import { styles } from './style';
export const LockerPicker = ({ token, authToken, selectLocker, selectLockerFromMap, onLockerPick, selectedLocker, isPickingFromMap = false, isFetching, lockerSearchResults = [], selectedSearchResult = [], setIsPickingFromMap, resetLockerSearchState, }) => {
    const consumerAddress = useConsumerAddress();
    const consumerAddressBox = useConsumerAddressBox();
    const [showLockerPickers, setShowLockerPickers] = useState(false);
    const [mapMounted, setMapMounted] = useState(false);
    const [mapboxGeolocation, setMapboxGeolocation] = useState(null);
    const [showPermissionAlert, setShowPermissionAlert] = useState(false);
    const [requestedGeolocation, setRequestedGeolocation] = useState(false);
    const [locationIsLoading, setLocationIsLoading] = useState(false);
    useEffect(() => {
        if (!isFetching) {
            setShowLockerPickers(true);
        }
    }, [isFetching]);
    // Mount map the first time the user switches to map view
    useEffect(() => {
        if (!mapMounted && isPickingFromMap) {
            setMapMounted(true);
        }
    }, [isPickingFromMap, mapMounted]);
    useEffect(() => {
        if (requestedGeolocation) {
            mapboxGeolocation === null || mapboxGeolocation === void 0 ? void 0 : mapboxGeolocation.on('geolocate', () => {
                setLocationIsLoading(false);
            });
            mapboxGeolocation === null || mapboxGeolocation === void 0 ? void 0 : mapboxGeolocation.on('trackuserlocationstart', () => {
                setLocationIsLoading(true);
            });
            mapboxGeolocation === null || mapboxGeolocation === void 0 ? void 0 : mapboxGeolocation.on('error', (error) => {
                setLocationIsLoading(false);
                setShowPermissionAlert((error === null || error === void 0 ? void 0 : error.code) === 1);
            });
        }
    }, [mapboxGeolocation, requestedGeolocation]);
    // Reset map picker type on modal close
    useEffect(() => {
        return function cleanup() {
            setIsPickingFromMap(false);
            resetLockerSearchState();
        };
    }, [resetLockerSearchState, setIsPickingFromMap]);
    const onLockerSelect = useCallback((lockerIdentifier) => {
        selectLocker(lockerIdentifier);
        onLockerPick();
    }, [selectLocker, onLockerPick]);
    const onLockerSelectFromMap = useCallback((lockerDetails) => {
        selectLockerFromMap(lockerDetails);
        onLockerPick();
    }, [selectLockerFromMap, onLockerPick]);
    const onCompassPress = useCallback(() => {
        setRequestedGeolocation(!requestedGeolocation);
        if (requestedGeolocation) {
            mapboxGeolocation === null || mapboxGeolocation === void 0 ? void 0 : mapboxGeolocation.trigger();
        }
    }, [mapboxGeolocation, requestedGeolocation]);
    const onSwitch = useCallback(() => {
        setIsPickingFromMap(!isPickingFromMap);
    }, [isPickingFromMap, setIsPickingFromMap]);
    const onDismiss = useCallback(() => {
        setShowPermissionAlert(false);
    }, [setShowPermissionAlert]);
    return (_jsxs("div", { children: [_jsx(Controls, { authToken: authToken, locationIsLoading: locationIsLoading, showPermissionAlert: showPermissionAlert, isPickingFromMap: isPickingFromMap, onSwitch: onSwitch, onCompassPress: onCompassPress, onDismiss: onDismiss }), showLockerPickers && (_jsxs(_Fragment, { children: [!isPickingFromMap && (_jsx(LockerListContainer, { onSelectLocker: onLockerSelect, authToken: authToken, orderToken: token, lockerSearchResults: lockerSearchResults })), mapMounted && (consumerAddress || consumerAddressBox) && (_jsx(LockersMap, { visible: isPickingFromMap, selectedLocker: selectedLocker, onSelectLocker: onLockerSelectFromMap, authToken: authToken, orderToken: token, consumerAddress: consumerAddress || consumerAddressBox, searchedPlaceFeature: selectedSearchResult, requestedGeolocation: requestedGeolocation, setMapboxGeolocation: setMapboxGeolocation }))] }))] }));
};
const Controls = ({ authToken, locationIsLoading, showPermissionAlert, isPickingFromMap = false, onSwitch, onCompassPress, onDismiss, }) => {
    const { t } = useI18n();
    return (_jsx(View, { style: styles.controlsContainer, children: _jsxs("div", { className: css(styles.lockerPickerControls), children: [_jsx(LockersSearchGroupContainer, { authToken: authToken, submitting: locationIsLoading, onSubmit: isPickingFromMap ? () => onCompassPress() : undefined, isPickingFromMap: isPickingFromMap }), _jsx("div", { className: css(styles.horizontalSwitchContainer), children: _jsx(HorizontalSwitch, { initialActiveIndex: !isPickingFromMap ? 0 : 1, activeIndex: !isPickingFromMap ? 0 : 1, leftText: t('showLockerList'), rightText: t('showLockerMap'), onChange: onSwitch }) }), showPermissionAlert ? (_jsx("div", { className: css(styles.permissionsAlertContainer), children: _jsx(Alert, { title: t('locationModal.title'), text: t('locationModal.subtitle'), dismissible: true, onDismiss: onDismiss }) })) : null] }) }));
};
