import { Border, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    button: {
        flex: 1,
        border: 'none',
        display: 'flex',
        height: 44,
        borderRadius: Border.Radius.sm,
        alignItems: 'center',
        justifyContent: 'center',
        ...ButtonEffect.Enabled,
    },
    text: {
        ...Typography.Budbee.Web.h5,
        textAlign: 'center',
        margin: 0,
    },
});
