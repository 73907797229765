import { createElement } from 'react';
import { css } from '@css';
import { featureCollection } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { allowLabelsOverlapSettings } from '../components/shared/locker-picker/features-styles';
import { LockerCallout } from '../components/shared/locker-picker/locker-callout';
import imageList from './image-list';
import { styles } from '../components/shared/locker-picker/style';
const { LockerPin, LockerPinCurrent, LockerPinDisabled, LockersGroupedPin, ConsumerAddress, Place, } = imageList;
export const ZOOM_MAX_LEVEL = 20;
export const ZOOM_MIN_LEVEL = 5;
export const CLUSTER_RADIUS = 44;
export const ZOOM_LEVEL_UNTIL_PINS_ARE_CLUSTERED = ZOOM_MAX_LEVEL - 7;
export const OVERRIDING_LOCKERS_RADIUS = 5;
export const LOCKERS_SOURCE_ID = 'LOCKERS_SOURCE_ID';
export const CLUSTERS_LAYER_ID = 'CLUSTERS_LAYER_ID';
export const CONSUMER_ADDRESS_SOURCE_ID = 'CONSUMER_ADDRESS_SOURCE_ID';
export const SEARCH_PLACE_SOURCE_ID = 'SEARCH_PLACE_SOURCE_ID';
export const AVAILABLE_LOCKERS_LAYER_ID = 'AVAILABLE_LOCKERS_LAYER_ID';
export const UNAVAILABLE_LOCKERS_LAYER_ID = 'UNAVAILABLE_LOCKERS_LAYER_ID';
export const CURRENT_LOCKER_LAYER_ID = 'CURRENT_LOCKER_LAYER_ID';
export const CONSUMER_ADDRESS_LAYER_ID = 'CONSUMER_ADDRESS_LAYER_ID';
export const SEARCH_PLACE_LAYER_ID = 'SEARCH_PLACE_LAYER_ID';
export const toCoordinateObject = (coordinate) => {
    if (!Array.isArray(coordinate)) {
        return {
            longitude: 0,
            latitude: 0,
        };
    }
    return {
        longitude: coordinate[0],
        latitude: coordinate[1],
    };
};
export const toCoordinatePair = (coordinate) => {
    return [coordinate.longitude, coordinate.latitude];
};
export const createFeature = (id, coordinates, properties) => {
    return {
        type: 'Feature',
        id,
        properties: {
            id,
            ...properties,
        },
        geometry: {
            type: 'Point',
            coordinates: toCoordinatePair(coordinates),
        },
    };
};
export const createFeatureCollection = (features) => {
    return featureCollection(features);
};
const createSingleFeatureCollection = (id, coordinates, properties) => {
    return createFeatureCollection([createFeature(id, coordinates, properties)]);
};
export const getOverlappingLockers = (mapRef, coordinates) => {
    const bbox = [
        [coordinates.x - OVERRIDING_LOCKERS_RADIUS, coordinates.y - OVERRIDING_LOCKERS_RADIUS],
        [coordinates.x + OVERRIDING_LOCKERS_RADIUS, coordinates.y + OVERRIDING_LOCKERS_RADIUS],
    ];
    return mapRef.queryRenderedFeatures(bbox, {
        layers: [AVAILABLE_LOCKERS_LAYER_ID, UNAVAILABLE_LOCKERS_LAYER_ID, CURRENT_LOCKER_LAYER_ID],
    });
};
export const getCameraBounds = (deliveryAddressPosition, lockerPosition) => {
    return {
        sw: lockerPosition
            ? toCoordinatePair(lockerPosition)
            : toCoordinatePair(deliveryAddressPosition),
        ne: toCoordinatePair(deliveryAddressPosition),
    };
};
export const createNewPopup = () => {
    return new mapboxgl.Popup({
        closeButton: false,
    }).setMaxWidth('300px');
};
// Lockers map sources and layers management
const handleSource = (mapRef, sourceId, featuresCollection, options) => {
    const existingSource = mapRef.getSource(sourceId);
    if (existingSource) {
        existingSource.setData(featuresCollection);
    }
    else {
        mapRef.addSource(sourceId, {
            type: 'geojson',
            data: featuresCollection,
            ...options,
        });
    }
};
const handleLayer = (mapRef, sourceId, layerId, options) => {
    const mapCanvas = mapRef.getCanvas();
    if (!mapRef.getLayer(layerId)) {
        mapRef.addLayer({
            id: layerId,
            source: sourceId,
            ...options,
        });
        mapRef.on('mouseenter', layerId, () => {
            mapCanvas.style.cursor = 'pointer';
        });
    }
};
const handleImageFeature = (mapRef, Image, sourceId, layerId, imageId, filter, sortKey, iconSize, options) => {
    if (!mapRef.hasImage(imageId)) {
        mapRef.loadImage(Image, (_error, image) => {
            mapRef.addImage(imageId, image, options);
            handleLayer(mapRef, sourceId, layerId, {
                type: 'symbol',
                filter,
                layout: {
                    ...allowLabelsOverlapSettings,
                    'icon-image': imageId,
                    'symbol-sort-key': sortKey,
                    'icon-size': iconSize || 1,
                },
            });
        });
    }
};
export const handleLockersSource = (mapRef, lockerFeatures) => {
    handleSource(mapRef, LOCKERS_SOURCE_ID, lockerFeatures, {
        cluster: true,
        clusterRadius: CLUSTER_RADIUS,
    });
};
export const handleClusterFeatures = (mapRef) => {
    handleImageFeature(mapRef, LockersGroupedPin, LOCKERS_SOURCE_ID, CLUSTERS_LAYER_ID, 'grouped-lockers-pin', ['has', 'point_count'], 2, 1, { pixelRatio: 3 });
};
export const handleLockerPins = (mapRef) => {
    handleImageFeature(mapRef, LockerPinDisabled, LOCKERS_SOURCE_ID, UNAVAILABLE_LOCKERS_LAYER_ID, 'locker-pin-disabled', ['all', ['!has', 'point_count'], ['==', 'available', false], ['==', 'isCurrent', false]], 1, 1.3, { pixelRatio: 3 });
    handleImageFeature(mapRef, LockerPin, LOCKERS_SOURCE_ID, AVAILABLE_LOCKERS_LAYER_ID, 'locker-pin', ['all', ['!has', 'point_count'], ['==', 'available', true], ['==', 'isCurrent', false]], 2, 1, { pixelRatio: 3 });
    handleImageFeature(mapRef, LockerPinCurrent, LOCKERS_SOURCE_ID, CURRENT_LOCKER_LAYER_ID, 'locker-pin-current', ['all', ['!has', 'point_count'], ['==', 'isCurrent', true]], 3, 1, { pixelRatio: 3 });
};
export const handleConsumerAddressPin = (mapRef, consumerAddress) => {
    handleSource(mapRef, CONSUMER_ADDRESS_SOURCE_ID, createSingleFeatureCollection('consumer-address', consumerAddress.coordinate, {
        markerRadius: 30,
    }));
    handleImageFeature(mapRef, ConsumerAddress, CONSUMER_ADDRESS_SOURCE_ID, CONSUMER_ADDRESS_LAYER_ID, 'consumer-address-pin', ['all'], 4, 0.4);
};
export const handleSearchedPlacePin = (mapRef, searchPlaceCoordinates) => {
    const searchPlaceFeatureCollection = searchPlaceCoordinates
        ? createSingleFeatureCollection('search-place-location', searchPlaceCoordinates, {
            markerRadius: 30,
        })
        : createFeatureCollection([]);
    handleSource(mapRef, SEARCH_PLACE_SOURCE_ID, searchPlaceFeatureCollection);
    handleImageFeature(mapRef, Place, SEARCH_PLACE_SOURCE_ID, SEARCH_PLACE_LAYER_ID, 'search-place-pin', ['all'], 1, 0.4);
};
export const handleLockerPinClick = (clickEvent, mapRef, selectedLocker, lockers, onSelectLocker, fetchLockerDetails, popup) => {
    const lockersFeatures = clickEvent.features;
    const firstLockerFeature = clickEvent.features[0];
    const calloutCoordinates = firstLockerFeature.geometry.coordinates.slice();
    const currentZoom = mapRef.getZoom();
    // if ( features are not clustered anymore )
    // { Run a search to identify overlapping lockers in a radius of OVERRIDING_LOCKERS_RADIUS px. }
    // else { We know for sure that there is only one locker on the clicked feature. }
    const lockerFeaturesToAddInCallout = currentZoom >= ZOOM_LEVEL_UNTIL_PINS_ARE_CLUSTERED
        ? getOverlappingLockers(mapRef, clickEvent.point)
        : lockersFeatures;
    const lockersIdentifiersToAddInCallout = lockerFeaturesToAddInCallout.map((lockerFeature) => lockerFeature.properties.id);
    const lockersInCallout = lockers.filter((locker) => lockersIdentifiersToAddInCallout.includes(locker.id));
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // callout appears over the copy being pointed to.
    while (Math.abs(clickEvent.lngLat.lng - calloutCoordinates[0]) > 180) {
        calloutCoordinates[0] += clickEvent.lngLat.lng > calloutCoordinates[0] ? 360 : -360;
    }
    const callout = createElement(LockerCallout, {
        selectedLocker,
        lockers: lockersInCallout,
        onSelectLocker,
        fetchLockerDetails,
    });
    const calloutContainer = document.createElement('div');
    calloutContainer.className = css(styles.calloutWrapper);
    const root = createRoot(calloutContainer);
    root.render(callout);
    const { markerRadius } = firstLockerFeature.properties;
    popup
        .setOffset({
        top: [0, markerRadius],
        bottom: [0, -markerRadius],
        left: [markerRadius, 0],
        right: [-markerRadius, 0],
    })
        .setMaxWidth('345px')
        .setLngLat(calloutCoordinates)
        .setDOMContent(calloutContainer)
        .addTo(mapRef);
};
export const addClusterClickEventListener = (mapRef) => {
    mapRef.on('click', CLUSTERS_LAYER_ID, (e) => {
        const renderedFeatures = mapRef.queryRenderedFeatures(e.point, {
            layers: [CLUSTERS_LAYER_ID],
        });
        const clusterId = renderedFeatures[0].properties.cluster_id;
        mapRef
            .getSource(LOCKERS_SOURCE_ID)
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err)
                return;
            mapRef.easeTo({
                center: renderedFeatures[0].geometry.coordinates,
                zoom,
                duration: 100,
            });
        });
    });
};
export const addLockerClickEventListener = (mapRef, selectedLocker, lockers, onSelectLocker, fetchLockerDetails, popup) => {
    const layers = [CURRENT_LOCKER_LAYER_ID, AVAILABLE_LOCKERS_LAYER_ID, UNAVAILABLE_LOCKERS_LAYER_ID];
    layers.forEach((layerID) => {
        mapRef === null || mapRef === void 0 ? void 0 : mapRef.on('click', layerID, (e) => {
            // Trigger callback for the top most layer only.
            if (!e.originalEvent.defaultPrevented) {
                e.originalEvent.preventDefault();
                handleLockerPinClick(e, mapRef, selectedLocker, lockers, onSelectLocker, fetchLockerDetails, popup);
            }
        });
    });
};
export const getUserUserLocation = (mapRef) => {
    const geolocation = new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: false,
        },
        trackUserLocation: true,
        showAccuracyCircle: false,
        showUserHeading: false,
    });
    mapRef.addControl(geolocation);
    const added = mapRef.hasControl(geolocation);
    return added ? geolocation : null;
};
