import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useUserSelectedLocker } from '../../../state/lockers/hooks';
import { setLocker } from '../../../state/order/actions';
import { useToken } from '../../../state/order/hooks';
import { LockerLocationCard } from '../../locker-location-card';
import { LockerMap } from '../../locker-map';
// this component is not a locker picker, it just displays info about the selected locker and a small map
export const LockerPickerOverview = ({ onContinue, authToken, canContinueChanged, closeModal, reloadOrder, }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const trackingApi = useTrackingApi();
    const token = useToken();
    const selectedLocker = useUserSelectedLocker();
    const selectLocker = useCallback((locker) => dispatch(setLocker(locker)), [dispatch]);
    useEffect(() => {
        if (!selectedLocker || !onContinue || !authToken) {
            return;
        }
        onContinue((next, onError) => {
            trackingApi
                .changeLocker(authToken, token, selectedLocker.lockerIdentifier, selectedLocker.nextEta)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    closeModal();
                    selectLocker(selectedLocker);
                    if (reloadOrder) {
                        reloadOrder();
                    }
                    next();
                }
                else {
                    onError();
                }
            });
        });
    }, [authToken, token, onContinue, selectedLocker, trackingApi, closeModal, selectLocker]);
    useEffect(() => canContinueChanged(selectedLocker != null), [selectedLocker, canContinueChanged]);
    if (!selectedLocker) {
        return null;
    }
    return (_jsxs("div", { className: "locker-picker-container", children: [_jsx("p", { children: t('lockerOverviewTitle') }), _jsx(LockerMap, { coordinate: selectedLocker.lockerAddress.coordinate, style: { height: 150 } }), _jsx(LockerLocationCard, { lockerAddress: selectedLocker.lockerAddress, openHours: selectedLocker.openHours, variant: "borderless" })] }));
};
