import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { css, StyleSheet } from '@css';
import cx from 'classnames';
import { Button } from '../components/shared/button';
import { Loader } from '../components/shared/loader';
import TrackingV2 from './tracking-v2';
import TrackingV3 from './tracking-v3';
import { getQueryVariable } from './url-params';
const styles = StyleSheet.create({
    root: {
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    redirect: {
        alignSelf: 'center',
        width: '80vw',
    },
    loading: {
        marginBottom: 150,
    },
});
const MAX_NUMBER_OF_RETRIES = 12;
const REFETCH_INTERVAL = 3000;
const getStatusFromPayload = (payload) => typeof payload === 'string' ? payload : payload.status;
export class AdyenRedirect extends Component {
    constructor(props) {
        super(props);
        this.checkout = null;
        this.widget = null;
        this.state = {
            loading: true,
            numberOfRetries: 0,
        };
        this.onStatusResponse = this.onStatusResponse.bind(this);
        this.navigateToTracking = this.navigateToTracking.bind(this);
        this.fetchStatus = this.fetchStatus.bind(this);
    }
    componentDidMount() {
        if (getQueryVariable('resultCode') === 'cancelled') {
            this.navigateToTracking();
        }
        this.checkout = new global.AdyenCheckout({});
        this.widget = this.checkout.create('dropin', {}).mount(this.ref);
        this.fetchStatus();
    }
    fetchStatus() {
        const reference = getQueryVariable('payment_reference');
        const version = getQueryVariable('pversion');
        if (typeof version === 'string' && version.toLowerCase() === 'v3') {
            TrackingV3().paymentStatus(reference).then(this.onStatusResponse);
        }
        else {
            TrackingV2()
                .paymentStatus(this.props.match.params.token, reference)
                .then(this.onStatusResponse);
        }
        const { numberOfRetries } = this.state;
        this.setState({ numberOfRetries: numberOfRetries + 1 });
    }
    onStatusResponse(response) {
        if (response.status !== 'COMPLETED') {
            return;
        }
        const status = getStatusFromPayload(response.payload);
        if (status === 'COMPLETED') {
            this.widget.setStatus('success');
            this.setState({ loading: false });
        }
        else if (status === 'FAILED') {
            this.widget.setStatus('error');
            this.setState({ loading: false });
        }
        else if (status === 'ABORTED') {
            this.navigateToTracking();
        }
        else {
            const { numberOfRetries } = this.state;
            if (numberOfRetries <= MAX_NUMBER_OF_RETRIES) {
                setTimeout(() => this.fetchStatus(), REFETCH_INTERVAL);
            }
            else {
                this.navigateToTracking();
            }
        }
    }
    navigateToTracking() {
        const { token } = this.props.match.params;
        const url = `/${token}?auth=${getQueryVariable('auth')}&version=${getQueryVariable('version')}`;
        window.location.assign(url);
    }
    renderOkButton() {
        return (_jsx(Button, { className: cx('button-discrete', 'adyen-redirect-ok adyen-redirect-padded button-secondary'), onClick: this.navigateToTracking, children: "Ok" }));
    }
    render() {
        const { loading } = this.state;
        const pleaseWait = (_jsxs("div", { children: [_jsx("h1", { className: css(styles.loading), children: "Please wait" }), _jsx(Loader, {})] }));
        return (_jsxs("div", { className: css(styles.root), children: [loading ? pleaseWait : null, _jsx("div", { className: css(styles.redirect), id: "dropin", ref: (node) => {
                        this.ref = node;
                    } }), !loading ? this.renderOkButton() : null] }));
    }
}
