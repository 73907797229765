import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useIsReturnConsignment, useParcelDeliveryOverview } from '../../state/order/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { DeliveryInformation } from '../delivery-information';
import { ParcelDeliveryOverview } from '../parcel-delivery-overview';
import { View } from '../shared/view';
import { styles } from './style';
export function HomeOrderLocation({ authenticated, address, logo, orderStatus, merchantName, }) {
    var _a, _b;
    const isReturnConsignment = useIsReturnConsignment();
    const deliveryOverview = useParcelDeliveryOverview();
    const brand = getBrand();
    const { t } = useI18n();
    const { withNeighbour, neighbourName, neighbourAddress } = orderStatus !== null && orderStatus !== void 0 ? orderStatus : {};
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('parcelInformation') }), _jsxs(View, { style: styles.root, children: [authenticated && withNeighbour ? (_jsxs(_Fragment, { children: [_jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { topText: t('deliveredToNeighbour'), middleText: `${neighbourName}, ${neighbourAddress}`, rightIconType: "hidden", leftIcon: imageList.Profile }) }), _jsx(View, { style: styles.divider })] })) : null, _jsx(_Fragment, { children: _jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { leftIcon: brand === 'instabox' ? imageList.MerchantInstabox : imageList.Box, topText: t('deliveryFrom'), middleText: merchantName, rightIcon: logo, rightIconType: "merchantLogo" }) }) }), authenticated ? (_jsxs(_Fragment, { children: [_jsx(View, { style: styles.divider }), _jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { topText: t(isReturnConsignment ? 'returnPickup' : 'homeDelivery'), middleText: address, leftIcon: brand === 'instabox' ? imageList.HouseInstabox : imageList.House, rightIconType: "hidden" }) })] })) : null, authenticated &&
                        deliveryOverview &&
                        (((_a = deliveryOverview.parcels) === null || _a === void 0 ? void 0 : _a.length) > 1 ||
                            ((brand === 'instabox' || brand === 'porterbuddy') &&
                                ((_b = deliveryOverview.parcels) === null || _b === void 0 ? void 0 : _b.length) > 0)) ? (_jsxs(_Fragment, { children: [_jsx(View, { style: styles.divider }), _jsx(View, { style: styles.container, children: _jsx(ParcelDeliveryOverview, { deliveryOverview: deliveryOverview }) })] })) : null] })] }));
}
