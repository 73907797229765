import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Color } from '@budbee/js/dist/ui';
import { countries } from '@budbee/js/tracking';
import { css } from '@css';
import { IconSize } from '../../../styles';
import { getBrand } from '../../../utils/brand/get-brand';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { styles } from './style';
export const PhoneContact = (props) => {
    const { phone, countryCode } = props;
    const brand = getBrand();
    const { available, phoneNumber } = phone || {};
    const supportPhoneNumber = useMemo(() => {
        if (brand === 'budbee' && available) {
            return phoneNumber;
        }
        if (brand === 'porterbuddy') {
            return '+47 923 34 015';
        }
        if (brand === 'instabox') {
            if (countryCode === countries.DENMARK) {
                return '29 79 79 79';
            }
            return '08-409 028 02';
        }
        return null;
    }, [available, countryCode, brand, phoneNumber]);
    return supportPhoneNumber ? (_jsxs("a", { className: css([styles.linkView, !phone ? styles.disabledLinkView : undefined]), href: `tel:${supportPhoneNumber}`, children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.Phone,
                    porterbuddy: imageList.PhonePorterbuddy,
                    instabox: imageList.Phone,
                }, size: IconSize['3xl'], color: {
                    budbee: Color.Budbee.Grey5,
                    instabox: Color.Budbee.Grey5,
                } }), _jsx("span", { className: css(styles.link), children: supportPhoneNumber })] })) : null;
};
