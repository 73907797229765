import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { AltColor, FontSize, FontWeight, IconSize, Spacing, marginVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Color.White100,
        border: 0,
        padding: 0,
        width: '100%',
        porterbuddy: {
            backgroundColor: 'transparent',
        },
    },
    leftContainer: {
        width: IconSize['10xl'],
        height: IconSize['10xl'],
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        marginRight: Spacing.md,
    },
    rightContainer: {
        flex: 1,
        textAlign: 'left',
    },
    header: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.Grey6,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h4,
            color: Color.White100,
        },
    },
    headerAlt: {
        fontSize: FontSize.sm,
        fontWeight: FontWeight.SemiBold,
        color: AltColor.SecondaryDark,
    },
    textContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        porterbuddy: {
            flexDirection: 'column',
        },
        instabox: {
            flexDirection: 'column',
        },
    },
    title: {
        marginTop: Space['2xs'],
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey5,
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            color: Color.White100,
            ...marginVertical(Space['3xs']),
        },
    },
    topText: {
        ...Typography.Porterbuddy.Web.h5,
        color: Color.White100,
        ...marginVertical(Space['3xs']),
        instabox: {
            ...Typography.Budbee.Web.h5,
        },
    },
    description: {
        marginTop: Space['2xs'],
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey5,
        porterbuddy: {
            display: 'none',
        },
        instabox: {
            display: 'none',
        },
    },
});
