import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '../../components/shared/icon';
import { getBrand } from './get-brand';
// Will return the appropriate Icon component for a brand, if brand is missing it will default to budbee
export const BrandedIcon = (icon) => {
    try {
        const brand = getBrand();
        const currentSrc = typeof icon.src === 'string' ? icon.src : icon.src[brand];
        // eslint-disable-next-line no-nested-ternary
        const currentColor = icon.color
            ? typeof icon.color === 'string'
                ? icon.color
                : icon.color[brand]
            : undefined;
        // eslint-disable-next-line no-nested-ternary
        const currentSize = icon.size
            ? typeof icon.size === 'number'
                ? icon.size
                : icon.size[brand]
            : undefined;
        const props = {
            ...icon,
            src: currentSrc,
            color: currentColor,
            size: currentSize,
        };
        return _jsx(Icon, { ...props });
    }
    catch {
        return null;
    }
};
