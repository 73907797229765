export const REGISTER_PAYMENT_REQUEST = 'REGISTER_PAYMENT_REQUEST';
export const REGISTER_PAYMENT_REQUEST_V3 = 'REGISTER_PAYMENT_REQUEST_V3';
export const CLEAR_PAYMENT_REQUEST = 'CLEAR_PAYMENT_REQUEST';
export const CLEAR_PAYMENT_REQUEST_V3 = 'CLEAR_PAYMENT_REQUEST_V3';
export const REGISTER_PRELIMINARY_PAYMENT = 'REGISTER_PRELIMINARY_PAYMENT';
export const SET_PAYMENT_REFERENCE = 'SET_PAYMENT_REFERENCE';
export const UPDATE_ADYEN_PAYMENT_STATUS = 'UPDATE_ADYEN_PAYMENT_STATUS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';
export const clearPaymentRequest = () => ({
    type: CLEAR_PAYMENT_REQUEST,
});
export const clearPaymentRequestV3 = () => ({
    type: CLEAR_PAYMENT_REQUEST_V3,
});
export const registerPaymentRequest = (request) => ({
    type: REGISTER_PAYMENT_REQUEST,
    payload: { request },
});
export const registerPaymentRequestV3 = (request) => ({
    type: REGISTER_PAYMENT_REQUEST_V3,
    payload: { request, version: 'V3' },
});
export const registerPreliminaryPayment = () => ({
    type: REGISTER_PRELIMINARY_PAYMENT,
});
export const setPaymentReference = (reference) => ({
    type: SET_PAYMENT_REFERENCE,
    payload: { reference },
});
export const updateAdyenPaymentStatus = (status) => ({
    type: UPDATE_ADYEN_PAYMENT_STATUS,
    payload: { status },
});
export const setProduct = (product) => ({
    type: SET_PRODUCT,
    payload: { product },
});
export const resetPaymentState = () => ({
    type: RESET_PAYMENT_STATE,
});
