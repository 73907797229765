export const getBrand = () => {
    const { hostname } = window.location;
    if (hostname.includes('budbee')) {
        return 'budbee';
    }
    if (hostname.includes('instabee')) {
        return 'instabox';
    }
    if (hostname.includes('porterbuddy')) {
        return 'porterbuddy';
    }
    return 'budbee';
};
