import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Transition, border } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        transition: Transition.Ease,
        marginTop: Space['2xl'],
        flexDirection: 'row',
        ...border(Border.Width.md, 'solid', '#B6CDC7'),
        borderRadius: Border.Radius.md,
        padding: Space.xl,
        instabox: {
            border: 0,
            backgroundColor: Color.White100,
            padding: Space.lg,
        },
        porterbuddy: {
            border: 0,
            borderRadius: 0,
            backgroundColor: Color.White100,
        },
    },
    text: {
        ...Typography.Budbee.Web['body-md'],
        marginLeft: Space.lg,
        instabox: {
            ...Typography.Instabox.Web.h5,
            color: Color.Instabox.GreyGray,
        },
    },
});
