import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, IconSize, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        border: 'none',
        display: 'flex',
        backgroundColor: Color.Grey60,
        borderRadius: Border.Radius.sm,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        ...ButtonEffect.Enabled,
    },
    circle: {
        width: IconSize.md,
        height: IconSize.md,
        borderRadius: 50,
    },
    budbee: {
        backgroundColor: Color.Budbee.Green,
        transform: `translateX(-${Space['2xs']}px)`,
    },
    instabox: {
        backgroundColor: Color.Instabox.ReallyRed,
    },
    text: {
        ...Typography.Budbee.Web.h5,
        ...paddingHorizontal(Space.sm),
    },
});
