import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Color as OldColor, ButtonEffect, border, paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    textinput: {
        textAlign: 'left',
        ...paddingHorizontal(Space.md),
    },
    p: {
        ...Typography.Budbee.Web['body-lg'],
        paddingLeft: Space.sm,
        paddingRight: Space.sm,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        marginTop: Space['2xs'],
        marginBottom: Space['2xs'],
    },
    input: {
        flex: 1,
    },
    invalidInput: {
        flex: 1,
        ...border(Border.Width.sm, 'solid', Color.Budbee.ReadyRed),
        borderRadius: Border.Radius.sm,
    },
    trash: {
        position: 'absolute',
        right: Space.lg,
        top: 0,
        bottom: 0,
    },
    warning: {
        color: OldColor.Alert,
    },
    addButton: {
        border: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: Color.Primary20,
        alignItems: 'center',
        paddingVertical: Space.sm,
        borderRadius: Border.Radius.sm,
        marginTop: Space.lg,
        marginBottom: Space.sm,
        ...ButtonEffect.Enabled,
    },
    disabled: {
        ...ButtonEffect.Disabled,
    },
    buttonText: {
        marginLeft: Space.sm,
    },
});
