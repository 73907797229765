import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { css } from '@css';
import { injectIntl } from '../../utils/i18n';
import imageList from '../../utils/image-list';
import TrackingV3 from '../../utils/tracking-v3';
import { getQueryVariable } from '../../utils/url-params';
import { BaseButton } from '../base-button';
import { ErrorMessage } from '../error-message';
import { Loader } from '../shared/loader';
import { styles } from './style';
const STATUS_REATTEMPTS = 2;
class BankIdRedirectComponent extends Component {
    constructor(props) {
        super(props);
        this.fetchStatus = this.fetchStatus.bind(this);
        this.onStatusResponse = this.onStatusResponse.bind(this);
        this.navigateToTracking = this.navigateToTracking.bind(this);
        this.retry = this.retry.bind(this);
        this.state = {
            failed: false,
            fetchError: false,
        };
    }
    componentDidMount() {
        this.fetchStatus(STATUS_REATTEMPTS);
    }
    fetchStatus(reattempts) {
        const { token } = this.props;
        TrackingV3()
            .identificationStatus(token)
            .then((response) => this.onStatusResponse(response, reattempts))
            .catch(() => this.retry(reattempts));
    }
    onStatusResponse(response, reattempts) {
        const { status } = response.payload;
        if (status === 'NOT_ATTEMPTED') {
            this.navigateToTracking();
        }
        else if (status === 'COMPLETED') {
            this.navigateToTracking();
        }
        else if (status === 'FAILED') {
            this.setState({ failed: true });
        }
        else {
            this.retry(reattempts);
        }
    }
    retry(reattempts) {
        if (reattempts > 0) {
            this.fetchStatus(reattempts - 1);
        }
        else {
            this.setState({ fetchError: true });
        }
    }
    navigateToTracking() {
        const { token } = this.props;
        const url = `/${token}?auth=${getQueryVariable('auth')}&version=${getQueryVariable('version')}`;
        window.location.assign(url);
    }
    static renderChecking(intl) {
        return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.text), children: intl.formatMessage({ id: 'authenticationInProgress' }) }), _jsx(Loader, { variant: "inline", style: styles.loader })] }));
    }
    renderFailed(intl) {
        return (_jsxs("div", { className: css(styles.failed), children: [_jsx(ErrorMessage, { message: intl.formatMessage({ id: 'authenticationFailed' }) }), _jsx("div", { className: css(styles.divider) }), _jsx(BaseButton, { label: intl.formatMessage({ id: 'cancel' }), onClick: this.navigateToTracking })] }));
    }
    renderFetchError(intl) {
        return (_jsxs("div", { className: css(styles.root), children: [intl.formatMessage({ id: 'alertModalTryAgainTitle' }), _jsx("div", { className: css(styles.divider) }), _jsx(BaseButton, { label: "Ok", onClick: this.navigateToTracking })] }));
    }
    render() {
        const { intl } = this.props;
        const { failed, fetchError } = this.state;
        if (fetchError) {
            return this.renderFetchError(intl);
        }
        return (_jsxs("div", { className: css(styles.root), children: [!failed && _jsx("img", { className: css(styles.logo), src: imageList.bankId, alt: "bankId" }), failed ? this.renderFailed(intl) : BankIdRedirectComponent.renderChecking(intl)] }));
    }
}
export const BankIdRedirect = injectIntl(BankIdRedirectComponent);
