import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { css } from '@css';
import { useAppStoreLink } from '../../hooks/use-app-store-link';
import { styles } from './style';
export const UseAppButton = ({ text, callback, style, ...props }) => {
    const link = useAppStoreLink();
    const openLink = useCallback(() => {
        window.open(link);
        if (callback) {
            callback();
        }
    }, [callback, link]);
    return (_jsx("button", { onClick: openLink, className: css(styles.actionButton, style), ...props, type: "button", children: text }));
};
