import * as latlng from './latlng';
export class MapMoveData {
    constructor(targetCoordinate, targetTimestamp, previousCoordinate, previousTimestamp, previousHeading) {
        this.timestamp = targetTimestamp;
        this.previousTimestamp = previousTimestamp;
        this.timestampDiff = targetTimestamp.diff(previousTimestamp);
        this.targetCoordinate = targetCoordinate;
        this.previousCoordinate = previousCoordinate;
        this.moveDistance = latlng.sphericalDistance(previousCoordinate, targetCoordinate);
        this.heading = latlng.heading(previousCoordinate, targetCoordinate);
        this.previousHeading = previousHeading;
    }
    shouldIgnore(lowerLimit, upperLimit) {
        const angleDiff = this.heading - this.previousHeading;
        const moveAngle = Math.abs(angleDiff % 180);
        const differenceSesitivity = moveAngle / 180;
        const threshold = Math.max(lowerLimit, upperLimit * differenceSesitivity);
        const differenceToSmall = this.moveDistance < threshold;
        return differenceToSmall;
    }
    targetDistanceTo(position) {
        return latlng.sphericalDistance(this.targetCoordinate, position);
    }
    targetInBounds(bounds) {
        return bounds.contains(latlng.mapboxCoordinate(this.targetCoordinate));
    }
    getTargetCoordinate() {
        return this.targetCoordinate;
    }
    getHeading() {
        return this.heading;
    }
    getMoveDistance() {
        return this.moveDistance;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getTimestampDiff() {
        return this.timestampDiff;
    }
}
