import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { ProductsEligibleForPayment } from '../../../utils/payment';
import { ErrorMessage } from '../../error-message';
import { LockerListItem } from '../../locker-list-item';
import { Loader } from '../loader';
import { styles } from './style';
export const LockerList = ({ authToken, orderToken, requestLockersSuccess, requestLockersError, setProduct, setSwitchDeliveryTypePrice, resetPaymentState, selectedLocker, onSelectLocker, requestLockers, isFetching, lockers: defaultLockers, isSearchingForPlace, lockerSearchResults, placeSearchKeyword, }) => {
    const trackingApi = useTrackingApi();
    const { t } = useI18n();
    const [responseLockers, setResponseLockers] = useState([]);
    useEffect(() => {
        setResponseLockers(lockerSearchResults.length > 0 && placeSearchKeyword.length > 0
            ? lockerSearchResults
            : defaultLockers);
    }, [isSearchingForPlace, lockerSearchResults, defaultLockers, placeSearchKeyword]);
    useEffect(() => {
        if (isSearchingForPlace || !authToken) {
            return;
        }
        requestLockers();
        trackingApi
            .getLockers(authToken, orderToken)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                const { canSwitchToHomeOrLockerFree, price, lockers } = response.payload;
                resetPaymentState();
                if (!canSwitchToHomeOrLockerFree) {
                    setProduct(ProductsEligibleForPayment.SWITCH_DELIVERY_TYPE);
                }
                setSwitchDeliveryTypePrice({
                    canSwitchToHomeOrLockerFree,
                    price,
                    message: null,
                });
                requestLockersSuccess(lockers);
            }
            else {
                setSwitchDeliveryTypePrice({
                    canSwitchToHomeOrLockerFree: false,
                    price: null,
                    message: null,
                });
                requestLockersError(response.errorMsg);
            }
        });
    }, [authToken, orderToken, trackingApi, selectedLocker, isSearchingForPlace]);
    const renderLockersList = useCallback(() => {
        if (isFetching) {
            return _jsx(Loader, {});
        }
        if (placeSearchKeyword.length > 0 && lockerSearchResults.length === 0) {
            return (_jsx(ErrorMessage, { positionVariant: "inline", message: t('lockersSearchResultsMessage.noLockersFound'), contentContainerStyle: styles.error }));
        }
        if (responseLockers.length === 0) {
            return _jsx(ErrorMessage, { positionVariant: "inline", contentContainerStyle: styles.error });
        }
        return responseLockers.map((locker) => (_jsx("div", { className: css(styles.lockerListItem), children: _jsx(LockerListItem, { lockerName: locker.lockerAddress.name, onClick: () => onSelectLocker(locker.lockerIdentifier), address: locker.lockerAddress, boxDemand: locker.demand, nextEta: locker.nextEta, fasterDeliveryOption: locker.fasterDeliveryOption }) }, locker.lockerIdentifier)));
    }, [
        isFetching,
        placeSearchKeyword.length,
        lockerSearchResults.length,
        responseLockers,
        t,
        onSelectLocker,
    ]);
    return (_jsx("div", { className: css(styles.root, responseLockers.length === 0 && styles.rootError), children: _jsx("div", { className: css(styles.lockersList), children: renderLockersList() }) }));
};
