import { composeHeaders, NO_CACHE, JSON_API, AUTH, FLAVOR, UTM_MEDIUM } from './headers';
const baseUrl = (() => {
    return `/api/v2/`;
})();
const parse = (response) => {
    if (response.ok) {
        return response.json();
    }
    const error = new Error(response.statusText);
    // @ts-expect-error ts-migrate error
    error.response = response;
    throw error;
};
const request = (method, headers, url, body) => {
    const options = {
        method,
        mode: 'cors',
        cache: 'no-cache',
        // credentials: 'include',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body,
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ method: any; mode: string; cac... Remove this comment to see the full error message
    return fetch(baseUrl + url.join('/'), options).then(parse);
};
/**
 * @deprecated Use useTrackingApi hook instead
 */
const TrackingV2 = (method, headers = {}, url = [], body) => ({
    paymentStatus(token, reference) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('payment')
            .path(reference)
            .path('status')
            .get();
    },
    createPayment(token, checkoutId, price, paymentDetails) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('orders')
            .path(token)
            .path('payment')
            .post({ checkoutId, price, paymentDetails });
    },
    path(part) {
        return TrackingV2(method, headers, [...url, part], body);
    },
    header(key, value) {
        return TrackingV2(method, { ...headers, [key]: value }, url, body);
    },
    composeHeaders(...fns) {
        return TrackingV2(method, { ...headers, ...composeHeaders(...fns) }, url, body);
    },
    get() {
        return request('get', headers, url);
    },
    post(payload) {
        return request('post', headers, url, JSON.stringify(payload));
    },
    put(payload) {
        return request('put', headers, url, payload ? JSON.stringify(payload) : body);
    },
    delete() {
        return request('delete', headers, url, body);
    },
    identifyAction(orderToken) {
        return this.composeHeaders(JSON_API, NO_CACHE, AUTH, FLAVOR, UTM_MEDIUM)
            .path('identification')
            .path('orders')
            .path(orderToken)
            .path('action')
            .get();
    },
});
export default TrackingV2;
