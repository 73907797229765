import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { TextInput } from '../../shared/text-input';
import { Footer } from '../footer';
import { styles } from '../style';
export const Comment = (props) => {
    const { currentComment, onBack, onNext, onChangeComment } = props;
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('reviewModal.subTitle') }), _jsx("span", { className: css(styles.subtitle), children: t('reviewModal.commentSubTitle') }), _jsx("div", { className: css(styles.optionsWrapper), children: _jsx(TextInput, { value: currentComment || '', onChange: (value) => onChangeComment(value), contentContainerStyle: styles.input, style: styles.input2, placeholder: t('reviewModalPlaceholder'), rows: 4 }) }), _jsx(Footer, { onBack: onBack, onNext: onNext })] }));
};
