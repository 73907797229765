import { useState, useCallback } from 'react';
export const useToggledState = (defaultState) => {
    const [state, setState] = useState(defaultState);
    return [
        state,
        useCallback((value) => {
            setState(value === state ? null : value);
        }, []),
    ];
};
