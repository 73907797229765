import { Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
    },
    title: {
        ...Typography.Budbee.Web['body-lg'],
    },
});
