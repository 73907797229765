import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, ButtonEffect, marginHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    buttonDefault: {
        ...Typography.Budbee.Web['body-lg'],
        backgroundColor: Color.Budbee.Grey1,
        borderRadius: Border.Radius.sm,
        width: '100%',
        minHeight: 56,
        textRendering: 'optimizeLegibility',
        ...ButtonEffect.Enabled,
        border: 0,
        marginBottom: Space.sm,
        padding: Space.lg,
        porterbuddy: {
            borderRadius: Border.Radius['2xs'],
            ...Typography.Porterbuddy.Web.h4,
            backgroundColor: Color.White100,
            marginBottom: Space.sm,
            ...marginHorizontal(-Space['2xl']),
            width: 'auto',
        },
        instabox: {
            backgroundColor: Color.White100,
            border: 0,
            marginBottom: Space['2xl'],
        },
    },
    buttonSelected: {
        ...border(Border.Width.sm, 'solid', Color.Budbee.Grey5),
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.PalPurple,
            border: 0,
        },
    },
    timeSlotContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleSelected: {
        porterbuddy: {
            color: Color.Porterbuddy.BuddyPurple,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    price: {
        marginRight: Space.md,
        color: Color.Budbee.Grey4,
    },
    eta: {
        paddingLeft: Space.md,
        porterbuddy: {
            textAlign: 'left',
        },
    },
    padding: {
        marginBottom: Space.lg,
    },
    icon: {
        marginRight: Space.lg,
    },
});
