import { Color, Space, Typography } from '@budbee/js/dist/ui';
import { StyleSheet } from '@css';
import { paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    container: {
        ...Typography.Porterbuddy.Web.h4,
        backgroundColor: Color.Porterbuddy.BuddyPurple,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: Space['2xl'],
        paddingRight: Space.lg,
        ...paddingVertical(Space.lg),
        color: Color.White100,
    },
    leftContainer: {
        flexDirection: 'row',
    },
    textContainer: {
        flexDirection: 'column',
        marginLeft: Space.md,
    },
    textRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    toggleMap: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: Space.md,
        cursor: 'pointer',
    },
    open: {
        transform: 'rotate(-90deg)',
    },
    close: {
        transform: 'rotate(90deg)',
    },
});
