import { Space, Color, Typography, Border } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, FontWeight, marginHorizontal } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.sm,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: 0,
        padding: Space.md,
        ...ButtonEffect.Enabled,
    },
    primaryRoot: {
        backgroundColor: Color.Budbee.GreenDark,
    },
    secondaryRoot: {
        backgroundColor: Color.Budbee.GreenLight,
    },
    darkRoot: {
        backgroundColor: Color.Budbee.Grey6,
    },
    lightRoot: {
        backgroundColor: Color.White100,
    },
    disabled: {
        ...ButtonEffect.Disabled,
        cursor: 'not-allowed',
        opacity: 0.5,
    },
    lg: {
        ...Typography.Budbee.Web.h3,
        fontSize: 16,
        ...marginHorizontal(Space.md),
        fontWeight: FontWeight.Medium,
    },
    md: {
        ...Typography.Budbee.Web.h4,
        ...marginHorizontal(Space.md),
        fontWeight: FontWeight.Medium,
    },
    sm: {
        ...Typography.Budbee.Web.h5,
        ...marginHorizontal(Space.md),
        fontWeight: FontWeight.Medium,
    },
    whiteText: {
        color: Color.White100,
    },
    greenText: {
        color: Color.Budbee.GreenDark,
    },
    greyText: {
        color: Color.Budbee.Grey5,
    },
});
