import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { date } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { styles } from '../box-header/style';
export const DeliveredBoxHeader = ({ authenticated, latestPickupDate, code, needsVerification, countryCode, canExtendPickupTime, toggleIdentificationModal, toggleExtendLockerDurationModal, }) => {
    const { t } = useI18n();
    const instructions = (() => {
        if (needsVerification) {
            return t('pinCodeInstructionsVerification');
        }
        if (code) {
            return t('pinCodeInstructions');
        }
        return null;
    })();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('readyToBePickedUp'), middleText: latestPickupDate != null
                    ? t('latestPickup', {
                        date: date.formatLatestPickupDate(latestPickupDate, countryCode),
                    })
                    : undefined, bottomText: instructions }), authenticated ? (_jsxs(View, { style: styles.actionsWrapper, children: [needsVerification ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })) : null, !needsVerification && canExtendPickupTime ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('extendPickupTime.title'), iconSrc: imageList.Clock, onClick: toggleExtendLockerDurationModal, variant: "primary" }) })) : null] })) : null] }));
};
