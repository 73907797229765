import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { eta as etaUtils, price, } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useTimeZone } from '../../state/locale/hooks';
import { inlineStyles } from '../../utils/inline-styles';
import { View } from '../shared/view';
import { styles } from './style';
export const DeliveryWindowSummaryItem = ({ consignment, routedEta, orderStatus, isReturn, timeWindow, eta, countryCode, language, }) => {
    var _a;
    const { i18n } = useI18n();
    const isInProgress = etaUtils.isInProgress(orderStatus, isReturn);
    const timeZone = useTimeZone();
    let etaCalculated;
    if (isInProgress) {
        etaCalculated = eta;
    }
    else if (routedEta) {
        etaCalculated = etaUtils.fromRoutedEta(routedEta);
    }
    const etaText = timeZone &&
        i18n.etaText(etaCalculated || null, orderStatus, isReturn, consignment || null, countryCode, language, timeZone);
    return (_jsxs(View, { style: styles.root, children: [_jsx("p", { className: css(styles.title), children: etaText }), ((_a = timeWindow === null || timeWindow === void 0 ? void 0 : timeWindow.timeWindow) === null || _a === void 0 ? void 0 : _a.price) ? (_jsxs("p", { className: css(styles.title, inlineStyles({ marginLeft: 4 })), children: ['- ', price.formatPrice(timeWindow.timeWindow.price)] })) : null] }));
};
