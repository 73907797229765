export const featureEnabled = (featureKey) => (state) => {
    if (!state) {
        return false;
    }
    if (state.features) {
        const feature = state.features[featureKey];
        return feature === undefined || feature === true;
    }
    return false;
};
