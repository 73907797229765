import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, paddingHorizontal, marginVertical, marginHorizontal, paddingVertical, } from '../../styles';
import imageList from '../../utils/image-list';
export const styles = StyleSheet.create({
    root: {
        overflow: 'hidden',
        backgroundColor: Color.White100,
        borderRadius: BorderRadius.md,
        ...paddingVertical(Space['2xs']),
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.BuddyPurple,
        },
    },
    divider: {
        ...marginHorizontal(Space.lg),
        height: Border.Width.sm,
        backgroundColor: Color.Budbee.Grey1,
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.AmigoPurple,
            ...marginHorizontal(Space.sm),
        },
        instabox: {
            backgroundImage: `url(${imageList.SquigglyLine})`,
            height: 3,
            backgroundColor: Color.White100,
        },
    },
    title: {
        marginTop: Space.md,
        marginLeft: Space.sm,
        marginBottom: Space.lg,
        textAlign: 'left',
        ...Typography.Budbee.Web.h3,
        porterbuddy: {
            color: Color.White100,
        },
    },
    container: {
        ...paddingHorizontal(Space.lg),
        ...marginVertical(Space.lg),
        porterbuddy: {
            ...paddingHorizontal(Space.sm),
        },
    },
});
