import { StyleSheet } from '@css';
import { Color, TextStyle, FontSize, FontWeight } from '../../styles';
export const styles = StyleSheet.create({
    title: {
        ...TextStyle,
        fontWeight: FontWeight.Medium,
        fontSize: FontSize.lg,
        textAlign: 'left',
        color: Color.Black,
        margin: 0,
    },
});
