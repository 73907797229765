import { jsx as _jsx } from "react/jsx-runtime";
import { AccessModeType } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { CheckButtonOptions } from '../check-button-options';
import { TextInput } from '../shared/text-input';
import { styles } from './style';
export const SelectAccessMode = (props) => {
    const { t } = useI18n();
    const { currentAccessMode, onChangeAccessMode, doorCode, onDoorCodeChange, intercomCode, onIntercomCodeChange, intercomFirst = false, } = props;
    const brand = getBrand();
    const intercomOption = {
        label: t('accessSettings.intercom'),
        leftIcon: brand === 'instabox' ? imageList.IntercomInstabox : imageList.Intercom,
        checked: currentAccessMode === AccessModeType.INTERCOM,
        onPress: () => {
            onChangeAccessMode(AccessModeType.INTERCOM);
        },
        disabled: false,
        hideCheckButton: brand === 'porterbuddy',
        trailingComponent: currentAccessMode === AccessModeType.INTERCOM ? (_jsx(TextInput, { maxChars: 30, value: intercomCode || '', style: styles.input, placeholder: t('accessSettings.intercom'), onChange: (newCode) => onIntercomCodeChange(newCode), showCounter: false })) : null,
    };
    const howDoWeGetIn = {
        title: brand !== 'porterbuddy' ? t('accessSettings.howToAccess') : undefined,
        trailingComponent: null,
        options: [
            {
                ...intercomOption,
                shouldRender: intercomFirst,
            },
            {
                label: t('accessSettings.doorCode'),
                leftIcon: brand === 'instabox' ? imageList.DoorCodeInstabox : imageList.DoorCode,
                checked: currentAccessMode === AccessModeType.DOOR_CODE,
                onPress: () => {
                    onChangeAccessMode(AccessModeType.DOOR_CODE);
                },
                disabled: false,
                shouldRender: brand !== 'porterbuddy',
                trailingComponent: currentAccessMode === AccessModeType.DOOR_CODE ? (_jsx(TextInput, { maxChars: 30, value: doorCode || '', style: styles.input, placeholder: t('accessSettings.doorCode'), onChange: (newCode) => onDoorCodeChange(newCode) })) : null,
            },
            {
                ...intercomOption,
                shouldRender: !intercomFirst,
            },
            {
                label: t('accessSettings.freePassage'),
                leftIcon: brand === 'instabox' ? imageList.OpenDoorInstabox : imageList.OpenDoor,
                checked: currentAccessMode === AccessModeType.NONE,
                onPress: () => {
                    onChangeAccessMode(AccessModeType.NONE);
                },
                disabled: false,
                shouldRender: brand !== 'porterbuddy',
                trailingComponent: null,
            },
        ],
    };
    return _jsx(CheckButtonOptions, { data: howDoWeGetIn });
};
