import { ResponseStatus } from '@budbee/js/tracking-api';
export const SET_ETA = 'SET_ETA';
export const setEta = (eta) => ({
    type: SET_ETA,
    payload: { eta },
});
export const fetchCachedEta = (token) => (dispatch) => {
    fetch(`/api/v3/orders/${token}/eta`)
        .then((response) => (response.ok ? response.json() : {}))
        .then((response) => {
        if (response.status === ResponseStatus.COMPLETED) {
            dispatch(setEta(response.payload));
        }
    });
};
