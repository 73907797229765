import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color } from '@budbee/js/ui';
import { IconSize } from '../../styles';
import { BrandedIcon } from '../../utils/brand/icon';
import { BrandedImage } from '../../utils/brand/image';
import imageList from '../../utils/image-list';
import { refreshTrackingPage } from '../../utils/refresh-tracking-page';
import { LanguagePicker } from '../shared/language-picker';
import { View } from '../shared/view';
import { styles } from './style';
export function PageHeader({ toggleSupportModal, authenticated }) {
    return (_jsxs(View, { style: styles.root, children: [_jsx(LanguagePicker, { onChangeLanguage: refreshTrackingPage }), _jsx(BrandedImage, { alt: "logo", ...imageProps }), _jsx(View, { style: [styles.supportButton, !authenticated ? styles.supportButtonDisabled : undefined], onClick: authenticated ? toggleSupportModal : undefined, children: _jsx(BrandedIcon, { ...iconProps }) })] }));
}
const imageProps = {
    src: {
        budbee: imageList.logoGreen,
        instabox: imageList.InstaboxLogo,
        porterbuddy: imageList.PorterbuddyLogo,
    },
    style: {
        budbee: styles.logo,
        instabox: styles.logo,
        porterbuddy: styles.logoWide,
    },
};
const iconProps = {
    src: {
        budbee: imageList.Chat,
        instabox: imageList.ChatInstabox,
        porterbuddy: imageList.ChatBox,
    },
    color: {
        budbee: Color.Budbee.GreenDark,
        instabox: Color.Budbee.ReadyRed,
    },
    size: {
        budbee: IconSize['3xl'],
        instabox: IconSize['3xl'],
        porterbuddy: IconSize['7xl'],
    },
};
