import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { View } from '../shared/view';
import { styles } from './style';
export const NotFound = () => {
    const { t } = useI18n();
    const root = document.getElementById('root');
    useEffect(() => {
        if (root) {
            root.style.height = '100%';
        }
    }, [root]);
    return (_jsxs(View, { style: styles.root, children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.SadFace,
                    instabox: imageList.ParcyFailed,
                }, size: 110 }), _jsx(View, { style: styles.divider }), _jsxs(View, { style: styles.container, children: [_jsx("p", { className: css(styles.title), children: t('notFoundTitle') }), _jsx("p", { className: css(styles.subtitle), children: t('notFoundSubtitle') })] })] }));
};
