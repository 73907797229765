import { useMemo } from 'react';
import { TrackingApi } from '../server/tracking';
import { useCurrentLocale } from '../state/locale/hooks';
const createTrackingApi = (locale) => TrackingApi({
    'App-Locale': locale,
});
export function useTrackingApi() {
    const locale = useCurrentLocale();
    return useMemo(() => createTrackingApi(locale), [locale]);
}
