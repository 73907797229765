import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    footerContainer: {
        padding: Space['2xl'],
    },
    itemsContainer: {
        flexGrow: 1,
    },
    error: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 18,
    },
    button: {
        width: '100%',
    },
});
