import { Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, Spacing, marginHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    container: {
        marginTop: Spacing.sm,
    },
    greyWrapper: {
        marginTop: Spacing.sm,
        backgroundColor: '#e1e1e1',
        marginBottom: Spacing.md,
        padding: Spacing.sm,
        borderRadius: BorderRadius.xs,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: 200,
    },
    image: {
        maxWidth: '100%',
        maxHeight: 200,
        width: 'auto',
        height: 'auto',
    },
    loader: {
        marginTop: 0,
        marginBottom: 0,
    },
    progressText: {
        marginBottom: Spacing.xs,
    },
    displayNone: {
        display: 'none',
    },
    cancelButton: {
        minWidth: 'auto',
        boxShadow: 'none',
    },
    uploadButton: {
        color: Color.White100,
        backgroundColor: Color.Budbee.Green,
    },
    changeButton: {
        marginTop: Spacing.xs,
    },
    errorContainer: {
        display: 'flex',
        flexDirection: 'column',
        ...marginHorizontal(Space['2xl']),
    },
    error: {
        marginTop: Space['2xl'],
        color: Color.Budbee.ReadyRed,
        textAlign: 'center',
        marginBottom: Space.lg,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: Space['2xl'],
    },
});
