import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { MediaQuery, ScreenSizes, Transition } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        minHeight: '100vh',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: ScreenSizes.md,
        transition: Transition.Ease,
        paddingTop: Space.xl - Space['3xs'],
        porterbuddy: {
            paddingTop: Space['2xl'],
        },
    },
    innerContainer: {
        transition: Transition.Ease,
        [MediaQuery.maxWidthLg]: {
            padding: `0 ${Space.lg}px ${Space.xl}px`,
        },
        porterbuddy: {
            padding: 0,
        },
        instabox: {
            padding: 0,
        },
    },
});
