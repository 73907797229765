import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Color } from '@budbee/js/dist/ui';
import { css } from '@css';
import moment from 'moment-timezone';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { IconButton } from '../icon-button';
import { styles } from './style';
export const DateChanger = ({ date, moveForward, moveBackward, canMoveForward, canMoveBackward, }) => {
    const { i18n, language, t } = useI18n();
    const formatDate = (dateArg) => dateArg ? moment(dateArg).format('D MMMM') : null;
    const day = i18n.getLocalizedDay(moment(date), language);
    return (_jsxs("div", { className: css(styles.dateChanger), children: [_jsx(IconButton, { src: imageList.angleLeftGreen, size: IconSize.sm, color: canMoveBackward ? Color.Budbee.Grey4 : Color.Budbee.Grey2, onClick: canMoveBackward ? moveBackward : undefined, disabled: !canMoveBackward }), _jsxs("div", { className: css(styles.dateContainer), children: [_jsx("span", { className: css(styles.day), children: t(day.toLowerCase()) }), _jsx("span", { className: css(styles.date), children: formatDate(date) })] }), _jsx(IconButton, { src: imageList.angleRightGreen, size: IconSize.sm, color: canMoveForward ? Color.Budbee.Grey4 : Color.Budbee.Grey2, onClick: canMoveForward ? moveForward : undefined, disabled: !canMoveForward })] }));
};
