import { useSelector } from 'react-redux';
import { getUserSelectedLocker, getCurrentLockerIdentifier, getLockersForList, getLockersForMap, getPlaceSearchResults, getLockerSearchResults, getSelectedSearchResult, getPlaceSearchKeyword, getIsSearchingForPlace, getIsFetchingLockers, } from './selectors';
export function useUserSelectedLocker() {
    return useSelector(getUserSelectedLocker);
}
export function useCurrentLockerIdentifier() {
    return useSelector(getCurrentLockerIdentifier);
}
export function useLockersForList() {
    return useSelector(getLockersForList);
}
export function useLockersForMap() {
    return useSelector(getLockersForMap);
}
export function usePlaceSearchResults() {
    return useSelector(getPlaceSearchResults);
}
export function useLockerSearchResults() {
    return useSelector(getLockerSearchResults);
}
export function useSelectedSearchResult() {
    return useSelector(getSelectedSearchResult);
}
export function usePlaceSearchKeyword() {
    return useSelector(getPlaceSearchKeyword);
}
export function useIsSearchingForPlace() {
    return useSelector(getIsSearchingForPlace);
}
export function useIsFetchingLockers() {
    return useSelector(getIsFetchingLockers);
}
