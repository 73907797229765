import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReviewEnabled } from '../../state/features/hooks';
import { BoxOrderLocation } from '../box-order-location';
import { BoxParcelJourney } from '../box-parcel-journey';
import { Rating } from '../rating';
import { View } from '../shared/view';
import { styles } from '../box-content/style';
export const PickedupBoxContent = ({ authenticated, merchantName, merchantLogo, token, authToken, }) => {
    const reviewEnabled = useReviewEnabled();
    return (_jsxs(_Fragment, { children: [authenticated && reviewEnabled && _jsx(Rating, { token: token, authToken: authToken }), _jsx(BoxParcelJourney, { style: styles.spacing }), _jsx(View, { style: styles.spacing, children: _jsx(BoxOrderLocation, { merchantName: merchantName, logo: merchantLogo, hideLocationInfo: true }) })] }));
};
