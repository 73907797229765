import { useMemo } from 'react';
import { BoxParcelStatus, CancellationAlertLevel, ParcelStatus } from '@budbee/js/tracking';
import { useBoxOrder } from '../state/box-order/hooks';
import { useConsignment, useConspectus } from '../state/order/hooks';
import { isConsignmentCancelled } from '../utils/cancellation';
export function useIsOrderFailed() {
    const boxOrder = useBoxOrder();
    const consignment = useConsignment();
    const conspectus = useConspectus();
    const isFailed = useMemo(() => {
        var _a;
        if (boxOrder !== null) {
            const recalled = (boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.recall) ? boxOrder.recall.recalled : false;
            const cancelled = Boolean((_a = boxOrder.consignment) === null || _a === void 0 ? void 0 : _a.cancellation);
            const undelivered = boxOrder.status === BoxParcelStatus.Undelivered;
            return recalled || cancelled || undelivered;
        }
        if (conspectus && consignment) {
            if (!conspectus) {
                return false;
            }
            if (conspectus.identification.needsVerification) {
                return false;
            }
            const cancellation = consignment === null || consignment === void 0 ? void 0 : consignment.cancellation;
            const isMiss = conspectus.status.state === ParcelStatus.Miss;
            let isCancellation = false;
            if (isConsignmentCancelled(consignment)) {
                if ((cancellation === null || cancellation === void 0 ? void 0 : cancellation.level) === CancellationAlertLevel.ALERT) {
                    isCancellation = true;
                }
            }
            return isMiss || isCancellation;
        }
        return false;
    }, [boxOrder, consignment, conspectus]);
    return isFailed;
}
