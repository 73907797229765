export const replaceTrackingUrl = (token, authToken) => {
    const url = window.location.href;
    const parts = url.split('/');
    if (authToken) {
        parts[parts.length - 1] = `${token}?auth=${authToken}`;
    }
    else {
        parts[parts.length - 1] = `${token}`;
    }
    const newUrl = parts.join('/');
    window.history.replaceState({}, '', newUrl);
};
