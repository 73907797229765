import { Color as JSColor } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, BorderRadius, BorderWidth, Color, FontSize, FontWeight, marginHorizontal, paddingHorizontal, paddingVertical, Spacing, } from '../../styles';
const overlayFadeIn = {
    from: {
        backgroundColor: 'transparent',
    },
    to: {
        opacity: 1,
    },
};
const overlayFadeOut = {
    from: {
        backgroundColor: Color.Black,
        opacity: 0.5,
    },
    to: {
        backgroundColor: 'transparent',
    },
};
const liftUp = {
    '0%': { transform: 'translateY(100%)' },
    '100%': { transform: 'translateY(0)' },
};
const takeDown = {
    '0%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(100%)' },
};
export const styles = StyleSheet.create({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        pointerEvents: 'all',
    },
    overlayActive: {
        backgroundColor: Color.Black,
        opacity: 0.5,
        animationName: overlayFadeIn,
        animationDuration: '0.4s',
    },
    overlayClosed: {
        backgroundColor: 'transparent',
        animationName: overlayFadeOut,
        animationDuration: '0.4s',
    },
    content: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 'auto',
        zIndex: 100,
        backgroundColor: Color.White,
        borderTopLeftRadius: BorderRadius.md,
        borderTopRightRadius: BorderRadius.md,
        pointerEvents: 'all',
        transition: 'bottom 0.5s',
    },
    contentActive: {
        animationName: liftUp,
        animationDuration: '0.4s',
    },
    contentClosed: {
        animationName: takeDown,
        animationDuration: '0.4s',
    },
    header: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ...border(BorderWidth.sm, 'solid', Color.BorderColorLight, 'bottom'),
        color: Color.Black,
        fontSize: FontSize.lg,
        fontWeight: FontWeight.Medium,
        lineHeight: `${Spacing.xl}px`,
        ...marginHorizontal(Spacing.lg),
        paddingTop: Spacing.md,
        textAlign: 'center',
    },
    subHeader: {
        fontSize: FontSize.sm,
        fontWeight: FontWeight.Regular,
        height: 'auto',
        lineHeight: `${Spacing.md}px`,
        marginBottom: Spacing.md,
        marginTop: Spacing.md,
        textAlign: 'left',
        whiteSpace: 'normal',
    },
    actions: {
        ...paddingHorizontal(Spacing.lg),
        ...paddingVertical(Spacing.md),
    },
    popupAction: {
        alignItems: 'center',
        display: 'flex',
        height: 'auto',
        position: 'relative',
        ':first-child': {
            marginBottom: Spacing.md,
        },
    },
    imageWrapper: {
        backgroundColor: 'transparent',
        borderRadius: BorderRadius.sm,
        marginRight: Spacing.md,
        ...paddingHorizontal(Spacing.xs),
        paddingTop: Spacing.xs,
        paddingBottom: BorderWidth.sm,
    },
    image: {
        borderRadius: BorderRadius.xs,
        width: Spacing['5xl'],
        height: Spacing['5xl'],
        border: 0,
    },
    actionTitle: {
        position: 'relative',
        left: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: Color.Black,
        fontSize: FontSize.md,
        fontWeight: FontWeight.Medium,
        lineHeight: `${Spacing['2xl']}px`,
        marginRight: 2 * Spacing['6xl'],
    },
    openAppButtonBoxTracking: {
        marginRight: Spacing['6xl'],
    },
    continueOnBrowserButton: {
        backgroundColor: JSColor.Budbee.Grey1,
        ...border(BorderWidth.sm, 'solid', Color.BorderColor),
        color: Color.MediumDarkGrey,
    },
    actionButton: {
        position: 'absolute',
        right: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: Spacing['4xl'],
        fontSize: FontSize.sm,
        fontWeight: FontWeight.SemiBold,
        outline: 'none',
        ...paddingVertical(Spacing.sm),
        ...paddingHorizontal(Spacing.sm),
        textAlign: 'center',
        textTransform: 'capitalize',
        minWidth: 100,
        textDecoration: 'none',
        color: Color.White,
        pointer: 'cursor',
        backgroundColor: Color.PrimaryText,
    },
    footer: {
        alignItems: 'center',
        color: Color.Black,
        fontSize: FontSize['2xs'],
        fontWeight: FontWeight.Regular,
        lineHeight: Spacing.md,
        ...marginHorizontal(Spacing.md),
        ...paddingVertical(Spacing.sm),
        textAlign: 'center',
    },
});
