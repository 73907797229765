import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useToken } from '../../state/order/hooks';
import { formatLockerOpenHours } from '../../utils/date';
import imageList from '../../utils/image-list';
import { ChangeLockerBadge } from '../change-locker-badge';
import { DeliveryInformation } from '../delivery-information';
import { JointLockerBadge } from '../joint-locker-badge';
import { View } from '../shared/view';
import { styles } from './style';
export function BoxOrderLocation({ logo, merchantName, lockerName, openHours, isReturn = false, toggleLocationModal, hideLocationInfo = false, children, toggleLockerPickerModal, }) {
    const { t } = useI18n();
    const token = useToken();
    const dates = openHours ? formatLockerOpenHours({ openHours }) : null;
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('parcelInformation') }), _jsxs(View, { style: styles.root, children: [_jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { topText: t('boxDelivery'), middleText: merchantName, leftIcon: imageList.Box, rightIcon: logo, rightIconType: "merchantLogo" }) }), !hideLocationInfo && lockerName ? (_jsxs(_Fragment, { children: [_jsx(View, { style: styles.divider }), _jsx(View, { style: styles.container, children: _jsxs(DeliveryInformation, { topText: t(isReturn ? 'returnAt' : 'pickupAt'), middleText: lockerName, leftIcon: imageList.Locker, rightIcon: imageList.chevronRight, rightIconType: "icon", onClick: toggleLocationModal, children: [_jsx(JointLockerBadge, {}), toggleLockerPickerModal && (_jsx(ChangeLockerBadge, { toggleLockerPickerModal: toggleLockerPickerModal }))] }) }), _jsx(View, { style: styles.divider }), _jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { topText: t('openingHours'), middleText: dates ? t('openBetween', { date: dates }) : t('closedToday'), leftIcon: imageList.Clock, rightIconType: "hidden", onClick: toggleLocationModal }) })] })) : null, _jsx(View, { style: styles.divider }), _jsx(View, { style: styles.container, children: _jsx(DeliveryInformation, { topText: t('parcelIdTitle'), middleText: token, leftIcon: imageList.Fingerprint, rightIconType: "hidden" }) }), children] })] }));
}
