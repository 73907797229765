import { useEffect } from 'react';
import { AccessModeType } from '@budbee/js/tracking';
import { useDispatch } from 'react-redux';
import { useTrackingApi } from '../../../../hooks/use-tracking-api';
import { updateAccessSettings } from '../../../../state/order/actions';
import { useAuthToken, useToken } from '../../../../state/order/hooks';
export function useOnContinue({ onContinue, state, }) {
    const trackingApi = useTrackingApi();
    const token = useToken();
    const authToken = useAuthToken();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!authToken || !token) {
            return;
        }
        onContinue((next, onError) => {
            trackingApi
                .setAllAccessPreferences(token, authToken, getValueFromState(state))
                .call()
                .then(() => {
                const { accessMode, intercom, doorCode, ...rest } = state;
                dispatch(updateAccessSettings({
                    ...rest,
                    accessMode: getAccessModeValue({
                        accessMode,
                        intercom,
                        doorCode,
                    }),
                }));
                next();
            })
                .catch(onError);
        });
    }, [state]);
}
function getValueFromState({ estateType, accessMode, doorCode, intercom, floor, careOf, instructions, }) {
    return {
        estateType: {
            value: estateType,
        },
        accessMode: getAccessModeValue({
            accessMode,
            intercom,
            doorCode,
        }),
        floor: {
            value: floor,
        },
        careOf: {
            value: careOf,
        },
        specialInstructions: {
            value: instructions,
        },
    };
}
function getAccessModeValue({ accessMode, intercom, doorCode, }) {
    if (accessMode === AccessModeType.INTERCOM) {
        return {
            type: accessMode,
            value: intercom,
        };
    }
    if (accessMode === AccessModeType.DOOR_CODE) {
        return {
            type: accessMode,
            value: doorCode,
        };
    }
    return {
        type: accessMode !== null && accessMode !== void 0 ? accessMode : AccessModeType.NONE,
        value: null,
    };
}
