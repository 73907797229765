import { jsx as _jsx } from "react/jsx-runtime";
import { BoxParcelStatus } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { OrderHero } from '../order-hero';
const useBoxReturnHeaderContent = (status, merchantName) => {
    const { t } = useI18n();
    switch (status) {
        case BoxParcelStatus.DroppedOff:
            return {
                header: t('boxReturn.droppedOffHeader'),
                subHeader: t('boxReturn.droppedOffSubHeader'),
            };
        case BoxParcelStatus.Collected:
            return {
                header: t('boxReturn.collectedHeader'),
                subHeader: t('boxReturn.collectedSubHeader'),
            };
        case BoxParcelStatus.ReturnedToTerminal:
            return {
                header: t('boxReturn.returnedToTerminalHeader', { merchantName }),
                subHeader: t('boxReturn.returnedToTerminalSubHeader', {
                    merchantName,
                }),
            };
        case BoxParcelStatus.ReturnedToMerchant:
            return {
                header: t('boxReturn.returnedToMerchantHeader', { merchantName }),
                subHeader: '',
            };
        default:
            return {
                header: t('boxReturn.droppedOffHeader'),
                subHeader: t('boxReturn.droppedOffSubHeader'),
            };
    }
};
const BoxReturnHeader = ({ status, merchantName }) => {
    const { header, subHeader } = useBoxReturnHeaderContent(status, merchantName);
    return _jsx(OrderHero, { middleText: header, bottomText: subHeader });
};
export const ReturnBookedBoxHeader = ({ status, merchantName, }) => {
    return _jsx(BoxReturnHeader, { merchantName: merchantName, status: status });
};
