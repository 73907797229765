import { createReducer } from '../../utils/create-reducer';
import { REQUEST_LOCKERS, REQUEST_LOCKERS_ERROR, REQUEST_LOCKERS_FOR_MAP, REQUEST_LOCKERS_FOR_MAP_ERROR, REQUEST_LOCKERS_FOR_MAP_SUCCESS, REQUEST_LOCKERS_SUCCESS, RESET_LOCKERS_STATE, RESET_LOCKER_SEARCH_STATE, SELECT_LOCKER, SELECT_LOCKER_FROM_MAP, SET_IS_PICKING_FROM_MAP, SET_IS_SEARCHING_FOR_PLACES, SET_LOCKERS_FOR_SEARCHED_PLACE, SET_PLACE_SEARCH_RESULTS, SET_SEARCH_PLACE_KEYWORD, SET_SELECTED_SEARCH_RESULT, } from './actions';
const initState = {
    isFetching: false,
    isReloading: false,
    userSelectedLocker: null,
    userSelectedMapLocker: null,
    lockers: [],
    mapLockers: [],
    isPickingFromMap: false,
    success: false,
    error: null,
    isSearchingForPlace: false,
    placeSearchKeyword: '',
    placeSearchResults: [],
    lockerSearchResults: [],
    selectedSearchResult: null,
};
export const lockers = createReducer(initState, {
    [REQUEST_LOCKERS]: (state = initState) => ({
        ...state,
        isFetching: true,
        error: null,
        userSelectedLocker: null,
        userSelectedMapLocker: null,
    }),
    [REQUEST_LOCKERS_FOR_MAP]: (state = initState) => ({
        ...state,
        isFetching: true,
        error: null,
        userSelectedLocker: null,
        userSelectedMapLocker: null,
    }),
    [REQUEST_LOCKERS_SUCCESS]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        error: null,
        lockers: action.payload.lockers,
    }),
    [REQUEST_LOCKERS_FOR_MAP_SUCCESS]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        error: null,
        mapLockers: action.payload.lockers,
    }),
    [REQUEST_LOCKERS_ERROR]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        error: action.payload.error,
    }),
    [REQUEST_LOCKERS_FOR_MAP_ERROR]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        error: action.payload.error,
    }),
    [SELECT_LOCKER]: (state = initState, action) => ({
        ...state,
        userSelectedLocker: action.payload.id,
    }),
    [SELECT_LOCKER_FROM_MAP]: (state = initState, action) => ({
        ...state,
        userSelectedMapLocker: action.payload.lockerDetails,
    }),
    [SET_IS_SEARCHING_FOR_PLACES]: (state = initState, action) => ({
        ...state,
        isSearchingForPlace: action.payload.isSearching,
    }),
    [SET_SEARCH_PLACE_KEYWORD]: (state = initState, action) => ({
        ...state,
        placeSearchKeyword: action.payload.keyword,
    }),
    [SET_PLACE_SEARCH_RESULTS]: (state = initState, action) => ({
        ...state,
        placeSearchResults: action.payload.placeSearchResults,
    }),
    [SET_LOCKERS_FOR_SEARCHED_PLACE]: (state = initState, action) => ({
        ...state,
        lockerSearchResults: action.payload.lockerSearchResults,
    }),
    [SET_SELECTED_SEARCH_RESULT]: (state = initState, action) => ({
        ...state,
        selectedSearchResult: action.payload.selectedSearchResult,
    }),
    [SET_IS_PICKING_FROM_MAP]: (state = initState, action) => ({
        ...state,
        userSelectedLocker: null,
        userSelectedMapLocker: null,
        isPickingFromMap: action.payload.isPickingFromMap,
    }),
    [RESET_LOCKERS_STATE]: (state = initState) => ({
        ...state,
        userSelectedLocker: null,
        isPickingFromMap: false,
        lockers: [],
        mapLockers: [],
        isSearchingForPlace: false,
        placeSearchKeyword: '',
        placeSearchResults: [],
        lockerSearchResults: [],
        selectedSearchResult: null,
    }),
    [RESET_LOCKER_SEARCH_STATE]: (state = initState) => ({
        ...state,
        isSearchingForPlace: false,
        placeSearchKeyword: '',
        placeSearchResults: [],
        lockerSearchResults: [],
        selectedSearchResult: null,
    }),
});
