import { Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    header: {
        marginBottom: Space.sm,
        marginTop: 0,
    },
    link: {
        display: 'block',
        marginTop: Space.sm,
        textDecoration: 'none',
        color: Color.Budbee.GreenDark,
        porterbuddy: {
            color: Color.Porterbuddy.BuddyPurple,
        },
        instabox: {
            color: Color.Instabox.ReallyRed,
        },
    },
    externalLink: {
        wordBreak: 'break-word',
    },
    button: { width: '100%', marginTop: Space.md },
});
