import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ParcelStatus } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { View } from '../shared/view';
import { styles } from './style';
export const TimetableInfo = ({ isReturn, merchantName, orderStatus }) => {
    const { t } = useI18n();
    const missTextTitle = isReturn
        ? t('rebook.failed.return.title')
        : t('rebook.failed.delivery.title');
    const missTextInstructions = isReturn
        ? t('rebook.failed.return.instructions', { merchant: merchantName })
        : t('rebook.failed.delivery.instructions', { merchant: merchantName });
    if (orderStatus === ParcelStatus.Delivered) {
        return (_jsx(View, { style: styles.header, children: _jsx("h3", { className: css(styles.text), children: t('bookReturnTitle') }) }));
    }
    if (orderStatus === ParcelStatus.Miss) {
        return (_jsxs(View, { style: styles.header, children: [_jsx("h3", { className: css(styles.text), children: missTextTitle }), _jsx("p", { className: css(styles.black), children: missTextInstructions })] }));
    }
    return (_jsx(View, { style: styles.header, children: _jsx("h3", { className: css(styles.text), children: t('upgrade.deliveryTime.title') }) }));
};
