import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { css } from '@css';
import { getBrand } from '../../utils/brand/get-brand';
import { MerchantLogo } from '../merchant-logo';
import { AnimatedView } from '../shared/animated-view';
import { View } from '../shared/view';
import { styles } from './style';
export function ParcelJourneyStep({ topText, middleText, bottomText, iconComponent, hideLine, image, index = 0, variant = 'default', }) {
    const brand = getBrand();
    const lineStyle = useMemo(() => {
        if (brand === 'instabox') {
            return styles.lineRed;
        }
        if (brand === 'porterbuddy') {
            return styles.linePurple;
        }
        if (variant === 'default') {
            return styles.lineInactive;
        }
        if (variant === 'failed') {
            return styles.lineFailed;
        }
        return undefined;
    }, [brand, variant]);
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.leftContainer, children: [_jsx(View, { style: styles.iconContainer, children: iconComponent }), !hideLine && (_jsx(AnimatedView, { animation: "fadeIn", delay: index * 3 + 3, contentContainerStyle: css(styles.flex), children: _jsx(View, { style: [styles.line, lineStyle] }) }))] }), _jsxs(View, { style: styles.rightContainer, children: [topText ? _jsx("span", { className: css(styles.topText), children: topText }) : null, middleText ? _jsx("span", { className: css(styles.middleText), children: middleText }) : null, typeof bottomText === 'function' ? (_jsx("div", { className: css(styles.bottomText), children: bottomText() })) : null, typeof bottomText === 'string' ? (_jsx("span", { className: css([
                            styles.bottomText,
                            variant === 'default' ? styles.descriptionInactive : undefined,
                        ]), children: bottomText })) : null] }), _jsx(ParcelJourneyImage, { image: image })] }));
}
function ParcelJourneyImage({ image }) {
    if (!image) {
        return null;
    }
    return (_jsx(View, { style: styles.imageContainer, children: _jsx(MerchantLogo, { logoUrl: image, size: 36, variant: "round" }) }));
}
