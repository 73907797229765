import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { View } from '../shared/view';
import { styles } from './style';
export const UnauthorizedOrder = () => {
    const { t } = useI18n();
    return (_jsxs(View, { style: styles.root, children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.InfoFilledRed,
                    instabox: imageList.InfoFilledRed,
                    porterbuddy: imageList.InfoFilledPurple,
                }, size: IconSize['3xl'] }), _jsx("span", { className: css(styles.text), children: t('unauthorizedTrackingBox.message') })] }));
};
