import { jsx as _jsx } from "react/jsx-runtime";
import { useDispatch } from 'react-redux';
import { DeliverySettings } from '../../../components/preface/delivery-settings';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useLanguage } from '../../../state/locale/hooks';
import { updateDeliverySettings } from '../../../state/order/actions';
import { useAuthToken, useConspectus, useIsReturn, useToken } from '../../../state/order/hooks';
export const DeliverySettingsContainer = (props) => {
    const token = useToken();
    const authToken = useAuthToken();
    const isReturn = useIsReturn();
    const language = useLanguage();
    const conspectus = useConspectus();
    const dispatch = useDispatch();
    const trackingApi = useTrackingApi();
    if (!conspectus || !authToken) {
        return null;
    }
    const orderStatus = conspectus.status.state;
    const setDeliveryExecution = (method, notification, alternativeLeaveWithNeighbour, neighbours) => {
        return trackingApi
            .setDeliveryExecution(authToken, token, method, notification, alternativeLeaveWithNeighbour, neighbours)
            .call()
            .then(() => {
            dispatch(updateDeliverySettings(method, notification, alternativeLeaveWithNeighbour, neighbours));
        });
    };
    return (_jsx(DeliverySettings, { token: token, conspectus: conspectus, isReturn: isReturn, language: language, orderStatus: orderStatus, ...props, authToken: authToken, setDeliveryExecution: setDeliveryExecution }));
};
