import { Border, Color, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing } from '../../styles';
export const styles = StyleSheet.create({
    instructionItem: {
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.sm,
        padding: Spacing['2xl'],
        cursor: 'pointer',
        userSelect: 'none',
    },
    text: {
        ...Typography.Budbee.Web['body-md'],
        margin: 0,
    },
});
