import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { languages, locale } from '@budbee/js/tracking';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import useLastValueComparer from '../../../hooks/use-last-value-comparer';
import { useCountryCode, useLanguage } from '../../../state/locale/hooks';
import { useIsReloading } from '../../../state/order/hooks';
import { IconSize } from '../../../styles';
import { BrandedIcon } from '../../../utils/brand/icon';
import { getFlagEmoji } from '../../../utils/flags';
import imageList from '../../../utils/image-list';
import { getPersistedPreferredLanguage, persistPreferredLanguage } from '../../../utils/locale';
import { Loader } from '../loader';
import { View } from '../view';
import { styles } from './style';
export const LanguagePicker = ({ onChangeLanguage }) => {
    const isReloading = useIsReloading();
    const countryCode = useCountryCode();
    const language = useLanguage();
    const countryCodeFromLanguage = locale.countryCodeForLanguageOrEN(language);
    const defaultOrderLanguage = locale.defaultLanguageForCountry(countryCode);
    const preferredLanguage = getPersistedPreferredLanguage();
    const [loading, setLoading] = useState(false);
    const [showLang, setShowLang] = useState(false);
    const [currentLang, setCurrentLang] = useState();
    useEffect(() => {
        const languageToUpdateTo = preferredLanguage || defaultOrderLanguage;
        setCurrentLang(locale.getLocalizedLanguageName(languageToUpdateTo));
        setShowLang(true);
        setTimeout(() => {
            setShowLang(false);
        }, 4000);
    }, []);
    const stoppedReloading = useLastValueComparer(isReloading, (wasReloading) => wasReloading && !isReloading);
    useEffect(() => {
        if (stoppedReloading) {
            setLoading(false);
        }
    }, [isReloading]);
    const flag = getFlagEmoji(countryCodeFromLanguage);
    const onClick = useCallback(() => {
        // Set default order language if user has already persisted English.
        // I.e. change back to default language if user is already has switched to english.
        const languageToUpdateTo = preferredLanguage ? defaultOrderLanguage : languages.ENGLISH;
        setCurrentLang(locale.getLocalizedLanguageName(languageToUpdateTo));
        setLoading(true);
        setShowLang(true);
        persistPreferredLanguage(languageToUpdateTo);
        onChangeLanguage();
        setTimeout(() => {
            setShowLang(false);
        }, 4000);
    }, [defaultOrderLanguage, onChangeLanguage, preferredLanguage]);
    return (_jsxs(View, { style: styles.root, children: [loading ? (_jsx(Loader, { variant: "inline", style: styles.loader })) : (_jsx(View, { style: styles.globe, onClick: onClick, children: _jsx(BrandedIcon, { src: {
                        budbee: imageList.Globe,
                        instabox: imageList.Globe,
                        porterbuddy: imageList.GlobePorterbuddy,
                    }, size: IconSize['3xl'], color: {
                        budbee: Color.Budbee.GreenDark,
                        instabox: Color.Budbee.ReadyRed,
                        porterbuddy: Color.Porterbuddy.BuddyPurple,
                    } }) })), showLang && !loading ? (_jsxs("div", { className: css(styles.langContainer), children: [_jsx("div", { children: flag }), _jsx("div", { className: css(styles.lang), children: currentLang })] })) : null] }));
};
