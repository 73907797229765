import { locale as jsLocale } from '@budbee/js/tracking';
import { getPersistedPreferredLanguage, setMomentLocale } from '../../utils/locale';
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE';
export const SET_DEFAULT_LOCALE = 'SET_DEFAULT_LOCALE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const setCurrentLocale = (locale) => {
    setMomentLocale(locale.slice(0, 2));
    return {
        type: SET_CURRENT_LOCALE,
        payload: { locale },
    };
};
export const setDefaultLocale = (locale) => ({
    type: SET_DEFAULT_LOCALE,
    payload: { locale },
});
export const setLanguage = (language) => ({
    type: SET_LANGUAGE,
    payload: { language },
});
export const setCountry = (country) => ({
    type: SET_COUNTRY,
    payload: { country },
});
export const setTimeZone = (timeZone) => ({
    type: SET_TIMEZONE,
    payload: { timeZone },
});
export const setLocaleForRedirect = (countryCode) => (dispatch) => {
    const persistedPreferredLanguage = getPersistedPreferredLanguage();
    const language = persistedPreferredLanguage || jsLocale.defaultLanguageForCountry(countryCode);
    dispatch(setCurrentLocale(`${language}-${countryCode}`));
    dispatch(setLanguage(language));
};
