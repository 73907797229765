import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { DropShadow } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'row',
        padding: Space.lg,
        borderRadius: Border.Radius.sm,
        backgroundColor: Color.White100,
        ...DropShadow,
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    closeIconButtonContainer: {
        position: 'relative',
        padding: 0,
        marginLeft: 'auto',
        marginRight: -Space.lg,
        marginTop: -Space.sm,
        right: Space.sm,
        borderWidth: 0,
    },
    title: {
        ...Typography.Budbee.Web.h4,
    },
    text: {
        marginTop: Space.sm,
        ...Typography.Budbee.Web['body-md'],
    },
});
