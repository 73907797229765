import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { css } from '@css';
import { useClickDetector } from '../../hooks/use-click-detector';
import { useI18n } from '../../hooks/use-i18n';
import { Loader } from '../shared/loader';
import { LocationSearchItem } from '../shared/location-search-item';
import { SearchBar } from '../shared/search-bar';
import { styles } from './style';
export const SearchGroup = (props) => {
    const { placeSearchResults, loading, successfulRequest = true, keyword, onSearchResultItemClick, setFocused, isFocused, } = props;
    const { t } = useI18n();
    const searchBarRef = useRef(null);
    useClickDetector(searchBarRef, () => {
        setFocused(false);
    }, () => {
        setFocused(true);
    }, ['search-bar-clear-button', 'search-bar-submit-button']);
    const requestStateWrapper = (component) => (_jsxs("div", { className: css(styles.loadingIndicatorWrapper), children: [" ", component, " "] }));
    const resultsItems = placeSearchResults === null || placeSearchResults === void 0 ? void 0 : placeSearchResults.map((feature) => (_jsx(LocationSearchItem, { feature: feature, onClick: () => {
            onSearchResultItemClick(feature);
            setFocused(false);
        } }, feature.id)));
    const LoadingAndErrorIndicators = () => {
        if (loading) {
            return requestStateWrapper(_jsxs(_Fragment, { children: [_jsx(Loader, { style: styles.loadingIndicator }), _jsx("p", { className: css(styles.loadingIndicatorText), children: t('loading') })] }));
        }
        if (!successfulRequest) {
            return requestStateWrapper(_jsx("p", { className: css(styles.searchResultsMessage), children: t('generalErrorMessage') }));
        }
        return requestStateWrapper(_jsx("p", { className: css(styles.searchResultsMessage), children: t(keyword && keyword.length > 0
                ? 'lockersSearchResultsTitle.NoResultsMessage'
                : 'lockersSearchResultsTitle.SearchPlaceLongMessage') }));
    };
    return (_jsxs("div", { className: css(styles.wrapper), children: [isFocused && _jsx("div", { className: css(styles.wrapperDarkUnderlay) }), _jsxs("div", { ref: searchBarRef, children: [_jsx(SearchBar, { ...props }), isFocused && (_jsx("div", { className: css(styles.searchResultsWrapper), children: !loading &&
                            successfulRequest &&
                            resultsItems &&
                            resultsItems.length > 0 &&
                            keyword &&
                            keyword.length > 0 ? (_jsx("div", { className: css(styles.searchResultsList), children: resultsItems })) : (_jsx(LoadingAndErrorIndicators, {})) }))] })] }));
};
