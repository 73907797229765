import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { css } from '@css';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { Modal } from '..';
import { nextStep, prevStep, showSteps } from '../../../state/preface/actions';
import { useStep } from '../../../state/preface/hooks';
import { animations } from '../../../utils/animations';
import { Step } from '../../step';
import { styles } from './style';
export function MultiStepModal({ steps, authToken, headerTitle, onClose, onOnFirstStepBackPress, }) {
    const dispatch = useDispatch();
    const currentStep = useStep();
    const [delta, setDelta] = useState(1);
    const onNextStep = useCallback(() => {
        if (currentStep === steps.length - 1) {
            dispatch(showSteps(false));
            return;
        }
        setDelta(1);
        dispatch(nextStep());
    }, [currentStep, dispatch, steps.length]);
    const onPrevStep = useCallback(() => {
        if (currentStep === 0) {
            onOnFirstStepBackPress === null || onOnFirstStepBackPress === void 0 ? void 0 : onOnFirstStepBackPress();
            return;
        }
        setDelta(-1);
        if (currentStep !== 0) {
            dispatch(prevStep());
        }
    }, [currentStep, dispatch, onOnFirstStepBackPress]);
    const step = steps[currentStep];
    if (!step)
        return null;
    const { footer, header, component: Component, props: componentProps, canCancel } = step;
    return (_jsx(Step, { onNext: onNextStep, onPrevious: onPrevStep, footer: footer, children: ({ canContinueChanged, onContinue, onNext, onPrevious, footer: stepFooter }) => {
            return (_jsx(Modal, { onClose: canCancel ? onClose : undefined, header: header, footer: stepFooter, headerTitle: headerTitle, children: _jsx(AnimatePresence, { children: _jsx("div", { className: css(styles.formContainer), children: _jsx(motion.div, { initial: "entering", animate: "still", exit: "exiting", custom: { delta }, variants: animations.slideAnimation, transition: animations.slideAnimationTransition, children: _jsx(Component, { ...componentProps, authToken: authToken, onContinue: onContinue, onNext: onNext, onPrevious: onPrevious, closeModal: onClose, canContinueChanged: canContinueChanged }) }, currentStep) }) }) }));
        } }));
}
