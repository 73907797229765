import { useCallback } from 'react';
import { i18n } from '@budbee/js/tracking';
import { useTranslation } from 'react-i18next';
import translations from '../../translations.json';
export const useI18n = () => {
    const i18next = useTranslation();
    const changeLanguage = useCallback((...args) => {
        i18next.i18n.changeLanguage(...args);
    }, [i18next.i18n]);
    return {
        language: i18next.i18n.language,
        t: i18next.t,
        changeLanguage,
        i18n: i18n(translations),
    };
};
