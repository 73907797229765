import { Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Color, FontSize, FontWeight, marginVertical, Spacing, TextStyle } from '../../../styles';
export const styles = StyleSheet.create({
    loader: {
        marginTop: 200,
    },
    title: {
        ...TextStyle,
        fontWeight: FontWeight.SemiBold,
        fontSize: FontSize.lg,
        color: Color.Black,
        ...marginVertical(Spacing.sm),
    },
    subtitle: {
        ...TextStyle,
        fontWeight: FontWeight.Light,
        fontSize: FontSize.sm,
        marginBottom: Spacing.lg,
        textAlign: 'left',
    },
    p: {
        ...Typography.Budbee.Web['body-md'],
        porterbuddy: {
            display: 'none',
        },
    },
});
