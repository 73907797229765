import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import mapboxgl from 'mapbox-gl';
import { Color, IconSize } from '../../styles';
import { envConfig } from '../../utils/env.config';
import imageList from '../../utils/image-list';
import * as latlng from '../../utils/latlng';
import { Icon } from '../shared/icon';
import { styles } from './style';
const MANUAL_CAMERA_TIMEOUT = 30000;
const createMarker = (coordinate, marker, map) => {
    const content = document.createElement('img');
    content.src = marker;
    content.width = 24;
    content.style.cursor = 'pointer';
    content.style.position = 'relative';
    content.style.width = '30px';
    content.style.height = '60px';
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.top = '30px';
    container.appendChild(content);
    return new mapboxgl.Marker(container, { offset: [0, -50 / 2] })
        .setLngLat(latlng.mapboxCoordinate(coordinate))
        .addTo(map);
};
const fitMapTo = (map, position) => {
    try {
        map.flyTo({ center: position, zoom: 15 });
    }
    catch (err) {
        Sentry.captureException(err);
    }
};
export const LockerMap = ({ onClose, coordinate, style }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef();
    const markerRef = useRef(null);
    const autoFit = useRef(false);
    const manualEventId = useRef();
    const handleManualMapEvent = useCallback(() => {
        autoFit.current = false;
        if (manualEventId.current) {
            clearTimeout(manualEventId.current);
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
        manualEventId.current = setTimeout(() => {
            autoFit.current = true;
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            fitMapTo(mapRef.current, markerRef.current.getLngLat());
        }, MANUAL_CAMERA_TIMEOUT);
    }, [autoFit]);
    const initMap = useCallback(() => {
        mapRef.current = new mapboxgl.Map({
            container: (mapContainerRef === null || mapContainerRef === void 0 ? void 0 : mapContainerRef.current) || '',
            style: envConfig.MAPBOX_LOCKERS_MAP_STYLE,
            center: latlng.mapboxCoordinate(coordinate),
            zoom: 15,
        });
        // @ts-expect-error ts-migrate(2531) FIXME: Remove this comment to see the full error message
        markerRef.current = createMarker(coordinate, imageList.pin, mapRef.current);
        mapRef.current.on('move', () => {
            handleManualMapEvent();
        });
    }, [coordinate, handleManualMapEvent]);
    useEffect(() => {
        setTimeout(() => initMap(), 400);
    }, [initMap]);
    return (_jsxs("div", { children: [typeof onClose === 'function' ? (_jsx("button", { onClick: onClose, type: "button", className: css(styles.closeIcon), children: _jsx(Icon, { src: imageList.Close, color: Color.Black, size: IconSize['2xs'] }) })) : null, _jsx("div", { ref: mapContainerRef, className: css(styles.map), style: style })] }));
};
