import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal, paddingVertical } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        ...paddingHorizontal(Space['2xl']),
        ...paddingVertical(Space['3xl']),
        position: 'relative',
        justifyContent: 'center',
        zIndex: 0,
    },
    textContainer: {
        justifyContent: 'center',
        width: '100%',
        zIndex: 1,
    },
    topText: {
        ...Typography.Porterbuddy.Web.h5,
        color: Color.Porterbuddy.BuddyPurple,
        marginTop: 0,
        marginBottom: Space.md,
        textAlign: 'left',
    },
    middleText: {
        ...Typography.Porterbuddy.Web.h1,
        marginTop: 0,
        marginBottom: Space.lg,
        color: Color.Porterbuddy.Grey4,
        textAlign: 'left',
    },
    badgeContainer: {
        flexDirection: 'row',
    },
    badge: {
        backgroundColor: Color.Porterbuddy.PalPurple,
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        marginTop: Space.md,
        position: 'relative',
        alignSelf: 'flex-start',
    },
    badgeText: {
        ...Typography.Porterbuddy.Web.h5,
        color: Color.Porterbuddy.BuddyPurple,
        margin: 0,
    },
    triangle: {
        borderBottom: `15px solid ${Color.Porterbuddy.PalPurple}`,
        borderLeft: '15px solid transparent',
        borderRight: '15px solid transparent',
        width: 0,
        height: 0,
        transform: 'rotate(90deg) translateX(6px) translateY(8px)',
        alignSelf: 'center',
    },
    bottomText: {
        ...Typography.Porterbuddy.Web.h4,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 0,
        color: Color.Porterbuddy.Grey4,
        textAlign: 'left',
    },
    margin: {
        marginTop: -Space.lg,
    },
});
