import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, MediaQuery, paddingHorizontal, paddingVertical } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        backgroundColor: Color.White100,
        borderRadius: BorderRadius.md,
        ...paddingHorizontal(Space['2xl']),
        ...paddingVertical(Space['3xl']),
        position: 'relative',
        justifyContent: 'center',
        zIndex: 0,
    },
    textContainer: {
        justifyContent: 'center',
        width: '100%',
        zIndex: 1,
    },
    topText: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.Grey4,
        marginTop: 0,
        marginBottom: Space.md,
        textAlign: 'left',
    },
    middleText: {
        ...Typography.Budbee.Web.h2,
        fontSize: 24,
        marginTop: 0,
        marginBottom: Space.md,
        color: Color.Budbee.Grey5,
        textAlign: 'left',
        [MediaQuery.maxWidthMd]: {
            ...Typography.Budbee.Web.h2,
            marginBottom: Space.sm,
        },
    },
    badge: {
        backgroundColor: Color.Budbee.GreenLight,
        borderRadius: Border.Radius.sm,
        padding: Space.sm,
        marginBottom: Space.md,
        alignSelf: 'flex-start',
    },
    badgeText: {
        ...Typography.Budbee.Web['badge-sm'],
        color: Color.Budbee.GreenDark,
        margin: 0,
    },
    bottomText: {
        ...Typography.Budbee.Web.h4,
        fontWeight: 400,
        marginTop: 0,
        marginBottom: 0,
        color: Color.Budbee.Grey6,
        textAlign: 'left',
    },
    icon: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        borderBottomRightRadius: BorderRadius.md,
        zIndex: 0,
    },
});
