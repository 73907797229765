import { jsx as _jsx } from "react/jsx-runtime";
import { Contact } from '../../components/modal/contact';
import { useAuthenticated, useConspectus, useToken } from '../../state/order/hooks';
import { localizeMerchantName } from '../../utils/locale';
// Only used for Home Deliveries,
// check box-support-container for the box props
export const ContactContainer = ({ authToken }) => {
    const authenticated = useAuthenticated();
    const conspectus = useConspectus();
    const token = useToken();
    if (!conspectus) {
        return null;
    }
    return (_jsx(Contact, { authenticated: authenticated, authToken: authToken, consumer: conspectus.consumer, orderToken: token, countryCode: conspectus.address.countryCode, supportPhone: conspectus.merchant.supportPhone, supportEmail: conspectus.merchant.supportEmail, supportLink: conspectus.merchant.supportLink, merchantName: localizeMerchantName(conspectus.merchant.name, conspectus.address.countryCode) }));
};
