export const SET_PARCEL_DIMENSIONS = 'SET_PARCEL_DIMENSIONS';
export const SET_RETURN_LOCKER = 'SET_RETURN_LOCKER';
export const SET_RETURN_TYPE = 'SET_RETURN_TYPE';
export const SET_RETURN_PARCEL_COUNT = 'SET_RETURN_PARCEL_COUNT';
export const SET_RETURN_SAME_PACKAGE = 'SET_RETURN_SAME_PACKAGE';
export const setParcelDimensions = (dimensions) => ({
    type: SET_PARCEL_DIMENSIONS,
    payload: { dimensions },
});
export const setReturnLocker = (locker) => ({
    type: SET_RETURN_LOCKER,
    payload: { locker },
});
export const setReturnType = (returnType) => ({
    type: SET_RETURN_TYPE,
    payload: { returnType },
});
export const setReturnParcelCount = (count) => ({
    type: SET_RETURN_PARCEL_COUNT,
    payload: { count },
});
export const setReturnSamePackage = (samePackage) => ({
    type: SET_RETURN_SAME_PACKAGE,
    payload: { samePackage },
});
