import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { css } from '@css';
import Lightbox from 'react-image-lightbox';
import { styles } from './style';
export const Thumbnail = ({ src, alt }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(!isOpen);
    const img = (_jsx("button", { type: "button", onClick: onOpen, className: css(styles.btn), children: _jsx("img", { src: src, alt: alt, className: css(styles.imgStyles) }) }));
    return (_jsxs(_Fragment, { children: [img, isOpen && _jsx(Lightbox, { mainSrc: src, onCloseRequest: onOpen })] }));
};
