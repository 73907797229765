import { useSelector } from 'react-redux';
import { getCurrentStep, getCurrentSteps, getStep, getSteps, getStepsVisible } from './selectors';
export function useCurrentStep() {
    return useSelector(getCurrentStep);
}
export function useCurrentSteps() {
    return useSelector(getCurrentSteps);
}
export function useStepsVisible() {
    return useSelector(getStepsVisible);
}
export function useStep() {
    return useSelector(getStep);
}
export function useSteps() {
    return useSelector(getSteps);
}
