import { PrefaceId } from '@budbee/js/tracking';
export const promptPayment = (steps, prefaceSteps, prompt, reloadOrderType) => {
    const hasPayment = steps.some((step) => step.id === PrefaceId.PAYMENT);
    if (prompt) {
        if (!hasPayment) {
            const paymentStep = prefaceSteps === null || prefaceSteps === void 0 ? void 0 : prefaceSteps.find((step) => (step === null || step === void 0 ? void 0 : step.id) === PrefaceId.PAYMENT);
            if (paymentStep) {
                return {
                    steps: steps.concat({
                        ...paymentStep,
                        props: { reloadOrderType },
                        prompt: true,
                    }),
                    keepIndex: true,
                };
            }
        }
    }
    else if (hasPayment) {
        return { steps: steps.filter((step) => step.id !== PrefaceId.PAYMENT) };
    }
    return { steps: null };
};
