import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState, useRef } from 'react';
import { hooks, date, timetable, utils, } from '@budbee/js/tracking';
import { DateChanger } from '../date-changer';
import { View } from '../shared/view';
import { TimeWindowItem } from '../time-window-item';
import { styles } from './style';
export const TimeWindowSelector = (props) => {
    const { deliveryWindow, countryCode, onChange, value, timetableTimeWindows, days } = props;
    const timeWindowsIds = useRef(new WeakMap());
    const onChangeCached = useCallback((valueArg) => onChange(valueArg), [onChange]);
    const defaultDayIndex = deliveryWindow
        ? days.findIndex((day) => day === date.formatTimeSlotDate(deliveryWindow.start))
        : 0;
    const [currentDay, setCurrentDay] = useState(Math.max(0, defaultDayIndex));
    const [list, setList] = useState((timetableTimeWindows === null || timetableTimeWindows === void 0 ? void 0 : timetableTimeWindows.length) > 0
        ? timetableTimeWindows.filter(timetable.applyFilter(days[currentDay]))
        : []);
    useEffect(() => {
        setList((timetableTimeWindows === null || timetableTimeWindows === void 0 ? void 0 : timetableTimeWindows.length) > 0
            ? timetableTimeWindows.filter(timetable.applyFilter(days[currentDay]))
            : []);
    }, [timetableTimeWindows, currentDay, days]);
    hooks.useChanged(currentDay, (day, prevDay) => {
        if (day !== prevDay && prevDay) {
            onChange(null);
        }
    });
    const canMoveForward = currentDay < days.length - 1;
    const canMoveBackward = currentDay > 0;
    const moveForward = useCallback(() => {
        const nextDate = currentDay + 1;
        if (days[nextDate]) {
            setCurrentDay(nextDate);
        }
    }, [currentDay, days]);
    const moveBackward = useCallback(() => {
        const prevDate = currentDay - 1;
        if (days[prevDate]) {
            setCurrentDay(prevDate);
        }
    }, [currentDay, days]);
    if ((timetableTimeWindows === null || timetableTimeWindows === void 0 ? void 0 : timetableTimeWindows.length) <= 0) {
        return null;
    }
    return (_jsxs(View, { style: styles.root, children: [_jsx(DateChanger, { moveForward: moveForward, moveBackward: moveBackward, canMoveForward: canMoveForward, canMoveBackward: canMoveBackward, date: days[currentDay] }), list &&
                list.map((timetableTimeWindow) => (_jsx(TimeWindowItem, { timetableTimeWindow: timetableTimeWindow, selected: value ? timetable.timeWindowEquals(timetableTimeWindow, value) : false, countryCode: countryCode, onChange: onChangeCached }, utils.getUniqueKey(timeWindowsIds.current, timetableTimeWindow))))] }));
};
