import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/default-props-match-prop-types */
import { PureComponent } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { applyModalBodyHtmlClass, getModalRootElement } from '../../utils/dom-classname';
const hasValidTimeout = (timeout) => typeof timeout === 'number' && timeout > 0;
const initialState = {
    open: false,
    opening: false,
    closing: false,
};
export class ModalPresenter extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { ...initialState };
        this.element = document.createElement('div');
        this.element.className = 'modal-presenter';
        this.root = getModalRootElement();
        this.afterCloseModal = this.afterCloseModal.bind(this);
        this.setModalOpen = this.setModalOpen.bind(this);
    }
    componentDidMount() {
        try {
            this.root.appendChild(this.element);
        }
        catch (e) {
            throw new Error('Modal root was not found');
        }
        if (this.props.isOpen) {
            this.openModal();
        }
    }
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen && !this.props.isOpen) {
            this.openModal();
        }
        else if (!nextProps.isOpen && this.props.isOpen) {
            this.closeModal();
        }
        if (this.props.anyModalOpen && !nextProps.anyModalOpen) {
            applyModalBodyHtmlClass(false);
        }
    }
    componentWillUnmount() {
        this.afterCloseModal();
        try {
            this.root.removeChild(this.element);
        }
        catch (e) {
            throw new Error('Modal root was not found');
        }
    }
    toState(state, callback) {
        this.setState(() => ({
            open: state === 'open',
            opening: state === 'opening',
            closing: state === 'closing',
        }), callback);
    }
    resetState() {
        this.setState(() => ({ ...initialState }));
    }
    closingTimeout(timeout) {
        setTimeout(this.afterCloseModal, timeout);
    }
    openingTimeout(timeout) {
        setTimeout(() => this.toState('open'), timeout);
    }
    openModal() {
        this.props.openModal(this.props.id);
        applyModalBodyHtmlClass();
        setTimeout(() => this.toState('opening', this.setModalOpen), 0);
    }
    setModalOpen() {
        if (hasValidTimeout(ModalPresenter.defaultProps.openTimeout)) {
            this.openingTimeout(ModalPresenter.defaultProps.openTimeout);
        }
        else {
            this.toState('open');
        }
    }
    closeModal() {
        if (hasValidTimeout(ModalPresenter.defaultProps.closeTimeout)) {
            this.setState({ closing: true }, this.closingTimeout.bind(this, ModalPresenter.defaultProps.closeTimeout));
        }
        else {
            this.resetState();
        }
    }
    afterCloseModal() {
        this.props.closeModal(this.props.id);
        if (!this.props.anyModalOpen) {
            applyModalBodyHtmlClass(false);
        }
        this.resetState();
    }
    render() {
        const { children, isOpen, topMostModal, numberOfModalsOpen, id, ...options } = this.props;
        const { open, opening, closing } = this.state;
        return createPortal(isOpen || closing ? (_jsxs("div", { className: cx('modal-container', { 'modal-container--open': open }, { 'modal-container--closing': closing }), children: [_jsx("div", { className: cx('modal-overlay', { 'modal-overlay--open': open }, { 'modal-overlay--opening': opening }, { 'modal-overlay--closing': closing }, options.overlayClassName) }), _jsx("div", { className: cx('modal-content', { 'modal-content--open': open }, { 'modal-content--opening': opening }, { 'modal-content--closing': closing }, {
                        'modal-content--disabled': numberOfModalsOpen > 1 && topMostModal !== id,
                    }, options.className), children: children })] })) : null, this.element);
    }
}
ModalPresenter.defaultProps = {
    closeTimeout: 200,
    openTimeout: 0,
};
