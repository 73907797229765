import { OrderType, RatingCategory } from '@budbee/js/tracking';
export const getRatingCategories = (orderType, rating, brand, t) => {
    if (brand === 'porterbuddy' && orderType === OrderType.DELIVERY && rating > 3) {
        return [
            {
                value: RatingCategory.HOME_POLITE_DRIVER,
                label: t('reviewModal.positive.homePoliteDriver'),
            },
            {
                value: RatingCategory.GOOD_INFORMATION,
                label: t('reviewModal.positive.goodInformation'),
            },
            {
                value: RatingCategory.HOME_QUICK_DELIVERY,
                label: t('reviewModal.positive.homeQuickDelivery'),
            },
            {
                value: RatingCategory.HOME_OTHER,
                label: t('reviewModal.positive.homeOther'),
            },
        ];
    }
    // Home Deliveries
    if (orderType === OrderType.DELIVERY) {
        return [
            {
                value: RatingCategory.HOME_WRONG_PLACE,
                label: t('reviewModal.negative.homeWrongPlace'),
            },
            {
                value: RatingCategory.HOME_DRIVER_INSTRUCTIONS,
                label: t('reviewModal.negative.homeDriverInstructions'),
            },
            {
                value: RatingCategory.HOME_DRIVER_BEHAVIOUR,
                label: t('reviewModal.negative.homeDriverBehaviour'),
            },
            {
                value: RatingCategory.HOME_PARCEL_PROBLEM,
                label: t('reviewModal.negative.homeParcelProblem'),
            },
            {
                value: RatingCategory.HOME_DELAY,
                label: t('reviewModal.negative.homeDelay'),
            },
            {
                value: RatingCategory.HOME_OTHER,
                label: t('reviewModal.negative.other'),
            },
        ];
    }
    // Box Deliveries
    return [
        {
            value: RatingCategory.SUPPORT_SERVICE,
            label: t('reviewModal.negative.support'),
        },
        {
            value: RatingCategory.APPLICATION,
            label: t('reviewModal.negative.application'),
        },
        {
            value: RatingCategory.PRODUCT_HANDLING,
            label: t('reviewModal.negative.productHandling'),
        },
        {
            value: RatingCategory.OTHER,
            label: t('reviewModal.negative.other'),
        },
    ];
};
