import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginVertical, MediaQuery, paddingHorizontal, Spacing } from '../../styles';
export const styles = StyleSheet.create({
    actionsWrapper: {
        marginTop: Space.lg,
        position: 'relative',
        zIndex: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -Spacing.md,
        [MediaQuery.maxWidthMd]: {
            marginRight: -Spacing.md,
            porterbuddy: {
                ...paddingHorizontal(Space['2xl']),
                marginRight: 0,
            },
            instabox: {
                ...paddingHorizontal(Space.lg),
                marginRight: 0,
            },
        },
        [MediaQuery.maxWidthXs]: {
            flexDirection: 'column',
        },
        porterbuddy: {
            marginBottom: Space.md,
        },
    },
    actionContainer: {
        flex: '50%',
        boxSizing: 'border-box',
        paddingRight: Spacing.md,
        paddingTop: Spacing.md,
        [MediaQuery.maxWidthMd]: {
            flex: '100%',
            porterbuddy: {
                paddingRight: 0,
            },
        },
    },
    topIconsContainer: {
        ...marginVertical(Spacing['4xl']),
        display: 'flex',
        zIndex: 0,
        position: 'relative',
        justifyContent: 'space-between',
        [MediaQuery.maxWidthMd]: {
            ...marginVertical(Spacing.md),
        },
    },
    settingsAndSupportIconsWrapper: {
        flexDirection: 'row',
    },
    leftButton: {
        marginRight: Spacing.sm,
    },
    divider: {
        height: Space['2xl'],
    },
});
