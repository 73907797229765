import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { View } from '../../shared/view';
import { styles } from '../style';
export const ThankYou = () => {
    const { t } = useI18n();
    return (_jsxs(View, { style: styles.thankYouView, children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.HeartsInCircle,
                    porterbuddy: imageList.ThumbsUp,
                    instabox: imageList.InstaboxMascot,
                }, size: {
                    budbee: 72,
                    porterbuddy: 64,
                    instabox: 86,
                } }), _jsx("span", { className: css(styles.titleAlt), children: t('reviewModal.thankYou') }), _jsx("span", { className: css(styles.subtitleAlt), children: t('reviewModal.thankYouSubtitle') })] }));
};
