import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, ButtonEffect, border } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
        alignItems: 'center',
        borderRadius: BorderRadius.md,
        marginHorizontal: Space.lg,
        padding: Space.xl,
        ...border(Border.Width.md, 'solid', Color.Budbee.ReadyRed),
        width: '100%',
        backgroundColor: Color.Budbee.Grey1,
        ...ButtonEffect.Enabled,
        marginTop: Space.md,
    },
    wrapper: {
        background: 'none',
        border: 'none',
        outline: 0,
        margin: 0,
        padding: 0,
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftIconContainer: {
        transform: 'rotate(180deg)',
    },
    title: {
        margin: 0,
        textAlign: 'left',
        wordWrap: 'break-word',
        maxWidth: '100%',
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        paddingLeft: Space.lg,
        flex: 1,
    },
    rightIcon: {
        position: 'absolute',
        right: Space.xl,
    },
    contentWrapper: {
        overflow: 'hidden',
        alignSelf: 'flex-start',
    },
    content: {
        paddingTop: Space.lg,
    },
    contentText: {
        textAlign: 'left',
        margin: 0,
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.Grey5,
    },
});
