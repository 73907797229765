import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelectedLocker } from '../../state/order/hooks';
import { BoxOrderLocation } from '../box-order-location';
import { BoxParcelJourney } from '../box-parcel-journey';
import { View } from '../shared/view';
import { styles } from '../box-content/style';
export const ParcelInTransitBoxContent = ({ merchantName, merchantLogo, toggleLocationModal, toggleLockerPickerModal, }) => {
    const locker = useSelectedLocker();
    return (_jsxs(_Fragment, { children: [_jsx(BoxParcelJourney, { style: styles.spacing }), locker ? (_jsx(View, { style: styles.spacing, children: _jsx(BoxOrderLocation, { lockerName: locker.lockerAddress.name, merchantName: merchantName, logo: merchantLogo, openHours: locker.openHours, toggleLocationModal: () => toggleLocationModal(), toggleLockerPickerModal: () => toggleLockerPickerModal() }) })) : null] }));
};
