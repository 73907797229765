import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ParcelStatus } from '@budbee/js/tracking';
import { css } from '@css';
import { useConspectus } from '../../../state/order/hooks';
import imageList from '../../../utils/image-list';
import { Icon } from '../../shared/icon';
import { View } from '../../shared/view';
import { styles } from './style';
export const InstaboxOrderHero = (props) => {
    const { topText, middleText, bottomText, badgeText } = props;
    const conspectus = useConspectus();
    const parcyIcon = useMemo(() => {
        if (!conspectus) {
            return null;
        }
        const { status: { state }, orderLocation, consignment, } = conspectus;
        if (state === ParcelStatus.Delivered) {
            return imageList.ParcyDelivered;
        }
        if (state === ParcelStatus.NotStarted) {
            return imageList.ParcyNotReceived;
        }
        if (orderLocation === null || orderLocation === void 0 ? void 0 : orderLocation.parcelsPickedUpFromMerchant) {
            return imageList.ParcyReceived;
        }
        if (state === ParcelStatus.Collected ||
            state === ParcelStatus.OnRouteDelivery ||
            state === ParcelStatus.CollectedShippingLabel ||
            state === ParcelStatus.CrossDocked) {
            return imageList.ParcyOnRoute;
        }
        if (state === ParcelStatus.Miss ||
            state === ParcelStatus.ReturnedToMerchant ||
            state === ParcelStatus.ReturnedToTerminal ||
            (consignment === null || consignment === void 0 ? void 0 : consignment.cancellation)) {
            return null;
        }
        return null;
    }, [conspectus]);
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.textContainer, children: [topText ? _jsx("p", { className: css(styles.topText), children: topText }) : null, middleText ? _jsx("p", { className: css(styles.middleText), children: middleText }) : null, badgeText ? (_jsx(View, { style: styles.badge, children: _jsx("p", { className: css(styles.badgeText), children: badgeText }) })) : null, bottomText ? _jsx("p", { className: css(styles.bottomText), children: bottomText }) : null] }), parcyIcon && (_jsx(View, { style: styles.parcy, children: _jsx(Icon, { src: parcyIcon, size: 80 }) }))] }));
};
