import { useReducer } from 'react';
import { AccessModeType } from '@budbee/js/tracking';
import { useConspectus } from '../../../../state/order/hooks';
import { getBrand } from '../../../../utils/brand/get-brand';
import { useCanContinue } from './use-can-continue';
import { useOnContinue } from './use-on-continue';
export function useAccessSettings({ canContinueChanged, onContinue }) {
    const conspectus = useConspectus();
    const [state, dispatch] = useReducer(accessSettingsReducer, getInitialState(conspectus));
    function handleChange(type) {
        return (payload) => {
            // eslint-disable-next-line
            // @ts-ignore
            dispatch({ type, payload });
        };
    }
    useCanContinue({ canContinueChanged, state });
    useOnContinue({ onContinue, state });
    return {
        values: state,
        handleChange,
    };
}
function accessSettingsReducer(state, action) {
    switch (action.type) {
        case 'SET_ESTATE_TYPE':
            return { ...state, estateType: action.payload };
        case 'SET_ACCESS_MODE':
            return { ...state, accessMode: action.payload };
        case 'SET_INTERCOM':
            return { ...state, intercom: action.payload };
        case 'SET_DOOR_CODE':
            return { ...state, doorCode: action.payload };
        case 'SET_FLOOR':
            return { ...state, floor: action.payload };
        case 'SET_CARE_OF':
            return { ...state, careOf: action.payload };
        case 'SET_INSTRUCTIONS':
            return { ...state, instructions: action.payload };
        default:
            return state;
    }
}
function getInitialState(conspectus) {
    const brand = getBrand();
    const initialState = {
        estateType: null,
        accessMode: brand === 'porterbuddy' ? AccessModeType.INTERCOM : null,
        intercom: '',
        doorCode: '',
        floor: brand === 'porterbuddy' ? 1 : 0,
        careOf: '',
        instructions: '',
    };
    if (!conspectus)
        return initialState;
    const { deliverySettings, address } = conspectus;
    if (deliverySettings) {
        const { estateType, accessMode, floor, specialInstructions } = deliverySettings;
        if (estateType) {
            initialState.estateType = estateType;
        }
        if (accessMode) {
            initialState.accessMode = accessMode.type;
            if (accessMode.value) {
                if (accessMode.type === AccessModeType.INTERCOM) {
                    initialState.intercom = accessMode.value;
                }
                else if (accessMode.type === AccessModeType.DOOR_CODE) {
                    initialState.doorCode = accessMode.value;
                }
            }
        }
        if (typeof floor === 'number') {
            initialState.floor = floor;
        }
        if (specialInstructions) {
            initialState.instructions = specialInstructions;
        }
    }
    if (address === null || address === void 0 ? void 0 : address.street2) {
        initialState.careOf = address.street2;
    }
    return initialState;
}
