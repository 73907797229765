import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { css } from '@css';
import { BorderWidth, Color, Spacing } from '../../styles';
import { inlineStyles } from '../../utils/inline-styles';
import { Icon } from '../shared/icon';
import { Spinner } from '../spinner';
import { styles } from './style';
export const IconButton = (props) => {
    const { size = Spacing.md, color = Color.Black, variant = 'regular', src, id, onClick, disabled, loading, padding = 0, opacity = 1, debounce = false, style, } = props;
    const [debouncing, setDebouncing] = useState(false);
    const onClickButton = useCallback(() => {
        if (disabled || !onClick) {
            return;
        }
        onClick();
        if (debounce) {
            setDebouncing(true);
            setTimeout(() => {
                setDebouncing(false);
            }, 500);
        }
    }, [debounce, disabled, onClick]);
    return (_jsx("button", { className: css(styles.button, inlineStyles({ opacity }), disabled ? styles.disabled : undefined, style), type: "button", onClick: onClickButton, disabled: disabled || debouncing, id: id, children: _jsx("div", { className: css(styles.icon, variant === 'roundOutlined' ? styles.iconDefault : undefined, inlineStyles({
                padding: variant === 'regular' ? 0 : padding || size,
                ...(variant === 'roundFilled' ? { backgroundColor: color } : { borderColor: color }),
            })), children: loading || debouncing ? (_jsx(Spinner, { color: variant === 'roundFilled' ? Color.White : color, size: size - 2 * BorderWidth.lg })) : (_jsx(Icon, { src: src, color: variant === 'roundFilled' ? Color.White : color, size: size })) }) }));
};
