import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { css } from '@css';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../hooks/use-i18n';
import { setReturnSamePackage } from '../../../state/returns/actions';
import { useReturnSamePackage } from '../../../state/returns/hooks';
import imageList from '../../../utils/image-list';
import { RadioButton } from '../../radio-button';
import { View } from '../../shared/view';
import { styles } from './style';
export const BoxReturnSamePackage = ({ canContinueChanged }) => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const samePackage = useReturnSamePackage();
    useEffect(() => canContinueChanged(samePackage !== null), [samePackage, canContinueChanged]);
    const onToggle = useCallback((value) => {
        dispatch(setReturnSamePackage(value));
    }, [dispatch]);
    return (_jsxs(View, { children: [_jsx("span", { className: css(styles.title), children: t('reusePackaging') }), _jsxs(View, { style: styles.options, children: [_jsx(RadioButton, { title: t('reusePackagingConfirm'), icon: imageList.BudbeeThumbsUp, toggled: samePackage === true, onPress: () => onToggle(true) }), _jsx("div", { className: css(styles.divider) }), _jsx(RadioButton, { title: t('reusePackagingDecline'), icon: imageList.BudbeeThumbsDown, toggled: samePackage === false, onPress: () => onToggle(false) })] })] }));
};
