import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { CustomerServiceContent } from '@budbee/js/tracking';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useConspectus } from '../../state/order/hooks';
import { BaseButton } from '../base-button';
import { styles } from './style';
export const CustomerServiceItemContent = {
    [CustomerServiceContent.MERCHANT_CONTACT_INFO]: ({ merchant }) => {
        const conspectus = useConspectus();
        const { supportEmail, supportPhone, supportLink } = merchant || (conspectus === null || conspectus === void 0 ? void 0 : conspectus.merchant) || {};
        return (_jsxs(_Fragment, { children: [supportEmail && (_jsx("a", { href: `mailto:${supportEmail}`, className: css(styles.link), children: supportEmail })), supportPhone && (_jsx("a", { href: `tel:${supportPhone}`, className: css(styles.link), children: supportPhone })), supportLink && (_jsx("a", { target: "_blank", rel: "noopener noreferrer", href: supportLink, className: css(styles.link), children: supportLink }))] }));
    },
    [CustomerServiceContent.EDIT_SETTINGS]: ({ toggleSettingsModal, authenticated }) => {
        const { t } = useI18n();
        return authenticated ? (_jsx(BaseButton, { size: "md", style: styles.button, onClick: toggleSettingsModal, label: t('goToSettings'), variant: "dark" })) : null;
    },
    [CustomerServiceContent.CHANGE_DELIVERY_WINDOW]: ({ toggleBookDeliveryModal }) => {
        const { t } = useI18n();
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: toggleBookDeliveryModal, label: t('upgrade.deliveryTime.delivery.change'), variant: "dark" }));
    },
    [CustomerServiceContent.CHANGE_RETURN_WINDOW]: ({ toggleReturnsModal }) => {
        const { t } = useI18n();
        const changeReturnWindow = useCallback(() => toggleReturnsModal(), []);
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: changeReturnWindow, label: t('upgrade.deliveryTime.return.change'), variant: "dark" }));
    },
    [CustomerServiceContent.BOOK_RETURN]: ({ toggleReturnsModal }) => {
        const { t } = useI18n();
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: toggleReturnsModal, label: t('bookReturnTitle'), variant: "dark" }));
    },
    [CustomerServiceContent.ELECTRONIC_IDENTIFICATION]: ({ toggleIdentificationModal }) => {
        const { t } = useI18n();
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: toggleIdentificationModal, label: t('identifyWithBankID'), variant: "dark" }));
    },
    [CustomerServiceContent.PACKAGE_REFERENCE]: ({ packageId }) => {
        const { t } = useI18n();
        return (_jsxs(_Fragment, { children: [_jsx("p", { className: css(styles.header), children: t('yourReferenceNumber') }), _jsx("p", { className: css(styles.link), children: packageId })] }));
    },
    [CustomerServiceContent.EXTERNAL_LINK]: ({ text }) => {
        const newText = text.replace(/(?:\[(.*?)\]\((.*?)\))/g, (_match, label, link) => `<a class=${css(styles.link)} href=${link} target="_blank" rel="noopener noreferrer">${label}</a>`);
        return (_jsx("span", { className: css(styles.externalLink), dangerouslySetInnerHTML: { __html: newText } }));
    },
    [CustomerServiceContent.CHANGE_TO_HOME_DELIVERY]: ({ toggleSwitchDeliveryTypeModal }) => {
        const { t } = useI18n();
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: () => toggleSwitchDeliveryTypeModal(), label: t('changeToHomeDelivery'), variant: "dark" }));
    },
    [CustomerServiceContent.CHANGE_LOCKER]: ({ toggleLockerPickerModal }) => {
        const { t } = useI18n();
        return (_jsx(BaseButton, { size: "md", style: styles.button, onClick: () => toggleLockerPickerModal(), label: t('actions.changeLocker'), variant: "dark" }));
    },
};
