import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useSustainabilityLink } from '../../state/order/hooks';
import imageList from '../../utils/image-list';
import { styles } from './style';
export const ClimateInformation = ({ climateBannerText }) => {
    const sustainabilityLink = useSustainabilityLink();
    const { t } = useI18n();
    const climateBanner = (_jsxs(_Fragment, { children: [_jsx("img", { src: imageList.climate, className: css(styles.icon), alt: "climate" }), _jsx("span", { children: t(climateBannerText) })] }));
    return (_jsx("div", { className: css(styles.climateCompensation), children: sustainabilityLink ? (_jsx("a", { href: sustainabilityLink, target: "_blank", rel: "noopener noreferrer", className: css(styles.anchor), children: climateBanner })) : (climateBanner) }));
};
