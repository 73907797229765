import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BoxTracking } from '../../components/box-tracking';
import { fetchBoxOrder } from '../../state/box-order/actions';
import { useBoxOrder, useIsFetchingBoxOrder } from '../../state/box-order/hooks';
import { useAdyenPaymentStatus, usePaymentRequired } from '../../state/payment/hooks';
export function BoxTrackingContainer({ token, authToken, reloadOrderType, setLoadingFinished, }) {
    const dispatch = useDispatch();
    const isFetchingBoxOrder = useIsFetchingBoxOrder();
    const boxOrder = useBoxOrder();
    const adyenPaymentStatus = useAdyenPaymentStatus();
    const paymentRequired = usePaymentRequired();
    const reloadOrder = useCallback(() => {
        dispatch(fetchBoxOrder(token, authToken));
    }, [authToken, token, dispatch]);
    useEffect(() => {
        dispatch(fetchBoxOrder(token, authToken));
    }, []);
    useEffect(() => {
        if (!isFetchingBoxOrder && boxOrder) {
            setLoadingFinished();
        }
    }, [boxOrder, isFetchingBoxOrder, setLoadingFinished]);
    if (isFetchingBoxOrder || !boxOrder) {
        return null;
    }
    return (_jsx(BoxTracking, { orderToken: token, authToken: authToken, boxOrder: boxOrder, reloadOrder: reloadOrder, adyenPaymentStatus: adyenPaymentStatus, paymentRequired: paymentRequired, reloadOrderType: reloadOrderType }));
}
