import { useState, useCallback } from 'react';
import { useMapboxApi } from './use-mapbox-api';
const LIMIT = 10;
export const useGeocoding = ({ country, language }) => {
    const mapboxApi = useMapboxApi();
    const [result, setResult] = useState();
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const clear = useCallback(() => {
        setResult(undefined);
        setFailed(false);
    }, []);
    const query = useCallback((keyword) => {
        setFailed(false);
        setLoading(true);
        mapboxApi
            .geocode(keyword, country, language, LIMIT)
            .then((response) => {
            setFailed(false);
            setLoading(false);
            setResult(response);
        })
            .catch(() => {
            setFailed(true);
            setLoading(false);
        });
    }, [mapboxApi, country, language]);
    return {
        query,
        result,
        clear,
        failed,
        loading,
    };
};
