import { connect } from 'react-redux';
import { ReturnInstructions } from '../../../components/steps/return-instructions';
import { getToken, getReturnableParcelCount } from '../../../state/order/selectors';
import { setReturnParcelCount } from '../../../state/returns/actions';
import { getReturnParcelCount } from '../../../state/returns/selectors';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
    returnableParcels: getReturnableParcelCount(state),
    returnParcelCount: getReturnParcelCount(state),
});
const mapDispatchToProps = (dispatch) => ({
    setReturnParcels: (count) => dispatch(setReturnParcelCount(count)),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps));
export const ReturnInstructionsContainer = decorate(ReturnInstructions);
