export const setupModalRoot = (container) => {
    var _a;
    // make sure that the modal root is present, might not be due
    // to and old index.html is cached at the client.
    // Can be removed if a content-hash cache strategy is implemented.
    const modalRoot = document.getElementById('modal-root');
    if (!modalRoot) {
        const div = document.createElement('div');
        div.setAttribute('id', 'modal-root');
        (_a = container === null || container === void 0 ? void 0 : container.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(div, container.nextSibling);
    }
};
