import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useCurrentLockerIdentifier } from '../../../state/lockers/hooks';
import { useToken } from '../../../state/order/hooks';
import { setReturnLocker } from '../../../state/returns/actions';
import { useParcelDimensions, useReturnLocker, useReturnSamePackage, } from '../../../state/returns/hooks';
import { ErrorMessage } from '../../error-message';
import { LockerListItem } from '../../locker-list-item';
import { Loader } from '../../shared/loader';
import { Title } from '../../title';
import { styles } from './style';
export const BoxReturnLockers = ({ onContinue, canContinueChanged, authToken, onNext, boxSamePackageAllowed, }) => {
    const trackingApi = useTrackingApi();
    const dispatch = useDispatch();
    const [lockers, setLockers] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = useToken();
    const dimensions = useParcelDimensions();
    const returnLocker = useReturnLocker();
    const currentLockerIdentifier = useCurrentLockerIdentifier();
    const samePackage = useReturnSamePackage();
    const { t } = useI18n();
    const chooseLocker = useCallback((locker) => {
        dispatch(setReturnLocker(locker));
        onNext();
    }, [onNext, dispatch]);
    useEffect(() => canContinueChanged((returnLocker === null || returnLocker === void 0 ? void 0 : returnLocker.lockerIdentifier) != null), [returnLocker, canContinueChanged]);
    useEffect(() => onContinue((next) => {
        next();
    }), []);
    const sortLockers = useCallback((lockerList) => {
        if (!currentLockerIdentifier || lockerList.length === 0) {
            return lockerList;
        }
        // display the current locker on top of the list if currentLockerIdentifier exists
        return [
            ...lockerList.filter((locker) => locker.lockerIdentifier === currentLockerIdentifier),
            ...lockerList.filter((locker) => locker.lockerIdentifier !== currentLockerIdentifier),
        ];
    }, [currentLockerIdentifier]);
    useEffect(() => {
        if (!authToken) {
            setError('generalErrorMessage');
            setLoading(false);
            return;
        }
        setLoading(true);
        trackingApi
            .getLockersForReturn(token, authToken, boxSamePackageAllowed, dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, dimensions === null || dimensions === void 0 ? void 0 : dimensions.height, dimensions === null || dimensions === void 0 ? void 0 : dimensions.length, !!samePackage, currentLockerIdentifier)
            .call()
            .then((response) => {
            if (response.status === ResponseStatus.COMPLETED) {
                if (response.payload.lockers.length === 0) {
                    setError('noLockersAvailable');
                    return;
                }
                const lockerList = sortLockers(response.payload.lockers);
                setLockers(lockerList);
            }
            else if (response.status === ResponseStatus.FAILED &&
                response.errorCode === 'INVALID_SIZE') {
                setError('boxReturn.parcelInvalidSize');
            }
            else {
                setError('generalErrorMessage');
            }
        })
            .catch((e) => {
            Sentry.captureException(e);
            setError('generalErrorMessage');
        })
            .finally(() => setLoading(false));
    }, [
        token,
        authToken,
        currentLockerIdentifier,
        dimensions,
        setLoading,
        setLockers,
        setError,
        trackingApi,
        sortLockers,
        chooseLocker,
        boxSamePackageAllowed,
        samePackage,
    ]);
    if (loading) {
        return _jsx(Loader, { variant: "inlineMiddle" });
    }
    if (error) {
        return _jsx(ErrorMessage, { message: t(error) });
    }
    return (_jsxs(_Fragment, { children: [_jsx(Title, { title: t('bookReturnTitle'), style: styles.title }), _jsx("p", { children: t('boxReturn.chooseLocker') }), _jsx("div", { children: lockers && lockers.length > 0
                    ? lockers.map((locker) => {
                        const { available, lockerAddress, openHours, demand } = locker;
                        const elevated = locker.lockerIdentifier === currentLockerIdentifier && available;
                        return (_jsxs("div", { className: css(styles.container), children: [elevated ? (_jsx("p", { className: css(styles.currentLocker), children: t('boxReturn.currentLocker') })) : null, _jsx(LockerListItem, { lockerName: lockerAddress.name, openHours: openHours, available: available, onClick: () => chooseLocker(locker), address: lockerAddress, boxDemand: demand }), elevated ? (_jsx("p", { className: css(styles.otherLocker), children: t('boxReturn.otherLocker') })) : null] }, locker.lockerIdentifier));
                    })
                    : null })] }));
};
