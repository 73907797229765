import { connect } from 'react-redux';
import { BankIdQR } from '../../../components/steps/bank-id-qr';
import { getLanguage } from '../../../state/locale/selectors';
import { reloadOrder } from '../../../state/order/actions';
import { getOrderType } from '../../../state/order/selectors';
import { injectIntl } from '../../../utils/i18n';
import { pipe } from '../../../utils/pipe';
const mergeOwnPropsLast = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});
const mapStateToProps = (state) => ({
    language: getLanguage(state),
    orderType: getOrderType(state),
});
const mapDispatchToProps = (dispatch) => ({
    reloadOrder: pipe(reloadOrder, dispatch),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps, mergeOwnPropsLast), injectIntl);
export const BankIdQRContainer = decorate(BankIdQR);
