import { jsx as _jsx } from "react/jsx-runtime";
import { RecallReason } from '@budbee/js/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { OrderHero } from '../order-hero';
const getRecallLabel = (recallReason) => {
    return recallReason === RecallReason.STORAGE_PERIOD_EXPIRED
        ? 'recallReason.storagePeriodExpired'
        : 'recallReason.other';
};
export const RecalledBoxHeader = ({ merchantName, recallReason, }) => {
    const { t } = useI18n();
    return (_jsx(OrderHero, { middleText: t(getRecallLabel(recallReason)), bottomText: t('recallText', {
            merchant: merchantName,
        }) }));
};
