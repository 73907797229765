import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { getBrand } from './get-brand';
// Will return the appropriate <img> for a brand, if brand is missing it will default to budbee
export const BrandedImage = (image) => {
    var _a;
    try {
        const brand = getBrand();
        const currentSrc = image.src[brand] || image.src.budbee;
        // eslint-disable-next-line
        // @ts-ignore
        const currentStyle = ((_a = image.style) === null || _a === void 0 ? void 0 : _a[brand]) || image.style;
        const props = {
            ...image,
            src: currentSrc,
        };
        return (
        // eslint-disable-next-line jsx-a11y/alt-text
        _jsx("img", { ...props, className: css(currentStyle) }));
    }
    catch {
        return null;
    }
};
