import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { OrderType } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { css } from '@css';
import { AnimatePresence, motion } from 'framer-motion';
import { useI18n } from '../../hooks/use-i18n';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import { useBoxOrder } from '../../state/box-order/hooks';
import { useConspectus } from '../../state/order/hooks';
import { animations } from '../../utils/animations';
import { getBrand } from '../../utils/brand/get-brand';
import { getRatingCategories } from '../../utils/get-rating-categories';
import { BaseButton } from '../base-button';
import { ErrorMessage } from '../error-message';
import { Loader } from '../shared/loader';
import { Categories } from './steps/categories';
import { Comment } from './steps/comment';
import { FaceScoring } from './steps/face-scoring';
import { StarRating } from './steps/star-rating';
import { ThankYou } from './steps/thank-you';
import { styles } from './style';
const emptyRating = {
    score: 0,
    comment: null,
    category: null,
    categories: null,
};
export const Rating = (props) => {
    const { token, authToken } = props;
    const { t } = useI18n();
    const trackingApi = useTrackingApi();
    const brand = getBrand();
    const conspectus = useConspectus();
    const boxOrder = useBoxOrder();
    const [loading, setLoading] = useState(false);
    const [delta, setDelta] = useState(1);
    const [rating, setRating] = useState((conspectus === null || conspectus === void 0 ? void 0 : conspectus.rating) || (boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.rating) || emptyRating);
    const ratingOptions = useMemo(() => getRatingCategories(boxOrder ? OrderType.BOX : OrderType.DELIVERY, rating.score, brand, t), [boxOrder, brand, rating.score, t]);
    const categoryCriteria = brand === 'porterbuddy' ? rating.score > 0 : rating.score > 0 && rating.score <= 3;
    const categoryMissing = rating && categoryCriteria && !rating.category;
    const hasRatedSuccessfully = Boolean(!categoryMissing && rating.category);
    const [error, setError] = useState(false);
    // (BUDBEE/INSTABOX) Set the initial step to categories if previously had rated 3 or less and not added category
    // (PORTERBUDDY) Set the initial step to categories if previusly rated 1/3/5 and not added category
    const [step, setStep] = useState(categoryMissing ? 1 : 0);
    const goNext = useCallback((skip = false) => {
        setStep(step + 1 + (skip ? 1 : 0));
        setDelta(1);
    }, [step]);
    const goBack = useCallback(() => {
        // For Budbee, skip category step when going back if score is higher than 3
        const skipCategoryStep = rating.score > 3 && brand === 'budbee' && step === 2;
        if (skipCategoryStep) {
            setDelta(-1);
            setStep(0);
            return;
        }
        setDelta(-1);
        setStep(step - 1);
    }, [brand, rating.score, step]);
    const updateRating = useCallback((newRating, callback) => {
        var _a;
        if (!authToken) {
            return;
        }
        setLoading(true);
        const trimmedComment = (newRating === null || newRating === void 0 ? void 0 : newRating.comment) !== null ? newRating === null || newRating === void 0 ? void 0 : newRating.comment.trim() : null;
        const category = ((_a = newRating === null || newRating === void 0 ? void 0 : newRating.categories) === null || _a === void 0 ? void 0 : _a.length) === 1 ? newRating.categories[0] : null;
        const updateObject = {
            ...newRating,
            comment: trimmedComment,
            category,
        };
        setRating(updateObject);
        trackingApi
            .upsertReview(authToken, token, updateObject)
            .call()
            .then((response) => {
            setLoading(false);
            if (response.status === ResponseStatus.FAILED) {
                setError(true);
                return;
            }
            callback();
        })
            .catch(() => setError(true));
    }, [authToken, trackingApi, token]);
    const steps = [
        {
            id: 0,
            component: brand === 'porterbuddy' ? (_jsx(FaceScoring, { score: rating.score, onChangeScore: (score) => {
                    updateRating({ ...rating, score }, () => goNext());
                }, disabled: hasRatedSuccessfully })) : (_jsx(StarRating, { score: rating.score, onChangeScore: (score) => {
                    // If Budbee users score more than 3, add skip one step (to avoid category step)
                    updateRating({ ...rating, score }, () => goNext(score > 3));
                }, disabled: hasRatedSuccessfully })),
        },
        {
            id: 1,
            component: (_jsx(Categories, { onBack: goBack, score: rating.score, currentCategories: rating.categories || (rating.category ? [rating.category] : []), options: ratingOptions, onChangeCategories: (categories) => {
                    updateRating({
                        ...rating,
                        categories,
                    }, () => goNext());
                } })),
        },
        {
            id: 2,
            component: (_jsx(Comment, { onBack: goBack, currentComment: rating.comment, onNext: () => {
                    updateRating(rating, () => goNext());
                }, onChangeComment: (comment) => setRating({ ...rating, comment }) })),
        },
        {
            id: 3,
            component: _jsx(ThankYou, {}),
        },
    ];
    const getSteps = () => {
        try {
            return steps[step].component;
        }
        catch {
            return null;
        }
    };
    if (error) {
        return (_jsxs("div", { className: css(styles.root), children: [_jsx(ErrorMessage, { positionVariant: "inline", colorVariant: brand === 'porterbuddy' ? 'light' : 'dark' }), _jsx(BaseButton, { label: t('alertModalTryAgainText'), onClick: () => {
                        setError(false);
                        setStep(0);
                        setRating(emptyRating);
                    }, style: styles.retryButton })] }));
    }
    return (_jsx(AnimatePresence, { children: _jsxs("div", { children: [_jsx("p", { className: css(styles.heading), children: t(hasRatedSuccessfully ? 'reviewModal.thankYou' : 'rateYourDelivery') }), _jsxs("div", { className: css(styles.root), children: [loading && _jsx(Loader, { style: styles.loader }), _jsx(motion.div, { initial: "entering", animate: "still", exit: "exiting", custom: { delta }, variants: animations.slideAnimation, transition: animations.slideAnimationTransition, children: _jsx("div", { className: css([styles.wrapper, loading ? styles.loading : undefined]), children: getSteps() }) }, step)] })] }, "new-rating") }));
};
