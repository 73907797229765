import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BoxParcelStatus } from '@budbee/js/dist/tracking';
import { useDateFormat } from '../../hooks/use-date-format';
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { styles } from '../box-header/style';
export function EstimatedBoxHeader({ authenticated, eta, needsVerification, canSwitchLocker, canSwitchDeliveryType, toggleIdentificationModal, toggleSwitchDeliveryTypeModal, status, parcelsPickedUpFromMerchant, }) {
    const { t } = useI18n();
    const format = useDateFormat();
    const verificationInstructions = canSwitchLocker
        ? 'pinCodeChangeLockerVerification'
        : 'pinCodeInstructionsVerification';
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('estimatedDeliveryTime'), middleText: format.formatDateTimeWithTilde(eta), bottomText: needsVerification ? t(verificationInstructions) : t('boxDeliveryInformation'), badgeText: status === BoxParcelStatus.Collected || parcelsPickedUpFromMerchant
                    ? null
                    : t('parcelNotYetReceived') }), authenticated && (_jsxs(View, { style: styles.actionsWrapper, children: [needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })), canSwitchDeliveryType && !needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { toggleSwitchDeliveryTypeModal: toggleSwitchDeliveryTypeModal }) }))] }))] }));
}
