import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { AdyenPaymentStatus, price as priceUtils } from '@budbee/js/tracking';
import { Button } from '../components/shared/button';
import TrackingV2 from './tracking-v2';
export class Adyen extends Component {
    constructor(props) {
        super(props);
        this.checkout = null;
        this.widget = null;
        this.onResponse = this.onResponse.bind(this);
        this.renderTryAgain = this.renderTryAgain.bind(this);
        this.resetPayment = this.resetPayment.bind(this);
    }
    componentDidMount() {
        const { updatePaymentStatus, session, token, price, locale } = this.props;
        this.checkout = new global.AdyenCheckout({ ...session, locale });
        this.checkout
            .create('dropin', {
            onSubmit: (state, widget) => {
                this.widget = widget;
                updatePaymentStatus(AdyenPaymentStatus.IN_PROGRESS);
                TrackingV2()
                    .createPayment(token, session.checkoutId, price, state.data)
                    .then((resp) => this.onResponse(state, widget, resp));
            },
            onAdditionalDetails: (state, widget) => {
                this.widget = widget;
                updatePaymentStatus(AdyenPaymentStatus.IN_PROGRESS)(TrackingV2())
                    .updatePayment(token, session.reference, state.data)
                    .then((resp) => this.onResponse(state, widget, resp));
            },
        })
            .mount(this.ref);
    }
    onResponse(_state, widget, resp) {
        const { updatePaymentStatus, setPaymentReference } = this.props;
        const { status, payload } = resp;
        setPaymentReference(payload.reference);
        const adyenResponse = payload.response;
        if (status !== 'COMPLETED') {
            widget.setStatus('error');
            updatePaymentStatus(AdyenPaymentStatus.DENIED);
            return;
        }
        if (adyenResponse.action) {
            widget.handleAction(adyenResponse.action);
        }
        else if (adyenResponse.resultCode === 'Authorised' ||
            adyenResponse.resultCode === 'Pending') {
            updatePaymentStatus(AdyenPaymentStatus.APPROVED);
            widget.setStatus('success');
        }
        else if (adyenResponse.resultCode === 'Refused') {
            widget.setStatus('error');
            updatePaymentStatus(AdyenPaymentStatus.DENIED);
        }
    }
    resetPayment() {
        const { updatePaymentStatus } = this.props;
        if (this.widget && this.widget.setStatus) {
            this.widget.setStatus('');
        }
        updatePaymentStatus(AdyenPaymentStatus.IDLE);
    }
    renderTryAgain() {
        return (_jsx(Button, { className: "adyen-try-again", onClick: this.resetPayment, children: "Try Again" }));
    }
    render() {
        const { price, paymentStatus } = this.props;
        return (_jsxs("div", { children: [_jsx("h1", { children: priceUtils.formatPrice(price) }), _jsx("div", { id: "dropin", ref: (node) => {
                        this.ref = node;
                    }, className: "dropin" }), paymentStatus === AdyenPaymentStatus.DENIED ? this.renderTryAgain() : null] }));
    }
}
