import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Border, Color } from '@budbee/js/ui';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useKeyPress } from '../../hooks/use-key-press';
import { useWindowDimensions } from '../../hooks/use-window-dimensions';
import { BorderRadius, IconSize, MediaQuery, marginVertical } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { inlineStyles } from '../../utils/inline-styles';
import { Icon } from '../shared/icon';
import { Loader } from '../shared/loader';
import { styles } from './style';
export function Modal({ children, onClose, footer, header, headerTitle, inlineTitle, loading, }) {
    const escape = useKeyPress('Escape');
    const brand = getBrand();
    useEffect(() => {
        if (escape && onClose) {
            onClose();
        }
    }, [escape, onClose]);
    const closeButton = _jsx(CloseButton, { onClose: onClose, shouldAddStyles: !header || !!inlineTitle });
    if (loading) {
        return (_jsxs(ModalWrapper, { children: [closeButton, _jsx(Loader, { style: styles.loader, variant: "inline" })] }));
    }
    return (_jsxs(ModalWrapper, { children: [header || headerTitle ? (_jsxs(_Fragment, { children: [brand === 'porterbuddy' && closeButton, _jsx(ModalHeader, { closeButton: closeButton, header: header, headerTitle: headerTitle, inlineTitle: inlineTitle })] })) : (closeButton), _jsx("div", { className: css(styles.content), children: children }), _jsx("div", { className: css(styles.footer), children: footer })] }));
}
function ModalHeader({ closeButton, header, headerTitle, inlineTitle }) {
    const brand = getBrand();
    return (_jsxs("div", { className: css(styles.header, inlineTitle ? styles.headerInline : undefined), children: [brand !== 'porterbuddy' && closeButton, _jsxs("div", { className: css(styles.headerItem, inlineTitle ? styles.headerItemInline : styles.headerItemRegular), children: [header, headerTitle ? _jsx("span", { className: css(styles.headerTitle), children: headerTitle }) : null] })] }));
}
function ModalWrapper({ children }) {
    const brand = getBrand();
    const { windowHeight } = useWindowDimensions();
    const borderRadius = brand === 'porterbuddy' ? Border.Radius['2xs'] : BorderRadius.md;
    return (_jsx("div", { className: css(styles.root, inlineStyles({
            minHeight: windowHeight,
            [MediaQuery.minWidthMd]: {
                minHeight: windowHeight ? windowHeight - 60 : '100%',
                ...marginVertical(30),
            },
        })), children: _jsx("div", { className: css(styles.container, inlineStyles({
                [MediaQuery.minWidthMd]: {
                    borderRadius,
                },
            })), children: children }) }));
}
function CloseButton({ onClose, shouldAddStyles }) {
    const { t } = useI18n();
    const brand = getBrand();
    if (!onClose) {
        return null;
    }
    return (_jsxs("button", { onClick: onClose, type: "button", className: css(styles.closeIcon, shouldAddStyles ? styles.closeIconBlack : undefined), "data-test": "close-icon", children: [brand === 'porterbuddy' && _jsx("span", { className: css(styles.closeText), children: t('close') }), _jsx(Icon, { src: imageList.Close, color: Color.Black100, size: IconSize.xs })] }));
}
