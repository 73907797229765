import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback, Fragment } from 'react';
import { useI18n } from '../../../hooks/use-i18n';
import { FontSize, Spacing } from '../../../styles';
import { inlineStyles } from '../../../utils/inline-styles';
import { ReturnPhotoUploader } from '../../return-photo-uploader';
import { Title } from '../../title';
export const ChangeReturnPhoto = (props) => {
    const { onContinue, token, authToken, canContinueChanged, setHasReturnPhotoUploaded } = props;
    const { t } = useI18n();
    const [uploadRequest, setUploadRequest] = useState();
    const [photoChanged, setPhotoChanged] = useState(false);
    useEffect(() => {
        canContinueChanged(photoChanged);
    }, [canContinueChanged, photoChanged]);
    useEffect(() => {
        onContinue((next, onError) => {
            setUploadRequest(() => (fn) => {
                fn(next, onError, setHasReturnPhotoUploaded);
            });
        });
    }, [onContinue, setUploadRequest]);
    const onPhotoChanged = useCallback(() => {
        setPhotoChanged(true);
    }, [setPhotoChanged]);
    return (_jsxs(Fragment, { children: [_jsx(Title, { title: t('returnUploadPhoto.title'), style: inlineStyles({
                    fontSize: FontSize.md,
                    marginTop: Spacing.xs,
                    textAlign: 'center',
                }) }), _jsx("p", { children: t('returnUploadPhoto.descriptionShort') }), _jsx(ReturnPhotoUploader, { token: token, authToken: authToken, onPhotoChanged: onPhotoChanged, onUploadRequested: uploadRequest })] }));
};
