import { jsx as _jsx } from "react/jsx-runtime";
import { AdyenPaymentStatus } from '@budbee/js/tracking';
import { AccessSettings } from '../components/preface/access-settings';
import { PrefaceFooter } from '../components/preface-footer';
import { PrefaceHeader } from '../components/preface-header';
import { LockerPickerOverview } from '../components/shared/locker-picker/overview';
import { BankIdMethod } from '../components/steps/bank-id-method';
import { BoxReturnLockers } from '../components/steps/box-return-lockers';
import { BoxReturnSamePackage } from '../components/steps/box-return-same-package';
import { BoxReturnSummary } from '../components/steps/box-return-summary';
import { ExtendLockerDuration } from '../components/steps/extend-locker-duration';
import { RebookExpiredBoxDelivery } from '../components/steps/rebook-expired-box-delivery';
import { ReturnTypeSelection } from '../components/steps/return-type-selection';
import { DeliverySettingsContainer } from '../container/preface/delivery-settings-container';
import { PaymentContainer } from '../container/preface/payment-container';
import { SummaryContainer } from '../container/preface/summary-container';
import { TimetableContainer } from '../container/preface/timetable-container';
import { BankIdQRContainer } from '../container/steps/bank-id-qr-container';
import { BoxReturnParcelDimensionsContainer } from '../container/steps/box-return-parcel-dimensions-container';
import { ChangeReturnPhotoContainer } from '../container/steps/change-return-photo-container';
import { RebookContainer } from '../container/steps/rebook-container';
import { ReturnInstructionsContainer } from '../container/steps/return-instructions-container';
import { ReturnSelectDateContainer } from '../container/steps/return-select-date-container';
import { ReturnUploadPhotoContainer } from '../container/steps/return-upload-photo-container';
import { SwitchDeliveryTypeOverviewContainer } from '../container/steps/switch-delivery-type-overview-container';
import { ACCESS_SETTINGS, BANK_ID_METHOD, BANK_ID_QR, BOX_RETURN_LOCKERS, BOX_RETURN_PARCEL_DIMENSIONS, BOX_RETURN_SAME_PACKAGE, BOX_RETURN_SUMMARY, CHANGE_LOCKER_CONFIRM, CHANGE_RETURN_PHOTO, DELIVERY_SETTINGS, EXTEND_LOCKER_DURATION, PAYMENT, REBOOK_DELIVERY, REBOOK_EXPIRED_BOX_DELIVERY, RETURN_INSTRUCTIONS, RETURN_SELECT_DATE, RETURN_TYPE_SELECTION, RETURN_UPLOAD_PHOTO, SUMMARY, SWITCH_DELIVERY_TYPE_CONFIRM, TIMETABLE, } from './steps';
export const getStepComponent = ({ id }) => {
    const components = {
        [TIMETABLE]: TimetableContainer,
        [DELIVERY_SETTINGS]: DeliverySettingsContainer,
        [ACCESS_SETTINGS]: AccessSettings,
        [PAYMENT]: PaymentContainer,
        [SUMMARY]: SummaryContainer,
        [REBOOK_DELIVERY]: RebookContainer,
        [RETURN_INSTRUCTIONS]: ReturnInstructionsContainer,
        [RETURN_UPLOAD_PHOTO]: ReturnUploadPhotoContainer,
        [CHANGE_RETURN_PHOTO]: ChangeReturnPhotoContainer,
        [RETURN_SELECT_DATE]: ReturnSelectDateContainer,
        [BANK_ID_METHOD]: BankIdMethod,
        [BANK_ID_QR]: BankIdQRContainer,
        [CHANGE_LOCKER_CONFIRM]: LockerPickerOverview,
        [SWITCH_DELIVERY_TYPE_CONFIRM]: SwitchDeliveryTypeOverviewContainer,
        [BOX_RETURN_PARCEL_DIMENSIONS]: BoxReturnParcelDimensionsContainer,
        [BOX_RETURN_LOCKERS]: BoxReturnLockers,
        [BOX_RETURN_SUMMARY]: BoxReturnSummary,
        [RETURN_TYPE_SELECTION]: ReturnTypeSelection,
        [EXTEND_LOCKER_DURATION]: ExtendLockerDuration,
        [REBOOK_EXPIRED_BOX_DELIVERY]: RebookExpiredBoxDelivery,
        [BOX_RETURN_SAME_PACKAGE]: BoxReturnSamePackage,
    };
    if (!(id in components)) {
        throw new Error(`No component found for step with id: ${id}`);
    }
    return components[id];
};
export function getMultiStepModalSteps({ steps, paymentRequired, adyenPaymentStatus, showBackButton, showNextButton = true, }) {
    return steps.reduce((acc, step, index, allSteps) => {
        const component = getStepComponent(step);
        let continueText = 'continue';
        let finishedText = 'done';
        let isBackButtonVisible = showBackButton || index !== 0;
        let isNextButtonVisible = ![PAYMENT, BANK_ID_METHOD, BANK_ID_QR, RETURN_UPLOAD_PHOTO].includes(step.id);
        if (paymentRequired &&
            [SUMMARY, REBOOK_DELIVERY, RETURN_SELECT_DATE, SWITCH_DELIVERY_TYPE_CONFIRM].includes(step.id)) {
            const nextStep = allSteps[index + 1];
            if (nextStep && nextStep.id === PAYMENT) {
                continueText = 'pay';
            }
        }
        if ([RETURN_SELECT_DATE, BOX_RETURN_SUMMARY].includes(step.id)) {
            finishedText = 'book';
        }
        else if (step.id === CHANGE_LOCKER_CONFIRM) {
            finishedText = 'confirm';
        }
        else if (step.id === PAYMENT && adyenPaymentStatus === AdyenPaymentStatus.APPROVED) {
            isBackButtonVisible = false;
            isNextButtonVisible = true;
        }
        const finished = step.id === RETURN_TYPE_SELECTION ? false : index === allSteps.length - 1;
        if (step.id === CHANGE_LOCKER_CONFIRM) {
            isNextButtonVisible = true;
        }
        const footer = (_jsx(PrefaceFooter, { finished: finished, isBackButtonVisible: isBackButtonVisible, isNextButtonVisible: isNextButtonVisible && !!showNextButton, continueText: continueText, finishedText: finishedText }));
        acc.push({
            component,
            props: step.props || {},
            footer,
            header: step.prefaceHeader ? _jsx(PrefaceHeader, {}) : null,
            canCancel: Boolean(step.canCancel),
        });
        return acc;
    }, []);
}
