import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ScreenSizes, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        position: 'absolute',
        bottom: Space.lg,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        bottom: Space.lg,
        borderRadius: Border.Radius.md,
        margin: Space['2xl'],
        alignSelf: 'center',
        maxWidth: ScreenSizes.md,
        backgroundColor: Color.White100,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: Space.lg,
        ...paddingHorizontal(Space.lg),
    },
    icon: {
        width: 50,
        height: 50,
        objectFit: 'cover',
        borderRadius: 50,
        marginRight: Space.lg,
    },
    eta: {
        display: 'flex',
        backgroundColor: Color.Budbee.Grey1,
        alignSelf: 'flex-start',
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        borderRadius: Border.Radius.sm,
        flexDirection: 'row',
        alignItems: 'center',
    },
    etaText: {
        ...Typography.Budbee.Web.h5,
        marginLeft: Space.sm,
        color: Color.Budbee.Grey6,
    },
    bottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: Space.lg,
    },
    driverName: {
        ...Typography.Budbee.Web.h3,
        color: Color.Budbee.Grey6,
        marginBottom: Space.sm,
    },
    driverStatusText: {
        ...Typography.Budbee.Web['body-lg'],
        color: Color.Budbee.Grey5,
    },
});
