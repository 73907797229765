import { jsx as _jsx } from "react/jsx-runtime";
import { Color, Typography } from '@budbee/js/ui';
import { StyleSheet, css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
const styles = StyleSheet.create({
    root: {
        ...Typography.Budbee.Web['badge-sm'],
        color: Color.Budbee.Grey4,
        textDecoration: 'none',
        padding: 0,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h6,
            color: Color.Porterbuddy.BuddyPurple,
        },
        instabox: {
            ...Typography.Instabox.Web.h5,
            color: Color.Instabox.ReallyRed,
        },
    },
});
export const CompanyInfo = () => {
    const { t } = useI18n();
    return _jsx("span", { className: css(styles.root), children: t('partOfInstabee') });
};
