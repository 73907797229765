import { createSelector } from '@reduxjs/toolkit';
export const getUserSelectedLocker = createSelector((state) => state.lockers, (lockersState) => {
    const { isPickingFromMap, lockers, lockerSearchResults } = lockersState;
    if (isPickingFromMap) {
        return lockersState.userSelectedMapLocker;
    }
    const selectedLocker = lockers.find((locker) => {
        const identifier = locker.lockerIdentifier;
        return identifier === lockersState.userSelectedLocker;
    });
    if (!selectedLocker) {
        return lockerSearchResults.find((locker) => {
            const identifier = locker.lockerIdentifier;
            return identifier === lockersState.userSelectedLocker;
        });
    }
    return selectedLocker;
});
export const getCurrentLockerIdentifier = createSelector((state) => state.boxOrder, (boxOrder) => { var _a; return (_a = boxOrder.boxOrderInfo) === null || _a === void 0 ? void 0 : _a.lockerAttributes.identifier; });
export const getLockersForList = createSelector((state) => state.lockers, (lockersState) => lockersState.lockers);
export const getLockersForMap = createSelector((state) => state.lockers, (lockersState) => lockersState.mapLockers);
export const getPlaceSearchResults = createSelector((state) => state.lockers, (lockersState) => lockersState.placeSearchResults);
export const getLockerSearchResults = createSelector((state) => state.lockers, (lockersState) => lockersState.lockerSearchResults);
export const getSelectedSearchResult = createSelector((state) => state.lockers, (lockersState) => lockersState.selectedSearchResult);
export const getPlaceSearchKeyword = createSelector((state) => state.lockers, (lockersState) => lockersState.placeSearchKeyword);
export const getIsSearchingForPlace = createSelector((state) => state.lockers, (lockersState) => {
    return lockersState.isSearchingForPlace;
});
export const getIsFetchingLockers = createSelector((state) => state.lockers, (lockersState) => lockersState.isFetching);
