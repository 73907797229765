import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, BorderWidth, ButtonEffect, IconSize, paddingHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.sm,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: Color.Budbee.Grey1,
        color: Color.Budbee.Grey6,
    },
    toggled: {
        ...border(BorderWidth.sm, 'solid', Color.Budbee.Grey4),
    },
    disabled: {
        boxShadow: 'none',
        ...ButtonEffect.Disabled,
    },
    button: {
        borderRadius: Border.Radius.sm,
        display: 'flex',
        justifyContent: 'center',
        border: 'none',
        ...paddingVertical(Space.lg),
        paddingLeft: Space.lg,
        paddingRight: Space['2xl'],
        color: Color.Budbee.Grey6,
        textRendering: 'optimizeLegibility',
        backgroundColor: 'transparent',
        ...ButtonEffect.Enabled,
        ':hover': {
            backgroundColor: Color.Budbee.Grey2,
        },
    },
    buttonInner: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        ...Typography.Budbee.Web['body-lg'],
        marginLeft: Space.lg,
        textAlign: 'left',
    },
    checkmark: {
        width: IconSize.xl,
        height: IconSize.xl,
    },
    children: {
        ...paddingHorizontal(Space.lg),
        marginTop: Space.md,
        marginBottom: Space.lg,
    },
});
