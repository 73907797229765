import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, marginVertical, DropShadow, paddingHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.sm,
        textAlign: 'left',
        ...border(Border.Width.sm, 'solid', Color.Budbee.Grey1),
        ...DropShadow,
    },
    borderless: {
        border: 0,
        boxShadow: 'none',
    },
    name: {
        ...Typography.Budbee.Web.h2,
        margin: 0,
        marginBottom: Space.md,
    },
    badgeWrapper: {
        display: 'flex',
        gap: Space.sm,
        alignItems: 'flex-start',
    },
    badge: {
        borderRadius: Border.Radius.sm,
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        marginBottom: Space['3xl'],
        ...Typography.Budbee.Web.h5,
    },
    open: {
        backgroundColor: Color.Budbee.GreenPop,
        color: Color.White100,
    },
    closed: {
        backgroundColor: Color.Budbee.ReadyRed,
        color: Color.White100,
    },
    bookUntil: {
        backgroundColor: Color.White100,
        color: Color.Budbee.Grey5,
    },
    subtitle: {
        ...Typography.Budbee.Web.h5,
        marginBottom: Space.xs,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        transform: `translateY(${Space.sm})`,
    },
    wrapper: {
        paddingLeft: Space.lg,
        width: '100%',
    },
    divider: {
        ...marginVertical(Space.lg),
        height: Border.Width.sm,
        backgroundColor: Color.Budbee.Grey2,
        width: '100%',
        opacity: 0.25,
    },
    text: {
        ...Typography.Budbee.Web['body-lg'],
        textAlign: 'left',
        margin: 0,
    },
    openingHoursRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: Space['2xs'],
        ...Typography.Budbee.Web['body-lg'],
    },
});
