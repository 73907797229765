import { jsx as _jsx } from "react/jsx-runtime";
import { css } from 'aphrodite/no-important';
import cx from 'classnames';
import { styles } from './style';
/**
 * @deprecated Use BaseButton instead
 */
export const Button = ({ children, className, loading, ...props }) => {
    const buttonProps = {
        className: cx(css(styles.default), className),
        ...props,
    };
    return (_jsx("button", { type: "button", ...buttonProps, children: children }));
};
