import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { ReturnUploadPhoto } from '../../../components/steps/return-upload-photo';
import * as orderActions from '../../../state/order/actions';
import { getToken } from '../../../state/order/selectors';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
});
const mapDispatchToProps = (dispatch) => ({
    setHasReturnPhotoUploaded: compose(dispatch, orderActions.setHasReturnPhotoUploaded),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps));
export const ReturnUploadPhotoContainer = decorate(ReturnUploadPhoto);
