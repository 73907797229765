import { pipe } from './pipe';
const removeQuestionMark = (query) => query && query.length && query[0] === '?' ? query.substring(1) : query;
const splitToPairs = (searchString) => (searchString ? searchString.split('&') : []);
const splitPairs = (pairs) => pairs.map((pair) => (pair ? pair.split('=') : []));
const reducePairs = (pairs) => pairs.reduce((acc, [key, value]) => acc[key] ? { ...acc, [key]: acc[key].concat(value) } : { ...acc, [key]: [value] }, {});
export const toVariables = pipe(removeQuestionMark, splitToPairs, splitPairs, reducePairs);
export const getVariableByKey = (searchString) => (key) => toVariables(searchString)[key] || [];
export const queryVariables = () => {
    return toVariables(window.location.search);
};
export const getQueryVariable = (key, defaultValue = '') => {
    const values = getVariableByKey(window.location.search)(key);
    return values[0] || defaultValue;
};
export const getFlavor = () => getQueryVariable('version', 'default');
