import { EtaMissingReason } from '@budbee/js/dist/tracking';
import { useI18n } from '../../hooks/use-i18n';
export function useOrderHeroProps({ returnEnabled, missingReason, needsVerification, canSwitchLocker, distributionCity, merchantName, }) {
    const { t } = useI18n();
    const value = {
        topText: t('delivery'),
        middleText: t('waitingforGoods', { merchant: merchantName }),
        bottomText: t('informAboutETAUponArrivalAtDistributionTerminal', {
            distributionCity,
        }),
    };
    if (missingReason === EtaMissingReason.ETA_HIDDEN_UNTIL_PARCEL_SORTED) {
        value.middleText = t('order.status.title.no_eta.hidden_until_sorted', {
            merchant: merchantName,
        });
        value.bottomText = t('order.status.description.no_eta.hidden_until_sorted');
    }
    else if (missingReason === EtaMissingReason.NOT_YET_ON_LAST_MILE) {
        value.middleText = t('order.status.title.no_eta.not_yet_on_last_mile');
        value.bottomText = t('order.status.description.no_eta.not_yet_on_last_mile');
    }
    if (returnEnabled) {
        value.topText = null;
        value.middleText = t('box.returnCreated', { merchant: merchantName });
        value.bottomText = t('box.bookYourReturn');
    }
    if (needsVerification && canSwitchLocker) {
        value.bottomText = t('pinCodeChangeLockerVerification');
    }
    return value;
}
