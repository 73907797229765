import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { ChangeReturnPhoto } from '../../../components/steps/change-return-photo';
import * as orderActions from '../../../state/order/actions';
import { getAuthToken, getToken } from '../../../state/order/selectors';
import { pipe } from '../../../utils/pipe';
const mapStateToProps = (state) => ({
    token: getToken(state),
    authToken: getAuthToken(state),
});
const mapDispatchToProps = (dispatch) => ({
    setHasReturnPhotoUploaded: compose(dispatch, orderActions.setHasReturnPhotoUploaded),
});
const decorate = pipe(connect(mapStateToProps, mapDispatchToProps));
export const ChangeReturnPhotoContainer = decorate(ChangeReturnPhoto);
