import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { IconButton } from '../icon-button';
import { styles } from './style';
export const Alert = (props) => {
    const { dismissible = true, visible = true, title, text, onDismiss } = props;
    const [isVisible, setIsVisible] = useState(visible);
    const onClose = useCallback(() => {
        setIsVisible(false);
        if (onDismiss) {
            onDismiss();
        }
    }, [onDismiss]);
    if (!isVisible) {
        return null;
    }
    return (_jsxs("div", { className: css(styles.root), children: [_jsxs("div", { className: css(styles.container), children: [_jsx("span", { className: css(styles.title), children: title }), _jsx("span", { className: css(styles.text), children: text })] }), dismissible && (_jsx("div", { className: css(styles.closeIconButtonContainer), children: _jsx(IconButton, { src: imageList.Close, size: IconSize['3xs'], onClick: onClose, color: Color.Black100, variant: "roundFilled" }) }))] }));
};
