import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, MediaQuery, ScreenSizes, Transition, marginHorizontal, paddingHorizontal, paddingVertical, } from '../../../styles';
export const styles = StyleSheet.create({
    modal: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
    },
    content: {
        backgroundColor: Color.White100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...paddingHorizontal(Space['2xl']),
        ...paddingVertical(Space['2xl']),
        ...marginHorizontal(Space.lg),
        position: 'relative',
        maxWidth: ScreenSizes.sm,
        overflow: 'auto',
        transition: Transition.Ease,
        borderRadius: Border.Radius.lg,
        [MediaQuery.minWidthMd]: {
            padding: Space['8xl'],
        },
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'center',
        minHeight: 64,
    },
    closeButton: {
        border: 'none',
        background: 'none',
        position: 'absolute',
        right: Space['2xl'],
        top: Space['2xl'],
        ...ButtonEffect.Enabled,
    },
    headerText: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.GreenDark,
        backgroundColor: Color.Budbee.GreenLight,
        ...paddingVertical(Space.sm),
        ...paddingHorizontal(Space.md),
        borderRadius: Border.Radius.sm,
        position: 'absolute',
        top: Space['2xl'],
        right: '50%',
        transform: 'translateX(50%)',
    },
    heartIcon: {
        ...marginHorizontal(Space['2xs']),
    },
    heading: {
        ...Typography.Budbee.Web.h1,
        textAlign: 'center',
        marginBottom: Space.lg,
    },
    text: {
        ...Typography.Budbee.Web['body-lg'],
        marginBottom: Space.lg,
    },
    lockerImage: {
        height: 'auto',
        width: 286,
        alignSelf: 'center',
        marginBottom: Space['2xl'],
    },
    button: {
        width: '100%',
        ...marginHorizontal(Space['2xl']),
        alignSelf: 'center',
    },
});
