export const SHOW_STEPS = 'SHOW_STEPS';
export const SHOW_STEP = 'SHOW_STEP';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';
export const PREFACE_FETCHED = 'PREFACE_FETCHED';
export const SET_STEPS = 'SET_STEPS';
export const prefaceFetched = (preface) => ({
    type: PREFACE_FETCHED,
    payload: { preface },
});
export const setSteps = (steps, keepIndex = false) => ({
    type: SET_STEPS,
    payload: {
        steps,
        keepIndex,
    },
});
export const showSteps = (show) => ({
    type: SHOW_STEPS,
    payload: { show },
});
export const showStep = (step) => ({
    type: SHOW_STEP,
    payload: { step },
});
export const nextStep = () => ({
    type: NEXT_STEP,
});
export const prevStep = () => ({
    type: PREV_STEP,
});
