import { Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { IconSize } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        ...Typography.Budbee.Web.h3,
    },
    logo: {
        height: 125,
        width: 125,
        marginTop: Space['5xl'],
        marginBottom: Space['2xl'],
    },
    failed: {
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        height: Space['2xl'],
    },
    loader: {
        height: IconSize['11xl'],
        width: IconSize['11xl'],
        marginTop: Space.xl,
    },
});
