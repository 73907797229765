import { createReducer } from '../../utils/create-reducer';
import { CLEAR_BANNER, SET_BANNER } from './actions';
const initState = {
    data: null,
};
export const banner = createReducer(initState, {
    [SET_BANNER]: (state = initState, action) => ({
        ...state,
        data: action.payload.banner,
    }),
    [CLEAR_BANNER]: (state = initState) => ({
        ...state,
        data: null,
    }),
});
