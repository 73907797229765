import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { hooks, languages } from '@budbee/js/tracking';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import rawTranslations from '../../translations.json';
import { useI18n } from '../hooks/use-i18n';
import { useLanguage } from '../state/locale/hooks';
import { getBrand } from './brand/get-brand';
const translationsToI18n = (translations) => {
    const brand = getBrand();
    const resources = Object.values(languages).reduce((acc, langCode) => {
        acc[langCode] = {
            translation: {},
        };
        return acc;
    }, {});
    Object.entries(translations).forEach(([id, translationByLang]) => {
        const { en: englishTranslation } = translationByLang;
        Object.entries(translationByLang).forEach(([lang, translation]) => {
            resources[lang].translation[id] = translation || englishTranslation;
        });
    });
    if (brand === 'porterbuddy') {
        resources[languages.NORWEGIAN] = resources[languages.PORTERBUDDY_NORWEGIAN];
        resources[languages.ENGLISH] = resources[languages.PORTERBUDDY_ENGLISH];
    }
    return resources;
};
export function FormattedMessage({ id, values, children, }) {
    const { t } = useI18n();
    if (children && typeof children === 'function') {
        return children(t(id, values));
    }
    return _jsx("span", { children: t(id, values) });
}
export function useLanguageHandler() {
    const language = useLanguage();
    const lastLanguage = hooks.usePrevious(language);
    const { changeLanguage } = useI18n();
    useEffect(() => {
        if (lastLanguage !== language) {
            changeLanguage(language);
        }
    }, [language]);
}
export const injectIntl = (Component) => (props) => {
    const { t, i18n } = useI18n();
    const intl = {
        formatMessage: (selector, options) => {
            if (selector && selector.id) {
                return t(selector.id, options);
            }
            return null;
        },
        i18n,
    };
    return _jsx(Component, { ...props, intl: intl });
};
export const translations = translationsToI18n(rawTranslations);
export default () => {
    const language = 'en';
    return i18next.use(initReactI18next).init({
        fallbackLng: 'en',
        lng: language,
        keySeparator: '::',
        interpolation: {
            escapeValue: false,
            prefix: '{',
            suffix: '}',
        },
        resources: translations,
        missingKeyHandler: (lng, ns, key) => {
            Sentry.captureMessage(`translation for "${ns}:${key}" was not found in "${lng}"`);
        },
    });
};
