import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing } from '../../../styles';
export const styles = StyleSheet.create({
    title: {
        marginTop: Spacing.xs,
        marginBottom: Spacing.md,
        textAlign: 'center',
    },
    subtitle: {
        marginBottom: Space.lg,
    },
    radioButton: {
        marginBottom: Space.sm,
    },
});
