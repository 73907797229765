import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { IconSize } from '../../styles';
import imageList from '../../utils/image-list';
import { IconButton } from '../icon-button';
import { AnimatedView } from '../shared/animated-view';
import { Icon } from '../shared/icon';
import { TextInput } from '../shared/text-input';
import { styles } from './style';
const NEIGHBOUR_LIMIT = 2;
export const NeighbourSettings = (props) => {
    const { currentNeighbours, onChangeNeighbours, onChangeNeighboursValid } = props;
    const { t } = useI18n();
    const invalid = useMemo(() => currentNeighbours.some((n) => (n === null || n === void 0 ? void 0 : n.deliveryInfo) === '' ||
        !(n === null || n === void 0 ? void 0 : n.deliveryInfo.replace(/\s/g, '').length) ||
        (n === null || n === void 0 ? void 0 : n.deliveryInfo.length) <= 2), [currentNeighbours]);
    useEffect(() => onChangeNeighboursValid(!invalid), [invalid, onChangeNeighboursValid]);
    const onUpdate = useCallback((text, i) => {
        const updatedNeighbours = currentNeighbours.map((neighbour, index) => {
            if (index === i) {
                return { ...neighbour, deliveryInfo: text };
            }
            return { ...neighbour };
        });
        onChangeNeighbours(updatedNeighbours);
    }, [currentNeighbours, onChangeNeighbours]);
    const onAdd = useCallback(() => {
        onChangeNeighbours([...currentNeighbours, { deliveryInfo: '' }]);
    }, [currentNeighbours, onChangeNeighbours]);
    const onRemove = useCallback((i) => {
        const neighboursCopy = [...currentNeighbours.filter((_n, index) => i !== index)];
        onChangeNeighbours(neighboursCopy);
    }, [currentNeighbours, onChangeNeighbours]);
    return (_jsxs(AnimatedView, { animation: "slideInDown", children: [_jsxs("p", { className: css(styles.p), children: [t('deliverySettings.enterNeighboursHouseNumber'), "*"] }), currentNeighbours.map((neighbour, index) => {
                const isInvalid = (neighbour === null || neighbour === void 0 ? void 0 : neighbour.deliveryInfo) === '' ||
                    !(neighbour === null || neighbour === void 0 ? void 0 : neighbour.deliveryInfo.replace(/\s/g, '').length) ||
                    (neighbour === null || neighbour === void 0 ? void 0 : neighbour.deliveryInfo.length) <= 2;
                return (_jsx(Fragment, { children: _jsx(AnimatedView, { animation: "fadeIn", style: styles.container, children: _jsx(TextInput, { style: styles.textinput, maxChars: 140, value: neighbour.deliveryInfo, placeholder: t('deliverySettings.neighbourAddress'), onChange: (newAddress) => onUpdate(newAddress, index), contentContainerStyle: isInvalid ? styles.invalidInput : styles.input, showCounter: false, children: index + 1 >= NEIGHBOUR_LIMIT ? (_jsx(IconButton, { src: imageList.TrashCan, onClick: () => onRemove(index), size: IconSize['3xl'], style: styles.trash })) : null }) }) }, neighbour.deliveryInfo + index.toString()));
            }), _jsxs("button", { type: "button", onClick: onAdd, className: css([
                    styles.addButton,
                    currentNeighbours.length >= NEIGHBOUR_LIMIT ? styles.disabled : undefined,
                ]), disabled: currentNeighbours.length >= NEIGHBOUR_LIMIT || invalid, children: [_jsx(Icon, { src: imageList.AddNeighbour, size: IconSize.md }), _jsx("p", { className: css(styles.buttonText), children: t('deliverySettings.addNeighbour') })] })] }));
};
