import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, IconSize } from '../../styles';
const getPaddingVertical = (size) => {
    switch (size) {
        case 'sm': {
            return Space.md;
        }
        case 'md': {
            return Space.lg;
        }
        case 'lg': {
            return Space.xl;
        }
        default: {
            return Space.lg;
        }
    }
};
export const getStyles = (variant, size, checked, disabled) => {
    const BACKGROUND_COLOR = variant === 'secondary' ? Color.Budbee.Grey1 : Color.White100;
    const OPACITY = disabled ? 0.4 : 1;
    const LABEL_STYLE_BB = checked ? Typography.Budbee.Web.h4 : Typography.Budbee.Web['body-lg'];
    const LABEL_STYLE_PB = checked
        ? Typography.Porterbuddy.Web.h3
        : Typography.Porterbuddy.Web['body-lg'];
    const LABEL_STYLE_IBX = checked ? Typography.Instabox.Web.h4 : Typography.Instabox.Web['body-md'];
    const PADDING_VERTICAL = getPaddingVertical(size);
    const styles = StyleSheet.create({
        root: {},
        container: {
            backgroundColor: BACKGROUND_COLOR,
            padding: `${PADDING_VERTICAL}px ${Space.lg}px`,
            opacity: OPACITY,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: Border.Radius.sm,
            borderWidth: Border.Width.sm,
            border: checked ? `1px solid ${Color.Budbee.Grey3}` : '0px',
            ...ButtonEffect.Enabled,
            porterbuddy: {
                borderRadius: Border.Radius['2xs'],
                border: 0,
                backgroundColor: checked ? Color.Porterbuddy.PalPurple : Color.White100,
            },
            instabox: {
                border: 0,
                backgroundColor: Color.White100,
                minHeight: 60,
                borderRadius: 10,
            },
        },
        disabled: {
            ...ButtonEffect.Disabled,
        },
        left: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 1,
            porterbuddy: {
                paddingTop: Space['3xs'],
            },
        },
        leftIconWrapper: {
            marginRight: Space.md,
            justifyContent: 'center',
        },
        label: {
            ...LABEL_STYLE_BB,
            paddingTop: Space['3xs'],
            flexShrink: 1,
            porterbuddy: {
                ...LABEL_STYLE_PB,
                paddingTop: 0,
                transform: 'translateY(-1px)',
                color: checked ? Color.Porterbuddy.BuddyPurple : Color.Porterbuddy.Grey4,
            },
            instabox: {
                ...LABEL_STYLE_IBX,
            },
        },
        right: {
            marginLeft: Space.xl,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: Space.md,
        },
        icon: {
            width: IconSize['3xl'],
            height: IconSize['3xl'],
            color: Color.Budbee.Grey6,
        },
        dot: {
            backgroundColor: Color.Instabox.BetterBlush,
            width: IconSize.xl,
            height: IconSize.xl,
            borderRadius: 50,
            marginRight: 1,
        },
    });
    return styles;
};
