import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { timetable as timetableUtils } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import { useToggledState } from '../../../hooks/use-toggled-state';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { useConspectus } from '../../../state/order/hooks';
import { consignmentToDeliveryWindow } from '../../../utils/consignment-to-delivery-window';
import { localizeMerchantName } from '../../../utils/locale';
import { ErrorMessage } from '../../error-message';
import { styles } from '../../preface/timetable/style';
import { Loader } from '../../shared/loader';
import { View } from '../../shared/view';
import { TimeWindowSelector } from '../../time-window-selector';
import { TimetableInfo } from '../../timetable-info';
const getDefaultDeliveryWindow = (timeWindows) => {
    const bookedTimeWindow = timeWindows.find((timeWindow) => timeWindow.booked);
    return bookedTimeWindow || null;
};
export const Rebook = ({ token, authToken, onContinue, canContinueChanged, timeWindows, isFetchingTimeWindows, afterBookConsignment, fetchTimeWindows, registerPreliminaryPayment, clearPaymentRequest, }) => {
    const [currentDeliveryWindow, onDeliveryWindowChange] = useToggledState(null);
    const trackingApi = useTrackingApi();
    const [error, setError] = useState(false);
    const conspectus = useConspectus();
    const deliveryWindow = useMemo(() => conspectus && conspectus.consignment
        ? consignmentToDeliveryWindow(conspectus.consignment)
        : undefined, [conspectus]);
    const updateDeliveryWindow = (timeWindow) => {
        if (timeWindow) {
            const { price } = timeWindow.timeWindow;
            if (price && price.costInCents > 0) {
                registerPreliminaryPayment();
            }
            else {
                clearPaymentRequest();
            }
        }
        onDeliveryWindowChange(timeWindow);
    };
    useEffect(() => {
        if (!token) {
            return;
        }
        fetchTimeWindows(token);
        clearPaymentRequest();
    }, []);
    useEffect(() => {
        if (timeWindows) {
            onDeliveryWindowChange(getDefaultDeliveryWindow(timeWindows));
        }
    }, [timeWindows]);
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        onContinue((next, onError) => {
            trackingApi
                .bookConsignment(authToken, token, currentDeliveryWindow)
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.FAILED) {
                    setError(true);
                    onError();
                    onDeliveryWindowChange(null);
                    if (!token) {
                        return;
                    }
                    fetchTimeWindows(token);
                }
                else {
                    afterBookConsignment(response, currentDeliveryWindow);
                    next();
                }
            })
                .catch(() => {
                setError(true);
                onError();
            });
        });
    }, [currentDeliveryWindow]);
    useEffect(() => canContinueChanged(currentDeliveryWindow !== null), [currentDeliveryWindow]);
    if (error) {
        return _jsx(ErrorMessage, {});
    }
    if (timeWindows === null || !conspectus) {
        return null;
    }
    const bookableTimeWindows = timetableUtils.filterValidTimeWindows(timeWindows);
    return (_jsx(View, { children: isFetchingTimeWindows ? (_jsx(View, { style: styles.loader, children: _jsx(Loader, {}) })) : (_jsxs(_Fragment, { children: [_jsx(TimetableInfo, { isReturn: false, merchantName: localizeMerchantName(conspectus.merchant.name, conspectus.address.countryCode), orderStatus: conspectus.status.state }), _jsx(TimeWindowSelector, { timetableTimeWindows: bookableTimeWindows, deliveryWindow: deliveryWindow, countryCode: conspectus.address.countryCode, days: timetableUtils.filteredTimetableDays(timeWindows), onChange: updateDeliveryWindow, value: currentDeliveryWindow })] })) }));
};
