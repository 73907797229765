import { StyleSheet } from '@css';
import { MediaQuery, ScreenSizes } from '../../../styles';
export const styles = StyleSheet.create({
    formContainer: {
        maxWidth: ScreenSizes.md,
        margin: 'auto',
        width: '100%',
        position: 'relative',
        [MediaQuery.minWidthXs]: {
            minHeight: '100%',
            margin: '0 auto',
            maxWidth: ScreenSizes.sm,
        },
        porterbuddy: {
            maxWidth: ScreenSizes.md,
        },
    },
});
