import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { getBrand } from '../../utils/brand/get-brand';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { View } from '../shared/view';
import { getStyles } from './style';
export const CheckButton = ({ onPress, label, checked, variant = 'primary', size = 'md', leftIcon, leftIconImage, disabled = false, hideRightIcon = false, }) => {
    const styles = getStyles(variant, size, checked, disabled);
    const brand = getBrand();
    const handlePress = () => {
        if (disabled) {
            return;
        }
        onPress();
    };
    const renderRightIcon = () => {
        if (brand === 'porterbuddy') {
            return null;
        }
        if (checked) {
            return (_jsx(BrandedIcon, { src: {
                    budbee: imageList.CheckboxSelected,
                    instabox: imageList.CheckboxSelectedRed,
                }, size: {
                    budbee: 26,
                    instabox: 20,
                } }));
        }
        if (brand === 'instabox') {
            return _jsx("div", { className: css(styles.dot) });
        }
        return null;
    };
    return (_jsx(View, { style: styles.root, children: _jsxs("button", { type: "button", onClick: handlePress, disabled: disabled, className: css(styles.container), children: [_jsxs(View, { style: styles.left, children: [leftIcon && (_jsx(View, { style: styles.leftIconWrapper, children: _jsx(BrandedIcon, { src: {
                                    budbee: leftIcon,
                                    instabox: leftIcon,
                                    porterbuddy: checked
                                        ? imageList.PorterbuddySelected
                                        : imageList.PorterbuddyUnselected,
                                }, size: {
                                    budbee: 30,
                                    porterbuddy: 20,
                                    instabox: 24,
                                } }) })), leftIconImage, _jsx(View, { style: styles.label, children: label })] }), !hideRightIcon && renderRightIcon()] }) }));
};
