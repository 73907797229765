import { jsx as _jsx } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../hooks/use-i18n';
import { useBoxNeedsVerification, useBoxOrder } from '../../state/box-order/hooks';
import { styles } from './style';
export const ChangeLockerBadge = ({ toggleLockerPickerModal }) => {
    const { t } = useI18n();
    const boxOrder = useBoxOrder();
    const needsVerification = useBoxNeedsVerification();
    const showBadge = (boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.canSwitchLocker) && !needsVerification;
    if (!showBadge) {
        return null;
    }
    return (_jsx("button", { type: "button", className: css(styles.root), onClick: toggleLockerPickerModal, children: _jsx("span", { className: css(styles.text), children: t('actions.changeLocker') }) }));
};
