import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PrefaceId } from '@budbee/js/dist/tracking';
import { css } from '@css';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../hooks/use-i18n';
import { reloadOrder } from '../../state/order/actions';
import { useConspectus } from '../../state/order/hooks';
import { useCurrentStep } from '../../state/preface/hooks';
import { getBrand } from '../../utils/brand/get-brand';
import { LanguagePicker } from '../shared/language-picker';
import { View } from '../shared/view';
import { Title } from '../title';
import { styles } from './style';
export const PrefaceHeader = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const conspectus = useConspectus();
    const currentStep = useCurrentStep();
    const brand = getBrand();
    return (_jsxs(_Fragment, { children: [_jsxs(View, { style: styles.root, children: [_jsx(LanguagePicker, { onChangeLanguage: () => dispatch(reloadOrder()) }), conspectus && brand !== 'porterbuddy' ? (_jsxs("div", { className: css(styles.container), children: [_jsx(Title, { title: conspectus.merchant.name, style: styles.title }), _jsx(Title, { title: conspectus.address.street, style: styles.subtitle })] })) : null] }), brand === 'porterbuddy' && (_jsx("span", { className: css(styles.headerTitle), children: currentStep.id === PrefaceId.TIMETABLE
                    ? t('prefaceTitle.timeslot')
                    : t('prefaceTitle.deliveryPreferences') }))] }));
};
