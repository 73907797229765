import { isNullOrUndefined } from './null-helpers';
export const prop = ([key, ...path] = []) => (object) => {
    if (isNullOrUndefined(object)) {
        return undefined;
    }
    if (typeof object[key] === 'object') {
        return prop(path)(object[key]);
    }
    return isNullOrUndefined(object[key]) ? undefined : object[key];
};
