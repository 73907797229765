import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, TextStyle, FontSize, FontWeight, marginHorizontal, border } from '../../../styles';
import { getBrand } from '../../../utils/brand/get-brand';
const TEXT_INPUT_STYLE = {
    backgroundColor: Color.White100,
    textAlign: 'start',
    padding: Space.lg,
    borderRadius: Border.Radius.sm,
};
export const styles = StyleSheet.create({
    root: {
        height: '100%',
        gap: Space['7xl'],
        porterbuddy: {
            gap: 0,
        },
    },
    title: {
        ...Typography.Budbee.Web.h3,
        ...marginHorizontal(Space.sm),
        marginTop: 0,
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h2,
            marginBottom: Space['2xl'],
            ...marginHorizontal(0),
        },
        instabox: {
            ...Typography.Instabox.Web.h2,
        },
    },
    options: {
        gap: Space['7xl'],
    },
    option: {
        marginBottom: Spacing.lg,
    },
    courtYardContainer: {
        marginTop: Spacing.xs,
    },
    courtYardTitle: {
        ...TextStyle,
        fontSize: FontSize.sm,
        fontWeight: FontWeight.SemiBold,
    },
    specialInstructions: {
        ...TEXT_INPUT_STYLE,
        height: 120,
        '::placeholder': {
            color: getBrand() === 'porterbuddy' ? Color.Porterbuddy.Grey4 : Color.Budbee.Grey4,
        },
        porterbuddy: {
            backgroundColor: 'inherit',
            borderRadius: Border.Radius['2xs'],
            ...Typography.Porterbuddy.Web['body-lg'],
            ...border(Border.Width.md, 'solid', Color.Porterbuddy.AmigoPurple),
        },
    },
    inputContentContainer: {
        padding: 0,
        porterbuddy: {
            backgroundColor: 'inherit',
        },
    },
});
