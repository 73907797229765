import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { styles } from '../box-header/style';
export const CancelledBoxHeader = ({ authenticated, toggleIdentificationModal, toggleReturnsModal, toggleSwitchDeliveryTypeModal, title, reason, allowedToRebook, returns, needsVerification, canSwitchDeliveryType, }) => {
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { middleText: needsVerification ? t('generalVerification') : title, bottomText: needsVerification ? '' : reason }), authenticated ? (_jsxs(View, { style: styles.actionsWrapper, children: [returns.enabled && allowedToRebook ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: "primary" }) })) : null, needsVerification ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })) : null, !needsVerification && canSwitchDeliveryType ? (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { toggleSwitchDeliveryTypeModal: toggleSwitchDeliveryTypeModal }) })) : null] })) : null] }));
};
