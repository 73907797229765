import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from '../../../hooks/use-i18n';
import { IntercomProvider } from '../../../utils/intercom';
import { ChatButton } from './chat-button';
export const ChatContact = (props) => {
    const { chat, countryCode, token, orderType, consumer, merchantName } = props;
    const { t } = useI18n();
    const { open, temporarilyClosed, intercom } = chat;
    const chatOpen = open && !temporarilyClosed;
    const noOperator = open && temporarilyClosed;
    return (_jsx(_Fragment, { children: _jsx(IntercomProvider, { appId: intercom === null || intercom === void 0 ? void 0 : intercom.appId, children: _jsx(ChatButton, { countryCode: countryCode, token: token, orderType: orderType, consumer: consumer, merchantName: merchantName, intercom: intercom, chatNoOperatorMessage: t('helpModal.chatNotAvailable'), noOperator: noOperator, disabled: !chatOpen }) }) }));
};
