import { StyleSheet as AphroditeStyleSheet, } from 'aphrodite';
import { getBrand } from './get-brand';
// WARNING
// To not move this file due to the alias @css.
// This module should be imported with alias @css as a dropin replacement for aphrodite.js but with support for brand specific styling
// See vite.config.js, webpack.config.js, tsconfig.json and index.d.ts
const brand = getBrand();
const brandSelectorHandler = (selector, baseSelector, generateSubtreeStyles) => {
    if (selector === brand) {
        return generateSubtreeStyles(`${baseSelector} `);
    }
    return null;
};
const extension = { selectorHandler: brandSelectorHandler };
export const { StyleSheet, css } = AphroditeStyleSheet.extend([extension]);
