import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Spacing, BorderRadius, paddingVertical, paddingHorizontal, border } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: BorderRadius.md,
        marginHorizontal: Space.lg,
        ...paddingVertical(Spacing.lg),
        ...paddingHorizontal(Space.xl),
        ...border(Border.Width.md, 'solid', Color.Budbee.GreenDark),
        marginTop: Space.md,
    },
    leftIcon: {
        marginRight: Space.lg,
    },
    close: {
        cursor: 'pointer',
        marginLeft: Space.lg,
        color: Color.Budbee.GreenDark,
    },
    message: {
        margin: 0,
        textAlign: 'left',
        wordWrap: 'break-word',
        maxWidth: '100%',
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        flex: 1,
        paddingRight: Space.lg,
    },
});
