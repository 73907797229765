import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { css } from '@css';
import InputRange from 'react-input-range';
import { styles } from './style';
export const ParcelDimensionSlider = (props) => {
    const { label, unit, value, maxValue, onChangeValue, contentContainerStyle } = props;
    const onInputChange = useCallback((e) => {
        const newValue = parseInt(e.target.value, 10) || 0;
        onChangeValue(newValue > maxValue ? maxValue : newValue);
    }, [maxValue, onChangeValue]);
    const onSliderRangeChange = (sliderValue) => {
        if (typeof sliderValue === 'number') {
            onChangeValue(sliderValue);
        }
    };
    return (_jsxs("div", { style: contentContainerStyle, children: [_jsxs("div", { className: css(styles.root), children: [_jsx("p", { className: css(styles.label), children: label }), _jsxs("div", { className: css(styles.dimensionContainer), children: [_jsx("input", { value: value, min: 0, onChange: onInputChange, type: "number", dir: "rtl", className: css(styles.number) }), _jsx("p", { className: css(styles.unit), children: unit })] })] }), _jsx(InputRange, { minValue: 0, maxValue: maxValue, value: value, onChange: onSliderRangeChange })] }));
};
