import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Component } from 'react';
import { climate, } from '@budbee/js/tracking';
import { css } from '@css';
import * as Sentry from '@sentry/react';
import mapboxgl from 'mapbox-gl';
import moment from 'moment-timezone';
import { Color, Spacing } from '../../../styles';
import { continuityAngle, angleBetweenCoordinates } from '../../../utils/angles';
import { getBrand } from '../../../utils/brand/get-brand';
import { transitionEndEventName } from '../../../utils/dom-events';
import imageList from '../../../utils/image-list';
import * as latlng from '../../../utils/latlng';
import { MapMoveData } from '../../../utils/map-move-data';
import { vehicleIcon } from '../../../utils/vehicle-icon';
import { ClimateInformation } from '../../climate-information';
import { DriverStatusCard } from '../../driver-status-card';
import { IconButton } from '../../icon-button';
import { styles } from './style';
const brand = getBrand();
const ANIMATE_MOVE_TIME = 11000;
const TIME_TELEPORT_LIMIT = 60000; // Miliseconds
const DISTANCE_TELEPORT_LIMIT = 2000; // Meters
const DISTANCE_DIFF_FRACTION_LIMIT = 0.6;
const MANUAL_CAMERA_TIMEOUT = 30000;
const MOVE_DISTANCE_MIN_FILTER_LIMIT = 4; // Meters
const MOVE_DISTANCE_MAX_FILTER_LIMIT = 20; // Meters
const emptyEta = (eta) => !eta || !eta.date;
export class MapModal extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.updateMap = this.updateMap.bind(this);
        this.marker = null;
        this.deliveryMarker = null;
        this.rotation = 0;
        this.previousTimestamp = moment(0);
        this.autoFit = true;
        this.lastManualCameraMove = moment(0);
        this.manualEventId = null;
        this.map = null;
        this.previousAngle = 360;
        this.state = {
            lastDistanceCheckpoint: Infinity,
            updatedOnce: false,
        };
    }
    componentDidMount() {
        const { driver, position, consignment } = this.props;
        if (driver && driver.id && position && consignment) {
            this.initMap();
            setTimeout(() => this.map.resize(), 100);
        }
    }
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { open } = this.props;
        const { updatedOnce, lastDistanceCheckpoint } = this.state;
        if (nextProps.open !== open && brand !== 'porterbuddy') {
            setTimeout(() => this.map.resize(), 100);
            if (nextProps.open) {
                document.body.style.overflow = 'hidden';
                document.body.style.position = 'fixed';
            }
            else {
                document.body.style.overflow = '';
                document.body.style.position = '';
            }
        }
        const timestamp = moment(nextProps.position.date);
        const moveData = new MapMoveData(nextProps.position.coordinate, timestamp, this.marker.getLngLat(), this.previousTimestamp, this.rotation);
        const shouldIgnore = moveData.shouldIgnore(MOVE_DISTANCE_MIN_FILTER_LIMIT, MOVE_DISTANCE_MAX_FILTER_LIMIT);
        if (!updatedOnce || !shouldIgnore) {
            if (lastDistanceCheckpoint !== Infinity) {
                this.setState({ updatedOnce: true });
            }
            this.updateMap(moveData);
        }
    }
    componentDidUpdate(prevProps) {
        const { eta } = this.props;
        const { eta: prevEta } = prevProps;
        // if we lose eta, resize map
        if ((emptyEta(eta) && !emptyEta(prevEta)) || (!emptyEta(eta) && emptyEta(prevEta))) {
            if (this.map) {
                setTimeout(() => this.map.resize(), 0);
            }
        }
    }
    updateMap(moveData) {
        const shouldTeleport = moveData.getTimestampDiff() > TIME_TELEPORT_LIMIT ||
            moveData.getMoveDistance() > DISTANCE_TELEPORT_LIMIT;
        if (shouldTeleport) {
            this.teleportTo(moveData);
            if (this.autoFit && moveData) {
                this.fitMapTo(moveData.getTargetCoordinate());
            }
        }
        else {
            const angle = angleBetweenCoordinates(this.marker.getLngLat(), moveData.getTargetCoordinate());
            this.rotateTo(angle).then(() => this.animateTo(moveData.getTargetCoordinate()));
            if (this.autoFit) {
                this.conditionalFitMap(moveData);
            }
        }
        this.updateDistanceCheckpoint(moveData.getTargetCoordinate());
        this.previousTimestamp = moveData.getTimestamp();
    }
    conditionalFitMap(moveData) {
        const { lastDistanceCheckpoint } = this.state;
        const newDeliveryDist = moveData.targetDistanceTo(this.deliveryMarker.getLngLat());
        const distanceDiff = newDeliveryDist / lastDistanceCheckpoint;
        const newPositionIsOnMap = moveData.targetInBounds(this.map.getBounds());
        const shouldFit = !newPositionIsOnMap || distanceDiff < DISTANCE_DIFF_FRACTION_LIMIT;
        if (shouldFit && moveData) {
            this.fitMapTo(moveData.getTargetCoordinate());
            this.setState({ lastDistanceCheckpoint: newDeliveryDist });
        }
        return shouldFit;
    }
    fitMapTo(position) {
        const bounds = latlng.bounds2(position, this.props.deliveryAddress.coordinate);
        try {
            this.map.fitBounds(bounds, { padding: 50 });
        }
        catch (err) {
            Sentry.captureException(err);
        }
    }
    teleportTo(moveData) {
        if (this.moveId !== null) {
            cancelAnimationFrame(this.moveId);
        }
        this.marker.setLngLat(latlng.mapboxCoordinate(moveData.getTargetCoordinate()));
        this.conditionalFitMap(moveData);
    }
    rotateTo(angle) {
        if (brand === 'porterbuddy') {
            // For PB don't rotate the icon
            return new Promise((resolve) => resolve());
        }
        if (this.marker) {
            return this.rotateMarker(angle);
        }
        return new Promise((resolve) => resolve());
    }
    rotateMarker(angle) {
        return new Promise((resolve) => {
            const element = this.marker.getElement().querySelectorAll('img')[0];
            const transitionEnd = transitionEndEventName();
            const onTransitionEnded = () => {
                element.removeEventListener(transitionEnd, onTransitionEnded, false);
                element.style.transition = 'transition: none !important';
                resolve();
            };
            const rotationAngle = continuityAngle(this.previousAngle, angle);
            if (transitionEndEventName !== null) {
                element.addEventListener(transitionEnd, onTransitionEnded, false);
                element.style.transition = `transform 1s ease-out`;
            }
            element.style.transform += `rotate(${rotationAngle}deg)`;
            this.previousAngle = angle;
        });
    }
    animateStep(marker, startTime, duration, startPosition, targetPosition) {
        const ellapsedTime = Date.now() - startTime;
        const durationRatio = ellapsedTime / duration;
        if (durationRatio < 1) {
            const deltaPosition = latlng.add(startPosition, latlng.mulScalar(latlng.subtract(targetPosition, startPosition), durationRatio));
            marker.setLngLat(latlng.mapboxCoordinate(deltaPosition));
            this.moveId = requestAnimationFrame(this.animateStep.bind(this, marker, startTime, duration, startPosition, targetPosition));
        }
        else {
            marker.setLngLat(latlng.mapboxCoordinate(targetPosition));
        }
    }
    animateTo(targetPosition) {
        if (this.moveId !== null) {
            cancelAnimationFrame(this.moveId);
        }
        const { marker } = this;
        const currentPosition = marker.getLngLat();
        this.animateStep(marker, Date.now(), ANIMATE_MOVE_TIME, currentPosition, targetPosition);
    }
    updateDistanceCheckpoint(position) {
        const distance = latlng.sphericalDistance(this.marker.getLngLat(), position);
        this.lastDistanceCheckpoint = distance;
    }
    handleManualMapEvent() {
        this.autoFit = false;
        if (this.manualEventId) {
            clearTimeout(this.manualEventId);
        }
        this.manualEventId = setTimeout(() => {
            this.autoFit = true;
            this.fitMapTo(this.marker.getLngLat());
        }, MANUAL_CAMERA_TIMEOUT);
    }
    initMap() {
        const { deliveryAddress, position, consignment } = this.props;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/budbee/ck6izjrl70eny1ip1i6jebofe',
            center: latlng.mapboxCoordinate(position.coordinate),
            zoom: 15,
        });
        const getIcon = () => {
            if (brand === 'budbee' && (consignment === null || consignment === void 0 ? void 0 : consignment.vehicleIcon)) {
                return vehicleIcon(consignment.vehicleIcon);
            }
            if (brand === 'instabox') {
                return imageList.VehicleInstabox;
            }
            if (brand === 'porterbuddy') {
                return imageList.VehiclePorterbuddy;
            }
            return '';
        };
        const getMarkerIcon = () => {
            if (brand === 'budbee') {
                return imageList.markerDeliveryAddress;
            }
            if (brand === 'instabox') {
                return imageList.markerDeliveryAddressRed;
            }
            if (brand === 'porterbuddy') {
                return imageList.markerDeliveryPorterbuddy;
            }
            return '';
        };
        this.marker = this.loadMarker(position.coordinate, getIcon());
        if (deliveryAddress.coordinate && position) {
            this.deliveryMarker = this.loadMarker(deliveryAddress.coordinate, getMarkerIcon());
            this.fitMapTo(position.coordinate);
        }
        this.map.on('move', () => {
            this.handleManualMapEvent();
        });
    }
    loadMarker(coordinate, icon) {
        const marker = this.createMarker(coordinate, icon);
        return marker;
    }
    createMarker(coordinate, marker) {
        const content = document.createElement('img');
        content.src = marker;
        content.width = 24;
        content.style.cursor = 'pointer';
        content.style.position = 'relative';
        content.style.width = '30px';
        content.style.height = '60px';
        const container = document.createElement('div');
        container.style.position = 'absolute';
        container.style.top = '30px';
        container.appendChild(content);
        return new mapboxgl.Marker(container, { offset: [0, -50 / 2] })
            .setLngLat(latlng.mapboxCoordinate(coordinate))
            .addTo(this.map);
    }
    render() {
        const { open, close, merchant, driver, eta, isReturn, identification, consignment } = this.props;
        const climateBannerText = consignment
            ? climate.getClimateBanner(consignment.vehicleIcon, isReturn)
            : null;
        return (_jsxs(_Fragment, { children: [consignment && open && climateBannerText !== null && brand !== 'porterbuddy' ? (_jsx("span", { className: css(styles.topLeft), children: _jsx(ClimateInformation, { climateBannerText: climateBannerText }) })) : null, open ? (_jsx("span", { className: css(styles.topRight), children: _jsx(IconButton, { onClick: () => {
                            close();
                        }, src: imageList.Close, variant: "roundFilled", size: Spacing.md, color: Color.Black, padding: Spacing.sm }) })) : null, _jsx("div", { className: css([styles.mapContainer, open ? styles.open : styles.closed]), children: _jsxs("div", { className: css(styles.mapModal), children: [_jsx("div", { ref: (el) => {
                                    this.mapContainer = el;
                                }, className: css(styles.trackingMap) }), brand !== 'porterbuddy' && (_jsx(DriverStatusCard, { isReturn: isReturn, merchant: merchant, driver: driver, eta: eta, code: identification && identification.code }))] }) })] }));
    }
}
