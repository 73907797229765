import { createReducer } from '../../utils/create-reducer';
import { SET_LANGUAGE, SET_COUNTRY, SET_DEFAULT_LOCALE, SET_CURRENT_LOCALE, SET_TIMEZONE, } from './actions';
const initState = {
    currentLocale: '',
    defaultLocale: '',
    language: '',
    country: '',
    timeZone: null,
};
export const locale = createReducer(initState, {
    [SET_CURRENT_LOCALE]: (state = initState, action) => ({
        ...state,
        currentLocale: action.payload.locale,
    }),
    [SET_DEFAULT_LOCALE]: (state = initState, action) => ({
        ...state,
        defaultLocale: action.payload.locale,
    }),
    [SET_LANGUAGE]: (state = initState, action) => ({
        ...state,
        language: action.payload.language,
    }),
    [SET_COUNTRY]: (state = initState, action) => ({
        ...state,
        country: action.payload.country,
    }),
    [SET_TIMEZONE]: (state = initState, action) => ({
        ...state,
        timeZone: action.payload.timeZone,
    }),
});
