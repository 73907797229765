import { createReducer } from '../../utils/create-reducer';
import { SOCKET_CONNECTED, SOCKET_CONNECTING, SOCKET_DISCONNECTED } from './actions';
const initState = {
    isConnected: false,
    isConnecting: false,
};
export const socket = createReducer(initState, {
    [SOCKET_CONNECTED]: (state = initState) => ({
        ...state,
        isConnected: true,
        isConnecting: false,
    }),
    [SOCKET_CONNECTING]: (state = initState) => ({
        ...state,
        isConnecting: true,
    }),
    [SOCKET_DISCONNECTED]: (state = initState) => ({
        ...state,
        isConnected: false,
        isConnecting: false,
    }),
});
