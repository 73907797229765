import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect } from 'react';
import { ParcelStatus } from '@budbee/js/tracking';
import { ResponseStatus } from '@budbee/js/tracking-api';
import PageVisibility from 'react-page-visibility';
import { connect, useDispatch } from 'react-redux';
import { HomeTracking } from '../../components/home-tracking';
import { useTrackingApi } from '../../hooks/use-tracking-api';
import { fetchCachedEta } from '../../state/eta/actions';
import * as orderActions from '../../state/order/actions';
import { fetchOrder } from '../../state/order/actions';
import { useConspectus } from '../../state/order/hooks';
import { getAuthenticated, getIsFetchingOrder, getIsReturnConsignment, } from '../../state/order/selectors';
import { getAdyenPaymentStatus, getPaymentRequired } from '../../state/payment/selectors';
import { getPosition } from '../../state/positions/actions';
import { getLatestPosition } from '../../state/positions/selectors';
import { setSteps as setStepsAction, showSteps as showStepsAction, } from '../../state/preface/actions';
import { getCurrentSteps, getSteps, getStepsVisible } from '../../state/preface/selectors';
import { getReturnType } from '../../state/returns/selectors';
import { initSockets } from '../../state/socket/actions';
import { useSocketIsConnected, useSocketIsConnecting } from '../../state/socket/hooks';
import { shouldRequestPositionForStatus } from '../../utils/statuses';
const TrackingContainerInner = (props) => {
    const isSocketConnected = useSocketIsConnected();
    const isSocketConnecting = useSocketIsConnecting();
    const conspectus = useConspectus();
    const dispatch = useDispatch();
    const trackingApi = useTrackingApi();
    const { token, authToken, isFetching, setLoadingFinished } = props;
    useEffect(() => {
        dispatch(fetchOrder(token, authToken));
    }, [authToken, dispatch, token]);
    const handleVisibilityChange = useCallback((isVisible) => {
        if (!conspectus || conspectus.identification == null) {
            return;
        }
        const { driver, status } = conspectus;
        if (isVisible &&
            !conspectus.identification.needsVerification &&
            status.state !== ParcelStatus.Delivered) {
            dispatch(fetchCachedEta(token));
            trackingApi
                .getCachedDriverPosition(token, authToken || '')
                .call()
                .then((response) => {
                if (response.status === ResponseStatus.COMPLETED) {
                    dispatch(getPosition(response.payload));
                }
            });
            if (!isSocketConnected && !isSocketConnecting) {
                const requestPositions = status ? shouldRequestPositionForStatus(status.state) : false;
                if (driver) {
                    dispatch(initSockets({
                        token,
                        authToken,
                        driver,
                        requestPositions,
                    }));
                }
            }
        }
    }, [dispatch, isSocketConnected, isSocketConnecting, conspectus, props, trackingApi]);
    useEffect(() => {
        if (conspectus && !isFetching) {
            setLoadingFinished();
        }
    }, [isFetching, props, setLoadingFinished, conspectus]);
    if (!conspectus || isFetching) {
        return null;
    }
    return (_jsx(PageVisibility, { onChange: handleVisibilityChange, children: _jsx(HomeTracking, { ...props }) }));
};
const mapStateToProps = (state) => ({
    isFetching: getIsFetchingOrder(state),
    authenticated: getAuthenticated(state),
    isReturn: getIsReturnConsignment(state),
    paymentRequired: getPaymentRequired(state),
    adyenPaymentStatus: getAdyenPaymentStatus(state),
    steps: getCurrentSteps(state),
    prefaceSteps: getSteps(state),
    prefaceVisible: getStepsVisible(state),
    position: getLatestPosition(state),
    returnType: getReturnType(state),
});
const mapDispatchToProps = (dispatch) => ({
    setSteps: (steps, keepIndex) => dispatch(setStepsAction(steps, keepIndex)),
    showSteps: (show) => dispatch(showStepsAction(show)),
    reloadOrder: () => dispatch(orderActions.reloadOrder()),
});
export const TrackingContainer = connect(mapStateToProps, mapDispatchToProps)(TrackingContainerInner);
