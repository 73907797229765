import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { IconSize } from '../../styles';
export const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginBottom: Space.md,
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.sm,
        porterbuddy: {
            marginBottom: Space.sm,
        },
    },
    disabled: {
        cursor: 'default',
        boxShadow: 'none',
        border: 'none',
        opacity: 0.5,
    },
    itemDivider: {
        height: Border.Width.sm,
        backgroundColor: Color.Budbee.Grey1,
        marginLeft: Space.lg,
        marginRight: Space.lg,
        porterbuddy: {
            display: 'none',
        },
    },
    itemWrapper: {
        background: 'none',
        border: 'none',
        padding: 0,
        outline: 0,
        margin: 0,
        width: '100%',
        paddingLeft: Space.lg,
        paddingRight: Space.lg,
    },
    itemTitle: {
        minHeight: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        porterbuddy: {
            minHeight: 56,
        },
    },
    itemIconRight: {
        marginLeft: 'auto',
        height: IconSize.xs,
        width: IconSize.xs,
        porterbuddy: {
            width: IconSize.md,
            height: IconSize.md,
        },
    },
    itemIconRightClose: {
        color: Color.Budbee.ReadyRed,
        porterbuddy: {
            color: Color.Porterbuddy.RosyRed,
        },
    },
    itemTitleText: {
        ...Typography.Budbee.Web.h4,
        flexWrap: 'wrap',
        margin: 0,
        padding: 0,
        textAlign: 'left',
        porterbuddy: {
            ...Typography.Porterbuddy.Web['body-lg'],
            marginRight: Space.md,
        },
    },
    itemContentWrapper: {
        overflow: 'hidden',
    },
});
