import { IdentificationProvider, ReturnType } from '@budbee/js/tracking';
import { getBrand } from './brand/get-brand';
import { BANK_ID_METHOD, BANK_ID_QR, BOX_RETURN_LOCKERS, BOX_RETURN_PARCEL_DIMENSIONS, BOX_RETURN_SAME_PACKAGE, BOX_RETURN_SUMMARY, CHANGE_LOCKER_CONFIRM, CHANGE_RETURN_PHOTO, EXTEND_LOCKER_DURATION, PAYMENT, REBOOK_DELIVERY, REBOOK_EXPIRED_BOX_DELIVERY, RETURN_INSTRUCTIONS, RETURN_SELECT_DATE, RETURN_TYPE_SELECTION, SUMMARY, SWITCH_DELIVERY_TYPE_CONFIRM, } from './steps';
export const getIdentificationSteps = (provider, orderToken, authToken) => {
    if (provider === IdentificationProvider.BANK_ID) {
        return [
            {
                id: BANK_ID_METHOD,
                props: { orderToken, authToken },
                canCancel: true,
            },
            {
                id: BANK_ID_QR,
                props: { orderToken },
                canCancel: true,
            },
        ];
    }
    return [];
};
export const getNewDeliverySteps = () => [
    {
        id: REBOOK_DELIVERY,
        canCancel: true,
    },
];
export function getReturnSteps({ returnType, orderType, isReturn, reloadOrderType, isCancelled, isMissed, onContactUsClick, paymentRequired, showBoxSamePackage, samePackage, }) {
    const isCancelledReturn = isReturn && isCancelled;
    const isMissedReturn = isReturn && isMissed;
    if (!returnType && !isReturn) {
        return [
            {
                id: RETURN_TYPE_SELECTION,
                props: {
                    onContactUsClick,
                },
                canCancel: true,
            },
        ];
    }
    if (returnType === ReturnType.BOX) {
        return getBoxReturnSteps({
            onContactUsClick,
            orderType,
            reloadOrderType,
            isReturn,
            isCancelledReturn,
            isMissedReturn,
            paymentRequired,
            showBoxSamePackage,
            samePackage,
        });
    }
    return getHomeReturnSteps({
        onContactUsClick,
        orderType,
        reloadOrderType,
        isReturn,
        isCancelledReturn,
        isMissedReturn,
    });
}
function getBoxReturnSteps({ onContactUsClick, orderType, reloadOrderType, isReturn, isCancelledReturn, isMissedReturn, paymentRequired, showBoxSamePackage, samePackage, }) {
    const boxSteps = [
        {
            id: RETURN_TYPE_SELECTION,
            props: {
                onContactUsClick,
            },
            canCancel: true,
        },
        {
            id: RETURN_INSTRUCTIONS,
            props: {
                returnType: ReturnType.BOX,
                orderType,
            },
            canCancel: true,
        },
        {
            id: BOX_RETURN_PARCEL_DIMENSIONS,
            canCancel: true,
        },
        {
            id: BOX_RETURN_LOCKERS,
            canCancel: true,
            props: {
                boxSamePackageAllowed: showBoxSamePackage,
            },
        },
        {
            id: BOX_RETURN_SUMMARY,
            props: {
                orderType,
                reloadOrderType,
            },
            canCancel: true,
        },
    ];
    if (isReturn && !isCancelledReturn && !isMissedReturn) {
        const index = boxSteps.findIndex((s) => s.id === RETURN_TYPE_SELECTION);
        boxSteps.splice(index, 1);
    }
    if (paymentRequired) {
        boxSteps.push({
            id: PAYMENT,
            props: { reloadOrderType },
            canCancel: true,
        });
    }
    if (showBoxSamePackage) {
        boxSteps.splice(1, 0, { id: BOX_RETURN_SAME_PACKAGE, canCancel: true });
        if (samePackage) {
            const index = boxSteps.findIndex((s) => s.id === BOX_RETURN_PARCEL_DIMENSIONS);
            boxSteps.splice(index, 1);
        }
    }
    return boxSteps;
}
function getHomeReturnSteps({ onContactUsClick, orderType, reloadOrderType, isReturn, isCancelledReturn, isMissedReturn, }) {
    const homeSteps = [
        {
            id: RETURN_TYPE_SELECTION,
            props: {
                onContactUsClick,
            },
            canCancel: true,
        },
        {
            id: RETURN_INSTRUCTIONS,
            props: {
                returnType: ReturnType.HOME_PICKUP,
                orderType,
            },
            canCancel: true,
        },
        {
            id: RETURN_SELECT_DATE,
            props: {
                orderType,
                reloadOrderType,
            },
            canCancel: true,
        },
    ];
    if (isReturn && !isCancelledReturn && !isMissedReturn) {
        const index = homeSteps.findIndex((s) => s.id === RETURN_TYPE_SELECTION);
        homeSteps.splice(index, 1);
    }
    return homeSteps;
}
export const getChangeReturnPhotoSteps = () => [
    {
        id: CHANGE_RETURN_PHOTO,
        canCancel: true,
    },
];
export const getConfirmChangeLockerLocation = (closeModal, reloadOrder, authToken) => {
    return [
        {
            id: CHANGE_LOCKER_CONFIRM,
            props: { closeModal, reloadOrder, authToken },
            canCancel: true,
        },
    ];
};
export const getSwitchToHomeDeliverySteps = (consumerAddress, authToken, reloadOrderType) => {
    return [
        {
            id: SWITCH_DELIVERY_TYPE_CONFIRM,
            props: { consumerAddress, authToken, reloadOrderType },
            canCancel: true,
        },
    ];
};
export const getSwitchToBoxDeliverySteps = (closeModal, authToken, reloadOrderType) => {
    return [
        {
            id: SWITCH_DELIVERY_TYPE_CONFIRM,
            props: { closeModal, authToken, reloadOrderType },
            canCancel: true,
        },
    ];
};
export const getExtendLockerDurationSteps = (latestPickupDate) => {
    return [
        {
            id: EXTEND_LOCKER_DURATION,
            canCancel: true,
            props: { latestPickupDate },
        },
    ];
};
export const getRebookExpiredBoxDeliverySteps = () => {
    return [
        {
            id: REBOOK_EXPIRED_BOX_DELIVERY,
            canCancel: true,
        },
    ];
};
export const getSummarySteps = () => {
    return [
        {
            id: SUMMARY,
            canCancel: true,
            prefaceHeader: getBrand() === 'porterbuddy',
            props: {
                showDeliveryTime: false,
            },
        },
    ];
};
