import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import imageList from '../../../utils/image-list';
import { Icon } from '../../shared/icon';
import { View } from '../../shared/view';
import { styles } from '../style';
export const FaceScoring = (props) => {
    const { score, onChangeScore, disabled } = props;
    const { t } = useI18n();
    const getRating = () => {
        if (score === 1) {
            return {
                icon: imageList.FaceSadOutlined,
                title: t('rating.bad'),
            };
        }
        if (score === 3) {
            return {
                icon: imageList.FaceOKOutlined,
                title: t('rating.ok'),
            };
        }
        if (score === 5) {
            return {
                icon: imageList.FaceHappyOutlined,
                title: t('rating.great'),
            };
        }
        return {
            icon: '',
            title: '',
        };
    };
    if (disabled) {
        const { icon, title } = getRating();
        return (_jsxs(View, { style: styles.thankYouView, children: [_jsx(Icon, { src: icon, size: 48 }), _jsx("span", { className: css(styles.rateTitle), children: title }), _jsx("span", { className: css(styles.titleAlt), children: t('reviewModal.yourRatingForThisDelivery') }), _jsx("span", { className: css(styles.subtitleAlt), children: t('reviewModal.thankYouForYourFeedback') })] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('reviewModal.faceScoringTitle') }), _jsx("span", { className: css(styles.subtitle), children: t('reviewModal.faceScoringSubtitle') }), _jsxs("div", { className: css(styles.container), children: [_jsxs("button", { type: "button", onClick: () => onChangeScore(1), className: css(styles.rating), "data-test": "star-icon", disabled: disabled, children: [_jsx(Icon, { src: imageList.FaceSad, size: 48 }), _jsx("span", { className: css(styles.rateTitle), children: t('rating.bad') })] }), _jsxs("button", { type: "button", onClick: () => onChangeScore(3), className: css(styles.rating), "data-test": "star-icon", disabled: disabled, children: [_jsx(Icon, { src: imageList.FaceOK, size: 48 }), _jsx("span", { className: css(styles.rateTitle), children: t('rating.ok') })] }), _jsxs("button", { type: "button", onClick: () => onChangeScore(5), className: css(styles.rating), "data-test": "star-icon", disabled: disabled, children: [_jsx(Icon, { src: imageList.FaceHappy, size: 48 }), _jsx("span", { className: css(styles.rateTitle), children: t('rating.great') })] })] })] }));
};
