import { jsx as _jsx } from "react/jsx-runtime";
import { Contact } from '../../components/modal/contact';
import { useBoxOrder } from '../../state/box-order/hooks';
import { useLanguage } from '../../state/locale/hooks';
import { localizeMerchantName } from '../../utils/locale';
export const BoxContactContainer = ({ lockerAddress, authToken, orderToken, }) => {
    const boxOrder = useBoxOrder();
    const language = useLanguage();
    if (!boxOrder) {
        return null;
    }
    const { merchant, consumer, authorized } = boxOrder;
    const { countryCode } = lockerAddress;
    const { supportPhone, supportEmail, supportLink, name } = merchant;
    const contactProps = {
        authenticated: authorized,
        orderToken,
        authToken,
        countryCode,
        supportEmail,
        supportPhone,
        supportLink,
        merchantName: localizeMerchantName(name, language),
        consumer,
        boxOrder: true,
    };
    return _jsx(Contact, { ...contactProps });
};
