import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { Transition } from '../../styles';
export const styles = StyleSheet.create({
    footer: {
        marginTop: Space['2xl'],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: Transition.Ease,
    },
});
