import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { css } from '@css';
import { Color, IconSize } from '../../../styles';
import imageList from '../../../utils/image-list';
import { inlineStyles } from '../../../utils/inline-styles';
import { IconButton } from '../../icon-button';
import { styles } from './style';
export const SearchBar = ({ canClear = true, placeholder, keyword = '', onChange, onClear, onSubmit, submitting = false, submitButtonIcon, isPickingFromMap, }) => {
    const onKeywordChange = useCallback((e) => onChange(e.target.value), [onChange]);
    const clearSearchBar = useCallback(() => {
        onClear();
        onChange('');
    }, [onChange, onClear]);
    return (_jsxs("div", { className: css(styles.wrapper), children: [_jsxs("div", { className: css(styles.container, isPickingFromMap && inlineStyles({ backgroundColor: Color.White })), children: [_jsx("div", { className: css(styles.iconWrapper), children: _jsx("div", { className: css(styles.iconContainer), children: _jsx("img", { src: imageList.Search, alt: "icon", className: css(styles.searchIcon) }) }) }), _jsx("div", { className: css(styles.inputWrapper), children: _jsx("input", { value: keyword, onChange: onKeywordChange, placeholder: placeholder, className: css(styles.input) }) }), canClear && keyword.length > 0 && (_jsx("div", { className: css(styles.clearIconWrapper), children: _jsx(IconButton, { size: IconSize['4xs'], src: imageList.Close, color: Color.SecondaryGreen, variant: "roundFilled", onClick: clearSearchBar, id: "search-bar-clear-button" }) }))] }), onSubmit && (_jsx("div", { className: css(styles.submitButtonWrapper), children: _jsx(IconButton, { loading: submitting, id: "search-bar-submit-button", variant: "roundFilled", color: Color.DarkGreen, src: submitButtonIcon, size: IconSize.xs, onClick: onSubmit }) }))] }));
};
