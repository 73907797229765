import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        backgroundColor: Color.White100,
        paddingTop: Space.sm,
        paddingBottom: Space.lg,
        marginBottom: 0,
        ...paddingHorizontal(Space['2xl']),
    },
    header: {
        marginTop: Space.lg,
        marginBottom: Space.xl,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        textAlign: 'left',
        display: 'flex',
        ...Typography.Porterbuddy.Web.h2,
    },
    button: {
        ...paddingHorizontal(Space.lg),
        borderRadius: Border.Radius['2xs'],
        minWidth: 64,
        minHeight: 38,
    },
    itemTitle: {
        ...Typography.Porterbuddy.Web.h5,
        color: Color.Porterbuddy.Grey4,
        marginBottom: Space.xs,
    },
    itemText: {
        ...Typography.Porterbuddy.Web['body-lg'],
        color: Color.Porterbuddy.Grey3,
        marginBottom: Space.md,
    },
    divider: {
        height: '1px',
        backgroundColor: Color.Porterbuddy.PalPurple,
        width: '100%',
        marginBottom: Space.md,
    },
});
