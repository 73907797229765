import { timetable as timetableUtils } from '@budbee/js/tracking';
import { createReducer } from '../../utils/create-reducer';
import { FETCH_TIME_WINDOWS_COMPLETED, FETCH_TIME_WINDOWS_FAILED, FETCH_TIME_WINDOWS_STARTED, SET_TIME_WINDOWS, } from './actions';
const initState = {
    timeWindows: [],
    isFetching: false,
    error: null,
};
export const timetable = createReducer(initState, {
    [SET_TIME_WINDOWS]: (state = initState, action) => ({
        ...state,
        timeWindows: timetableUtils.timeWindowSorter(action.payload.timeWindows),
        isFetching: false,
    }),
    [FETCH_TIME_WINDOWS_COMPLETED]: (state = initState, action) => ({
        ...state,
        timeWindows: timetableUtils.timeWindowSorter(action.payload.timeWindows),
        isFetching: false,
    }),
    [FETCH_TIME_WINDOWS_STARTED]: (state = initState) => ({
        ...state,
        isFetching: true,
        timeWindows: [],
        error: null,
    }),
    [FETCH_TIME_WINDOWS_FAILED]: (state = initState, action) => ({
        ...state,
        isFetching: false,
        error: action.payload.error,
    }),
});
