import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { BaseButton } from '../../../../components/base-button';
import { useI18n } from '../../../../hooks/use-i18n';
import imageList from '../../../../utils/image-list';
import { styles } from '../style';
export function EmptyReturnOptions({ onClick }) {
    const { t } = useI18n();
    return (_jsxs("div", { className: css(styles.noReturns), children: [_jsx("span", { className: css(styles.title), children: t('chooseReturnType') }), _jsxs("div", { className: css(styles.noReturnsContainer), children: [_jsx("img", { className: css(styles.errorIcon), src: imageList.Error, alt: "error" }), _jsx("span", { className: css(styles.noReturnOptionsHeader), children: t('errorMessageHeader') }), _jsx("span", { className: css(styles.noReturnOptionsSubtitle), children: t('noReturnOptionsContactSupport') }), _jsx("div", { className: css(styles.contactUs) }), _jsx(BaseButton, { onClick: onClick, label: t('contactUs') }), _jsx("div", { className: css(styles.divider) })] })] }));
}
