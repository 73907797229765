import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Color } from '@budbee/js/dist/ui';
import { css } from '@css';
import { IconSize } from '../../styles';
import { BrandedIcon } from '../../utils/brand/icon';
import imageList from '../../utils/image-list';
import { TextInput } from '../shared/text-input';
import { View } from '../shared/view';
import { styles } from './style';
export const SelectFloor = (props) => {
    const { floor, onFloorUpdated, setValid } = props;
    const [tempFloor, setTempFloor] = useState(floor || floor === 0 ? floor.toString() : '');
    const onChangeFloor = useCallback((newFloor) => {
        const parsedFloor = parseInt(newFloor, 10);
        const isValid = Number.isInteger(parsedFloor);
        if (isValid) {
            onFloorUpdated(parsedFloor);
        }
        setValid === null || setValid === void 0 ? void 0 : setValid(isValid);
        setTempFloor(isValid ? parsedFloor.toString() : newFloor);
    }, [setValid, onFloorUpdated, setTempFloor]);
    const changeFloorValue = useCallback((value) => {
        const parsedFloor = parseInt(tempFloor, 10);
        const newFloor = parsedFloor + value;
        const isValid = Number.isInteger(newFloor);
        if (!isValid) {
            setValid === null || setValid === void 0 ? void 0 : setValid(isValid);
            onChangeFloor('0');
            return;
        }
        onChangeFloor(newFloor.toString());
    }, [tempFloor, setValid, onChangeFloor]);
    const decrement = useCallback(() => changeFloorValue(-1), [changeFloorValue]);
    const increment = useCallback(() => changeFloorValue(1), [changeFloorValue]);
    return (_jsxs(View, { style: styles.root, children: [_jsx("button", { onClick: decrement, type: "button", className: css(styles.button), children: _jsx(BrandedIcon, { color: {
                        budbee: Color.Budbee.GreenDark,
                        instabox: Color.Instabox.ReallyRed,
                        porterbuddy: Color.Porterbuddy.BuddyPurple,
                    }, src: imageList.Minus, size: IconSize.sm }) }), _jsx(TextInput, { value: tempFloor, style: styles.input, placeholder: "e.g. 1", onChange: (newFloor) => onChangeFloor(newFloor) }), _jsx("button", { onClick: increment, type: "button", className: css(styles.button), children: _jsx(BrandedIcon, { color: {
                        budbee: Color.Budbee.GreenDark,
                        instabox: Color.Instabox.ReallyRed,
                        porterbuddy: Color.Porterbuddy.BuddyPurple,
                    }, src: imageList.Plus, size: IconSize.xs }) })] }));
};
