import * as latlng from './latlng';
export const continuityAngle = (from, to) => {
    if (from === null) {
        return to;
    }
    if (from === to) {
        return 0;
    }
    const angle = 180 - Math.abs(Math.abs(to - from) - 180);
    const left = -angle;
    const right = angle;
    if (to < from && to > from - 180) {
        return left;
    }
    if (from - 180 < 0 && to > 360 + (from - 180)) {
        return left;
    }
    return right;
};
export const angleBetweenCoordinates = (from, to) => {
    const dLon = latlng.longitude(from) - latlng.longitude(to);
    const dLat = latlng.latitude(from) - latlng.latitude(to);
    return 180 + (Math.atan2(dLon, dLat) * 180) / Math.PI;
};
