import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDateFormat } from '../../hooks/use-date-format';
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { styles } from '../box-header/style';
export const ReturnToLockerBoxHeader = ({ authenticated, code, needsVerification, stop, allowedToCancelReservation, toggleCancelBoxReturnModal, }) => {
    const { t } = useI18n();
    const format = useDateFormat();
    const instructions = (() => {
        if (needsVerification) {
            return t('pinCodeInstructionsVerification');
        }
        if (code) {
            return t('pinCodeInstructions');
        }
        return null;
    })();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('readyToBeReturned'), middleText: stop != null
                    ? t('latestReturn', {
                        date: format.formatDateTimeWithClockSymbol(stop, 'lowercase'),
                    })
                    : undefined, bottomText: instructions }), authenticated ? (_jsx(View, { style: styles.actionsWrapper, children: allowedToCancelReservation ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('cancelReturn'), iconSrc: imageList.CancelReturn, onClick: toggleCancelBoxReturnModal, variant: "primary" }) })) : null })) : null] }));
};
