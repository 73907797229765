import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { MediaQuery, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.md,
        backgroundColor: Color.White100,
        ...paddingVertical(Space.xl),
        ...paddingHorizontal(Space['2xl']),
        flexDirection: 'column',
        [MediaQuery.maxWidthLg]: {
            ...paddingHorizontal(Space.lg),
        },
        porterbuddy: {
            ...paddingHorizontal(Space.sm),
            ...paddingVertical(Space.lg),
        },
    },
    header: {
        flexDirection: 'row',
        marginBottom: Space.lg,
        alignItems: 'center',
    },
    headerInner: {
        flex: 1,
    },
    headerTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        ...Typography.Budbee.Web.h5,
    },
    text: {
        ...Typography.Budbee.Web['body-lg'],
    },
    pin: {
        marginRight: Space.md,
        color: Color.Budbee.Grey6,
    },
    copy: {
        height: Space.lg,
        width: Space.lg,
        color: Color.Budbee.Grey6,
    },
    codeWrapper: {
        flexDirection: 'row',
        gap: Space.sm,
        width: '100%',
        justifyContent: 'center',
    },
    codeInnerWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.Budbee.GreenLight,
        padding: Space.lg,
        borderRadius: Border.Radius.sm,
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.PalPurple,
            borderRadius: Border.Radius['2xs'],
        },
        [MediaQuery.minWidthSm]: {
            ...paddingHorizontal(Space['5xl']),
        },
    },
    code: {
        ...Typography.Budbee.Web.display,
        color: Color.Budbee.GreenDark,
        height: Typography.Budbee.Web.display.fontSize,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h1,
            color: Color.Porterbuddy.BuddyPurple,
        },
    },
    codeShort: {
        marginLeft: Space['2xl'],
        marginRight: Space['2xl'],
        porterbuddy: {
            marginLeft: Space.sm,
            marginRight: Space.sm,
        },
    },
});
