import { Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { FontSize, marginHorizontal, marginVertical } from '../../../styles';
export const styles = StyleSheet.create({
    title: {
        fontSize: FontSize.lg,
        marginTop: Space.lg,
        textAlign: 'center',
    },
    subtitle: {
        ...marginHorizontal(Space.lg),
        ...marginVertical(Space['2xl']),
    },
    center: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        ...marginHorizontal(Space.lg),
    },
});
