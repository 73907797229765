import { Color, Space } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, paddingHorizontal, paddingVertical } from '../../styles';
export const styles = StyleSheet.create({
    climateCompensation: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Color.Budbee.GreenPop,
        color: Color.White100,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space.sm),
        borderRadius: BorderRadius.md,
    },
    icon: {
        height: Space.lg,
        marginRight: Space.lg,
    },
    anchor: {
        color: 'inherit',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
});
