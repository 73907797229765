import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { FontWeight, paddingHorizontal, paddingVertical } from '../../../styles';
export const styles = StyleSheet.create({
    title: {
        marginTop: Space.lg,
        marginLeft: Space.sm,
        ...Typography.Budbee.Web.h3,
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space.sm,
    },
    options: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: Space.lg,
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.md,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space['2xl']),
    },
    divider: {
        height: Space.sm,
    },
    addressContainer: {
        ...paddingHorizontal(Space.sm),
        marginTop: Space.sm,
    },
    address: {
        ...Typography.Budbee.Web['body-md'],
    },
    street: {
        ...Typography.Budbee.Web['body-md'],
        fontWeight: FontWeight.Bold,
    },
    error: {
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.ReadyRed,
    },
});
