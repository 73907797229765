import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { BannerColor } from '@budbee/js/tracking';
import { useDispatch } from 'react-redux';
import { clearBanner } from '../../state/banner/actions';
import { useBanner } from '../../state/banner/hooks';
import { useLanguage } from '../../state/locale/hooks';
import { IconSize } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { AnimatedView } from '../shared/animated-view';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const Banner = () => {
    const banner = useBanner();
    const language = useLanguage();
    const brand = getBrand();
    const dispatch = useDispatch();
    const onClose = useCallback(() => {
        dispatch(clearBanner());
    }, [dispatch]);
    if (!banner) {
        return null;
    }
    const { localMessage, englishMessage, color } = banner;
    return (_jsxs(AnimatedView, { animation: "slideInDown", style: [styles.root, color === BannerColor.ALERT ? styles.alert : undefined], children: [brand === 'budbee' && (_jsx(View, { style: [styles.leftIcon, color === BannerColor.ALERT ? styles.leftIconAlert : undefined], children: _jsx(Icon, { src: color === BannerColor.ALERT ? imageList.InfoFilledRed : imageList.InfoFilledGreen, size: IconSize['3xl'] }) })), _jsx(View, { style: [styles.message, color === BannerColor.ALERT ? styles.messageAlert : undefined], children: language === 'en' ? englishMessage : localMessage }), _jsx(View, { style: [styles.close, color === BannerColor.ALERT ? styles.closeAlert : undefined], onClick: onClose, children: _jsx(Icon, { src: imageList.Close, size: IconSize.xs }) })] }));
};
