import { ConsignmentType } from '@budbee/js/tracking';
import { createSelector } from '@reduxjs/toolkit';
import { prop } from '../../utils/prop';
export const getConspectus = createSelector((state) => state.order, (order) => order.conspectus);
export const getConsignment = createSelector((state) => state.order, (order) => order.consignment);
export const getIsReloading = createSelector((state) => state.order, (order) => order.isReloading);
export const getIsReturn = createSelector((state) => state.order, (order) => { var _a, _b; return Boolean(((_b = (_a = order === null || order === void 0 ? void 0 : order.conspectus) === null || _a === void 0 ? void 0 : _a.consignment) === null || _b === void 0 ? void 0 : _b.type) === ConsignmentType.RETURN); });
export const getIsReturnConsignment = createSelector((state) => state.order, (order) => Boolean((order === null || order === void 0 ? void 0 : order.consignment) && (order === null || order === void 0 ? void 0 : order.consignment.type) === ConsignmentType.RETURN));
export const getToken = createSelector((state) => state.order, (order) => order.token);
export const getSwitchDeliveryTypePrice = createSelector((state) => state.order, (order) => ({
    canSwitchToHomeOrLockerFree: order.canSwitchToHomeOrLockerFree,
    switchDeliveryTypePrice: order.switchDeliveryTypePrice,
    switchDeliveryTypeMessage: order.switchDeliveryTypeMessage,
}));
export const getOrderType = createSelector((state) => state.order, (order) => order.type);
export const getAuthToken = createSelector((state) => state.order, (order) => order.authToken);
export const getSelectedLocker = createSelector((state) => state.order, (order) => order.selectedLocker);
export const getIsAllowedToChangeReturnPhoto = createSelector((state) => state.order, (order) => prop(['returns', 'photo', 'allowedToChangeReturnPhoto'])(order));
export const getHasReturnPhotoUploaded = createSelector((state) => state.order, (order) => prop(['returns', 'photo', 'hasPhotoUploaded'])(order));
export const getReturnableParcelCount = createSelector((state) => state.order, (order) => prop(['returns', 'returnableParcelCount'])(order));
export const getConsumerAddress = createSelector((state) => state.order, (order) => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.conspectus) === null || _a === void 0 ? void 0 : _a.address; });
export const getParcelDeliveryOverview = createSelector((state) => state.order, (order) => order.parcelDeliveryOverview);
export const getCanSwitchDeliveryType = createSelector((state) => state.order, (order) => order.canSwitchDeliveryType);
export const getIdentification = createSelector((state) => state.order, (order) => order.identification);
export const getReturnsEnabled = createSelector((state) => state.order, (order) => { var _a; return ((_a = order.returns) === null || _a === void 0 ? void 0 : _a.enabled) || false; });
export const getAllowedToBookDelivery = createSelector((state) => state.order, (order) => order.allowedToBookDelivery);
export const getAuthenticated = createSelector((state) => state.order, (order) => order.authenticated);
export const getIsFetchingOrder = createSelector((state) => state.order, (order) => order.isFetching);
export const getPrivacyNoticeLink = createSelector((state) => state.order, (order) => { var _a; return (_a = order.externalLinks) === null || _a === void 0 ? void 0 : _a.privacyNotice; });
export const getIntegrityPolicyLink = createSelector((state) => state.order, (order) => { var _a; return (_a = order.externalLinks) === null || _a === void 0 ? void 0 : _a.integrityPolicy; });
export const getSustainabilityLink = createSelector((state) => state.order, (order) => { var _a; return (_a = order.externalLinks) === null || _a === void 0 ? void 0 : _a.sustainability; });
export const getConspectusNudges = createSelector((state) => state.order, (order) => { var _a; return (_a = order.conspectus) === null || _a === void 0 ? void 0 : _a.nudges; });
export const getRoutedEta = createSelector((state) => state.order, (order) => order.routedEta);
