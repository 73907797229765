import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, FontWeight, paddingHorizontal, paddingVertical } from '../../../styles';
export const styles = StyleSheet.create({
    title: {
        marginTop: Space.lg,
        marginLeft: Space.sm,
        ...Typography.Budbee.Web.h3,
    },
    subtitle: {
        ...Typography.Budbee.Web['body-md'],
        marginTop: Space.sm,
    },
    options: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: Space.lg,
        backgroundColor: Color.White100,
        borderRadius: Border.Radius.md,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space['2xl']),
    },
    divider: {
        height: Space.lg,
    },
    addressContainer: {
        ...paddingHorizontal(Space.sm),
        marginTop: Space.sm,
    },
    address: {
        ...Typography.Budbee.Web['body-md'],
    },
    street: {
        ...Typography.Budbee.Web['body-md'],
        fontWeight: FontWeight.Bold,
    },
    error: {
        ...Typography.Budbee.Web['body-md'],
        color: Color.Budbee.ReadyRed,
    },
    errorIcon: {
        width: 100,
        height: 100,
        alignSelf: 'center',
        paddingTop: Space['2xl'],
    },
    noReturns: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    noReturnsContainer: {
        backgroundColor: 'white',
        borderRadius: BorderRadius.md,
        display: 'flex',
        flexDirection: 'column',
        padding: Space.md,
        marginTop: Space['2xl'],
    },
    noReturnOptionsHeader: {
        ...Typography.Budbee.Web.display,
        color: Color.Budbee.GreenPop,
        textAlign: 'center',
        alignSelf: 'center',
        paddingTop: Space['7xl'],
    },
    noReturnOptionsSubtitle: {
        ...Typography.Budbee.Web['body-lg'],
        textAlign: 'center',
        paddingTop: Space.md,
        paddingLeft: Space['3xl'],
        paddingRight: Space['3xl'],
    },
    contactUs: {
        paddingTop: Space['7xl'],
    },
    loader: {
        minHeight: '10rem',
    },
});
