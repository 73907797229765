import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { countries } from '@budbee/js/tracking';
import { css } from '@css';
import { IconSize } from '../../../styles';
import { getBrand } from '../../../utils/brand/get-brand';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
import { styles } from './style';
export const EmailContact = ({ countryCode }) => {
    const brand = getBrand();
    const emailAddress = useMemo(() => {
        if (brand === 'porterbuddy') {
            return 'support@porterbuddy.com';
        }
        if (brand === 'instabox') {
            if (countryCode === countries.DENMARK)
                return 'info@instabox.dk';
            if (countryCode === countries.NORWAY)
                return 'info@instabox.no';
            return 'info@instabox.se';
        }
        return null;
    }, [brand, countryCode]);
    return emailAddress ? (_jsxs("a", { className: css([styles.linkView]), href: `mailto:${emailAddress}`, children: [_jsx(BrandedIcon, { src: {
                    budbee: imageList.Email,
                    porterbuddy: imageList.PorterbuddyEmail,
                    instabox: imageList.InstaboxEmail,
                }, size: IconSize['3xl'] }), _jsx("span", { className: css(styles.link), children: emailAddress })] })) : null;
};
