import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { BorderRadius, paddingHorizontal, paddingVertical } from '../../../styles';
export const styles = StyleSheet.create({
    box: {
        marginTop: Space.xl,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Color.White100,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space.xl),
        borderRadius: BorderRadius.sm,
    },
    lockerName: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
        ...paddingHorizontal(Space.lg),
    },
    lockerBadge: {
        ...Typography.Budbee.Web.h4,
        backgroundColor: Color.Budbee.Grey1,
        ...paddingVertical(Space['2xs']),
        ...paddingHorizontal(Space.md),
        overflow: 'hidden',
        textTransform: 'capitalize',
        borderRadius: Border.Radius.sm,
        color: Color.Budbee.Grey5,
        fontSize: 12,
    },
    arrowContainer: {
        ...paddingVertical(Space.md),
        alignItems: 'center',
    },
    dot: {
        backgroundColor: Color.Budbee.GreenPop,
        width: 2.4,
        height: 3,
        borderRadius: 20,
        marginBottom: 2,
    },
    arrow: {
        transform: 'rotate(90deg)',
    },
    home: {
        backgroundColor: Color.White100,
        ...paddingHorizontal(Space.lg),
        ...paddingVertical(Space.xl),
        borderRadius: BorderRadius.sm,
        borderWidth: 1.5,
        borderColor: Color.Budbee.GreenPop,
    },
    homeContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    homeInner: {
        ...paddingHorizontal(Space.lg),
        marginRight: 'auto',
    },
    street: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
    },
    city: {
        ...Typography.Budbee.Web.h4,
        color: Color.Budbee.Grey5,
    },
    badgesRow: {
        marginTop: Space.lg,
        flexDirection: 'row',
    },
    homeBadge: {
        ...Typography.Budbee.Web.h4,
        backgroundColor: Color.Budbee.Grey1,
        ...paddingVertical(Space['2xs']),
        ...paddingHorizontal(Space.md),
        overflow: 'hidden',
        borderRadius: Border.Radius.sm,
        color: Color.Budbee.Grey5,
        fontSize: 12,
        textTransform: 'capitalize',
    },
});
