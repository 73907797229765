import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState, useEffect } from 'react';
import { Color } from '@budbee/js/ui';
import { css } from '@css';
import { inlineStyles } from '../../../utils/inline-styles';
import { styles } from './style';
export const HorizontalSwitch = ({ leftText, rightText, onChange, initialActiveIndex = 0, activeIndex, }) => {
    const [index, setIndex] = useState(initialActiveIndex);
    useEffect(() => {
        if (activeIndex !== undefined) {
            setIndex(activeIndex);
        }
    }, [activeIndex]);
    const onPress = useCallback((pressedIndex) => {
        setIndex(pressedIndex);
        onChange(pressedIndex);
    }, [setIndex, onChange]);
    const getBackgroundColor = useCallback((idx) => {
        return index === idx ? Color.Black100 : Color.White100;
    }, [index]);
    const getTextColor = useCallback((idx) => {
        return index === idx ? Color.White100 : Color.Black100;
    }, [index]);
    const getZIndex = useCallback((idx) => {
        return index === idx ? 2 : 1;
    }, [index]);
    return (_jsxs("div", { className: css(styles.root), children: [_jsx("button", { type: "button", className: css(styles.button, inlineStyles({
                    backgroundColor: getBackgroundColor(0),
                    marginRight: -20,
                    zIndex: getZIndex(0),
                })), onClick: () => onPress(0), children: _jsx("p", { className: css(styles.text, inlineStyles({ color: getTextColor(0) })), children: leftText }) }), _jsx("button", { type: "button", className: css(styles.button, inlineStyles({
                    backgroundColor: getBackgroundColor(1),
                    zIndex: getZIndex(1),
                    marginLeft: -20,
                })), onClick: () => onPress(1), children: _jsx("p", { className: css(styles.text, inlineStyles({ color: getTextColor(1) })), children: rightText }) })] }));
};
