import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { countries } from '@budbee/js/dist/tracking';
import { Space } from '@budbee/js/dist/ui';
import { css } from '@css';
import { BaseButton } from '../../../components/base-button';
import { ModalPresenterContainer } from '../../../container/modal-presenter';
import { useI18n } from '../../../hooks/use-i18n';
import { useWindowDimensions } from '../../../hooks/use-window-dimensions';
import { useBoxOrder, useIsBoxReturn } from '../../../state/box-order/hooks';
import { useCountryCode } from '../../../state/locale/hooks';
import imageList from '../../../utils/image-list';
import { inlineStyles } from '../../../utils/inline-styles';
import { Icon } from '../../shared/icon';
import { styles } from './style';
export const JointLockerModal = ({ isOpen, onClose }) => {
    var _a;
    const { t } = useI18n();
    const boxOrder = useBoxOrder();
    const isReturn = useIsBoxReturn();
    const countryCode = useCountryCode();
    const isDenmark = countryCode === countries.DENMARK;
    const { windowHeight } = useWindowDimensions();
    const color = (_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.lockerAttributes.color) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    const text = useMemo(() => {
        if (isDenmark) {
            return t('jointLocker.whereToPickup');
        }
        return isReturn ? t('jointLocker.returnModalHeading') : t('jointLocker.deliveryModalHeading');
    }, [isDenmark]);
    const subtitle = useMemo(() => {
        if (!isDenmark || !color)
            return null;
        return color === 'red'
            ? t('jointLocker.deliveredToRedBox')
            : t('jointLocker.deliveredToGreenBox');
    }, [isDenmark, color]);
    const boxImage = useMemo(() => {
        if (!color)
            return null;
        if (countryCode === countries.DENMARK) {
            return color === 'red' ? imageList.RedLocker : imageList.GreenLocker;
        }
        return imageList.InstaboxLocker;
    }, [boxOrder, countryCode, color]);
    return (_jsx(ModalPresenterContainer, { id: "joint-lockers", isOpen: isOpen, children: _jsx("div", { className: css(styles.modal, inlineStyles({ height: windowHeight })), children: _jsxs("div", { className: css(styles.content, inlineStyles({
                    paddingTop: isDenmark ? '0px !important' : Space['2xl'],
                })), children: [_jsxs("div", { className: css(styles.header), children: [countryCode !== countries.DENMARK && (_jsxs("span", { className: css(styles.headerText), children: ["Budbee", _jsx("span", { role: "img", "aria-label": "heart", className: css(styles.heartIcon), children: "\u2764\uFE0F" }), "Instabox"] })), _jsx("button", { type: "button", className: css(styles.closeButton), onClick: onClose, children: _jsx(Icon, { src: imageList.Close, size: Space.lg }) })] }), _jsx("span", { className: css(styles.heading), children: text }), subtitle ? _jsx("span", { className: css(styles.text), children: subtitle }) : null, _jsx("img", { className: css(styles.lockerImage), src: boxImage, alt: "" }), _jsx(BaseButton, { label: t('jointLocker.understood'), onClick: onClose, size: "md", variant: "dark", style: styles.button })] }) }) }));
};
