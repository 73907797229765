import { StyleSheet } from '@css';
import { Color, Spacing, BorderRadius, FontSize, FontWeight, paddingHorizontal, paddingVertical, } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        cursor: 'pointer',
        backgroundColor: Color.Black,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: 0,
        borderRadius: BorderRadius.sm,
        ...paddingVertical(Spacing.xs),
        width: '100%',
    },
    small: {
        ...paddingHorizontal(Spacing.xl),
        minHeight: Spacing['2xl'],
        fontSize: FontSize.xs,
    },
    large: {
        ...paddingHorizontal(Spacing['4xl']),
        minHeight: Spacing['6xl'],
        fontSize: FontSize.md,
    },
    disabled: {
        backgroundColor: Color.DisabledButton,
        cursor: 'default',
    },
    discrete: {
        width: 'unset',
        backgroundColor: 'transparent',
        border: 0,
        ...paddingHorizontal(0),
        ...paddingVertical(0),
    },
    title: {
        color: Color.White,
        fontWeight: FontWeight.Medium,
    },
    titleDiscrete: {
        color: Color.Black,
    },
});
