import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { OrderHero } from '../order-hero';
import { View } from '../shared/view';
import { styles } from '../box-header/style';
export const PickedupBoxHeader = ({ authenticated, returns, toggleReturnsModal, }) => {
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx(OrderHero, { topText: t('helpUsGetBetter'), middleText: t('pickedup'), bottomText: t('reviewModal.addReviewOfservice') }), authenticated ? (_jsx(View, { style: styles.actionsWrapper, children: (returns === null || returns === void 0 ? void 0 : returns.enabled) ? (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: "primary" }) })) : null })) : null] }));
};
