import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useIsOrderFailed } from '../../../hooks/use-is-order-failed';
import imageList from '../../../utils/image-list';
import { Icon } from '../../shared/icon';
import { View } from '../../shared/view';
import { styles } from './style';
export const BudbeeOrderHero = (props) => {
    const { topText, middleText, bottomText, badgeText } = props;
    const isFailed = useIsOrderFailed();
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.textContainer, children: [topText ? _jsx("p", { className: css(styles.topText), children: topText }) : null, middleText ? _jsx("p", { className: css(styles.middleText), children: middleText }) : null, badgeText ? (_jsx(View, { style: styles.badge, children: _jsx("p", { className: css(styles.badgeText), children: badgeText }) })) : null, bottomText ? _jsx("p", { className: css(styles.bottomText), children: bottomText }) : null] }), _jsx(View, { style: styles.icon, children: _jsx(Icon, { size: 160, src: isFailed ? imageList.ParallelHexagonsRed : imageList.ParallelHexagons }) })] }));
};
