import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { marginVertical, paddingHorizontal, paddingVertical, Transition } from '../../styles';
export const styles = StyleSheet.create({
    codeContainer: {
        transition: Transition.Ease,
        ...marginVertical(Space.lg),
        porterbuddy: {
            backgroundColor: Color.White100,
            paddingTop: Space.xl,
            paddingBottom: Space.sm,
            marginBottom: Space['2xl'],
            marginTop: 0,
            ...paddingHorizontal(Space.lg),
        },
    },
    pinTitle: {
        ...Typography.Budbee.Web.h3,
        marginLeft: Space.sm,
        marginBottom: Space.lg,
        transition: Transition.Ease,
        porterbuddy: {
            paddingBottom: Space.sm,
            backgroundColor: Color.White100,
            ...paddingHorizontal(Space.lg),
            marginBottom: 0,
            marginLeft: -Space.sm,
        },
    },
    homeParcelJourney: {
        transition: Transition.Ease,
        ...marginVertical(Space.lg),
        porterbuddy: {
            backgroundColor: Color.White100,
            paddingTop: Space.sm,
            paddingBottom: Space.lg,
            marginBottom: 0,
            ...paddingHorizontal(Space.lg),
        },
    },
    location: {
        transition: Transition.Ease,
        ...marginVertical(Space.lg),
        porterbuddy: {
            backgroundColor: Color.Porterbuddy.BuddyPurple,
            paddingTop: Space.sm,
            paddingBottom: Space.lg,
            ...marginVertical(0),
            ...paddingHorizontal(Space.lg),
        },
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: Transition.Ease,
        ...paddingHorizontal(Space.sm),
        ...paddingVertical(Space.lg),
        porterbuddy: {
            ...paddingHorizontal(Space.lg),
            background: Color.Porterbuddy.PalPurple,
        },
    },
    divider: {
        height: Space.md,
    },
});
