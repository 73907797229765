import { Border, Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { border, BorderWidth, ButtonEffect, FontSize, MediaQuery, paddingHorizontal, paddingVertical, Spacing, Transition, } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        ...paddingVertical(Space.xl),
    },
    rootError: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    selectable: {
        ...ButtonEffect.Enabled,
    },
    lockersList: {
        // Accounts for:
        //  * margins of .modal-wrapper: 60px
        //  * maxHeight LockerPickerHeader.styles.styles 90px
        //  * modal content marginBottom 10px and padding 50px,
        //  * height .locker-picker-controls 100px
        //  * padding of .lockers-list-container padding: 40px
        minHeight: 'calc(100vh - 350px)',
        display: 'flex',
        flexDirection: 'column',
    },
    lockerListItem: {
        width: '100%',
        marginBottom: Space.md,
    },
    permissionsAlertContainer: {
        marginTop: Spacing.lg,
    },
    horizontalSwitchContainer: {
        marginTop: Spacing.lg,
    },
    controlsContainer: {
        alignItems: 'center',
    },
    lockerPickerControls: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 1,
        backgroundColor: 'transparent',
        width: '100%',
    },
    calloutWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 345,
    },
    lockerCalloutItem: {
        backgroundColor: Color.White100,
        padding: Spacing.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        border: 'none',
        borderRadius: Border.Radius.sm,
        ...border(BorderWidth.sm, 'solid', Color.Grey100, 'bottom'),
    },
    lockerCalloutItemLast: {
        border: 'none',
    },
    calloutItemWrapper: {
        minHeight: Spacing['6xl'],
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    calloutItemContainer: {
        flexGrow: 1,
        ...paddingVertical(0),
        alignItems: 'flex-start',
        fontSize: FontSize['3xs'],
    },
    calloutItemText: {
        ...Typography.Budbee.Web['body-md'],
        margin: 0,
        color: Color.Budbee.Grey4,
        marginBottom: Space.sm,
    },
    lockerName: {
        ...Typography.Budbee.Web.h4,
        marginTop: 0,
        marginBottom: Space.sm,
    },
    row: {
        flexDirection: 'row',
    },
    label: {
        ...Typography.Budbee.Web['body-md'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Border.Radius.sm,
        marginRight: Space.sm,
        marginTop: Space['2xs'],
        marginBottom: 0,
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
    },
    currentLockerLabel: {
        backgroundColor: Color.Budbee.Grey1,
        color: Color.Budbee.Grey4,
    },
    unavailableLockerLabel: {
        background: Color.Budbee.LightPink,
        color: Color.Budbee.ReadyRed,
    },
    lockerOpeningHours: {
        ...Typography.Budbee.Web['body-md'],
        backgroundColor: Color.Budbee.GreenLight,
        ...paddingHorizontal(Space.md),
        ...paddingVertical(Space.sm),
        borderRadius: Border.Radius.sm,
        color: Color.Budbee.GreenDark,
        marginTop: Space['2xs'],
        marginBottom: 0,
    },
    lockerClosingHours: {
        backgroundColor: Color.Budbee.LightPink,
        color: Color.Budbee.ReadyRed,
    },
    lockerPickerMap: {
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 325px)',
        marginTop: Spacing.md,
        [MediaQuery.maxWidthMd]: {
            height: 'calc(100vh - 265px)',
        },
    },
    hidden: {
        display: 'none',
        height: 0,
        marginTop: 0,
    },
    boxTrackingMap: {
        transition: Transition.Ease,
        flexBasis: '100%',
        display: 'flex',
    },
    error: {
        padding: Space['2xl'],
    },
});
