import { Space, Color, Typography, Border } from '@budbee/js/ui';
import { StyleSheet } from 'aphrodite';
import { ButtonEffect, FontWeight } from '../../../styles';
export const styles = StyleSheet.create({
    root: {
        borderRadius: Border.Radius.xs,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        ...ButtonEffect.Enabled,
        position: 'relative',
    },
    primaryRoot: {
        backgroundColor: Color.Porterbuddy.BuddyPurple,
    },
    secondaryRoot: {
        border: 'solid',
        borderWidth: Border.Width.sm,
        borderColor: Color.Porterbuddy.BuddyPurple,
        backgroundColor: Color.Porterbuddy.ChumPurple,
    },
    lightRoot: {
        border: 'solid',
        borderWidth: Border.Width.sm,
        borderColor: Color.White100,
        backgroundColor: 'transparent',
    },
    disabled: {
        ...ButtonEffect.Disabled,
        cursor: 'not-allowed',
        opacity: 0.5,
    },
    lg: {
        ...Typography.Porterbuddy.Web.h3,
        fontSize: 20,
        fontWeight: FontWeight.Medium,
    },
    md: {
        ...Typography.Porterbuddy.Web.h4,
        fontWeight: FontWeight.Medium,
    },
    sm: {
        ...Typography.Porterbuddy.Web.h5,
        fontWeight: FontWeight.Medium,
    },
    whiteText: {
        color: Color.White100,
    },
    purpleText: {
        color: Color.Porterbuddy.BuddyPurple,
    },
    iconleft: {
        position: 'absolute',
        left: Space.lg,
    },
    iconRight: {
        position: 'absolute',
        right: Space.lg,
    },
});
