import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { preface, PrefaceSet } from '@budbee/js/tracking';
import { css } from '@css';
import { EstatePicker } from '../../../components/estate-picker';
import { TextInput } from '../../../components/shared/text-input';
import { useI18n } from '../../../hooks/use-i18n';
import { useConspectus } from '../../../state/order/hooks';
import { ApartmentSettings } from '../../apartment-settings';
import { View } from '../../shared/view';
import { useAccessSettings } from './hooks/use-access-settings';
import { styles } from './style';
export const AccessSettings = (props) => {
    const { t } = useI18n();
    const conspectus = useConspectus();
    const { values, handleChange } = useAccessSettings(props);
    if (!conspectus) {
        return null;
    }
    const { estateType, instructions, ...apartmentSettings } = values;
    const isVisible = !!estateType && preface.isApartment(estateType);
    return (_jsxs(View, { style: styles.root, children: [_jsx(EstatePicker, { currentEstateType: estateType, onChange: handleChange('SET_ESTATE_TYPE') }), _jsx(ApartmentSettings, { isVisible: isVisible, intercomFirst: conspectus.deliveryOptions.flags.includes(PrefaceSet.INTERCOM_OVER_DOOR_CODE), ...apartmentSettings, onChangeFloor: handleChange('SET_FLOOR'), onChangeAccessMode: handleChange('SET_ACCESS_MODE'), onChangeDoorCode: handleChange('SET_DOOR_CODE'), onChangeIntercom: handleChange('SET_INTERCOM'), onChangeCareOf: handleChange('SET_CARE_OF') }), estateType ? (_jsxs(View, { children: [_jsx("p", { className: css(styles.title), children: t('accessSettings.specialInstructions.title') }), _jsx(TextInput, { contentContainerStyle: styles.inputContentContainer, style: styles.specialInstructions, maxChars: 160, value: instructions, placeholder: t('accessSettings.specialInstructions.placeholder'), onChange: (value) => handleChange('SET_INSTRUCTIONS')(typeof value === 'string' ? value : ''), rows: 1 })] })) : null] }));
};
