import { useSelector } from 'react-redux';
import { getBoxCheckpoints, getBoxCode, getBoxNeedsVerification, getBoxNudges, getBoxOrder, getBoxOrderAuthorized, getBoxOrderConsignment, getBoxOrderStatus, getBoxSystem, getConsumerAddressBox, getIsBoxReturn, getIsFetchingBoxOrder, getLockerAttributes, } from './selectors';
export function useBoxOrder() {
    return useSelector(getBoxOrder);
}
export function useIsFetchingBoxOrder() {
    return useSelector(getIsFetchingBoxOrder);
}
export function useBoxOrderStatus() {
    return useSelector(getBoxOrderStatus);
}
export function useBoxOrderConsignment() {
    return useSelector(getBoxOrderConsignment);
}
export function useBoxOrderAuthorized() {
    return useSelector(getBoxOrderAuthorized);
}
export function useLockerAttributes() {
    return useSelector(getLockerAttributes);
}
export function useConsumerAddressBox() {
    return useSelector(getConsumerAddressBox);
}
export function useBoxCheckpoints() {
    return useSelector(getBoxCheckpoints);
}
export function useBoxNudges() {
    return useSelector(getBoxNudges);
}
export function useBoxCode() {
    return useSelector(getBoxCode);
}
export function useBoxNeedsVerification() {
    return useSelector(getBoxNeedsVerification);
}
export function useIsBoxReturn() {
    return useSelector(getIsBoxReturn);
}
export function useBoxSystem() {
    return useSelector(getBoxSystem);
}
