import { connect } from 'react-redux';
import { LockerPickerModal } from '../../../components/modal/locker-picker';
import { setIsPickingFromMap, requestLockers, requestLockersSuccess, requestLockersError, resetLockerSearchState, } from '../../../state/lockers/actions';
import { setSwitchDeliveryTypePrice } from '../../../state/order/actions';
import { getSelectedLocker, getToken } from '../../../state/order/selectors';
import { setProduct, resetPaymentState } from '../../../state/payment/actions';
const mapStateToProps = (state) => ({
    selectedLocker: getSelectedLocker(state),
    token: getToken(state),
});
const mapDispatchToProps = (dispatch) => ({
    setIsPickingFromMap: (isPickingFromMap) => dispatch(setIsPickingFromMap(isPickingFromMap)),
    requestLockers: () => dispatch(requestLockers()),
    requestLockersSuccess: (lockers) => dispatch(requestLockersSuccess(lockers)),
    requestLockersError: () => dispatch(requestLockersError()),
    setSwitchDeliveryTypePrice: (payload) => dispatch(setSwitchDeliveryTypePrice(payload)),
    setProduct: (payload) => dispatch(setProduct(payload)),
    resetPaymentState: () => dispatch(resetPaymentState()),
    resetLockerSearchState: () => dispatch(resetLockerSearchState()),
});
export default connect(mapStateToProps, mapDispatchToProps)(LockerPickerModal);
