import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ButtonEffect, paddingHorizontal } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: Space.lg + Space['3xs'],
        porterbuddy: {
            ...paddingHorizontal(Space.lg),
        },
        instabox: {
            ...paddingHorizontal(Space.lg),
        },
    },
    title: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.GreenDark,
        textAlign: 'center',
    },
    titleRed: {
        ...Typography.Budbee.Web.h5,
        color: Color.Budbee.ReadyRed,
        textAlign: 'center',
    },
    supportButton: {
        ...ButtonEffect.Enabled,
    },
    supportButtonDisabled: {
        ...ButtonEffect.Disabled,
        opacity: 0,
    },
    logo: {
        width: 80,
    },
    logoWide: {
        width: 120,
    },
});
