import { Color, Space, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
import { ScreenSizes } from '../../styles';
export const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: Color.Budbee.Grey1,
    },
    divider: {
        height: Space['2xl'],
    },
    container: {
        maxWidth: ScreenSizes.sm,
        padding: Space['2xl'],
    },
    title: {
        ...Typography.Budbee.Web.h1,
        textAlign: 'center',
        instabox: {
            ...Typography.Instabox.Web.h1,
            marginBottom: Space.xs,
            color: Color.Instabox.GreyGray,
        },
    },
    subtitle: {
        ...Typography.Budbee.Web['body-lg'],
        textAlign: 'center',
        instabox: {
            ...Typography.Instabox.Web.h3,
        },
    },
});
