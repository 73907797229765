import { jsx as _jsx } from "react/jsx-runtime";
import { Color, Space } from '@budbee/js/ui';
import { css, StyleSheet } from '@css';
import { ButtonEffect, IconSize, marginHorizontal, MediaQuery } from '../../../styles';
import { BrandedIcon } from '../../../utils/brand/icon';
import imageList from '../../../utils/image-list';
export const styles = StyleSheet.create({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        ...marginHorizontal(Space['6xl']),
        [MediaQuery.minWidthMd]: {
            ...marginHorizontal(80),
        },
    },
    icon: {
        background: 'none',
        border: 0,
        padding: 0,
        width: IconSize['9xl'],
        height: IconSize['9xl'],
        ...ButtonEffect.Enabled,
    },
    disabled: {
        cursor: 'not-allowed',
    },
});
const AMOUNT_OF_STARS = 5;
const toStar = (index, filled, onClick, disabled) => (_jsx("button", { type: "button", onClick: () => onClick(index), className: css([styles.icon, disabled ? styles.disabled : undefined]), "data-test": "star-icon", disabled: disabled, children: _jsx(BrandedIcon, { src: filled ? imageList.StarFilled : imageList.Star, color: {
            budbee: Color.Budbee.GreenDark,
            instabox: Color.Instabox.PrisonPink,
        }, size: IconSize['9xl'] }) }, index));
const generateStars = (rating, onRating, disabled) => {
    const stars = [];
    for (let i = 1; i <= AMOUNT_OF_STARS; i += 1) {
        stars[i] = toStar(i, i <= rating, onRating, disabled);
    }
    return stars;
};
export const StarRating = (props) => {
    const { score, onChangeScore, disabled } = props;
    return (_jsx("div", { className: css(styles.root), children: _jsx("div", { className: css(styles.container), children: generateStars(score, onChangeScore, disabled) }) }));
};
