import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from '@css';
import { useI18n } from '../../../hooks/use-i18n';
import { View } from '../../shared/view';
import { styles } from './style';
export const CustomerSupportHeader = (props) => {
    const { merchantName, supportEmail, supportPhone, supportLink } = props;
    const { t } = useI18n();
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: css(styles.title), children: t('helpModal.merchantSupport', {
                    merchant: merchantName,
                }) }), _jsxs(View, { style: styles.container, children: [_jsx("span", { className: css(styles.subtitle), children: t('helpModal.instructions.boxOrder', {
                            merchant: merchantName,
                        }) }), supportEmail && (_jsxs("a", { className: css(styles.linkView), href: `mailto:${supportEmail}`, children: [_jsx("span", { className: css(styles.merchantLinkText), children: t('email') }), _jsx("span", { className: css(styles.link), children: supportEmail })] })), supportPhone && (_jsxs("a", { className: css(styles.linkView), href: `tel:${supportPhone}`, children: [_jsx("span", { className: css(styles.merchantLinkText), children: t('phone') }), _jsx("span", { className: css(styles.link), children: supportPhone })] })), supportLink && (_jsxs("a", { className: css(styles.linkView), target: "_blank", rel: "noopener noreferrer", href: supportLink, children: [_jsx("span", { className: css(styles.merchantLinkText), children: t('website') }), _jsx("span", { className: css(styles.link), children: supportLink })] }))] })] }));
};
