import { jsx as _jsx } from "react/jsx-runtime";
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import 'react-image-lightbox/style.css';
import '@babel/polyfill';
import './less/app.less';
import 'mapbox-gl/dist/mapbox-gl.css';
import { App } from './app';
import { envConfig } from './utils/env.config';
import { printtReleaseId } from './utils/print-release-id';
import { setupBannerMetaTags } from './utils/setup/setup-banner-meta-tags';
import { setupBrands } from './utils/setup/setup-brands';
import { setupModalRoot } from './utils/setup/setup-modal-root';
import { setupSentry } from './utils/setup/setup-sentry';
printtReleaseId();
if (envConfig.MAPBOX_ACCESS_TOKEN) {
    mapboxgl.accessToken = envConfig.MAPBOX_ACCESS_TOKEN;
}
const container = document.getElementById('root');
setupModalRoot(container);
setupBannerMetaTags();
setupBrands();
setupSentry();
const render = (Component) => {
    const root = createRoot(container);
    root.render(_jsx(Component, {}));
};
render(App);
