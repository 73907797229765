import store from 'store/dist/store.modern';
export function getItem(key) {
    if (!store || !store.enabled) {
        return null;
    }
    const val = store.get(key);
    if (val === undefined) {
        return null;
    }
    return val;
}
export function setItem(key, value) {
    if (!store || !store.enabled) {
        return;
    }
    store.set(key, value);
}
export function removeItem(key) {
    if (!store || !store.enabled) {
        return;
    }
    store.remove(key);
}
