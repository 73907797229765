import { Color, Typography } from '@budbee/js/ui';
import { StyleSheet } from '@css';
export const styles = StyleSheet.create({
    root: {
        ...Typography.Budbee.Web['badge-sm'],
        color: Color.Budbee.Grey4,
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        porterbuddy: {
            ...Typography.Porterbuddy.Web.h6,
            color: Color.Porterbuddy.BuddyPurple,
        },
        instabox: {
            ...Typography.Instabox.Web.h5,
            color: Color.Instabox.GreyGray,
        },
    },
});
